import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Button, Space, message } from "antd";
import CommonCompbar from "../../../Components/BaseLayout";
import ScrollBar from "../../../Components/scroll/scroll";
import "./index.scss";
import QuestTable from "./Section_A/sec_a";
import SectionB from "./Section_B";
import SectionCP1 from "./Section_C_P1";
import SectionCP2 from "./Section_C_P2";
import SectionCP3 from "./Section_C_P3";
import SectionCP4 from "./Section_C_P4";
import SectionCP5 from "./Section_C_P5";
import SectionCP6 from "./Section_C_P6";
import SectionCP7 from "./Section_C_P7";
import SectionCP8 from "./Section_C_P8";
import SectionCP9 from "./Section_C_P9";
import SectionD from "./Section_D";
import SectionE from "./Section_E";
import FinalSubmit from "./FinalSubmit";
import { useFinancialYear } from "../../../hooks/useFinancialYear";
import { useCustomer } from "../../../hooks/useCustomer";
//import { fetchCustomer } from "../Customer-Details/dashboardSlice";
import { messageAlert } from "../../../Components/Alert";
import {
  //get_comments_summary,
  questionaireReviewedSubmitSlice,
  generateTempReportSubmitSlice,
} from "./SectionSlice/sectionSlice";

let questComp = "";
export const Main = ({ key, cust_id }) => {
  const location = useLocation();
  const { current_fy } = useFinancialYear();
  const {
    notificationData,
    assigned_template_id,
    setAssignedTemplateId,
    setQuestionaire_status,
  } = useCustomer();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState("");
  const [titlehead, setTitlehead] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [pagetitle, setPagetitle] = useState("");
  const [subpagetitle, setSubPagetitle] = useState("");
  const [customer_qstatus, setCustomer_QStatus] = useState({});
  const [customer, setCustomer] = useState(location?.state || "");
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );

  useEffect(() => {
    getlandindDetails();
  }, []);

  const getlandindDetails = () => {
    setSubtitle("");
    setCurrent(customer.key);
    if (customer.cust_id === "") {
      if (sessionState?.roletype === "AUDITOR") {
        if (customer.key === "finalsubmit") {
          setTitlehead("");
          setSubtitle("");
          return (questComp = <FinalSubmit customer_id={customer.cust_id} />);
        } else {
          questComp = "";
        }
      }
    }
    if (customer.key === "sectiona") {
      setTitlehead("Basic Information: GENERAL INFORMATION ABOUT THE COMPANY");
      return (questComp = <QuestTable customer_id={customer.cust_id} />);
    }
    if (customer.key === "sectionb") {
      setTitlehead("Policies and Guidelines");
      setSubtitle(`This section is aimed at helping businesses demonstrate the structures, policies and processes put in place towards adopting the NGRBC principles and core elements
      `);
      return (questComp = <SectionB customer_id={customer.cust_id} />);
    }
    if (customer.key === "sectionc") {
      setTitlehead("Compliance: PRINCIPLE WISE PERFORMANCE DISCLOSURE	");
      setSubtitle(
        `This section is aimed at helping entities demonstrate their performance in integrating the principles and core elements with key processes and decisions. The information is sought is categorized as "essential" and "leadership". Essential indicators are expected to be disclosed by everyentity that is mandated to file this report, the leadership indicators may be voluntarily disclosed by entities which aspire to progress toa higher level in their quest to be socially, environmentally and ethically responsible.`
      );
      setPagetitle("PRINCIPLE 1 ");
      setSubPagetitle(
        `Businesses should conduct and govern themselves with integrity, and in a manner that is Ethical, Transparent and Accountable.`
      );
      return (questComp = <SectionCP1 customer_id={customer.cust_id} />);
    }
    if (customer.key === "sectiond") {
      setTitlehead(
        "PRINCIPLE 2 Businesses should provide goods and services in a manner that is sustainable and safe"
      );
      return (questComp = <SectionCP2 customer_id={customer.cust_id} />);
    }
    if (customer.key === "sectionep1") {
      setTitlehead("PRINCIPLE 3");
      setSubtitle(
        "Businesses should respect and promote the well-being of all employees, including those in their value chains"
      );
      setPagetitle("Priciple 1");
      return (questComp = <SectionCP3 customer_id={customer.cust_id} />);
    }
    if (customer.key === "sectionep2") {
      setTitlehead("PRINCIPLE 4:");
      setSubtitle(
        "Businesses should respect the interests of and be responsive to all its stakeholders"
      );
      return (questComp = <SectionCP4 customer_id={customer.cust_id} />);
    }
    if (customer.key === "sectionep3") {
      setTitlehead(
        "PRINCIPLE 5 Businesses should respect and promote human rights"
      );
      return (questComp = <SectionCP5 customer_id={customer.cust_id} />);
    }
    if (customer.key === "sectionep4") {
      setTitlehead(
        "PRINCIPLE 6: Businesses should respect and make efforts to protect and restore the environment"
      );
      return (questComp = <SectionCP6 customer_id={customer.cust_id} />);
    }
    if (customer.key === "C") {
      setTitlehead("Risk mitigation: Principlewise performance disclosure");
      setSubtitle("This section is aimed at helping businesses demonstrate .");
      return (questComp = <SectionCP6 customer_id={customer.cust_id} />);
    }
    if (customer.key === "sectionep5") {
      setTitlehead("BRSR Core");
      return (questComp = <SectionD customer_id={customer.cust_id} />);
    }
    if (customer.key === "sectionep6") {
      setTitlehead("How can you mitigate these risks?	");
      return (questComp = <SectionE customer_id={customer.cust_id} />);
    } else if (customer.key === "sectionep7") {
      setTitlehead("PRINCIPLE 7");
      setSubtitle(
        "Businesses, when engaging in influencing public and regulatory policy, should do so in a manner that is responsible and transparent."
      );
      return (questComp = <SectionCP7 customer_id={customer.cust_id} />);
    } else if (customer.key === "sectionep8") {
      setTitlehead("PRINCIPLE 8");
      setSubtitle(
        "Businesses should promote inclusive growth and equitable development."
      );
      return (questComp = <SectionCP8 customer_id={customer.cust_id} />);
    } else if (customer.key === "sectionep9") {
      setTitlehead("SECTION-9");
      setSubtitle(
        "Businesses should engage with and provide value to their consumers in a responsible manner"
      );
      return (questComp = <SectionCP9 customer_id={customer.cust_id} />);
    } else if (customer.key === "finalsubmit") {
      setTitlehead("");
      return (questComp = <FinalSubmit customer_id={customer.cust_id} />);
    }
    setTitlehead("DEVELOPEMENT IN PROGRESS  ... !");
    questComp = "";
  };

  const handleSubmitReview = () => {
    if (customer.cust_id === "") {
      return messageAlert({
        title: "Customer",
        msg: "Please select the Customer first!..",
      });
    }
    if (notificationData.total_notifications > 0) {
      return message.info(
        "Please Review the Comments first: " +
          notificationData.total_notifications
      );
    }
    let Payload = {
      customer_id: customer.cust_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      status:'Reviewed'
    };
    dispatch(questionaireReviewedSubmitSlice({ ...Payload }))
      .unwrap()
      .then((res) => {
        if (res?.data?.code === 0) {
          message.info("Submitted succesfully!");
        } else {
          message.info(res?.data?.msg);
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const handleGenerateDraftReport = () => {
    if (customer.cust_id === "") {
      return messageAlert({
        title: "Customer",
        msg: "Please select the Customer first!..",
      });
    }

    let Payload = {
      customer_id: customer.cust_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
    };
    dispatch(generateTempReportSubmitSlice({ ...Payload }))
      .unwrap()
      .then((res) => {
        if (res?.data?.code === 0) {
          message.info("Submitted succesfully!");
        } else {
          message.info(res?.data?.msg);
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };
  return (
    <CommonCompbar sidebar={false}>
      <div className="main-title-header">
        <div className="main-title-header">
          <div className="table-title-header-top">
            <div>
              <div>{titlehead}</div>
              <span>{subtitle}</span>
            </div>
            <Button type="link" href="/questionaire">
              Go Back
            </Button>
          </div>
        </div>
      </div>
      <ScrollBar>
        <div style={{ marginBottom: "0px", marginRight: "10px" }}>
          {questComp}
        </div>
      </ScrollBar>
      <Space
        style={{
          marginTop: "10px",
          justifyContent: "right",
          width: "100%",
        }}
      >
        {sessionState?.roletype === "CUSTOMER" ||
        sessionState?.roletype === "ADMIN" ||
        customer_qstatus === "Reviewed" ? (
          ""
        ) : (
          <Button style={{ marginLeft: 10 }} onClick={handleSubmitReview}>
            Approve
          </Button>
        )}
        {sessionState?.roletype === "AUDITOR" ? (
          <Button
            style={{ marginLeft: 10 }}
            onClick={handleGenerateDraftReport}
          >
            Generate Draft Report
          </Button>
        ) : (
          ""
        )}
      </Space>
    </CommonCompbar>
  );
};
