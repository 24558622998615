import React, { useEffect } from "react";
import { Row, Col, Form, Input } from "antd";

const CP3_1C = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div id="tbl-cell">
      <Row>
        <Col span={8}>
          <span style={{ fontSize: "12px", fontWeight: "600" }}></span>
        </Col>
        <Col span={8} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            FY :{props?.data?.current_fy} Current Financial Year
          </span>
        </Col>
        <Col span={8} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            FY :{props?.data?.previous_fy} Previous Financial Year
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={8} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            Cost incurred on wellbeing measures as a % of total revenue of the
            company
          </span>
        </Col>
        <Col span={8}>
          <Form.Item name={[props.ques_id, "r1c1"]}>
            <Input readOnly={props.readonly} type="number" min="0" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={[props.ques_id, "r1c2"]}>
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default CP3_1C;
