import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Table, Tooltip, Space, Button } from "antd";
import CommonCompbar from "../../Components/BaseLayout";
import { getReportsList, putReportStatus } from "./reportSlice/reportSlice";
import Loader from "../../Components/loader";
import { BASE_API_URL, urlReports } from "../../Config/url";

const Reports = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [report_data, setReportsData] = useState([]);

  const ReportsList = () => {
    setLoader(true);
    dispatch(getReportsList())
      .unwrap()
      .then((res) => {
        setLoader(false);
        if (res.code === 0) {
          setReportsData(res.data);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  const ReportStatusUpdate = (report_id) => {
    setLoader(true);
    dispatch(putReportStatus({ report_id: report_id }))
      .unwrap()
      .then((res) => {
        setLoader(false);
        dispatch(getReportsList())
        .unwrap()
        .then((res) => {
          setLoader(false);
          if (res.code === 0) {
            setReportsData(res.data);
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        });
        
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  const columns = [
    {
      title: "Sl No.",
      dataIndex: "key",
      key: "key",
      render: (text, record, index) => index + 1,
      width: 150,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      ellipsis: {
        showTitle: false,
      },
      render: (customer_name) => (
        <Tooltip placement="topLeft" title={customer_name}>
          {customer_name}
        </Tooltip>
      ),
    },
    {
      title: "Financial Year",
      dataIndex: "financial_year",
      key: "financial_year",
      ellipsis: {
        showTitle: false,
      },
      render: (financial_year) => (
        <Tooltip placement="topLeft" title={financial_year}>
          {financial_year}
        </Tooltip>
      ),
    },
    {
      title: "Reviewed By",
      dataIndex: "reviewed_by",
      key: "reviewed_by",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "Created On",
      dataIndex: "created_date",
      key: "created_date",
      ellipsis: {
        showTitle: false,
      },
      render: (created_date) => (
        <Tooltip placement="topLeft" title={created_date}>
          {created_date}
        </Tooltip>
      ),
    },
    {
      title: "Download Count",
      dataIndex: "download_count",
      key: "download_count",
      ellipsis: {
        showTitle: false,
      },
      render: (download_count) => (
        <Tooltip placement="topLeft" title={download_count}>
          {download_count}
        </Tooltip>
      ),
    },
    {
      title: "Refresh Count",
      dataIndex: "refresh_count",
      key: "refresh_count",
      ellipsis: {
        showTitle: false,
      },
      render: (refresh_count) => (
        <Tooltip placement="topLeft" title={refresh_count}>
          {refresh_count}
        </Tooltip>
      ),
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (text, record, index) =>
        text === "Completed" ? (
          <Button
            className="ant-btn-download"
            type="link"
            href={`${BASE_API_URL}${urlReports}/download?report_id=${record.id}`}
            target="_blank"
            style={{ display: "block" }}
          >
            Download
          </Button>
        ) : text === "Ready To Generate" ? (
          <Button
            className="ant-btn-download"
            onClick={() => ReportStatusUpdate(parseInt(record.id))}
            style={{ display: "block" }}
          >
            Ready To Generate
          </Button>
        ) : (
          <span>{text}</span>
        ),
      width: 150,
    },
  ];

  useEffect(() => {
    ReportsList();
  }, []);
  return (
    <CommonCompbar sidebar={false}>
      <div className="formcontainer">
        <div className="table-title-header-top">
          <h2>All Reports</h2>
        </div>
        <Space>
          <Table
            columns={columns}
            bordered
            pagination={false}
            dataSource={report_data}
            className="f-table-wrapper knowldge-action"
            scroll={{
              y: 240,
            }}
          />
        </Space>
      </div>
      <Loader flag={loader} />
    </CommonCompbar>
  );
};

export default Reports;
