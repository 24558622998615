import React, { useEffect } from "react";
import { Row, Col, Form, Input, Select } from "antd";

const T3 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div>
      <Form.Item label="CP9-3" className="title-header">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <span style={{ fontSize: "12px", fontWeight: "700" }}>
              {`Number of consumer complaints in respect of the following:`}
            </span>
            <div id="tbl-cell">
              <Row>
                <Col span={4}>
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`Details of complaint`}
                  </span>
                </Col>
                <Col span={8} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    FY {props?.data?.current_fy} (Current)
                  </span>
                </Col>
                <Col span={2}>
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`Remarks`}
                  </span>
                </Col>
                <Col span={8} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    FY {props?.data?.previous_fy} (Previous)
                  </span>
                </Col>
                <Col span={2}>
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`Remarks`}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={4} offset={4}>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    {`Received during the year`}
                  </span>
                </Col>
                <Col span={4} offset={0}>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    {`Pending resolution at end of year`}
                  </span>
                </Col>
                <Col span={2} offset={0}>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    {`Remarks`}
                  </span>
                </Col>
                <Col span={4} offset={0}>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    {`Received during the year`}
                  </span>
                </Col>
                <Col span={4} offset={0}>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    {`Pending resolution at end of year`}
                  </span>
                </Col>
                <Col span={2} offset={0}>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    {`Remarks`}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col span={4}>
                  <span className="ant-col-header">Advertising</span>
                </Col>

                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r2c1"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r2c2"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r2c3"]}>
                    <Input readOnly={props.readonly} size="small" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r2c4"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r2c5"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r2c6"]}>
                    <Input readOnly={props.readonly} size="small" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <span className="ant-col-header">Cyber-security</span>
                </Col>

                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r3c1"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r3c2"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r3c3"]}>
                    <Input readOnly={props.readonly} size="small" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r3c4"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r3c5"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r3c6"]}>
                    <Input readOnly={props.readonly} size="small" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <span className="ant-col-header">
                    Delivery of essential services
                  </span>
                </Col>

                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r4c1"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r4c2"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r4c3"]}>
                    <Input readOnly={props.readonly} size="small" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r4c4"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r4c5"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r4c6"]}>
                    <Input readOnly={props.readonly} size="small" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <span className="ant-col-header">
                    Restrictive Trade Practices
                  </span>
                </Col>

                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r5c1"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r5c2"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r5c3"]}>
                    <Input readOnly={props.readonly} size="small" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r5c4"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r5c5"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r5c6"]}>
                    <Input readOnly={props.readonly} size="small" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <span className="ant-col-header">Unfair Trade Practices</span>
                </Col>

                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r6c1"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r6c2"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r6c3"]}>
                    <Input readOnly={props.readonly} size="small" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r6c4"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r6c5"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r6c6"]}>
                    <Input readOnly={props.readonly} size="small" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <span className="ant-col-header">Other</span>
                </Col>

                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r7c1"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r7c2"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r7c3"]}>
                    <Input readOnly={props.readonly} size="small" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r7c4"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r7c5"]}>
                    <Input
                      readOnly={props.readonly}
                      type="number" min="0"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r7c6"]}>
                    <Input readOnly={props.readonly} size="small" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

export default T3;
