import React, { useState, useEffect } from "react";
import { Row, Col, Input, Form } from "antd";

const TableCol = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);

  return (
    <>
      <Form.Item label="CP2-8" className="title-header">
        <Row>
          <Col span={28}>
            <span style={{ fontSize: "12px", fontWeight: "700" }}>
              {`Of the products and packaging reclaimed at end of life of products, amount (in metric tonnes) reused, recycled, and safely disposed, as per the following format:`}
            </span>

            <div id="tbl-cell">
              <Row>
                <Col span={6}></Col>
                <Col span={9} className="text-center">
                  FY :{props?.data?.current_fy} Current Financial Year
                </Col>
                <Col span={9} className="text-center">
                  FY :{props?.data?.previous_fy} Previous Financial Year
                </Col>
              </Row>
              <Row>
                <Col span={3} offset={6}>
                  {`Re-Used`}
                </Col>
                <Col span={3} offset={0}>
                  {`Recycled`}
                </Col>
                <Col span={3} offset={0}>
                  {`Safely Disposed`}
                </Col>

                <Col span={3} offset={0}>
                  {`Re-Used`}
                </Col>
                <Col span={3} offset={0}>
                  {` Recycled`}
                </Col>
                <Col span={3} offset={0}>
                  {` Safely Disposed`}
                </Col>
              </Row>
              <Row>
                <Col span={6}>{`Plastics (including packaging)`}</Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r1c1"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r1c2"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r1c3"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r1c4"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r1c5"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r1c6"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={6}>{`E-waste	`}</Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r2c1"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r2c2"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r2c3"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r2c4"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r2c5"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r2c6"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>{`Hazardous waste`}</Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r3c1"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r3c2"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r3c3"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r3c4"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r3c5"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r3c6"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>{`Other waste`}</Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r4c1"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r4c2"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r4c3"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r4c4"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r4c5"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r4c6"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default TableCol;
