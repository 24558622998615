import React, { createContext, useReducer, useState } from "react";
import { initialState, AuthReducer } from "./reducer";

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();
export const CommentsContext = createContext();
export function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
}
export function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }
  return context;
}
export const AuthProvider = ({ children }) => {
  const [user, dispatch] = useReducer(AuthReducer, initialState);
  const [list, setList] = useState([]);
  const [lite, setLite] = useState(false);
  const [universe, setUniverse] = useState(false);
  const [extended, setExtended] = useState(false);

  return (
    <AuthStateContext.Provider value={{ user, list, setList }}>
      <AuthDispatchContext.Provider value={dispatch}>
        <CommentsContext.Provider
          value={{ list, setList, lite, setLite, universe, setUniverse,extended, setExtended  }}
        >
          {children}
        </CommentsContext.Provider>
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
