import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Col, Row, Input } from "antd";
import { updateTicketsSlice } from "./ticketSlice/ticketSlice";

const { TextArea } = Input;
const UpdateTicket = (props) => {
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [ticket_title, setTicketTitle] = useState(
    props.ticket_data.ticket_title
  );
  const [ticket_description, setTicketDescription] = useState(
    props.ticket_data.ticket_description
  );

  const handleUpdateOk = async () => {
    setConfirmLoading(true);
    try {
      let response = await dispatch(
        updateTicketsSlice({
          ticket_id: props.ticket_data.id,
          customer_id: props.ticket_data.customer_id,
          ticket_title,
          ticket_description,
        })
      );
      if (!response === undefined) return;
      props.setOpen(false);
      setConfirmLoading(false);
      window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateCancel = () => {
    props.setOpen(false);
  };
  return (
    <Modal
      title="Update Ticket"
      open={props.open}
      onOk={handleUpdateOk}
      confirmLoading={confirmLoading}
      onCancel={handleUpdateCancel}
    >
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <div>
            <span>Title:</span>
          </div>
        </Col>
        <Col span={14}>
          <Input
            value={ticket_title}
            onChange={(e) => setTicketTitle(e.target.value)}
          />
        </Col>
      </Row>
      <div style={{ margin: "24px 0" }} />
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <div>
            <span>Description:</span>
          </div>
        </Col>
        <Col span={14}>
          <TextArea
            onChange={(e) => setTicketDescription(e.target.value)}
            placeholder="Messages"
            autoSize={{ minRows: 3, maxRows: 5 }}
            value={ticket_description}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default UpdateTicket;
