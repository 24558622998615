import React from "react";
import { Layout, Menu } from "antd";
import "./index.scss";
import ScrollBar from "../Comments/scroll";

const { Sider } = Layout;
const Sidebar = (props) => {
  const { current, items, btnClick } = props;

  return (
    <Sider width={200} trigger={null} collapsible>
      <div style={{ height: "520px" }}>
        <ScrollBar>
          <Menu
            onClick={btnClick}
            selectedKeys={[current]}
            mode="vertical"
            items={items}
          />
        </ScrollBar>
      </div>
    </Sider>
  );
};

export default Sidebar;
