import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCustomerList, updateCustomer } from "../../../Config/actions";

const initialState = {
  loading: false,
  error: null,
  result: null,
};

const fetchCustomer = createAsyncThunk(
  "customers/fetchCustomer",
  async (userId, thunkAPI) => {
    const response = await getCustomerList();
    return response.data;
  }
);
const updateCustomerSlice = createAsyncThunk(
  "customers/updateCustomer",
  async (value) => {
    return await updateCustomer(value);
  }
);
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    fetchCustomer: (state, action) => {
      state.loading = false;
      state.result = action.payload;
    },
  },
});

const { reducer } = dashboardSlice;
export { fetchCustomer, updateCustomerSlice };
export default reducer;
