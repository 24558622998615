import React, { useEffect } from "react";
import { Tooltip, message, Dropdown, Table, Button, Menu } from "antd";
import "./dashboard.scss";
import Loader from "../../../Components/loader";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import CommonCompbar from "../../../Components/BaseLayout";
import {
  fetchCustomerReviewerUsers,
  updateReviewerUsersSlice,
} from "./reviewerSlice/reviewerSlice";
import { updateCustomerReviewerMapperSlice } from "../../../Context/commonSlice";
import EditReviewer from "./edit_reviewer";

const CustomerReviewerUsers = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [backoffice_data, setBackofficeData] = useState([]);
  const [editflag, setEditflag] = useState(false);
  const [row_data, setRowData] = useState([]);

  const navigate = useNavigate();
  const [customer_id, setCustomerId] = useState(
    location?.state?.customer_id || ""
  );
  const handleEdit = async (values) => {
    try {
      let response = await dispatch(updateReviewerUsersSlice(values));
      if (response.payload.status === 200) {
        setEditflag(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCustomerReviewerUsers = (customer_id) => {
    setLoader(true);
    dispatch(fetchCustomerReviewerUsers({ customer_id }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setLoader(false);
          setBackofficeData(res.data);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  useEffect(() => {
    getCustomerReviewerUsers(customer_id);
  }, []);
  const btncreateReviewerUser = () => {
    navigate("/createrevieweruser");
  };
  const handleButtonClick = (e) => {
    message.info("Click on left button.");
  };
  const handleMenuClick = async (e, row) => {
    if (e.key === "unassign") {
      try {
        let response = await dispatch(
          updateCustomerReviewerMapperSlice({
            customer_id: customer_id,
            is_active: false,
          })
        );

        if (!response === undefined) return;
        message.info(response?.payload?.data?.msg);
        getCustomerReviewerUsers(customer_id);
      } catch (error) {
        console.log(error);
      }
    } else {
      //navigate("/reset");
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "display_name",
      key: "display_name",
      render: (text) => <a onClick={showMsgModal}>{text}</a>,
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address ? "Active" : "Deactivated"}
        </Tooltip>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: {
        showTitle: false,
      },
      render: (custid) => (
        <Tooltip placement="topLeft" title={custid}>
          {custid}
        </Tooltip>
      ),
    },
    {
      title: "Onboard Date",
      dataIndex: "assigned",
      key: "assigned",
      ellipsis: {
        showTitle: false,
      },
      render: (assigned) => (
        <Tooltip placement="topLeft" title={assigned}>
          {assigned}
        </Tooltip>
      ),
    },
    {
      title: "Role",
      dataIndex: "role_name",
      key: "role_name",
      ellipsis: {
        showTitle: false,
      },
      render: (provison) => (
        <Tooltip placement="topLeft" title={provison}>
          {provison}
        </Tooltip>
      ),
    },
    {
      title: "Manage",
      dataIndex: "createdon",
      key: "createdon",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const menu = (
          <Menu onClick={(e) => handleMenuClick(e, record)} className="items">
            <Menu.Item key={"unassign"}>Unassign</Menu.Item>
          </Menu>
        );
        return (
          <Dropdown.Button
            className="ant-btn1"
            onClick={(e) => handleButtonClick(record)}
            overlay={menu}
          >
            Select
          </Dropdown.Button>
        );
      },
    },
  ];
  const showMsgModal = () => {};
  return (
    <div>
      
      <CommonCompbar sidebar={false}>
        <div className="formcontainer">
          <div className="table-title-header-top">
            <h2>Reviewer</h2>
            
          </div>
          <Table
            columns={columns}
            bordered
            pagination={false}
            dataSource={backoffice_data}
            className="f-table-wrapper knowldge-action"
            scroll={{
              y: 240,
            }}
          />
        </div>
      </CommonCompbar>
      
      {editflag && (
        <EditReviewer
          handleEdit={handleEdit}
          handleFlag={setEditflag}
          data={row_data}
          status={editflag}
        />
      )}
      <Loader flag={loader} />
    </div>
  );
};

export default CustomerReviewerUsers;
