import React, { useState, useEffect } from "react";
import {
  Input,
  Divider,
  Select,
  Form,
  Button,
  AutoComplete,
  DatePicker,
  message,
  Space,
} from "antd";
import CommonCompbar from "../../../../Components/BaseLayout";
import ScrollBar from "../../../../Components/scroll/scroll";
import { useDispatch } from "react-redux";
import { createcustomer } from "../../cust_Slice/customerSlice";
import { useNavigate } from "react-router-dom";
import InputNumber from "../../../../Components/Input/Number/number";
import { getTemplateSlice } from "../../../../Context/commonSlice";
import "../index.scss";

const { Option } = Select;
const CreateCustomer = () => {
  const navigate = useNavigate();
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);
  const [selected_entid, setSelectedEnt] = useState(0);
  const [form] = Form.useForm();
  const [expireson, setExpireson] = useState("");
  const [selectedyear, setSelectedyear] = useState("");
  const [templates, setTemplates] = useState([]);
  const dispatch = useDispatch();
  const onFinish = (value) => {
    value['expired_date'] = value['year-picker'].format('YYYY-MM-DD');
    value["year_of_incorporation"] = selectedyear.toString();
    dispatch(createcustomer({ ...value }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          message.info(
            'Customer created. Please assign a reviewer to this customer'
          );
          navigate("/dashboard");
        } else {
          message.info(res.msg);
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };
  
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };
  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));
  const handleCancel = () => {
    form.resetFields();
    navigate("/dashboard");
  };
  const onchangeEntity = (event) => {
    setSelectedEnt(event);
  };
  const btngetmonth = (date, dateString) => {
    // let objectDate = new Date();
    // let day = objectDate.getDate();
    // const new1 = day + "-" + dateString + "-" + selectedyear;
    setExpireson(dateString);
  };
  const btngetyear = (year) => {
    const new1 = year.$y;
    setSelectedyear(new1);
  };
  useEffect(() => {
    dispatch(getTemplateSlice())
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setTemplates(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <CommonCompbar sidebar={false}>
      <h3>Customer Onboarding</h3>
      <Divider />
      <ScrollBar>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{
            prefix: '86',
            assigned_template: '',
            cin_number: '',
            company_email: '',
            company_name: '',
            company_phone: '',
            owner_email: '',
            entity_type: 0,
            expired_date: '',
            owner_name: '',
            owner_phone: '',
            pan_number: '',
            registered_address: '',
            sector: '',
            website: '',
            year_of_incorporation: "",
          }}
          style={{ width: '100%' }}
          scrollToFirstError
        >
          <div style={{ display: 'flex', width: '1000px' }}>
            <Form.Item
              style={{ width: '450px', marginLeft: '20px', marginRight: 20 }}
              name="company_name"
              label="Company Name"
              rules={[
                {
                  required: true,
                  message: 'Please input your Customer Name!',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ width: '450px', marginLeft: '20px', marginRight: 20 }}
              name="website"
              label="Website"
              rules={[
                { required: true, message: 'Website data needs to be filled!' },
              ]}
            >
              <AutoComplete
                options={websiteOptions}
                onChange={onWebsiteChange}
                placeholder="website"
              >
                <Input />
              </AutoComplete>
            </Form.Item>
          </div>
          <div style={{ display: 'flex', width: '1000px' }}>
            <Form.Item
              style={{ width: '450px', marginLeft: '20px', marginRight: 20 }}
              name="entity_type"
              label="Entity Type"
              rules={[
                { required: true, message: 'Please select Entity Type!' },
              ]}
            >
              <Select
                showSearch
                style={{ width: 300 }}
                onChange={onchangeEntity}
                placeholder="Select"
              >
                <Option value={0}>Select type</Option>
                <Option value={1}>Companies</Option>
                <Option value={2}>Sole Proprietorship</Option>
                <Option value={3}>Partnership</Option>
                <Option value={4}>Trust</Option>
              </Select>
            </Form.Item>
            {selected_entid === 1 ? (
              <Form.Item
                style={{ width: '450px', marginLeft: '20px', marginRight: 20 }}
                name="cin_number"
                label="CIN"
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                    message: 'No Space or Special Characters Allowed',
                  },
                  { required: true, message: 'Account name is required.' },
                ]}
              >
                <Input maxLength={21} />
              </Form.Item>
            ) : (
              <Form.Item
                style={{ width: '450px', marginLeft: '20px', marginRight: 20 }}
                name="pan_number"
                label="PAN"
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                    message: 'No Space or Special Characters Allowed',
                  },
                  { required: true, message: 'Account name is required.' },
                ]}
              >
                <Input maxLength={10} />
              </Form.Item>
            )}
          </div>

          <div style={{ display: 'flex', width: '1000px' }}>
            <Form.Item
              style={{ width: '450px', marginLeft: '20px', marginRight: 20 }}
              name="assigned_template"
              label="Assigned Template"
              rules={[
                {
                  required: true,
                  message: 'Please select Assigned Template!',
                },
              ]}
            >
              <Select showSearch style={{ width: 300 }} placeholder="Select">
                <Option value={0}>Select</Option>
                {templates &&
                  templates.map((template) => {
                    return (
                      <Option value={template.id}>
                        {template.template_name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              style={{ width: '450px', marginLeft: '20px', marginRight: 20 }}
              name="sector"
              label="Sector"
              rules={[
                {
                  type: 'text',
                  message: 'Sector data needs to be filled!',
                },
                {
                  required: true,
                  message: 'Sector data needs to be filled!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          {/* <div style={{ display: "flex", width: "1000px" }}></div> */}
          <div style={{ display: 'flex', width: '1000px' }}>
            <Form.Item
              style={{ width: '450px', marginLeft: '20px', marginRight: 20 }}
              name="company_phone"
              label="Company Phone No."
              rules={[{ required: true, message: 'Please input Phone No.!' }]}
            >
              {/* <Input /> */}
              <InputNumber maxLength={15} />
            </Form.Item>
            <Form.Item
              style={{ width: '450px', marginLeft: '20px', marginRight: 20 }}
              name="company_email"
              label="Company E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'E-mail data needs to be filled!!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div style={{ display: "flex", width: "1000px" }}>
            <Form.Item
              style={{ width: "450px", marginLeft: "20px", marginRight: 20 }}
              name="year_of_incorporation"
              label="Year Of Incorporation"
            >
              <DatePicker
                picker="year"
                onChange={(e) => btngetyear(e)}
                className="year_incorporation"
              />
            </Form.Item>
            <Form.Item
            style={{ width: '450px', marginLeft: '20px', marginRight: 20 }}
              name="registered_address"
              label="Registered Address"
              rules={[
              { required: true, message: 'Please input Registered Address' },
              ]}
            >
              <Input.TextArea showCount maxLength={100} />
            </Form.Item>
          </div>
          <h3>Person in communication (PIC)</h3>
          <Divider />
          <div style={{ display: 'flex', width: '1000px' }}>
            <Form.Item
              className="year-month"
              name="owner_name"
              label="Name"
              rules={[{ required: true, message: 'Please input name!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="year-picker" label="Access Until">
              <DatePicker
                onChange={(date, dateString) => btngetmonth(date, dateString)}
                format={'DD-MMM-YYYY'}
                className="year"
              />
              {/* <DatePicker
                picker="month"
                onChange={(date, dateString) => btngetmonth(date, dateString)}
                format={'MMM'}
                className="year"
              />
              <DatePicker
                picker="year"
                onChange={(e) => btngetyear(e)}
                className="year"
              /> */}
            </Form.Item>
          </div>
          <div style={{ display: 'flex', width: '1000px' }}>
            <Form.Item
              style={{ width: '450px', marginLeft: '20px', marginRight: 20 }}
              name="owner_email"
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                  required: true,
                },
                // {
                //   required: true,
                //   message: "Sector data needs to be filled!",
                // },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ width: '450px', marginLeft: '20px', marginRight: 20 }}
              name="expired_date"
              label="Expires on"
            >
              <span>{expireson}</span>
            </Form.Item>
          </div>
          <div style={{ display: 'flex', width: '1000px' }}>
            <Form.Item
              style={{ width: '450px', marginLeft: '20px', marginRight: 20 }}
              name="owner_phone"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: 'Please input your phone number!',
                },
              ]}
            >
              <InputNumber maxLength={15} />
            </Form.Item>
          </div>
          <Space
            style={{
              marginTop: '10px',
              justifyContent: 'right',
              width: '100%',
            }}
          >
            <Button
              className="ant-btn danger"
              onClick={handleCancel}
              htmlType="button"
            >
              Cancel
            </Button>
            <Button style={{ marginLeft: 10 }} htmlType="submit">
              Register
            </Button>
          </Space>
        </Form>
      </ScrollBar>
    </CommonCompbar>
  );
};
export default CreateCustomer;
