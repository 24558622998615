import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./index.scss";
import {
  Table,
  Tooltip,
  Space,
  Button,
  Modal,
  Col,
  Row,
  Input,
  Menu,
  Dropdown,
  message,
} from "antd";
import CommonCompbar from "../../Components/BaseLayout";
import {
  createTicketSlice,
  fetchTickets,
  updateTicketsSlice,
} from "./ticketSlice/ticketSlice";
import Loader from "../../Components/loader";
import UpdateTicket from "./UpdateTicket";
import ViewTicket from "./ViewTicket";
import Converations from "./Converations";
//import { getNotificationSlice } from "../../Context/commonSlice";

const { TextArea } = Input;
const Tickets = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [tickets_data, setTicketsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [update_open, setUpdateOpen] = useState(false);
  const [view_open, setViewOpen] = useState(false);
  const [conversation_open, setConversationOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const [ticket_title, setTicketTitle] = useState("");
  const [ticket_description, setTicketDescription] = useState("");
  const [ticket_data, setTicketData] = useState(null);
  //const [notification_data, setNotificationData] = useState({});
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );

  useEffect(() => {
    dispatch(fetchTickets())
      .unwrap()
      .then((res) => {
        setLoader(false);
        if (res.code === 0) {
          setTicketsData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // dispatch(getNotificationSlice())
    //   .unwrap()
    //   .then((res) => {
    //     if (res.code === 0) {
    //       setNotificationData(res.data);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [dispatch, tickets_data]);
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    try {
      let response = await dispatch(
        createTicketSlice({
          // customer_id:1,
          // ticket_type,
          ticket_title,
          ticket_description,
        })
      );
      if (!response === undefined) return;
      message.info(response?.payload?.data?.msg);
      setLoader(true);
      dispatch(fetchTickets())
        .unwrap()
        .then((res) => {
          setLoader(false);
          if (res.code === 0) {
            setTicketsData(res.data);
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        });
      setOpen(false);
      setConfirmLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuClick = async (e, row) => {
    if (e.key === "active" || e.key === "deactive") {
    } else if (e.key === "edit") {
      setTicketData(row);
      setUpdateOpen(true);
    } else if (e.key === "view") {
      setTicketData(row);
      setViewOpen(true);
    } else if (e.key === "conversations") {
      setTicketData(row);
      setConversationOpen(true);
    } else if (e.key === "end_conversations") {
      try {
        let response = await dispatch(
          updateTicketsSlice({
            ticket_id: row.id,
            ticket_status: "Completed",
          })
        );
        if (!response === undefined) return;
        window.location.reload(false);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const columns = [
    {
      title: "SNo.",
      dataIndex: "key",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Created From",
      dataIndex: "created_from",
      key: "key",
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Display Name",
      dataIndex: "display_name",
      key: "key",
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Ticket Title",
      dataIndex: "ticket_title",
      key: "key",
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Ticket Description",
      dataIndex: "ticket_description",
      key: "financialyear",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "Ticket Status",
      dataIndex: "ticket_status",
      key: "reviewedby",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    // {
    //   title: 'Assignee Type',
    //   dataIndex: 'assingee_type',
    //   key: 'reviewedby',
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   render: (address) => (
    //     <Tooltip placement="topLeft" title={address}>
    //       {address}
    //     </Tooltip>
    //   ),
    // },
    {
      title: "Created On",
      dataIndex: "created_date",
      key: "createdon",
      ellipsis: {
        showTitle: false,
      },
      render: (createdon) => (
        <Tooltip placement="topLeft" title={createdon}>
          {createdon}
        </Tooltip>
      ),
    },
    {
      title: "Last Update",
      dataIndex: "updated_date",
      key: "createdon",
      ellipsis: {
        showTitle: false,
      },
      render: (createdon) => (
        <Tooltip placement="topLeft" title={createdon}>
          {createdon}
        </Tooltip>
      ),
    },
    {
      title: "Manage",
      dataIndex: "createdon",
      key: "createdon",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const menu = (
          <Menu onClick={(e) => handleMenuClick(e, record)} className="items">
            <Menu.Item key={"view"}>View</Menu.Item>
            <Menu.Item key={"conversations"}>Conversations</Menu.Item>
            {record.ticket_status !== "Completed" && (
              <Menu.Item key={"end_conversations"}>End Conversations</Menu.Item>
            )}
          </Menu>
        );
        return (
          <Dropdown.Button className="ant-btn1" overlay={menu}>
            Select
          </Dropdown.Button>
        );
      },
    },
  ];
  return (
    <CommonCompbar sidebar={false}>
      <div className="formcontainer">
        <div className="table-title-header-top">
          <h2>Ticketing System</h2>
          {sessionState?.roletype === "ADMIN" ? (
            ""
          ) : (
            <Button onClick={showModal}>New Ticket</Button>
          )}
        </div>

        <Space>
          <Table
            columns={columns}
            bordered
            pagination={false}
            dataSource={tickets_data}
            className="f-table-wrapper knowldge-action"
            scroll={{
              y: 240,
            }}
          />
        </Space>
      </div>
      <Modal
        title="Create Ticket"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
        {/* <Row gutter={[16, 16]}>
          <Col span={8}>
            <div>
              <span>Ticket Type</span>
            </div>
          </Col>
          <Col span={14}>
            <Select
              style={{ width: 200 }}
              placeholder="Select"
              onChange={(value) => setTicketType(value)}
            >
              <Option value="Admin">Admin</Option>
              {localStorage.getItem("role") === "3" ? (
                <Option value="Customer">Customer</Option>
              ) : (
                <Option value="Reviewer">Reviewer</Option>
              )}
            </Select>
          </Col>
        </Row> */}
        <div style={{ margin: "24px 0" }} />
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <div>
              <span>Title:</span>
            </div>
          </Col>
          <Col span={14}>
            <Input onChange={(e) => setTicketTitle(e.target.value)} />
          </Col>
        </Row>
        <div style={{ margin: "24px 0" }} />
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <div>
              <span>Description:</span>
            </div>
          </Col>
          <Col span={14}>
            <TextArea
              onChange={(e) => setTicketDescription(e.target.value)}
              placeholder="Messages"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Col>
        </Row>
      </Modal>
      {update_open && (
        <UpdateTicket
          open={update_open}
          setOpen={setUpdateOpen}
          ticket_data={ticket_data}
        />
      )}
      {view_open && (
        <ViewTicket
          open={view_open}
          setOpen={setViewOpen}
          ticket_data={ticket_data}
        />
      )}
      {conversation_open && (
        <Converations
          open={conversation_open}
          setOpen={setConversationOpen}
          ticket_data={ticket_data}
        />
      )}
      <Loader flag={loader} />
    </CommonCompbar>
  );
};

export default Tickets;
