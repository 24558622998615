import React, { useEffect } from "react";
import { Row, Col, Input, Form } from "antd";
import * as common from "../../../../../util/common";

const T21 = (props) => {
  const formula_defination = {
    r1c3: { type: "per", cols: ["r1c2", "r1c1"] },
    r2c3: { type: "per", cols: ["r2c2", "r2c1"] },
  };

  const handleChange = (e) => {
    common.arthiFunc(props.ques_id, formula_defination, props.form);
  };
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <Row>
      <Col span={24}>
        <div id="tbl-cell">
          <Row>
            <Col span={6}></Col>
            <Col span={6}>Total (a)</Col>
            <Col span={11}>No. and percentage of females </Col>
          </Row>
          <Row>
            <Col span={6} offset={12}>
              No. (b)
            </Col>
            <Col span={6} offset={0}>
              % (b/a)
            </Col>
          </Row>
          <Row>
            <Col span={6}>Board of directors</Col>
            <Col span={6}>
              <Form.Item name={[props.ques_id, "r1c1"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name={[props.ques_id, "r1c2"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={[props.ques_id, "r1c3"]}>
                <Input readOnly={true} size="small" suffix="%" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6}>Key management personnel</Col>
            <Col span={6}>
              <Form.Item name={[props.ques_id, "r2c1"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={[props.ques_id, "r2c2"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={[props.ques_id, "r2c3"]}>
                <Input readOnly={true} size="small" suffix="%" />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default T21;
