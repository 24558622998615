import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import "./index.scss";
import { Avatar, Button, Form, Input, List, Modal, Space, message } from "antd";
import ScrollBar from "./scroll";
import { CommentOutlined, SendOutlined } from "@ant-design/icons";
import { messageAlert } from "../../Components/Alert";
import { useCustomer } from "../../hooks/useCustomer";
import {
  create_questionare_comments_slice,
  end_questionare_comments_slice,
  get_questionare_comments,
} from "./CommentsSlice/CommentsSlice";
import { CommentsContext } from "../../Context/context";
import avatarPic from "../../static/images/human.png";

const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${
      comments.length > 1 ? "Comments" : "Comment"
    }`}
    itemLayout="horizontal"
    renderItem={(props) => <content {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, handleEndSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <div class="textarea-container">
        <TextArea
          rows={2}
          placeholder="Reply to..."
          onChange={onChange}
          value={value}
          data-enable-grammarly="false"
        />
      </div>

      <Button
        style={{
          marginTop: "10px",
          width: "100%",
        }}
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
        // shape="circle"
        icon={<SendOutlined />}
      >
        Submit comment
      </Button>

      {localStorage.getItem("roletype") == "AUDITOR" ? (
        <Space
          style={{
            marginTop: "10px",
            justifyContent: "right",
            width: "100%",
          }}
        >
          <Button
            htmlType="submit"
            loading={submitting}
            onClick={handleEndSubmit}
            type="primary"
            //shape="circle"
            icon={<SendOutlined />}
          >
           Resolve comment
          </Button>
        </Space>
      ) : (
        ""
      )}
    </Form.Item>
  </>
);

const CommentComp = (props) => {
  const { list, setList } = useContext(CommentsContext);
  const { questionaire_status } = useCustomer();
  const dispatch = useDispatch();
  const [comments, setComments] = useState([]);
  const [comment_end, setCommentEnd] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);

  const get_comments = (payload) => {
    dispatch(get_questionare_comments({ ...payload }))
      .unwrap()
      .then((res) => {
        if (res?.data.code === 0) {
          setComments(res.data.data.conversations);
          setCommentEnd(
            res.data.data?.conversations[0]?.comment_status === "End"
              ? true
              : false
          );
          setOpen(true);
        } else {
          messageAlert({ title: "Alert!", msg: res.data.msg });
        }
      })
      .catch((Error) => {
        console.log(Error);
      });
  };
  const showModal = () => {
    if (questionaire_status === "Reviewed") {
      messageAlert({
        title: "Alert!",
        msg: "Questionnaire has already been Reviewed.",
      });
      return;
    }
    if (!props.question_response_id) {
      messageAlert({ title: "Alert!", msg: "Comment can not be added" });
      return;
    }
    let payload = {
      question_response_id: props.question_response_id,
      question_response_name: props.question_response_name,
    };
    get_comments(payload);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setList(list + 1);
    if (!value) return;
    let payload = {
      question_response_id: props.question_response_id,
      question_response_name: props.question_response_name,
      comment: value,
    };
    dispatch(create_questionare_comments_slice({ ...payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          messageAlert({ title: "Alert!", msg: "Comment added succesfully!" });
          props.setCommentReload(payload);
        }
      })
      .catch((Err) => {
        console.log(Err);
      });

    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      setValue("");
      let payload = {
        question_response_id: props.question_response_id,
        question_response_name: props.question_response_name,
      };
      get_comments(payload);
      props.setCommentReload(payload);
    }, 1000);
  };
  const handleEndSubmit = () => {
    //if (!value) return;
    let payload = {
      question_response_id: props.question_response_id,
      question_response_name: props.question_response_name,
    };
    dispatch(end_questionare_comments_slice({ ...payload }))
      .unwrap()
      .then((res) => {
        if (res.data.code === 0) {
          messageAlert({ title: "Alert", msg: "Chat Ended succesfully!" });
          props.setCommentReload(payload);
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <div className="comment-align">
        <div className="ant-comment-count" onClick={showModal}>
          {React.createElement(CommentOutlined)}
          {comments.length > 0 ? (
            <CommentList comments={comments} />
          ) : (
            "Comments"
          )}
        </div>
      </div>
      <Modal
        open={open}
        title={
          <div className="ant-comment-modalcount">
            {React.createElement(CommentOutlined)}
            {comments.length > 0 ? (
              <CommentList comments={comments} />
            ) : (
              "Comments"
            )}
          </div>
        }
        onCancel={handleCancel}
        footer={false}
      >
        <div style={{ height: "250px" }}>
          <ScrollBar>
            {comments.map((item, index) => {
              return (
                <>
                  {item.responsed_by ? (
                    <div className="ant-comment">
                      <div className="ant-comment-inner">
                        <div className="ant-comment-avatar" key={index}>
                          {<Avatar src={avatarPic} alt="Han Solo" />}
                        </div>
                        <div className="ant-comment-content-left">
                          <div class="ant-comment-content-author-left">
                            <span class="ant-comment-content-author-name">
                              {item.responsed_by}
                            </span>
                            <span class="ant-comment-content-author-time">
                              {item.created_date}
                            </span>
                          </div>
                          <div
                            className="ant-comment-content-detail"
                            key={index}
                          >
                            {item.comment}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="ant-comment">
                      <div className="ant-comment-inner">
                        <div className="ant-comment-content-right">
                          <div class="ant-comment-content-author-right">
                            <span class="ant-comment-content-author-name">
                              {item.requested_by}
                            </span>
                            <span class="ant-comment-content-author-time">
                              {item.created_date}
                            </span>
                          </div>
                          <div
                            className="ant-comment-content-detail"
                            key={index}
                          >
                            {item.comment}
                          </div>
                        </div>
                        <div className="ant-comment-avatar" key={index}>
                          {<Avatar src={avatarPic} alt="Han Solo" />}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </ScrollBar>
        </div>
        {!comment_end && (
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            handleEndSubmit={handleEndSubmit}
            submitting={submitting}
            value={value}
          />
        )}
      </Modal>
    </>
  );
};

export default CommentComp;
