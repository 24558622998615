import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
//import "./index.scss";
import {
  Button,
  Divider,
  Card,
  Row,
  Col,
  Input,
  Select,
  Space,
  Form,
  message,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CommentsApp from "../../../../Components/Comments";
import {
  create_section_slice,
  get_section,
} from "../SectionSlice/sectionSlice";
import T1 from "./Tables/t1";
import T2 from "./Tables/t2";
import T3A from "./Tables/t3a";
import T3B from "./Tables/t3b";
import T6 from "./Tables/t6";
import T7 from "./Tables/t7";
import T10 from "./Tables/t10";
import T15 from "./Tables/t15";
import { useFinancialYear } from "../../../../hooks/useFinancialYear";
import { useCustomer } from "../../../../hooks/useCustomer";
import TextFieldToTextArea from "../../../../Components/Input/textfieldtoarea";
import CommentsNotified from "../../../../Components/Comments/comments-notification";

const { Option } = Select;
const App = (props) => {
  const { current_fy, previous_fy } = useFinancialYear();
  const { customer_id, assigned_template_id } = useCustomer();
  const dispatch = useDispatch();
  const [spinning, setSpinning] = useState(false);
  const [readonly, setReadonly] = useState(false);
  const [form] = Form.useForm();
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const [defaultdata, setDefaultdata] = useState({});
  const [commentlist, setCommentlist] = useState([]);
  const [commentreload, setCommentReload] = useState();

  useEffect(() => {
    let section_update = "";
    let a20asavedData = JSON.parse(localStorage.getItem("a20a1")) || {};
    if (Object.keys(a20asavedData).length !== 0) {
      // 1X calculation
      const val_1y = parseFloat(a20asavedData["1Y"]);
      const val_1w = parseFloat(a20asavedData["1W"]);
      const num1y = isNaN(val_1y) ? 0 : val_1y;
      const num1w = isNaN(val_1w) ? 0 : val_1w;
      const num1X = num1y + num1w;

      // 2X calculation
      const val_2y = parseFloat(a20asavedData["2Y"]);
      const val_2w = parseFloat(a20asavedData["2W"]);
      const num2y = isNaN(val_2y) ? 0 : val_2y;
      const num2w = isNaN(val_2w) ? 0 : val_2w;
      const num2X = num2y + num2w;

      // 1J calculation
      const val_1k = parseFloat(a20asavedData["1K"]);
      const val_1m = parseFloat(a20asavedData["1M"]);
      const num1k = isNaN(val_1k) ? 0 : val_1k;
      const num1m = isNaN(val_1m) ? 0 : val_1m;
      const num1J = num1k + num1m;

      // 2J calculation
      const val_2k = parseFloat(a20asavedData["2K"]);
      const val_2m = parseFloat(a20asavedData["2M"]);
      const num2k = isNaN(val_2k) ? 0 : val_2k;
      const num2m = isNaN(val_2m) ? 0 : val_2m;
      const num2J = num2k + num2m;
      section_update = {
        ["CP5-1"]: {
          r1c1: num1X,
          r2c1: num2X,
          r4c1: num1J,
          r5c1: num2J,
        },
        ["CP5-2"]: {
          r1c1: a20asavedData["1Y"],
          r2c1: a20asavedData["1W"],
          r3c1: a20asavedData["2Y"],
          r4c1: a20asavedData["2W"],
          r5c1: a20asavedData["1K"],
          r6c1: a20asavedData["1M"],
          r7c1: a20asavedData["2K"],
          r8c1: a20asavedData["2M"],
        },
      };
    }
    //---------------------------
    if (sessionState?.roletype === "CUSTOMER") {
      setReadonly(false);
    } else {
      setReadonly(true);
    }
    let payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_C_P5",
    };
    setSpinning(true);
    dispatch(get_section({ payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          if (Object.keys(a20asavedData).length !== 0) {
            setDefaultdata({ ...res.data.section_C_P5, ...section_update });
            setCommentlist(res.data?.comments);
            setSpinning(false);
          } else {
            setDefaultdata(res.data.section_C_P5);
            setCommentlist(res.data?.comments);
            setSpinning(false);
          }
          // setTimeout(() => {
          //   setDefaultdata(res.data.section_C_P5);
          //   setSpinning(false);
          // }, 2000);
        } else {
          setSpinning(false);
          if (res.code != 800000) {
            if (Object.keys(a20asavedData).length !== 0) {
              setDefaultdata({ ...section_update });
              setCommentlist(res.data?.comments);
            }
            message.info(res.msg);
          }
        }
      })
      .catch((Error) => {
        console.log(Error);
      });
  }, [dispatch, commentreload]);

  useEffect(() => {
    form.setFieldsValue(defaultdata);
  }, [form, defaultdata]);

  // useEffect(() => {
  //   let a20asavedData = JSON.parse(localStorage.getItem("a20a")) || {};

  //   // 1X calculation
  //   const val_1y = parseFloat(a20asavedData["1Y"]);
  //   const val_1w = parseFloat(a20asavedData["1W"]);
  //   const num1y = isNaN(val_1y) ? 0 : val_1y;
  //   const num1w = isNaN(val_1w) ? 0 : val_1w;
  //   const num1X = num1y + num1w;

  //   // 2X calculation
  //   const val_2y = parseFloat(a20asavedData["2Y"]);
  //   const val_2w = parseFloat(a20asavedData["2W"]);
  //   const num2y = isNaN(val_2y) ? 0 : val_2y;
  //   const num2w = isNaN(val_2w) ? 0 : val_2w;
  //   const num2X = num2y + num2w;

  //   // 1J calculation
  //   const val_1k = parseFloat(a20asavedData["1K"]);
  //   const val_1m = parseFloat(a20asavedData["1M"]);
  //   const num1k = isNaN(val_1k) ? 0 : val_1k;
  //   const num1m = isNaN(val_1m) ? 0 : val_1m;
  //   const num1J = num1k + num1m;

  //   // 2J calculation
  //   const val_2k = parseFloat(a20asavedData["2K"]);
  //   const val_2m = parseFloat(a20asavedData["2M"]);
  //   const num2k = isNaN(val_2k) ? 0 : val_2k;
  //   const num2m = isNaN(val_2m) ? 0 : val_2m;
  //   const num2J = num2k + num2m;

  //   setDefaultdata({
  //     ["CP5-1"]: {
  //       r1c1: num1X,
  //       r2c1: num2X,
  //       r4c1: num1J,
  //       r5c1: num2J,
  //     },
  //     ["CP5-2"]: {
  //       r1c1: a20asavedData["1Y"],
  //       r2c1: a20asavedData["1W"],
  //       r3c1: a20asavedData["2Y"],
  //       r4c1: a20asavedData["2W"],
  //       r5c1: a20asavedData["1K"],
  //       r6c1: a20asavedData["1M"],
  //       r7c1: a20asavedData["2K"],
  //       r8c1: a20asavedData["2M"],
  //     },
  //   });
  // }, []);
  const onFinish = (datalist) => {
    setSpinning(true);
    let Payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      section_name: "section_C_P5",
      question_response: datalist,
      financial_year: current_fy,
    };
    dispatch(create_section_slice({ Payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setTimeout(() => {
            setSpinning(false);
            message.info("Section C P5 created succesfully!");
          }, 3000);
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  return (
    <>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 44, color: "white" }} spin />
        }
        tip="Loading..."
        spinning={spinning}
        fullscreen
        size="large"
      />
      <div className="formcontainer">
        <CommentsNotified commentlist={commentlist} />
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={defaultdata}
          scrollToFirstError
          style={{ width: "100%" }}
        >
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Card title="Essential Indicators" size="small">
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP5-1" className="title-header">
                    <Row>
                      <Col span={20}>
                        <span>
                          Employees and workers who have been provided training
                          on human rights issues and policy(ies) of the entity,
                          in the following format:
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T1
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"CP5-1"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="CP5-1"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP5-2" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={20}>
                        <span>
                          Details of minimum wages paid to employees and
                          workers, in the following format:
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T2
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"CP5-2"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="CP5-2"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP5-3" className="title-header">
                    <Row>
                      <Col span={20}>
                        <span>Details of remuneration/salary/wages</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}>
                        <span>a. Median annual remuneration/wages in INR:</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T3A
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"CP5-3A"}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}>
                        <span>
                          b. Gross wages paid to women as % of total wages paid
                          by the entity, in the following format:
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T3B
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"CP5-3B"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="CP5-3"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP5-4" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          Do you have a focal point (Individual/ Committee)
                          responsible for addressing human rights impacts or
                          issues caused or contributed to by the business?
                          (Yes/No)
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["cp5_4", "r1c1"]} noStyle>
                          <Select
                            placeholder="Select Option"
                            disabled={readonly}
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="CP5-4"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP5-5" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          Describe the internal mechanisms in place to redress
                          grievances related to human rights issues.
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["cp5_5", "r1c1"]} noStyle>
                          <TextFieldToTextArea props={props} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="CP5-5"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP5-6" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={20}>
                        <span>
                          Number of Complaints on the following made by
                          employees and workers:
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T6
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"CP5-6"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="CP5-6"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP5-7" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={20}>
                        <span>
                          Complaints filed under the Sexual Harassment of Women
                          at Workplace (Prevention, Prohibition and Redressal)
                          Act, 2013, in the following format:
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T7
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"CP5-7"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="CP5-7"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP5-8" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          Mechanisms to prevent adverse consequences to the
                          complainant in discrimination and harassment cases.
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["cp5_8", "r1c1"]} noStyle>
                          <TextFieldToTextArea props={props} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="CP5-8"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP5-9" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          Do human rights requirements form part of your
                          business agreements and contracts? (Yes/No)
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["cp5_9", "r1c1"]} noStyle>
                          <Select
                            placeholder="Select Option"
                            disabled={readonly}
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="CP5-9"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP5-10" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={22}>
                        <span>Assessments for the year:</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T10
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"CP5-10"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="CP5-10"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP5-11" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          Provide details of any corrective actions taken or
                          underway to address significant risks / concerns
                          arising from the assessments at question above.
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["cp5_11", "r1c1"]} noStyle>
                          <Input
                            placeholder="enter details"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="CP5-11"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              )}
            </Card>
            {[3, 4].includes(assigned_template_id) && (
              <Card title="Leadership Indicators" size="small">
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-12" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <span>
                            Details of a business process being modified /
                            introduced as a result of addressing human rights
                            grievances/complaints.
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_12", "r1c1"]} noStyle>
                            <Input
                              placeholder="enter details"
                              readOnly={readonly}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-12"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-13" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <span>
                            Details of the scope and coverage of any Human
                            rights due-diligence conducted.
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_13", "r1c1"]} noStyle>
                            <Input
                              placeholder="enter details"
                              readOnly={readonly}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-13"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-14" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <span>
                            Is the premise/office of the entity accessible to
                            differently abled visitors, as per the requirements
                            of the Rights of Persons with Disabilities Act,
                            2016?
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_14", "r1c1"]} noStyle>
                            <Select
                              placeholder="Select Option"
                              disabled={readonly}
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-14"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-15" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={20}>
                          <span>
                            Details on assessment of value chain partners:
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <T15
                            readonly={readonly}
                            data={{ current_fy, previous_fy, ...defaultdata }}
                            form={form}
                            ques_id={"CP5-15"}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-15"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-16" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <span>
                            Provide details of any corrective actions taken or
                            underway to address significant risks / concerns
                            arising from the assessments of human rights issues
                            caused or contributed by the business
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_16", "r1c1"]} noStyle>
                            <Input
                              placeholder="enter details"
                              readOnly={readonly}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-16"
                      question_response_id={defaultdata.question_response_id}
                    />
                  </>
                )}
              </Card>
            )}
          </Space>

          {/* <h1>END OF LITE/EXTENDED </h1> */}
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[4].includes(assigned_template_id) && (
              <Card title="" size="small">
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-17" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={20}>
                          <span>
                            Communication and training about anti-corruption
                            policies and procedures
                            <br />
                            The reporting organization shall report the
                            following information:
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Total number and percentage of governance body
                            members that the organization’s anticorruption
                            policies and procedures have been communicated to,
                            broken down by region.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_17", "r1c1"]}>
                            <Input placeholder="Enter" readOnly={readonly} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Total number and percentage of employees that the
                            organization’s anti-corruption policies and
                            procedures have been communicated to, broken down by
                            employee category and region.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_17", "r2c1"]}>
                            <Input placeholder="Enter" readOnly={readonly} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. Total number and percentage of business partners
                            that the organization’s anticorruption policies and
                            procedures have been communicated to, broken down by
                            type of business partner and region. Describe if the
                            organization’s anti-corruption policies and
                            procedures have been communicated to any other
                            persons or organizations.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_17", "r3c1"]}>
                            <Input placeholder="Enter" readOnly={readonly} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            d. Total number and percentage of governance body
                            members that have received training on
                            anti-corruption, broken down by region.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_17", "r4c1"]}>
                            <Input placeholder="Enter" readOnly={readonly} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-17"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-18" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <span>
                            Security personnel trained in human rights policies
                            or procedures
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            name={["cp5_18", "r1c1"]}
                            noStyle
                            rules={[
                              {
                                required: false,
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select Option"
                              disabled={readonly}
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-18"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-19" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={22}>
                          <span>
                            Ratios of standard entry level wage by gender
                            compared to local minimum wage
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Whether a local minimum wage is absent or
                            variable at significant locations of operation, by
                            gender. In circumstances in which different minimums
                            can be used as a reference, report which minimum
                            wage is being used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_19", "r1c1"]}>
                            <Input placeholder="Enter" readOnly={readonly} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. The definition used for ‘significant locations of
                            operation’.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_19", "r2c1"]}>
                            <Input placeholder="Enter" readOnly={readonly} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-19"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-20" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <span>Remuneration policies</span>
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            name={["cp5_20", "r1c1"]}
                            noStyle
                            rules={[
                              {
                                required: false,
                                message:
                                  "Please input your Name of the company!",
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter company name"
                              readOnly={readonly}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-20"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-21" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Process to determine remuneration
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            a. Describe the process for designing organization's
                            remuneration policies and for determining
                            remuneration, including:
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. whether independent highest governance body
                            members or an independent remuneration committee
                            oversees the process for determining remuneration;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_21", "r1c1"]}>
                            <Input placeholder="Enter" readOnly={readonly} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. how the views of stakeholders (including
                            shareholders) regarding remuneration are sought and
                            taken into consideration;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_21", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iii. whether remuneration consultants are involved
                            in determining remuneration and, if so, whether they
                            are independent of the organization, its highest
                            governance body and senior executives;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_21", "r3c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Report the results of votes of stakeholders
                            (including shareholders) on remuneration policies
                            and proposals, if applicable.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_21", "r4c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-21"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-22" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Annual total compensation ratio
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            Report the ratio of the percentage increase in
                            annual total compensation for the organization’s
                            highest-paid individual to the median percentage
                            increase in annual total compensation for all
                            employees (excluding the highest-paid individual);
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_22", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. report contextual information necessary to
                            understand the data and how the data has been
                            compiled.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_22", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-22"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-23" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Incidents of discrimination and corrective actions
                            taken
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Status of the incidents and actions taken with
                            reference to the following:
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. Incident reviewed by the organization;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_23", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. Remediation plans being implemented;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_23", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iii. Remediation plans that have been implemented,
                            with results reviewed through routine internal
                            management review processes;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_23", "r3c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iv. Incident no longer subject to action.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_23", "r4c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-23"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-24" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Proportion of senior management hired from the local
                            community
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Percentage of senior management at significant
                            locations of operation that are hired from the local
                            community.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_24", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. The definition used for ‘senior management’.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_24", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. The organization’s geographical definition of
                            ‘local’.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_24", "r3c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            d. The definition used for ‘significant locations of
                            operation’.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_24", "r4c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-24"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-25" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Operations assessed for risks related to corruption
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Total number and percentage of operations
                            assessed for risks related to corruption.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_25", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Significant risks related to corruption
                            identified through the risk assessment.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_25", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-25"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-26" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Operations and suppliers at significant risk for
                            incidents of child labor
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={22}>
                          <div style={{ fontSize: "12px" }}>
                            a. Operations and suppliers considered to have
                            significant risk for incidents of:
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. child labor;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_26", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. young workers exposed to hazardous work.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_26", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={22}>
                          <div style={{ fontSize: "12px" }}>
                            b. Operations and suppliers considered to have
                            significant risk for incidents of child labor either
                            in terms of:
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. type of operation (such as manufacturing plant)
                            and supplier;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_26", "r3c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. countries or geographic areas with operations
                            and suppliers considered at risk.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_26", "r4c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. Measures taken by the organization in the
                            reporting period intended to contribute to the
                            effective abolition of child labor.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_26", "r5c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-26"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-27" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Operations and suppliers at significant risk for
                            incidents of forced or compulsory labor
                            <br />
                            a. Operations and suppliers considered to have
                            significant risk for incidents of forced or
                            compulsory labor either in terms of:
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. type of operation (such as manufacturing plant)
                            and supplier;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_27", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. countries or geographic areas with operations
                            and suppliers considered at risk.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_27", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Measures taken by the organization in the
                            reporting period intended to contribute to the
                            elimination of all forms of forced or compulsory
                            labor.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_27", "r3c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-27"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-28" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Incidents of violations involving rights of
                            indigenous peoples
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Total number of identified incidents of
                            violations involving the rights of indigenous
                            peoples during the reporting period.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_28", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Status of the incidents and actions taken with
                            reference to the following:
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. Incident reviewed by the organization;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_28", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. Remediation plans being implemented;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_28", "r3c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iii. Remediation plans that have been implemented,
                            with results reviewed through routine internal
                            management review processes;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_28", "r4c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iv. Incident no longer subject to action.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_28", "r5c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-28"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-29" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Chair of the highest governance body
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Report whether the highest governance body is
                            responsible for reviewing and approving the reported
                            information, including the organization’s material
                            topics, and if so, describe the process for
                            reviewing and approving the information
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_29", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. if the highest governance body is not responsible
                            for reviewing and approving the reported
                            information, including the organization’s material
                            topics, explain the reason for this
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_29", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-29"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-30" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Role of the highest governance body in
                            sustainability reporting
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Report whether the highest governance body is
                            responsible for reviewing and approving the reported
                            information, including the organization’s material
                            topics, and if so, describe the process for
                            reviewing and approving the information
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_30", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. if the highest governance body is not responsible
                            for reviewing and approving the reported
                            information, including the organization’s material
                            topics, explain the reason for this
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_30", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-30"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-31" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Communication of critical concerns
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Describe whether and how critical concerns are
                            communicated to the highest governance body;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_31", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Report the total number and the nature of
                            critical concerns that were communicated to the
                            highest governance body during the reporting period.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_31", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-31"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-32" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Evaluation of the performance of the highest
                            governance body
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Describe the processes for evaluating the
                            performance of the highest governance body in
                            overseeing the management of the organization’s
                            impacts on the economy, environment, and people;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_32", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Report whether the evaluations are independent or
                            not, and the frequency of the evaluations;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_32", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. Describe actions taken in response to the
                            evaluations, including changes to the composition of
                            the highest governance body and organizational
                            practices.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_32", "r3c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-32"
                      question_response_id={defaultdata.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP5-33" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Mechanisms for seeking advice and raising concerns
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            a. Describe the mechanisms for individuals to:
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. seek advice on implementing the organization’s
                            policies and practices for responsible business
                            conduct;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_33", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. raise concerns about the organization’s business
                            conduct.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp5_33", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_name="CP5-33"
                      question_response_id={defaultdata.question_response_id}
                    />
                  </>
                )}
              </Card>
            )}
          </Space>

          {readonly ? (
            ""
          ) : (
            <Space
              style={{
                //marginTop: "10px",
                marginBottom: "10px",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <Button style={{ marginLeft: 10 }} htmlType="submit">
                Save and Continue
              </Button>
            </Space>
          )}
        </Form>
      </div>
    </>
  );
};

export default App;
