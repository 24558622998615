import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Table,
  Modal,
  message,
  Row,
  Col,
} from 'antd';
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';

const App = (props) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [casedetails, setCaseDetails] = useState('');
  const [regulatory, setRegulatory] = useState('');
  const [modelOpen, setModelOpen] = useState(false);
  const [modelClickType, setModelClickType] = useState('Add');

  const handleDelete = (key) => {
    const newData = dataSource.filter((item, index) => index !== key);
    setDataSource(newData);
    props.setdata(newData);
  };

  const handleEdit = (key) => {
    const editData = dataSource.filter((item) => item.key === key);
  };

  const Columns = [
    {
      title: 'SNo.',
      dataIndex: 'key',
      width: '10%',
      editable: true,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Case Details',
      dataIndex: 'r1c1',
      width: '30%',
      editable: true,
    },
    {
      title:
        'Name of the regulatory/ enforcement agencies/ judicial institutions',
      dataIndex: 'r1c2',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record, index) =>
        dataSource.length >= 1 ? (
          <>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(index)}
            >
              <Button className="ant-btn-link" type="link">
                <DeleteOutlined twoToneColor="#eb2f96" />
              </Button>
            </Popconfirm>
          </>
        ) : null,
    },
  ];
  useEffect(() => {
    setDataSource(props.data?.cp1_3 || []);
  }, [props.data]);

  const btnAdd = () => {
    const newData = {
      r1c1: casedetails,
      r1c2: regulatory,
    };
    let updatedData = [...dataSource, newData];
    setDataSource([...dataSource, newData]);
    props.setdata([...dataSource, newData]);
    message.success('Processing complete!');
    setModelOpen(false);
    setCaseDetails('');
    setRegulatory('');
    form.resetFields();
  };

  const btnEdit = () => {
    const newData = {
      r1c1: casedetails,
      r1c2: regulatory,
    };
    let updatedData = [...dataSource, newData];
    setDataSource([...dataSource, newData]);
    props.setdata([...dataSource, newData]);
    message.success('Processing complete!');
    setModelOpen(false);
    setCaseDetails('');
    setRegulatory('');
    form.resetFields();
  };

  return (
    <div>
      <Row>
        <Col span={24} offset={22}>
          <span>
            <Button
              onClick={() => {
                setModelClickType('Add');
                setModelOpen(true);
                setCaseDetails('');
                setRegulatory('');
                form.resetFields();
              }}
              disabled={props.readonly}
            >
              Add
            </Button>
          </span>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={Columns}
        pagination={false}
        className="f-table-wrapper"
        scroll={{
          y: 240,
        }}
      />

      <Modal
        title={modelClickType}
        style={{ top: 20 }}
        open={modelOpen}
        onOk={btnAdd}
        okText={'Submit'}
        onCancel={() => setModelOpen(false)}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 22 }}
          initialValues={{ remember: false }}
          autoComplete="off"
        >
          <div className="modalform">
            <Form.Item name="casedetails" label="Case Details">
              <Input
                onChange={(e) => setCaseDetails(e.target.value)}
                placeholder="Case Details"
              />
            </Form.Item>
            <Form.Item name="regulatory" label="Name of the regulatory">
              <Input
                onChange={(e) => setRegulatory(e.target.value)}
                placeholder="Name of the regulatory"
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default App;
