import React, { useEffect } from "react";
import { Row, Col, Form } from "antd";
import InputNumber from "../../../../../Components/Input/Number/number";

const T7 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div>
      <Row>
        <Col span={24}>
          <div id="tbl-cell">
            <Row>
              <Col span={8}></Col>
              <Col span={8}>
                FY {props?.data?.current_fy} (Current Financial Year)
              </Col>
              <Col span={8}>
                FY {props?.data?.previous_fy} (Previous Financial Year)
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                Total Complaints reported under Sexual Harassment on of Women at
                Workplace (Prevention, Prohibition and Redressal) Act, 2013
                (POSH)
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r1c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r1c2"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                Complaints on POSH as a % of female employees / workers
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r2c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r2c2"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Complaints on POSH upheld</Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r3c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r3c2"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default T7;
