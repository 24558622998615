import React, { useEffect, useState } from "react";
import { Divider, Row, Col, Form, Input, Select } from "antd";

const CP3_11 = (props) => {

  const DemoBox = (prop) => (
    <p className={`height-${prop.value}`}>{prop.children}</p>
  );
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <Form.Item label="CP3-11" className="title-header">
      <Row>
        <Col span={24}>
          <span style={{ fontSize: "12px", fontWeight: "700" }}>
            {`Details of safety related incidents, in the following format:`}
          </span>
          <div id="tbl-cell">
            <Row>
              <Col span={8}>
                <span
                  style={{ fontSize: "12px", fontWeight: "600" }}
                >{`Safety Incident/Number`}</span>
              </Col>
              <Col span={4}>
                <span
                  style={{ fontSize: "12px", fontWeight: "600" }}
                >{`Category*`}</span>
              </Col>
              <Col span={6} className="text-center">
                <span style={{ fontSize: "12px", fontWeight: "600" }}>
                  FY: {props?.data?.current_fy} Current Financial Year
                </span>
              </Col>
              <Col span={6} className="text-center">
                <span style={{ fontSize: "12px", fontWeight: "600" }}>
                  FY: {props?.data?.previous_fy} Previous Financial Year
                </span>
              </Col>
            </Row>

            <Row>
              <Col span={8} className="text-center">
                <span style={{ fontSize: "12px", fontWeight: "600" }}>
                  <DemoBox value={100}>
                    {`Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)`}
                  </DemoBox>
                </span>
              </Col>
              <Col span={4} className="text-center">
                <Col span={24} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    <DemoBox value={100}>{`Employees`}</DemoBox>
                  </span>
                </Col>
                <Col span={24} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    <DemoBox value={100}>{`Workers`}</DemoBox>
                  </span>
                </Col>
              </Col>
              <Col span={6}>
                <Col span={24}>
                  <Form.Item name={[props.ques_id, "r1c1"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name={[props.ques_id, "r2c1"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Col>
              <Col span={6}>
                <Row>
                  <Col span={24}>
                    <Form.Item name={[props.ques_id, "r1c2"]}>
                      <Input readOnly={props.readonly} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name={[props.ques_id, "r2c2"]}>
                      <Input readOnly={props.readonly} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col span={8} className="text-center">
                <span style={{ fontSize: "12px", fontWeight: "600" }}>
                  <DemoBox value={100}>
                    {`Total recordable work-related injuries`}
                  </DemoBox>
                </span>
              </Col>
              <Col span={4} className="text-center">
                <Col span={24} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    <DemoBox value={100}>{`Employees`}</DemoBox>
                  </span>
                </Col>
                <Col span={24} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    <DemoBox value={100}>{`Workers`}</DemoBox>
                  </span>
                </Col>
              </Col>
              <Col span={6}>
                <Col span={24}>
                  <Form.Item name={[props.ques_id, "r3c1"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name={[props.ques_id, "r4c1"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Col>
              <Col span={6}>
                <Row>
                  <Col span={24}>
                    <Form.Item name={[props.ques_id, "r3c2"]}>
                      <Input readOnly={props.readonly} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name={[props.ques_id, "r4c2"]}>
                      <Input readOnly={props.readonly} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={8} className="text-center">
                <span style={{ fontSize: "12px", fontWeight: "600" }}>
                  <DemoBox value={100}>{`No. of fatalities`}</DemoBox>
                </span>
              </Col>
              <Col span={4} className="text-center">
                <Col span={24} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    <DemoBox value={100}>{`Employees`}</DemoBox>
                  </span>
                </Col>
                <Col span={24} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    <DemoBox value={100}>{`Workers`}</DemoBox>
                  </span>
                </Col>
              </Col>
              <Col span={6}>
                <Col span={24}>
                  <Form.Item name={[props.ques_id, "r5c1"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name={[props.ques_id, "r6c1"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Col>
              <Col span={6}>
                <Row>
                  <Col span={24}>
                    <Form.Item name={[props.ques_id, "r5c2"]}>
                      <Input readOnly={props.readonly} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name={[props.ques_id, "r6c2"]}>
                      <Input readOnly={props.readonly} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ textAlign: "center" }}>
                <span style={{ fontSize: "12px", fontWeight: "600" }}>
                  <DemoBox value={100}>
                    {`High consequence work-related injury or ill-health (excluding fatalities)`}
                  </DemoBox>
                </span>
              </Col>
              <Col span={4} className="text-center">
                <Col span={24} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    <DemoBox value={100}>{`Employees`}</DemoBox>
                  </span>
                </Col>
                <Col span={24} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    <DemoBox value={100}>{`Workers`}</DemoBox>
                  </span>
                </Col>
              </Col>
              <Col span={6}>
                <Col span={24}>
                  <Form.Item name={[props.ques_id, "r7c1"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name={[props.ques_id, "r8c1"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Col>
              <Col span={6}>
                <Row>
                  <Col span={24}>
                    <Form.Item name={[props.ques_id, "r7c2"]}>
                      <Input readOnly={props.readonly} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name={[props.ques_id, "r8c2"]}>
                      <Input readOnly={props.readonly} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default CP3_11;
