// API BASE URL --> Replace with ENV Variable
//export const BASE_API_URL = "http://ec2-13-235-114-30.ap-south-1.compute.amazonaws.com:8001";
export const BASE_API_URL = "https://nesgauge.nesgreensolutions.com:8001";

//API URL
export const urlLogin = "/api/v1/login";
export const urlProfile = "/api/v1/profile";

export const urlAppState = "/api/v1/appstate";
export const url2fa = "/api/v1/2fa";
export const urlverify2facode = "/api/v1/2fa/verfiy";
export const createcustomerurl = "/api/v1/customer";
export const urlForgotPassword = "/api/v1/forgotpassword";
// Section
export const urlSection_a = "/api/v1/questionaire";
// Customer API URL
export const urlCustomerList = "/api/v1/customer";
export const urlUpdateCustomer = "/api/v1/customer/update";

// Customer Users API URL
export const urlCustomerUserList = "/api/v1/customeruser";
export const urlUpdateCustomerUser = "/api/v1/customeruser/update";

export const urlResetPassword = "/api/v1/resetpassword";
export const urlUserResetPassword = "/api/v1/userresetpassword";
export const urlReset2fa = "/api/v1/reset2fa";

// Backoffice Users API URL
export const urlBackoffice = "/api/v1/backoffice";
export const urlUpdateBackoffice = "/api/v1/backoffice/update";

export const urlReviewer = "/api/v1/backofficereviewer";

export const urlCustomerReviewerMapper = "/api/v1/customerreviewermapper";
// export const urlUpdateCustomerReviewerMapper =
//   "/api/v1/customerreviewermapper/update";
export const urlUpdateCustomerReviewerMapper =
  "/api/v1/customerreviewermapper/unassign";

export const urlReviewerCustomerMapperList = "/api/v1/reviewercustomerlist";

export const urlCustomerReviewerMapperList = "/api/v1/customerreviewerlist";
export const urlBackOfficeRoleList = "/api/v1/backoffice_user_role";

// template API
export const urlQuestionaireTemplate = "/api/v1/questionaire/template";

//Tickets
export const urlTickets = "/api/v1/tickets";
export const urlUpdateTicket = "/api/v1/ticket/update";
export const urlTicketConversations = "/api/v1/ticket/conversations";
export const urlTicketConversationsInfo = "/api/v1/ticket/conversations/info";
export const urlQuestionaireSection = "/api/v1/questionaire";
export const urlQuestionaireConversations = "/api/v1/questionaire/comment";
export const urlQuestionaireConversationsEnd = '/api/v1/questionaire/comment/end';
export const urlQuestionaireConversationsInfo = "/api/v1/questionaire/comment/info";
export const urlQuestionaireCommentSuummary = "/api/v1/questionaire/comment/summary";
export const urlQuestionaireSubmit = "/api/v1/questionaire/submit";
export const urlQuestionaireReviewedSubmit = "/api/v1/questionaire/reviewed";
export const urlNotification = "/api/v1/notification";
export const urlNotificationUpdate = "/api/v1/notification/update";

export const urlReports = "/api/v1/reports";
export const urlReportStatusUpdate = '/api/v1/reports/update';
// Templates
export const urlTemplates = "/api/v1/templates";
