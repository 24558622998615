import React from "react";
import { Row, Col, Form, Input } from "antd";
import FloatInput from "../../../../Components/Input/numbertofloat";

const CP8_4Comp = (props) => {
  return (
    <Form.Item label="CP8-4" className="title-header">
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <span style={{ fontSize: "12px", fontWeight: "700" }}>
            {`Percentage of input material (inputs to total inputs by value) sourced from suppliers.`}
          </span>
          <Row style={{ width: "100%" }}>
            <Col span={24}>
              <div id="tbl-cell">
                <Row>
                  <Col span={8} className="text-center">
                    <span
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >{``}</span>
                  </Col>
                  <Col span={8} className="text-center">
                    <span style={{ fontSize: "12px", fontWeight: "600" }}>
                      FY {props?.data?.current_fy} (Current)
                    </span>
                  </Col>
                  <Col span={8} className="text-center">
                    <span style={{ fontSize: "12px", fontWeight: "600" }}>
                      FY {props?.data?.previous_fy} (Previous)
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col span={8} className="text-center">
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      {`Directly sourced from MSMEs/ small producers`}
                    </span>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={["cp8_4", "r1c1"]}>
                      {/* <Input
                        readOnly={props.readonly}
                        type="number" min="0"
                        size="small"
                        suffix={"%"}
                      /> */}
                      <FloatInput
                        size="small"
                        suffix={"%"}
                        readOnly={props.readonly}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={["cp8_4", "r1c2"]}>
                      <FloatInput
                        size="small"
                        suffix={"%"}
                        readOnly={props.readonly}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className="text-center">
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      {`Directly from within India`}
                    </span>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={["cp8_4", "r2c1"]}>
                      <FloatInput
                        size="small"
                        suffix={"%"}
                        readOnly={props.readonly}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={["cp8_4", "r2c2"]}>
                      <FloatInput
                        size="small"
                        suffix={"%"}
                        readOnly={props.readonly}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default CP8_4Comp;
