import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createadmin,
  getBackofficeUsersList,
  updateBackofficeUser,
} from "../../../Config/actions";

const createBackofficeUserSlice = createAsyncThunk(
  "backoffice/createBackofficeUser",
  async (value) => {
    return await createadmin(value);
  }
);
const fetchBackofficeUsers = createAsyncThunk(
  "backoffice/backofficeUsers",
  async (customer_id, thunkAPI) => {
    const response = await getBackofficeUsersList();
    return response.data;
  }
);

const updateBackofficeUsersSlice = createAsyncThunk(
  "backoffice/updatebackofficeUsers",
  async (value) => {
    return await updateBackofficeUser(value);
  }
);
export {
  createBackofficeUserSlice,
  fetchBackofficeUsers,
  updateBackofficeUsersSlice,
};
