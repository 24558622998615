import React, { useState } from "react";
import Sidebar from "./sidebar";
import { Layout } from "antd";
import { AppstoreOutlined } from "@ant-design/icons";
import Navbar from "../../Pages/layout/Header/header";
import navdata from "./data.json";
import { useCustomer } from "../../hooks/useCustomer";

const { Content } = Layout;
const CommonCompbar = (props) => {
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const { assigned_template_id } = useCustomer();
  const items = [
    {
      label: 'Section A',
      key: 'sectiona',
      path: '/questionaire',
      icon: <AppstoreOutlined />,
    },
    {
      label: 'Section B',
      key: 'sectionb',
      icon: <AppstoreOutlined />,
      disabled: false,
    },
    {
      label: 'Section CP1',
      key: 'sectionc',
      icon: <AppstoreOutlined />,
    },
    {
      label: 'Section CP2',
      key: 'sectiond',
      icon: <AppstoreOutlined />,
      disabled: false,
    },
    {
      label: 'Section CP3',
      key: 'sectionep1',
      icon: <AppstoreOutlined />,
    },
    {
      label: 'Section CP4',
      key: 'sectionep2',
      icon: <AppstoreOutlined />,
    },
    {
      label: `Section CP5`,
      key: 'sectionep3',
      icon: <AppstoreOutlined />,
    },
    {
      label: `Section CP6`,
      key: 'sectionep4',
      icon: <AppstoreOutlined />,
    },

    {
      label: 'Section CP7',
      key: 'sectionep7',
      icon: <AppstoreOutlined />,
      disabled: assigned_template_id === 1 ? true : false,
    },
    {
      label: 'Section CP8',
      key: 'sectionep8',
      icon: <AppstoreOutlined />,
    },
    {
      label: 'Section CP9',
      key: 'sectionep9',
      icon: <AppstoreOutlined />,
    },
    {
      label: 'Section D',
      key: 'sectionep5',
      icon: <AppstoreOutlined />,
      disabled:
        assigned_template_id === 1 || assigned_template_id === 2 ? true : false,
    },
    {
      label: 'Section E',
      key: 'sectionep6',
      icon: <AppstoreOutlined />,
      disabled:
        assigned_template_id === 1 || assigned_template_id === 2 ? true : false,
    },
    {
      label: 'Submit for Review',
      key: 'finalsubmit',
      icon: <AppstoreOutlined />,
      disabled: sessionState.roletype === 'CUSTOMER' ? false : true,
    },
  ];
  return (
    <>
      <Layout>
        <Navbar navdata={navdata} />
        <Layout>
          {/* {props.sidebar && (
            <Sidebar
              items={items}
              collapsed={false}
              btnClick={props.btnClick}
              current={props.current}
              style={"ant-menu1"}
            />
          )} */}

          <Content
            style={{
              margin: "10px 16px",
              padding: 24,
              minHeight: 100,
              background: "#fff",
              borderRadius: "5px",
              border: "1px solid #fff",
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export default CommonCompbar;
