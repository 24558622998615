import { createContext } from "react";

const FinancialYearContext = createContext({});

export const FinancialYearProvider = ({ children }) => {
  let current_year = new Date().getFullYear();
  let current_month = new Date().getMonth() + 1;
  let current_fy =
    current_month > 3
      ? current_year - 1 + '-' + current_year
      : current_year + '-' + (current_year - 1);
  let previous_fy =
    current_month > 3
      ? (current_year - 2) + "-" + (current_year-1)
      : (current_year - 3) + "-" + (current_year - 2);
  let previous_previous_fy =
    current_month > 3
      ? (current_year -3) + "-" + (current_year - 2)
      : (current_year - 4) + "-" + (current_year - 3);
  console.log(current_fy, previous_fy, previous_previous_fy);
  return (
    <FinancialYearContext.Provider
      value={{ current_fy, previous_fy, previous_previous_fy }}
    >
      {children}
    </FinancialYearContext.Provider>
  );
};

export default FinancialYearContext;
