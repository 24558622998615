import { notification } from "antd";

// const [messageApi, contextHolder] = message.useMessage();
// export const message1 = (props) => {
//   const success = (props) => {
//     messageApi.open({
//       type: props.type,
//       content: props.msg,
//     });
//   };
//   return (
//     <>
//       {contextHolder}
//       {/* <Button onClick={success}>Customized display duration</Button> */}
//     </>
//   );
// };
export const messageAlert = (props) => {
  return notification.open({
    message: props.title,
    description: props.msg,
    onClick: () => {
      console.log("Notification Clicked!");
    },
  });
};
