import { useEffect, useState } from "react";
import intl from "react-intl-universal";
import { message, Button } from "antd";

const STATUS_MAP = [
  {
    label: intl.get("person.2fa.disabled"),
    btnContent: "Enable 2-Factor Authentication", //intl.get('person.2fa.btnContent.enabled'),
    className: "disabled",
  },
  {
    label: intl.get("person.2fa.enabled"),
    btnContent: "Disabled", //intl.get('person.2fa.btnContent.disabled'),
    className: "enabled",
  },
];

export default function Status(props) {
  const { setShowQRCode } = props;
  const [status, setStatus] = useState(0);
  const [label, setLabel] = useState(STATUS_MAP[status].label);
  const [btnContent, setBtnContent] = useState(STATUS_MAP[status].btnContent);

  useEffect(() => {
    setLabel(STATUS_MAP[status].label);
    setBtnContent(STATUS_MAP[status].btnContent);
  }, [status]);
  const handleClick = () => {
    if (status) {
      message.warn({
        title: intl.get("common.warning"),
        subtitle: intl.get("person.2fa.disable.warnMessage"),
        onOk: () => {
          // Api.manage2FAById({
          //   user_id: userId,
          //   switch_2fa: false,
          // }).then(res => {
          //   if (res.code === 0) {
          //     setStatus(0);
          //     Notification.success({ message: intl.get('admin.disabled2fa.success') });
          //   }
          // })
        },
      });
    } else {
      setShowQRCode(true);
    }
  };

  return (
    <div className="status-wrap">
      <div>
        <span className="f-vpn-name-label">
          {/* {intl.get('person.2fa.status')} */}
          Status:
        </span>
        <span className={`status-2fa ${STATUS_MAP[status].className}`}>
          Disabled{label}
        </span>
      </div>
      <div>
        <Button onClick={handleClick}>{btnContent}</Button>
      </div>
    </div>
  );
}
