import React, { useEffect, useState } from "react";
import { Divider, Row, Col, Form, Radio, Input } from "antd";
import CommentsApp from "../../../../Components/Comments";

const Cont13 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <>
      <Form.Item label="CP3-18" className="title-header">
        <Row>
          <Col span={24}>
            <span style={{ fontSize: "12px", fontWeight: "700" }}>
              {`Provide the details of number of employees / workers having suffered high consequence workrelated injury / ill-health / fatalities, who have been are rehabilitated and placed in suitable employment or whose family members have been placed in suitable employment:`}
            </span>
            <div id="tbl-cell">
              <Row>
                <Col span={4}>
                  <span style={{ fontSize: "12px", fontWeight: "600" }}></span>
                </Col>
                <Col span={10} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`Total no. of affected employees/ workers`}
                  </span>
                </Col>
                <Col span={10} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`No. of employees/workers that are rehabilitated and placed in suitable employment or whose family members have been placed in suitable employment`}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={5} offset={4}>
                  <span style={{ fontSize: "12px", fontWeight: "500" }}>
                    FY :{props?.data?.current_fy} Current Financial Year
                  </span>
                </Col>
                <Col span={5} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "500" }}>
                    FY :{props?.data?.previous_fy} Previous Financial Year
                  </span>
                </Col>

                <Col span={5}>
                  <span style={{ fontSize: "12px", fontWeight: "500" }}>
                    FY :{props?.data?.current_fy} Current Financial Year
                  </span>
                </Col>
                <Col span={5} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "500" }}>
                    FY :{props?.data?.previous_fy} Previous Financial Year
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={4} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`Employees`}
                  </span>
                </Col>
                <Col span={5}>
                  <Form.Item name={[props.ques_id, "r1c1"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name={[props.ques_id, "r1c2"]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={5}>
                  <Form.Item name={[props.ques_id, "r1c3"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name={[props.ques_id, "r1c4"]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`Workers`}
                  </span>
                </Col>
                <Col span={5}>
                  <Form.Item name={[props.ques_id, "r2c1"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name={[props.ques_id, "r2c2"]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={5}>
                  <Form.Item name={[props.ques_id, "r2c3"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name={[props.ques_id, "r2c4"]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default Cont13;
