import React, { useEffect, useState } from "react";
import { Button, Form, Input, Table, Modal, message, Row, Col, Popconfirm } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
const T11 = (props) => {
  const [form] = Form.useForm();
  const [modelOpen, setModelOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [notification, setNotification] = useState("");
  const [brief_details, setBrief_details] = useState("");
  const [notification_date, setNotificationDate] = useState("");
  const handleDelete = (key) => {
    const newData = dataSource.filter((item, index) => index !== key);
    setDataSource(newData);
    props.setdata(newData);
  };
  const Columns = [
    {
      title: 'SNo.',
      dataIndex: 'key',
      width: '10%',
      editable: true,
    },
    {
      title: 'Location of operations /offices	',
      dataIndex: 'r1c1',
      editable: true,
    },
    {
      title: 'Type of operations',
      dataIndex: 'r1c2',
    },
    {
      title:
        'Whether the conditions of environmental approval / clearance are being complied with? (Y/N) If no, the reasons thereof and corrective action taken, if any.',
      dataIndex: 'r1c3',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record, index) =>
        dataSource.length >= 1 ? (
          <>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(index)}
            >
              <Button className="ant-btn-link" type="link">
                <DeleteOutlined twoToneColor="#eb2f96" />
              </Button>
            </Popconfirm>
          </>
        ) : null,
    },
  ];

  useEffect(() => {
    setDataSource(props.data.cp6_11 || []);
  }, [props.data]);

  const btnAdd = (values) => {
    let count = dataSource.length;
    const newData = {
      key: count + 1,
      r1c1: brief_details,
      r1c2: notification,
      r1c3: notification_date,
    };
    let updatedData = [...dataSource, newData];
    setDataSource([...dataSource, newData]);
    props.setdata([...dataSource, newData]);
    message.success("Processing complete!");
    setModelOpen(false);
  };
  return (
    <div>
      <Row>
        <Col span={24} offset={22}>
          <span>
            <Button
              onClick={() => {
                setModelOpen(true);
                setBrief_details('');
                setNotification('');
                setNotificationDate('');
                form.resetFields();
              }}
              disabled={props.readonly}
            >
              Add
            </Button>
          </span>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={Columns}
        pagination={false}
        className="f-table-wrapper"
        scroll={{
          y: 240,
        }}
      />

      <Modal
        title="Add"
        style={{ top: 20 }}
        open={modelOpen}
        onOk={btnAdd}
        okText="Add"
        onCancel={() => setModelOpen(false)}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 22 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <div className="modalform">
            <Form.Item
              name="brief_details"
              label="Location of operations /offices"
            >
              <Input
                readOnly={props.readonly}
                onChange={(e) => setBrief_details(e.target.value)}
              />
            </Form.Item>
            <Form.Item name="notification" label="Type of operations">
              <Input
                readOnly={props.readonly}
                onChange={(e) => setNotification(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="notification_date"
              label="Whether the conditions of environmental approval / clearance are being complied with? (Y/N) If no, the reasons thereof and corrective action taken, if any."
            >
              <Input
                readOnly={props.readonly}
                onChange={(e) => setNotificationDate(e.target.value)}
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default T11;
