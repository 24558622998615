import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Divider,
  Radio,
  Card,
  message,
  Row,
  Col,
  Input,
  Select,
  Space,
  Form,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useDispatch } from "react-redux";
import {
  create_section_slice,
  get_section,
} from "../SectionSlice/sectionSlice";
import CommentsApp from "../../../../Components/Comments";
import T2 from "./T2";
import T3 from "./T3";
import T4 from "./T4";
import { useFinancialYear } from "../../../../hooks/useFinancialYear";
import { useCustomer } from "../../../../hooks/useCustomer";
import CommentsNotified from "../../../../Components/Comments/comments-notification";

const { Option } = Select;
const App = (props) => {
  const { current_fy, previous_fy } = useFinancialYear();
  const { customer_id, assigned_template_id } = useCustomer();
  const [cp2, setCP2] = useState([]);
  const [cp3, setCP3] = useState([]);
  const [cp4, setCP4] = useState([]);
  const [cp5, setCP5] = useState("No");
  const [cp11, setCP11] = useState("No");
  const [cp15, setCP15] = useState("No");
  const [cp16, setCP16] = useState("No");
  const [cp17, setCP17] = useState("No");
  const [cp18, setCP18] = useState("No");
  const [spinning, setSpinning] = useState(false);
  const [defaultdata, setDefaultdata] = useState({});
  const [readonly, setReadonly] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const [commentlist, setCommentlist] = useState([]);
  const [commentreload, setCommentReload] = useState();

  useEffect(() => {
    if (sessionState?.roletype === "CUSTOMER") {
      setReadonly(false);
    } else {
      setReadonly(true);
    }
    let payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_cp9",
    };
    setSpinning(true);
    dispatch(get_section({ payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setDefaultdata(res.data?.section_cp9);
          setCommentlist(res.data?.comments);
          setSpinning(false);
        } else {
          if (res.code != 800000) {
            message.info(res.msg);
          }

          setSpinning(false);
        }
      })
      .catch((Error) => {
        console.log(Error);
      });
  }, [commentreload]);

  const onFinish = (datalist) => {
    setSpinning(true);
    const datalist1 = {
      ...datalist,
    };
    let Payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_cp9",
      question_response: datalist1,
    };
    dispatch(create_section_slice({ Payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setSpinning(false);
          message.info("Section CP9 created succesfully!");
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };
  useEffect(() => {
    form.setFieldsValue(defaultdata);
    setCP5(form.getFieldValue(["cp9_5", "r1c1"]));
    setCP11(form.getFieldValue(["cp9_11", "r1c1"]));
    setCP15(form.getFieldValue(["cp9_15", "r1c1"]));
    setCP16(form.getFieldValue(["cp9_16", "r1c1"]));
    setCP17(form.getFieldValue(["cp9_17", "r4c1"]));
    setCP18(form.getFieldValue(["cp9_18", "r1c1"]));
  }, [form, defaultdata]);
  return (
    <>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 44, color: "white" }} spin />
        }
        tip="Loading..."
        spinning={spinning}
        fullscreen
        size="large"
      />
      <div className="formcontainer">
        <CommentsNotified commentlist={commentlist} />
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={defaultdata}
          scrollToFirstError
          style={{ width: "100%" }}
        >
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Card title="Essential Indicators" size="small">
              <Divider />
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP9-1" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Describe the mechanisms in place to receive and respond to consumer complaints and feedback.`}
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["cp9_1", "r1c1"]}>
                          <Input.TextArea
                            readOnly={readonly}
                            rows={2}
                            placeholder="Enter Describe the mechanisms"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp9_1"
                    />
                  </Form.Item>
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Row>
                    <Col span={24}>
                      <T2
                        readonly={readonly}
                        data={{ cp2 }}
                        setdata={setCP2}
                        form={form}
                        ques_id={"cp9_2"}
                      />
                    </Col>
                  </Row>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp9_2"
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Row>
                    <Col span={24}>
                      <T3
                        readonly={readonly}
                        data={{ current_fy, previous_fy, cp3 }}
                        setdata={setCP3}
                        form={form}
                        ques_id={"cp9_3"}
                      />
                    </Col>
                  </Row>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp9_3"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Row>
                    <Col span={24}>
                      <T4
                        readonly={readonly}
                        data={{ cp4 }}
                        setdata={setCP4}
                        form={form}
                        ques_id={"cp9_4"}
                      />
                    </Col>
                  </Row>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp9_4"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP9-5" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Does the entity have a framework/ policy on cyber security and risks related to data privacy? (Yes/No) If available, provide a web-link of the policy.`}
                        </span>
                      </Col>
                      <Col span={14}>
                        <div style={{ display: "flex" }}>
                          <Form.Item name={["cp9_5", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              onChange={(value) => setCP5(value)}
                              style={{ width: "200px", marginRight: "10px" }}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>

                          {cp5 === "Yes" && (
                            <Form.Item name={["cp9_5", "r1c2"]}>
                              <Input.TextArea
                                readOnly={readonly}
                                rows={3}
                                placeholder="Enter web link"
                              />
                            </Form.Item>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp9_5"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP9-6" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Provide details of any corrective actions taken or underway on issues relating to advertising, and delivery of essential services; cyber security and data privacy of customers; re-occurrence of instances of product recalls; penalty / action taken by regulatory authorities on safety of products / services.`}
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["cp9_6", "r1c1"]}>
                          <Input.TextArea
                            readOnly={readonly}
                            rows={3}
                            placeholder="Enter details"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp9_6"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP9-7" className="title-header">
                    <Row>
                      <Col span={22}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Provide the following information relating to data breaches:`}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`[A]: Number of instances of data breaches`}
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["cp9_7", "r1c1"]}>
                          <Input
                            readOnly={readonly}
                            type="number"
                            min="0"
                            placeholder="Enter Number"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={8}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`[B]: Percentage of data breaches involving personally identifiable information of customers`}
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["cp9_7", "r2c1"]}>
                          <Input
                            readOnly={readonly}
                            suffix="%"
                            placeholder="Enter Number"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={8}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`[C]: Impact, if any, of the data breaches`}
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["cp9_7", "r3c1"]}>
                          <Input.TextArea
                            readOnly={readonly}
                            rows={2}
                            placeholder="Enter data breaches"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp9_7"
                  />
                </>
              )}
            </Card>
          </Space>

          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[3, 4].includes(assigned_template_id) && (
              <Card title="Leadership Indicators 1" size="small">
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP9-8" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Channels / platforms where information on products and services of the entity can be accessed (provide web link, if available).`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp9_8", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp9_8"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP9-9" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Steps taken to inform and educate consumers about safe and responsible usage of products and/or services.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp9_9", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp9_9"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP9-10" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Mechanisms in place to inform consumers of any risk of disruption/discontinuation of essential services.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp9_10", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter Details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp9_10"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP9-11" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Does the entity display product information on the product over and above what is mandated as per local laws? (Yes/No/Not Applicable) If yes, provide details in brief.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <div style={{ display: "flex" }}>
                            <Form.Item name={["cp9_11", "r1c1"]}>
                              <Select
                                disabled={readonly}
                                onChange={(value) => setCP11(value)}
                                style={{ width: "200px", marginRight: "10px" }}
                                placeholder="Select Option"
                              >
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                                <Option value="Not Applicable">
                                  Not Applicable
                                </Option>
                              </Select>
                            </Form.Item>
                            {cp11 === "Yes" && (
                              <Form.Item name={["cp9_11", "r1c2"]}>
                                <Input.TextArea
                                  readOnly={readonly}
                                  rows={3}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp9_11"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP9-12" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Did your entity carry out any survey with regard to consumer satisfaction relating to the major products / services of the entity, significant locations of operation of the entity or the entity as a whole? (Yes/No)`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp9_12", "r1c1"]}>
                            <Radio.Group disabled={readonly}>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp9_12"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP9-13" className="title-header">
                      <Row>
                        <Col span={22}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Political contributions`}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: Total monetary value of financial and in-kind political contributions made directly and indirectly by the organization by country and recipient/beneficiary.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp9_13", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              type="number"
                              min="0"
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[B]: If applicable, how the monetary value of in-kind contributions was estimated.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp9_13", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter retained"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp9_13"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP9-14" className="title-header">
                      <Row>
                        <Col span={22}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Assessment of the health and safety impacts of product and service categories`}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Percentage of significant product and service categories for which health and safety impacts are assessed for improvement.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp9_14", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              type="number"
                              min="0"
                              placeholder="Enter number"
                              suffix="%"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp9_14"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP9-15" className="title-header">
                      <Row>
                        <Col span={22}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Incidents of non-compliance concerning the health and safety impacts of products and services`}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Has the organization identified any non-compliance with regulations and/or voluntary codes with respect to health and safety`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <div style={{ display: "flex" }}>
                            <Form.Item name={["cp9_15", "r1c1"]}>
                              <Select
                                disabled={readonly}
                                onChange={(value) => setCP15(value)}
                                style={{ width: "200px", marginRight: "10px" }}
                                placeholder="Select Option"
                              >
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                              </Select>
                            </Form.Item>

                            {cp15 === "Yes" && (
                              <Form.Item name={["cp9_15", "r1c2"]}>
                                <Input.TextArea
                                  readOnly={readonly}
                                  rows={3}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp9_15"
                    />
                  </>
                )}
              </Card>
            )}
          </Space>

          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[3, 4].includes(assigned_template_id) && (
              <Card title="Leadership Indicators 2" size="small">
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP9-16" className="title-header">
                      <Row>
                        <Col span={22}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            Incidents of non-compliance concerning product and
                            service information and labeling
                            <br></br>
                            a. Total number of incidents of non-compliance with
                            regulations and/or voluntary codes concerning
                            product and service information and labeling, by:
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            i. incidents of non-compliance with regulations
                            resulting in a fine or penalty;
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp9_16", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            ii. incidents of non-compliance with regulations
                            resulting in a warning;
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp9_16", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            iii. incidents of non-compliance with voluntary
                            codes.
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp9_16", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            Has the organization identified any non-compliance
                            with regulations and/or voluntary codes with respect
                            to product and service information
                          </span>
                        </Col>
                        <Col span={14}>
                          <div style={{ display: "flex" }}>
                            <Form.Item name={["cp9_16", "r4c1"]}>
                              <Select
                                disabled={readonly}
                                onChange={(value) => setCP16(value)}
                                style={{ width: "200px", marginRight: "10px" }}
                                placeholder="Select Option"
                              >
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                              </Select>
                            </Form.Item>

                            {cp16 === "Yes" && (
                              <Form.Item name={["cp9_16", "r4c2"]}>
                                <Input.TextArea
                                  readOnly={readonly}
                                  rows={3}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp9_16"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP9-17" className="title-header">
                      <Row>
                        <Col span={22}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            Incidents of non-compliance concerning marketing
                            communications
                            <br></br>
                            a. Total number of incidents of non-compliance with
                            regulations and/or voluntary codes concerning
                            marketing communications, including advertising,
                            promotion, and sponsorship, by:
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            i. incidents of non-compliance with regulations
                            resulting in a fine or penalty;
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp9_17", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            ii. incidents of non-compliance with regulations
                            resulting in a warning;
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp9_17", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            iii. incidents of non-compliance with voluntary
                            codes.
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp9_17", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            b. Has the organization identified any
                            non-compliance with regulations and/or voluntary
                            codes with respect to marketing communications
                          </span>
                        </Col>
                        <Col span={14}>
                          <div style={{ display: "flex" }}>
                            <Form.Item name={["cp9_17", "r4c1"]}>
                              <Select
                                disabled={readonly}
                                onChange={(value) => setCP17(value)}
                                style={{ width: "200px", marginRight: "10px" }}
                                placeholder="Select Option"
                              >
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                              </Select>
                            </Form.Item>

                            {cp17 === "Yes" && (
                              <Form.Item name={["cp9_17", "r4c2"]}>
                                <Input.TextArea
                                  readOnly={readonly}
                                  rows={3}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp9_17"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP9-18" className="title-header">
                      <Row>
                        <Col span={22}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            Substantiated complaints concerning breaches of
                            customer privacy and losses of customer data
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Has the organization identified any non-compliance with regulations and/or voluntary codes with respect to breaches of customer privacy and losses of customer data`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <div style={{ display: "flex" }}>
                            <Form.Item name={["cp9_18", "r1c1"]}>
                              <Select
                                disabled={readonly}
                                onChange={(value) => setCP18(value)}
                                style={{ width: "200px", marginRight: "10px" }}
                                placeholder="Select Option"
                              >
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                              </Select>
                            </Form.Item>

                            {cp18 === "Yes" && (
                              <Form.Item name={["cp9_18", "r1c2"]}>
                                <Input.TextArea
                                  readOnly={readonly}
                                  rows={3}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp9_18"
                    />
                  </>
                )}
              </Card>
            )}
          </Space>

          {readonly ? (
            ""
          ) : (
            <Space
              style={{
                //marginTop: "10px",
                marginBottom: "10px",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <Button style={{ marginLeft: 10 }} htmlType="submit">
                Save and Continue
              </Button>
            </Space>
          )}
        </Form>
      </div>
    </>
  );
};

export default App;
