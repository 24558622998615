import { useEffect, useState } from "react";
import intl from "react-intl-universal";
import { Button, Input, Tooltip, message } from "antd";
import googleAppAndroid from "./google-app-android.png";
import microsoftAppAndroid from "./microsoft-app-android.png";
import { useAuthDispatch, very2FAQRCode } from "../../../Context";
import { useCustomer } from "../../../hooks/useCustomer";
import { useNavigate } from "react-router-dom";
import "./index.scss";

export default function QRCode(props) {
  const [imgSrc, setImgSrc] = useState("");
  const [key, setKey] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [check, setCheck] = useState({ checkout: true });
  const { setProfile, setAssignedTemplateId, setCustomerId } = useCustomer();
  const navigate = useNavigate();

  const validate = (val) => {
    const codeLength = val === undefined ? verifyCode.length : val.length;
    let res = {
      checkout: true,
    };
    if (!codeLength) {
      res = {
        checkout: false,
      };
    } else if (codeLength > 32) {
      res = {
        checkout: false,
        msg: intl.get("person.2fa.googleAuth.code.maxLength"),
      };
    }
    setCheck(res);
    return res.checkout;
  };
  useEffect(() => {
    setImgSrc(`data:image/png;base64,${props.data.data.qr_code}`);
    setKey(props.data.data.secret_key);
  }, [props]);

  const renderGoogleAppQRCode = () => (
    <div className="app-qrcode-wrap">
      <section>
        <img alt="Google Authenticator for Android" src={googleAppAndroid} />
        <span>Android</span>
      </section>
    </div>
  );
  const renderMicrosoftAppQRCode = () => (
    <div className="app-qrcode-wrap">
      <section>
        <img alt="Google Authenticator for Android" src={microsoftAppAndroid} />
        <span>Android</span>
      </section>
    </div>
  );
  const dispatch = useAuthDispatch();
  const submit = async () => {
    const params = {
      username: localStorage.getItem("currentUser"),
      login_type: localStorage.getItem("login_type"),
      dynamic_code: verifyCode,
    };
    let response = await very2FAQRCode(dispatch, { params });
    if (response.code === 200009) {
      message.info(response.msg);
    } else {
      //message.info(response.msg);
      localStorage.setItem("auth", true);
      localStorage.setItem("token", response?.data?.token);
      localStorage.setItem("user_id", response?.data?.user_id);
      localStorage.setItem("roletype", response?.data?.role_type);
      var sessionObject = {
        auth: true,
        token: response?.data?.token,
        user_id: response?.data?.user_id,
        roletype: response?.data?.role_type,
      };
      localStorage.setItem("sessionObject", JSON.stringify(sessionObject));
      if (response?.data?.misc) {
        localStorage.setItem(
          "assigned_template_name",
          response?.data?.misc?.assigned_template_name
        );
        localStorage.setItem(
          "assigned_template_id",
          response?.data?.misc?.assigned_template_id
        );
        setAssignedTemplateId(response?.data?.misc?.assigned_template_id);
        setCustomerId(response?.data?.misc?.customer_id);
        localStorage.setItem("customer_id", response?.data?.misc?.customer_id);
        localStorage.setItem("cin_number", response?.data?.misc?.cin_number);
        localStorage.setItem(
          "company_email",
          response?.data?.misc?.company_email
        );
        localStorage.setItem("website", response?.data?.misc?.website);
        localStorage.setItem(
          "company_phone",
          response?.data?.misc?.company_phone
        );
        localStorage.setItem(
          "company_name",
          response?.data?.misc?.company_name
        );
        localStorage.setItem(
          "registered_address",
          response?.data?.misc?.registered_address
        );
        localStorage.setItem(
          "year_of_incorporation",
          response?.data?.misc?.year_of_incorporation
        );
      }
      navigate("/dashboard");
    }
  };
  const btncancel = () => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("login_type");
    localStorage.removeItem("auth2fa");
    localStorage.removeItem("token");
    localStorage.setItem("auth", false);
    window.location.reload();
  };
  return (
    <div className="code-wrap">
      <div style={{ margin: "20px" }}>
        <header>
          <span>
            Please download and install the
            <Tooltip title={renderGoogleAppQRCode} placement="bottom">
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                target="_blank"
                className="auth-red"
              >
                {" "}
                Google Authenticator
              </a>
            </Tooltip>
            Or
            <Tooltip title={renderMicrosoftAppQRCode} placement="bottom">
              <a
                href="https://play.google.com/store/apps/details?id=com.azure.authenticator&pli=1"
                target="_blank"
                className="auth-red"
              >
                {" "}
                Microsoft Authenticator
              </a>
            </Tooltip>
            , and then follow its Instructions
          </span>
        </header>
        <main>
          <img
            style={{ width: "100px" }}
            alt={intl.get("person.2fa.googleAuth.qrCode")}
            src={imgSrc}
          />
          <div>
            <p className="gray">
              Make sure to save it now, this will be the only time you will ever
              be able to see it.
            </p>
            <div className="row">
              <p>
                <b className="gray">KEY</b>
              </p>
              <p>{key}</p>
            </div>

            <div className="row">
              <p>
                <b className="gray">Enter Authentication Code</b>
              </p>
              <Input
                type="text"
                onChange={(e) => {
                  validate(e.target.value);
                  setVerifyCode(e.target.value);
                }}
              />
              {/* <ErrorTooltip check={check}/> */}
            </div>

            <div className="submit">
              <Button onClick={btncancel}>Cancel</Button>
              <Button onClick={submit}>Submit</Button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
