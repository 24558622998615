import React, { useEffect } from "react";
import { Row, Col, Form } from "antd";
import InputNumber from "../../../../../Components/Input/Number/number";
import * as common from "../../../../../util/common";

const T2 = (props) => {
  const formula_defination = {
    r1c3: { type: "perfloat", cols: ["r1c2", "r1c1"] },
    r2c3: { type: "perfloat", cols: ["r2c2", "r2c1"] },
    r3c3: { type: "perfloat", cols: ["r3c2", "r3c1"] },
    r4c3: { type: "perfloat", cols: ["r4c2", "r4c1"] },
    r5c3: { type: "perfloat", cols: ["r5c2", "r5c1"] },
    r6c3: { type: "perfloat", cols: ["r6c2", "r6c1"] },
    r7c3: { type: "perfloat", cols: ["r7c2", "r7c1"] },
    r8c3: { type: "perfloat", cols: ["r8c2", "r8c1"] },
    r1c5: { type: "perfloat", cols: ["r1c4", "r1c1"] },
    r2c5: { type: "perfloat", cols: ["r2c4", "r2c1"] },
    r3c5: { type: "perfloat", cols: ["r3c4", "r3c1"] },
    r4c5: { type: "perfloat", cols: ["r4c4", "r4c1"] },
    r5c5: { type: "perfloat", cols: ["r5c4", "r5c1"] },
    r6c5: { type: "perfloat", cols: ["r6c4", "r6c1"] },
    r7c5: { type: "perfloat", cols: ["r7c4", "r7c1"] },
    r8c5: { type: "perfloat", cols: ["r8c4", "r8c1"] },
    r1c8: { type: "perfloat", cols: ["r1c7", "r1c6"] },
    r2c8: { type: "perfloat", cols: ["r2c7", "r2c6"] },
    r3c8: { type: "perfloat", cols: ["r3c7", "r3c6"] },
    r4c8: { type: "perfloat", cols: ["r4c7", "r4c6"] },
    r5c8: { type: "perfloat", cols: ["r5c7", "r5c6"] },
    r6c8: { type: "perfloat", cols: ["r6c7", "r6c6"] },
    r7c8: { type: "perfloat", cols: ["r7c7", "r7c6"] },
    r8c8: { type: "perfloat", cols: ["r8c7", "r8c6"] },
    r1c10: { type: "perfloat", cols: ["r1c9", "r1c6"] },
    r2c10: { type: "perfloat", cols: ["r2c9", "r2c6"] },
    r3c10: { type: "perfloat", cols: ["r3c9", "r3c6"] },
    r4c10: { type: "perfloat", cols: ["r4c9", "r4c6"] },
    r5c10: { type: "perfloat", cols: ["r5c9", "r5c6"] },
    r6c10: { type: "perfloat", cols: ["r6c9", "r6c6"] },
    r7c10: { type: "perfloat", cols: ["r7c9", "r7c6"] },
    r8c10: { type: "perfloat", cols: ["r8c9", "r8c6"] },
  };

  const handleChange = (e) => {
    common.arthiFunc(props.ques_id, formula_defination, props.form);
  };
  useEffect(() => {
    handleChange();
    props.form.setFieldsValue(props.data);
  }, [props.data]);
  return (
    <div>
      <Row>
        <Col span={24}>
          <div id="tbl-cell">
            <Row>
              <Col span={4}>Category</Col>
              <Col
                span={10}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                FY {props?.data?.current_fy} (Current Financial Year)
              </Col>
              <Col
                span={10}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                FY {props?.data?.previous_fy} (Previous Financial Year)
              </Col>
            </Row>
            <Row>
              <Col span={4}></Col>
              <Col span={2}>Total (A)</Col>
              <Col span={4}>Equal to Minimum Wage </Col>
              <Col span={4}>More than Minimum Wage </Col>
              <Col span={2}>Total (D)</Col>
              <Col span={4}>Equal to Minimum Wage </Col>
              <Col span={4}>More than Minimum Wage </Col>
            </Row>
            <Row>
              <Col span={4}></Col>
              <Col span={2}></Col>
              <Col span={2}>No.(B) </Col>
              <Col span={2}>% (B/A) </Col>
              <Col span={2}>No.(C) </Col>
              <Col span={2}>% (C/A) </Col>
              <Col span={2}></Col>
              <Col span={2}>No.(E) </Col>
              <Col span={2}>% (E/D) </Col>
              <Col span={2}>No.(F) </Col>
              <Col span={2}>% (F/D) </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Employees
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Permanent
              </Col>
            </Row>
            <Row>
              <Col span={4}>Male</Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r1c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r1c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r1c3"]} className="ant-col1">
                  <InputNumber
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                  
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r1c4"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r1c5"]} className="ant-col1">
                  <InputNumber
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r1c6"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r1c7"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r1c8"]} className="ant-col1">
                  <InputNumber
               
                    placeholder="Enter"
                    readonly={true}
                     type="number"
                  />
                </Form.Item>{" "}
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r1c9"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r1c10"]} className="ant-col1">
                  <InputNumber
       
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Female</Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r2c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r2c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r2c3"]} className="ant-col1">
                  <InputNumber
            
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r2c4"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r2c5"]} className="ant-col1">
                  <InputNumber
                
                    placeholder="Enter"
                    readonly={true}
                     type="number"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r2c6"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r2c7"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r2c8"]} className="ant-col1">
                  <InputNumber
              
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>{" "}
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r2c9"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r2c10"]} className="ant-col1">
                  <InputNumber
             
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Other than Permanent
              </Col>
            </Row>
            <Row>
              <Col span={4}>Male</Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r3c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r3c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r3c3"]} className="ant-col1">
                  <InputNumber
         
                    placeholder="Enter"
                    readonly={true}
                     type="number"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r3c4"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r3c5"]} className="ant-col1">
                  <InputNumber
             
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r3c6"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r3c7"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r3c8"]} className="ant-col1">
                  <InputNumber
             
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>{" "}
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r3c9"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r3c10"]} className="ant-col1">
                  <InputNumber
                 
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Female</Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r4c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r4c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r4c3"]} className="ant-col1">
                  <InputNumber
              
                    placeholder="Enter"
                    readonly={true}
                     type="number"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r4c4"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r4c5"]} className="ant-col1">
                  <InputNumber
              
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r4c6"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r4c7"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r4c8"]} className="ant-col1">
                  <InputNumber
                 
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>{" "}
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r4c9"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r4c10"]} className="ant-col1">
                  <InputNumber
          
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Workers
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Permanent
              </Col>
            </Row>
            <Row>
              <Col span={4}>Male</Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r5c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r5c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r5c3"]} className="ant-col1">
                  <InputNumber
             
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r5c4"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r5c5"]} className="ant-col1">
                  <InputNumber
              
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r5c6"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r5c7"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r5c8"]} className="ant-col1">
                  <InputNumber
              
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>{" "}
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r5c9"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r5c10"]} className="ant-col1">
                  <InputNumber
              
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Female</Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r6c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r6c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r6c3"]} className="ant-col1">
                  <InputNumber
             
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r6c4"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r6c5"]} className="ant-col1">
                  <InputNumber
                
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r6c6"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r6c7"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r6c8"]} className="ant-col1">
                  <InputNumber
                   
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>{" "}
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r6c9"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r6c10"]} className="ant-col1">
                  <InputNumber
               
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Other than Permanent
              </Col>
            </Row>
            <Row>
              <Col span={4}>Male</Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r7c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r7c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r7c3"]} className="ant-col1">
                  <InputNumber
                  
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r7c4"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r7c5"]} className="ant-col1">
                  <InputNumber
               
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r7c6"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r7c7"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r7c8"]} className="ant-col1">
                  <InputNumber
               
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>{" "}
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r7c9"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r7c10"]} className="ant-col1">
                  <InputNumber
                
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Female</Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r8c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r8c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r8c3"]} className="ant-col1">
                  <InputNumber
                 
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r8c4"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r8c5"]} className="ant-col1">
                  <InputNumber
               
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r8c6"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r8c7"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r8c8"]} className="ant-col1">
                  <InputNumber
                  
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>{" "}
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r8c9"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={[props.ques_id, "r8c10"]} className="ant-col1">
                  <InputNumber
                 
                    placeholder="Enter"
                    readonly={true}
                     type="number" 
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default T2;
