import React from "react";
import { Form, Input, Button, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { forgotPasswordSlice } from "./forgotPasswordSlice/forgotPasswordSlice";
import logoPic from "../../static/images/nslogo-large.png";
import { messageAlert } from "../../Components/Alert";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const forgotPassword = async (data) => {
    try {
      let response = await dispatch(forgotPasswordSlice(data));
      if (!response === undefined) return;
      if (response.payload?.data?.code === 0) {
        messageAlert({
          title: "Alert!",
          msg: response.payload?.data?.data?.msg,
        });
      } else {
        message.info(response.payload?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="m-login-wrap">
      <div>
        <a href="#">
          <img src={logoPic} className="logo-large" alt="logo" />
        </a>
      </div>
      <div className={`m-login`}>
        
        <div className="m-login-title">
          Forgot Password
          <div className="subtitle">
            Enter the registered email id where you will receive the reset
            password link
          </div>
        </div>
        <div className="m-login-form">
          <Form
            layout="vertical"
            className="loginform"
            name="normal_login"
            onFinish={forgotPassword}
          >
            <div>
              <Form.Item
                label="Email Address"
                tooltip="Allow only for registered user email-id"
                name="username"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  className="m-login-input"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="email"
                />
              </Form.Item>
              <Button className="m-login-btn" htmlType="submit" block>
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
