import React, { useEffect, useState } from "react";
import { Button, Form, Input, Table, Modal, message, Row, Col, Popconfirm } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
const T17 = (props) => {
  const [form] = Form.useForm();
  const [modelOpen, setModelOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [age, setAge] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const handleDelete = (key) => {
    const newData = dataSource.filter((item, index) => index !== key);
    setDataSource(newData);
    props.setdata(newData);
    message.success('Row deleted!');
  };
  const Columns = [
    {
      title: 'SNo.',
      dataIndex: 'key',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Product/Service',
      dataIndex: 'r1c1',
      width: '30%',
      editable: true,
    },
    {
      title: 'NIC Code',
      dataIndex: 'r1c2',
    },
    {
      title: '%  of  total Turnover contributed',
      dataIndex: 'r1c3',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record, index) =>
        dataSource.length >= 1 ? (
          <>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(index)}
            >
              <Button className="ant-btn-link" type="link">
                <DeleteOutlined twoToneColor="#eb2f96" />
              </Button>
            </Popconfirm>
          </>
        ) : null,
    },
  ];

  useEffect(() => {
    setDataSource(props.data.a17 || []);
  }, [props.data]);

  const btnAdd = (values) => {
    const newData = {
      r1c1:name,
      r1c2:age,
      r1c3:address,
    };
    setDataSource([...dataSource, newData]);
    props.setdata([...dataSource, newData]);
    message.success("Processing complete!");
    setModelOpen(false);
  };
  return (
    <div>
      <Row>
        <Col span={24} offset={22}>
          <span>
            <Button
              onClick={() => {
                setModelOpen(true);
                setAge('');
                setName('');
                setAddress('');
                form.resetFields();
              }}
              disabled={props.readonly}
            >
              Add
            </Button>
          </span>
        </Col>
      </Row>
      <Table
        disabled={true}
        dataSource={dataSource}
        columns={Columns}
        pagination={false}
        className="f-table-wrapper"
        scroll={{
          y: 240,
        }}
      />

      <Modal
        title="Add"
        style={{ top: 20 }}
        open={modelOpen}
        onOk={btnAdd}
        okText="Add"
        onCancel={() => setModelOpen(false)}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 22 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <div className="modalform">
            <Form.Item name="name" label="Product/Service">
              <Input onChange={(e) => setName(e.target.value)} />
            </Form.Item>
            <Form.Item name="age" label="NIC Code">
              <Input onChange={(e) => setAge(e.target.value)} />
            </Form.Item>
            <Form.Item name="address" label="% of total Turnover contributed">
              <Input onChange={(e) => setAddress(e.target.value)} />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default T17;
