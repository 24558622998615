import React, { useEffect } from "react";
import { Row, Col, Form, Input } from "antd";
import * as common from "../../../../util/common";

const CP3_5 = (props) => {
  const formula_defination = {
    r3c1: { type: "sum", cols: ["r1c1", "r2c1"] },
    r3c2: { type: "sum", cols: ["r1c2", "r2c2"] },
    r3c3: { type: "sum", cols: ["r1c3", "r2c3"] },
    r3c4: { type: "sum", cols: ["r1c4", "r2c4"] },
  };

  const handleChange = (e) => {
    //common.arthiFunc(props.ques_id, formula_defination, props.form);
  };
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div id="tbl-cell">
      <Row>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}></span>
        </Col>
        <Col span={10} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            {`Permanent employees`}
          </span>
        </Col>
        <Col span={10} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            {`Permanent workers`}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={4} className="text-center">
          <span
            style={{ fontSize: "12px", fontWeight: "600" }}
          >{`Gender`}</span>
        </Col>
        <Col span={5} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`Return to work rate`}
          </span>
        </Col>
        <Col span={5} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`Retention rate`}
          </span>
        </Col>
        <Col span={5} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`Return to work rate`}
          </span>
        </Col>
        <Col span={5} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`Retention rate`}
          </span>
        </Col>
      </Row>

      <Row>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "400" }}>{`Male`}</span>
        </Col>
        <Col span={5}>
          <Form.Item name={[props.ques_id, "r1c1"]}>
            <Input
              onChange={handleChange}
              readOnly={props.readonly}
              type="number" min="0"
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name={[props.ques_id, "r1c2"]}>
            <Input
              onChange={handleChange}
              readOnly={props.readonly}
              type="number" min="0"
            />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item name={[props.ques_id, "r1c3"]}>
            <Input
              onChange={handleChange}
              readOnly={props.readonly}
              type="number" min="0"
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name={[props.ques_id, "r1c4"]}>
            <Input
              onChange={handleChange}
              readOnly={props.readonly}
              type="number" min="0"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "400" }}>
            {`Female`}
          </span>
        </Col>
        <Col span={5}>
          <Form.Item name={[props.ques_id, "r2c1"]}>
            <Input
              onChange={handleChange}
              readOnly={props.readonly}
              type="number" min="0"
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name={[props.ques_id, "r2c2"]}>
            <Input
              onChange={handleChange}
              readOnly={props.readonly}
              type="number" min="0"
            />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item name={[props.ques_id, "r2c3"]}>
            <Input
              onChange={handleChange}
              readOnly={props.readonly}
              type="number" min="0"
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name={[props.ques_id, "r2c4"]}>
            <Input
              onChange={handleChange}
              readOnly={props.readonly}
              type="number" min="0"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "400" }}>Total</span>
        </Col>
        <Col span={5}>
          <Form.Item name={[props.ques_id, "r3c1"]}>
            <Input
              onChange={handleChange}
              readOnly={props.readonly}
              type="number" min="0"
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name={[props.ques_id, "r3c2"]}>
            <Input
              onChange={handleChange}
              readOnly={props.readonly}
              type="number" min="0"
            />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item name={[props.ques_id, "r3c3"]}>
            <Input
              onChange={handleChange}
              readOnly={props.readonly}
              type="number" min="0"
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name={[props.ques_id, "r3c4"]}>
            <Input
              onChange={handleChange}
              readOnly={props.readonly}
              type="number" min="0"
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default CP3_5;
