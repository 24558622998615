import React, { useState, useEffect } from "react";
import { Button, Form, message } from "antd";
import "./index.scss";
import { InputOTP } from "antd-input-otp";
import { useNavigate, Link } from "react-router-dom";
import QRCode from "../../Components/2FA/Person2FA/QRCode";
import { very2FAQRCode, useAuthDispatch } from "../../Context";
import logoPic from "../../static/images/nslogo-large.png";
//import { CommentsContext } from "../../Context/context";
import { useCustomer } from "../../hooks/useCustomer";
import { messageAlert } from "../../Components/Alert";

const Render2FA = (props) => {
  const { setProfile, setAssignedTemplateId, setCustomerId } = useCustomer();
  //const { list, setList } = useContext(CommentsContext);
  const [status2FA, setStatus2FA] = useState("");
  const [code2FA, setcode2FA] = useState(null);
  const [form] = Form.useForm();
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setStatus2FA(props.status_2fa);
    setcode2FA(props);
  }, [props]);

  // const getComments_details = () => {
  //   dispatch1(getNotificationSlice())
  //     .unwrap()
  //     .then((res) => {
  //       if (res.code === 0) {
  //         setList(res.data.total_notifications);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const verify2FACode = async (values) => {
    const { otp } = values;
    if (otp === undefined || otp?.length < '6') {
      return messageAlert({
        title: "Alert!",
        msg: "Please enter the six digit OTP.",
      });
    }
    var otpcode = otp.join();
    otpcode = otpcode.split(",").join("");
    if (!otp || otp.includes(undefined) || otp.includes(""))
      return form.setFields([
        {
          name: "otp",
          errors: ["OTP is invalid."],
        },
      ]);
    const params = {
      username: localStorage.getItem("currentUser"),
      login_type: localStorage.getItem("login_type"),
      dynamic_code: otpcode,
    };
    let response = await very2FAQRCode(dispatch, { params });
    if (response.code === 200009) {
      message.info(response.msg);
    } else {
      localStorage.setItem("auth", true);
      localStorage.setItem("token", response?.data?.token);
      localStorage.setItem("user_id", response?.data?.user_id);
      localStorage.setItem("roletype", response?.data?.role_type);
      var sessionObject = {
        auth: true,
        token: response?.data?.token,
        user_id: response?.data?.user_id,
        roletype: response?.data?.role_type,
      };
      setProfile(response?.data);
      localStorage.setItem("sessionObject", JSON.stringify(sessionObject));
      if (response?.data?.misc) {
        localStorage.setItem(
          "assigned_template_name",
          response?.data?.misc?.assigned_template_name
        );
        localStorage.setItem(
          "assigned_template_id",
          response?.data?.misc?.assigned_template_id
        );
        setAssignedTemplateId(response?.data?.misc?.assigned_template_id);
        setCustomerId(response?.data?.misc?.customer_id);
        localStorage.setItem("customer_id", response?.data?.misc?.customer_id);
        localStorage.setItem("cin_number", response?.data?.misc?.cin_number);
        localStorage.setItem(
          "company_email",
          response?.data?.misc?.company_email
        );
        localStorage.setItem("website", response?.data?.misc?.website);
        localStorage.setItem(
          "company_phone",
          response?.data?.misc?.company_phone
        );
        localStorage.setItem(
          "company_name",
          response?.data?.misc?.company_name
        );
        localStorage.setItem(
          "registered_address",
          response?.data?.misc?.registered_address
        );
        localStorage.setItem(
          "year_of_incorporation",
          response?.data?.misc?.year_of_incorporation
        );
      }
      navigate("/dashboard");
    }
  };
  const handleCancel = () => {
    form.resetFields();
  };
  const render2FA = () => {
    return (
      <>
        <div className="m-login-wrap">
          <div>
            <Link>
              {" "}
              <img src={logoPic} className="logo-large" alt="logo" />
            </Link>
          </div>

          <div className="m-login">
            <div className="m-login-title">
              Two-Factor Authentication
              <div className="subtitle">
                Enter the six-digit code from your authentication app
              </div>
            </div>
            <header>{""}</header>
            <Form form={form} onFinish={verify2FACode}>
              <Form.Item
                name="otp"
                className="center-error-message"
                rules={[{ validator: async () => Promise.resolve() }]}
              >
                <InputOTP autoFocus inputType="numeric" length={6} />
              </Form.Item>

              <Form.Item noStyle>
                <footer className="m-login-btn">
                  <Button size="middle" onClick={handleCancel} danger>
                    Cancel
                  </Button>
                  <Button
                    size="middle"
                    className="m-login-btn"
                    htmlType="submit"
                  >
                    Verify
                  </Button>
                </footer>
              </Form.Item>
            </Form>
          </div>
        </div>
      </>
    );
  };
  const register2FA = () => {
    return (
      <div className="m-login-wrap">
        <div className="m-login">
          <header>{""}</header>
          {code2FA && <QRCode data={code2FA} />}
        </div>
      </div>
    );
  };
  return <>{status2FA === true ? render2FA() : register2FA()}</>;
};

export default Render2FA;
