import React from "react";
import "./index.scss";

const Loader = (props) => {
  return (
    <>
      {props.flag && (
        <div className="ring">
          Loading
          <span className='span'></span>
        </div>
      )}
    </>
  );
};
export default Loader;
