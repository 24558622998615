import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form, Select } from "antd";
import { useCustomer } from "../../../../../hooks/useCustomer";
import * as common from "../../../../../util/common";

const { Option } = Select;
const T25 = (props) => {
  const { assigned_template_id } = useCustomer();
  const [r1c1, setR1c1] = useState("no");
  const [r2c1, setR2c1] = useState("no");
  const [r3c1, setR3c1] = useState("no");
  const [r4c1, setR4c1] = useState("no");
  const [r5c1, setR5c1] = useState("no");
  const [r6c1, setR6c1] = useState("no");
  const [r7c1, setR7c1] = useState("no");
  const [r8c1, setR8c1] = useState("no");
  useEffect(() => {
    props.form.setFieldsValue(props.data);
    setR1c1(props.form.getFieldValue([props.ques_id, "r1c1"]));
    setR2c1(props.form.getFieldValue([props.ques_id, "r2c1"]));
    setR3c1(props.form.getFieldValue([props.ques_id, "r3c1"]));
    setR4c1(props.form.getFieldValue([props.ques_id, "r4c1"]));
    setR5c1(props.form.getFieldValue([props.ques_id, "r5c1"]));
    setR6c1(props.form.getFieldValue([props.ques_id, "r6c1"]));
    setR7c1(props.form.getFieldValue([props.ques_id, "r7c1"]));
    setR8c1(props.form.getFieldValue([props.ques_id, "r8c1"]));
  }, [props.form, props.data]);
  return (
    <Row>
      <Col span={24}>
        <div id="tbl-cell">
          <Row>
            <Col span={6}>
              <span style={{ fontSize: "12px", fontWeight: "600" }}>
                Stakeholder group from whom complaint is received
              </span>
            </Col>
            <Col span={4} className="text-center">
              <span style={{ fontSize: "12px", fontWeight: "600" }}>
                Grievance redressal mechanism in place (Yes/No)
              </span>
            </Col>
            <Col span={7} className="text-center">
              <span style={{ fontSize: "12px", fontWeight: "600" }}>
                Current financial year (FY:{props?.data?.current_fy})
              </span>
            </Col>
            <Col span={7} className="text-center">
              <span style={{ fontSize: "12px", fontWeight: "600" }}>
                Previous Financial Year (FY:{props?.data?.previous_fy})
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={4} offset={6}>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                If yes, then provide web-link for grievance redress policy
              </span>
            </Col>

            <Col span={2} offset={0}>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                Number of complaints filed during the year
              </span>
            </Col>
            <Col span={3} offset={0}>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                Number of complaints pending resolution at close of the year
              </span>
            </Col>
            <Col span={2} offset={0}>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                Remarks
              </span>
            </Col>
            <Col span={2} offset={0}>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                Number of complaints filed during the year
              </span>
            </Col>
            <Col span={3} offset={0}>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                Number of complaints pending resolution at close of the year
              </span>
            </Col>
            <Col span={2} offset={0}>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                Remarks
              </span>
            </Col>
          </Row>

          <Row>
            <Col span={6}>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                Communities
              </span>
            </Col>
            <Col span={4}>
              <Col>
                <Form.Item name={[props.ques_id, "r1c1"]} noStyle>
                  <Select
                    disabled={props.readonly}
                    placeholder="Select Option"
                    onChange={(value) => {
                      setR1c1(value);
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                    <Option value="NA">NA</Option>
                  </Select>
                </Form.Item>
              </Col>
              {r1c1 === "Yes" && (
                <Col span={14}>
                  <Form.Item name={[props.ques_id, "r1c1a"]} noStyle>
                    <Input readOnly={props.readonly} placeholder="" />
                  </Form.Item>
                </Col>
              )}
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r1c2"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name={[props.ques_id, "r1c3"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r1c4"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r1c5"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name={[props.ques_id, "r1c6"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r1c7"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
          </Row>
          {[2, 3, 4].includes(assigned_template_id) && (
            <>
              <Row>
                <Col span={6}>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    Investors (other then shareholders)
                  </span>
                </Col>
                <Col span={4}>
                  <Col>
                    <Form.Item
                      name={[props.ques_id, "r2c1"]}
                      noStyle
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                        disabled={props.readonly}
                        placeholder="Select Option"
                        onChange={(value) => {
                          setR2c1(value);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                        <Option value="NA">NA</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {r2c1 === "Yes" && (
                    <Col span={14}>
                      <Form.Item
                        name={[props.ques_id, "r2c1a"]}
                        noStyle
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input readOnly={props.readonly} placeholder="" />
                      </Form.Item>
                    </Col>
                  )}
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r2c2"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r2c3"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r2c4"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r2c5"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r2c6"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r2c7"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    Stakeholder group from whom complaint is received
                  </span>
                </Col>
                <Col span={4}>
                  <Col>
                    <Form.Item
                      name={[props.ques_id, "r3c1"]}
                      noStyle
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                        disabled={props.readonly}
                        placeholder="Select Option"
                        onChange={(value) => {
                          setR3c1(value);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                        <Option value="NA">NA</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {r3c1 === "Yes" && (
                    <Col span={14}>
                      <Form.Item
                        name={[props.ques_id, "r3c1a"]}
                        noStyle
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input readOnly={props.readonly} placeholder="" />
                      </Form.Item>
                    </Col>
                  )}
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r3c2"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r3c3"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r3c4"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r3c5"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r3c6"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r3c7"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    Shareholders
                  </span>
                </Col>
                <Col span={4}>
                  <Col>
                    <Form.Item
                      name={[props.ques_id, "r4c1"]}
                      noStyle
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                        disabled={props.readonly}
                        placeholder="Select Option"
                        onChange={(value) => {
                          setR4c1(value);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                        <Option value="NA">NA</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {r4c1 === "Yes" && (
                    <Col span={14}>
                      <Form.Item
                        name={[props.ques_id, "r4c1a"]}
                        noStyle
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input readOnly={props.readonly} placeholder="" />
                      </Form.Item>
                    </Col>
                  )}
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r4c2"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r4c3"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r4c4"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r4c5"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[props.ques_id, "r4c6"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item name={[props.ques_id, "r4c7"]}>
                    <Input readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col span={6}>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                Employees and workers
              </span>
            </Col>
            <Col span={4}>
              <Col>
                <Form.Item
                  name={[props.ques_id, "r5c1"]}
                  noStyle
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    disabled={props.readonly}
                    placeholder="Select Option"
                    onChange={(value) => {
                      setR5c1(value);
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                    <Option value="NA">NA</Option>
                  </Select>
                </Form.Item>
              </Col>
              {r5c1 === "Yes" && (
                <Col span={14}>
                  <Form.Item
                    name={[props.ques_id, "r5c1a"]}
                    noStyle
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input readOnly={props.readonly} placeholder="" />
                  </Form.Item>
                </Col>
              )}
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r5c2"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name={[props.ques_id, "r5c3"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r5c4"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r5c5"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name={[props.ques_id, "r5c6"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r5c7"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                Customers
              </span>
            </Col>
            <Col span={4}>
              <Col>
                <Form.Item
                  name={[props.ques_id, "r6c1"]}
                  noStyle
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    disabled={props.readonly}
                    placeholder="Select Option"
                    onChange={(value) => {
                      setR6c1(value);
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                    <Option value="NA">NA</Option>
                  </Select>
                </Form.Item>
              </Col>
              {r6c1 === "Yes" && (
                <Col span={14}>
                  <Form.Item
                    name={[props.ques_id, "r6c1a"]}
                    noStyle
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input readOnly={props.readonly} placeholder="" />
                  </Form.Item>
                </Col>
              )}
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r6c2"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name={[props.ques_id, "r6c3"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r6c4"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r6c5"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name={[props.ques_id, "r6c6"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r6c7"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                Value chain partners
              </span>
            </Col>
            <Col span={4}>
              <Col>
                <Form.Item
                  name={[props.ques_id, "r7c1"]}
                  noStyle
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    disabled={props.readonly}
                    placeholder="Select Option"
                    onChange={(value) => {
                      setR7c1(value);
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                    <Option value="NA">NA</Option>
                  </Select>
                </Form.Item>
              </Col>
              {r7c1 === "Yes" && (
                <Col span={14}>
                  <Form.Item
                    name={[props.ques_id, "r7c1a"]}
                    noStyle
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input readOnly={props.readonly} placeholder="" />
                  </Form.Item>
                </Col>
              )}
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r7c2"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name={[props.ques_id, "r7c3"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r7c4"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r7c5"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name={[props.ques_id, "r7c6"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r7c7"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                Others (Please specify)
              </span>
            </Col>
            <Col span={4}>
              <Col>
                <Form.Item
                  name={[props.ques_id, "r8c1"]}
                  noStyle
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    disabled={props.readonly}
                    placeholder="Select Option"
                    onChange={(value) => {
                      setR8c1(value);
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                    <Option value="NA">NA</Option>
                  </Select>
                </Form.Item>
              </Col>
              {r8c1 === "Yes" && (
                <Col span={14}>
                  <Form.Item
                    name={[props.ques_id, "r8c21a"]}
                    noStyle
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input readOnly={props.readonly} placeholder="" />
                  </Form.Item>
                </Col>
              )}
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r8c2"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name={[props.ques_id, "r8c3"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r8c4"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r8c5"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name={[props.ques_id, "r8c6"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r8c7"]}>
                <Input readOnly={props.readonly} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default T25;
