import React, { useEffect } from "react";
import { Row, Col, Form, Input } from "antd";

const CP3_14 = (props) => {

  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div id="tbl-cell">
      <Row>
        <Col span={9}>
          <span style={{ fontSize: "12px", fontWeight: "600" }}></span>
        </Col>
        <Col span={15} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            {`% of your plants and offices that were assessed (by entity or statutory authorities or third parties)`}
          </span>
        </Col>
      </Row>

      <Row>
        <Col span={9}>
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            {`Health and safety practices`}
          </span>
        </Col>
        <Col span={15}>
          <Form.Item
            name={[
              props.ques_id,
              "r1c1",
            ]}
          >
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={9}>
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            {`Working Conditions`}
          </span>
        </Col>
        <Col span={15}>
          <Form.Item
            name={[
              props.ques_id,
              "r2c1",
            ]}
          >
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default CP3_14;
