import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Card,
  Space,
  Input,
  Select,
  Button,
  message,
  Spin,
  Divider,
} from "antd";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import {
  create_section_slice,
  get_section,
} from "../SectionSlice/sectionSlice";
import { useFinancialYear } from "../../../../hooks/useFinancialYear";
import { useCustomer } from "../../../../hooks/useCustomer";
import CommentsApp from "../../../../Components/Comments";
import CommentsNotified from "../../../../Components/Comments/comments-notification";
import B10 from "./b10";
import B11 from "./b11";
import B12 from "./b12";

const { Option } = Select;
const Section_B = (props) => {
  const { current_fy } = useFinancialYear();
  const { assigned_template_id } = useCustomer();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [readonly, setReadonly] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [defaultdata, setDefaultdata] = useState({});
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const [commentreload, setCommentReload] = useState();
  const [commentlist, setCommentlist] = useState([]);

  useEffect(() => {
    if (sessionState?.roletype === "CUSTOMER") {
      setReadonly(false);
    } else {
      setReadonly(true);
    }
    let payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "Section_B",
    };
    setSpinning(true);
    dispatch(get_section({ payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setTimeout(() => {
            setDefaultdata(res.data?.Section_B);
            setCommentlist(res.data?.comments);
            setSpinning(false);
          }, 1000);
        } else {
          setSpinning(false);
          // setDefaultdata([])
          console.log(res.msg);
        }
      })
      .catch((Error) => {
        console.log(Error);
      });
  }, [dispatch,commentreload]);

  useEffect(() => {
    form.setFieldsValue(defaultdata);
  }, [form, defaultdata]);

  const handleR1C1Change = (value) => {
    if (value === "No") {
      form.setFieldsValue({ b1b: { r1c1: "No" } });

      form.setFieldsValue({ b2: { r1c1: "No" } });
      form.setFieldsValue({ b3: { r1c1: "No" } });
      form.setFieldsValue({ b11: { r1c1: "No" } });
    }

    setDefaultdata({});
  };

  const handleR1C2Change = (value) => {
    if (value === "No") {
      form.setFieldsValue({ b1b: { r1c2: "No" } });

      form.setFieldsValue({ b2: { r1c2: "No" } });
      form.setFieldsValue({ b3: { r1c2: "No" } });
      form.setFieldsValue({ b11: { r1c2: "No" } });
    }
    setDefaultdata({});
  };

  const handleR1C3Change = (value) => {
    if (value === "No") {
      form.setFieldsValue({ b1b: { r1c3: "No" } });

      form.setFieldsValue({ b2: { r1c3: "No" } });
      form.setFieldsValue({ b3: { r1c3: "No" } });
      form.setFieldsValue({ b11: { r1c3: "No" } });
    }
    setDefaultdata({});
  };

  const handleR1C4Change = (value) => {
    if (value === "No") {
      form.setFieldsValue({ b1b: { r1c4: "No" } });

      form.setFieldsValue({ b2: { r1c4: "No" } });
      form.setFieldsValue({ b3: { r1c4: "No" } });
      form.setFieldsValue({ b11: { r1c4: "No" } });
    }
    setDefaultdata({});
  };

  const handleR1C5Change = (value) => {
    if (value === "No") {
      form.setFieldsValue({ b1b: { r1c5: "No" } });

      form.setFieldsValue({ b2: { r1c5: "No" } });
      form.setFieldsValue({ b3: { r1c5: "No" } });
      form.setFieldsValue({ b11: { r1c5: "No" } });
    }
    setDefaultdata({});
  };

  const handleR1C6Change = (value) => {
    if (value === "No") {
      form.setFieldsValue({ b1b: { r1c6: "No" } });

      form.setFieldsValue({ b2: { r1c6: "No" } });
      form.setFieldsValue({ b3: { r1c6: "No" } });
      form.setFieldsValue({ b11: { r1c6: "No" } });
    }
    setDefaultdata({});
  };

  const handleR1C7Change = (value) => {
    if (value === "No") {
      form.setFieldsValue({ b1b: { r1c7: "No" } });

      form.setFieldsValue({ b2: { r1c7: "No" } });
      form.setFieldsValue({ b3: { r1c7: "No" } });
      form.setFieldsValue({ b11: { r1c7: "No" } });
    }
    setDefaultdata({});
  };

  const handleR1C8Change = (value) => {
    if (value === "No") {
      form.setFieldsValue({ b1b: { r1c8: "No" } });

      form.setFieldsValue({ b2: { r1c8: "No" } });
      form.setFieldsValue({ b3: { r1c8: "No" } });
      form.setFieldsValue({ b11: { r1c8: "No" } });
    }
    setDefaultdata({});
  };

  const handleR1C9Change = (value) => {
    if (value === "No") {
      form.setFieldsValue({ b1b: { r1c9: "No" } });

      form.setFieldsValue({ b2: { r1c9: "No" } });
      form.setFieldsValue({ b3: { r1c9: "No" } });
      form.setFieldsValue({ b11: { r1c9: "No" } });
    }
    setDefaultdata({});
  };

  const onFinish = (datalist) => {
    setSpinning(true);
    let Payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "Section_B",
      question_response: datalist,
    };
    dispatch(create_section_slice({ Payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setTimeout(() => {
            setSpinning(false);
            message.info("Section B created succesfully!");
          }, 3000);
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };
  return (
    <div className="formcontainer">
      <CommentsNotified commentlist={commentlist} />
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 44, color: "white" }} spin />
        }
        tip="Loading..."
        spinning={spinning}
        fullscreen
        size="large"
      />
      <Form
        form={form}
        name="section_b"
        onFinish={onFinish}
        initialValues={defaultdata}
        style={{ width: "100%" }}
      >
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Card title="Details" size="small">
            <Row className="title-header">
              <Col span={24}>
                <div id="tbl-cell">
                  <Row>
                    <Col span={6}>
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        Disclosure questions
                      </span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        P1
                      </span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span>P2</span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span>P3</span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span>P4</span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span>P5</span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span>P6</span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span>P7</span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span>P8</span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span>P9</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={1} className="text-center">
                      <span>I</span>
                    </Col>
                    <Col span={6} className="text-center">
                      <span>Policy and management processes</span>
                    </Col>
                  </Row>

                  {[1, 2, 3, 4].includes(assigned_template_id) && (
                    <Row>
                      <Col span={1} className="text-center">
                        <span>B1a</span>
                      </Col>
                      <Col span={5}>
                        <span
                          style={{ fontSize: "12px", fontWeight: "400" }}
                        ></span>
                        <span style={{ fontSize: "12px", fontWeight: "400" }}>
                          Whether your entity's policy/policies cover each
                          principle and its core elements of the National
                          Guidelines on Responsible Business Conduct. (Yes/No)
                        </span>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1a", "r1c1"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                            onChange={handleR1C1Change}
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1a", "r1c2"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                            onChange={handleR1C2Change}
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1a", "r1c3"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                            onChange={handleR1C3Change}
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1a", "r1c4"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                            onChange={handleR1C4Change}
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1a", "r1c5"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                            onChange={handleR1C5Change}
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1a", "r1c6"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                            onChange={handleR1C6Change}
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1a", "r1c7"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                            onChange={handleR1C7Change}
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1a", "r1c8"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                            onChange={handleR1C8Change}
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1a", "r1c9"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                            onChange={handleR1C9Change}
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {[1, 2, 3, 4].includes(assigned_template_id) && (
                    <Row>
                      <Col span={1} className="text-center">
                        <span>B1b</span>
                      </Col>
                      <Col span={5}>
                        <span
                          style={{ fontSize: "12px", fontWeight: "400" }}
                        ></span>
                        <span style={{ fontSize: "12px", fontWeight: "400" }}>
                          Has the policy been approved by the board (Yes/No)
                        </span>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1b", "r1c1"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1b", "r1c2"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1b", "r1c3"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1b", "r1c4"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1b", "r1c5"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1b", "r1c6"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1b", "r1c7"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1b", "r1c8"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={["b1b", "r1c9"]}>
                          <Select
                            disabled={readonly}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {[1, 2, 3, 4].includes(assigned_template_id) && (
                    <>
                      <Row>
                        <Col span={1} className="text-center">
                          <span>B1c</span>
                        </Col>
                        <Col span={5}>
                          <span
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          ></span>
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            Web link of policies, if available?
                          </span>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b1c", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b1c", "r1c2"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b1c", "r1c3"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b1c", "r1c4"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b1c", "r1c5"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b1c", "r1c6"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b1c", "r1c7"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b1c", "r1c8"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b1c", "r1c9"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata.question_response_id}
                        question_response_name="b1"
                      />
                    </>
                  )}
                  {[1, 2, 3, 4].includes(assigned_template_id) && (
                    <>
                      <Row>
                        <Col span={1} className="text-center">
                          <span>B2</span>
                        </Col>
                        <Col span={5}>
                          <span
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          ></span>
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            Whether the entity has translated the policy into
                            procedure (Yes/No)
                          </span>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b2", "r1c1"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b2", "r1c2"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b2", "r1c3"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b2", "r1c4"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b2", "r1c5"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b2", "r1c6"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b2", "r1c7"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b2", "r1c8"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b2", "r1c9"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata.question_response_id}
                        question_response_name="b2"
                      />
                    </>
                  )}
                  {[2, 3, 4].includes(assigned_template_id) && (
                    <>
                      <Row>
                        <Col span={1} className="text-center">
                          <span>B3</span>
                        </Col>
                        <Col span={5}>
                          <span
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          ></span>
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            Do the enlisted policies extend to your value chain
                            partners? (Yes/No)
                          </span>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b3", "r1c1"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b3", "r1c2"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b3", "r1c3"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b3", "r1c4"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b3", "r1c5"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b3", "r1c6"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b3", "r1c7"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b3", "r1c8"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b3", "r1c9"]}>
                            <Select
                              readOnly={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata.question_response_id}
                        question_response_name="b3"
                      />
                    </>
                  )}
                  {[1, 2, 3, 4].includes(assigned_template_id) && (
                    <>
                      <Row>
                        <Col span={1} className="text-center">
                          <span>B4</span>
                        </Col>
                        <Col span={5}>
                          <span
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          ></span>
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            Name of the national and international
                            codes/certifications/labels/standards (eg: Forest
                            stewardship council, fairtrade, rainforest alliance,
                            trustea) standards (eg: SA8000,OHSAS,ISO,BIS)
                            adopted by your entity and mapped to each principle.
                          </span>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b4", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b4", "r1c2"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b4", "r1c3"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b4", "r1c4"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b4", "r1c5"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b4", "r1c6"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b4", "r1c7"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b4", "r1c8"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b4", "r1c9"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata.question_response_id}
                        question_response_name="b4"
                      />
                    </>
                  )}
                  {[1, 2, 3, 4].includes(assigned_template_id) && (
                    <>
                      <Row>
                        <Col span={1} className="text-center">
                          <span>B5</span>
                        </Col>
                        <Col span={5}>
                          <span
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          ></span>
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            Specific commitments, goals and targets set by the
                            entity with defined timelines,if any.
                          </span>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b5", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b5", "r1c2"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b5", "r1c3"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b5", "r1c4"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b5", "r1c5"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b5", "r1c6"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b5", "r1c7"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b5", "r1c8"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b5", "r1c9"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata.question_response_id}
                        question_response_name="b5"
                      />
                    </>
                  )}
                  {[1, 2, 3, 4].includes(assigned_template_id) && (
                    <>
                      <Row>
                        <Col span={1} className="text-center">
                          <span>B6</span>
                        </Col>
                        <Col span={5}>
                          <span
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          ></span>
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            Performance of the entity against the specific
                            commitments, goals and targets, along with reasons
                            in case the same are not met.
                          </span>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b6", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b6", "r1c2"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b6", "r1c3"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b6", "r1c4"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b6", "r1c5"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b6", "r1c6"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b6", "r1c7"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b6", "r1c8"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b6", "r1c9"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata.question_response_id}
                        question_response_name="b6"
                      />
                    </>
                  )}

                  <Row>
                    <Col span={1} className="text-center">
                      <span>II</span>
                    </Col>
                    <Col span={6} className="text-center">
                      <span>Governance leadership and oversight</span>
                    </Col>
                  </Row>
                  {[2, 3, 4].includes(assigned_template_id) && (
                    <>
                      <Row>
                        <Col span={1} className="text-center">
                          <span>B7</span>
                        </Col>
                        <Col span={5}>
                          <span
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          ></span>
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            Statement by director responsible for the business
                            responsibility report, highlighting ESG related
                            challenges, targets and achievements (listed entity
                            has flexibility regarding the placement of this
                            disclosure).
                          </span>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b7", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b7", "r1c2"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b7", "r1c3"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b7", "r1c4"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b7", "r1c5"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b7", "r1c6"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b7", "r1c7"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b7", "r1c8"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b7", "r1c9"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata.question_response_id}
                        question_response_name="b7"
                      />
                    </>
                  )}
                  {[2, 3, 4].includes(assigned_template_id) && (
                    <>
                      <Row>
                        <Col span={1} className="text-center">
                          <span>B8</span>
                        </Col>
                        <Col span={5}>
                          <span
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          ></span>
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            Details of the highest authority responsible for
                            implementation and oversight of the business
                            responsibility policy (ies).
                          </span>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b8", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b8", "r1c2"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b8", "r1c3"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b8", "r1c4"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b8", "r1c5"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b8", "r1c6"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b8", "r1c7"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b8", "r1c8"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b8", "r1c9"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata.question_response_id}
                        question_response_name="b8"
                      />
                    </>
                  )}
                  {[2, 3, 4].includes(assigned_template_id) && (
                    <>
                      <Row>
                        <Col span={1} className="text-center">
                          <span>B9</span>
                        </Col>
                        <Col span={5}>
                          <span
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          ></span>
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            Does the entity have a specified committee of the
                            board/director responsible for decision making on
                            sustainability related issues? (Yes/No). If yes,
                            provide details.
                          </span>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b9", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b9", "r1c2"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b9", "r1c3"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b9", "r1c4"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b9", "r1c5"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b9", "r1c6"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b9", "r1c7"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b9", "r1c8"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item name={["b9", "r1c9"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata.question_response_id}
                        question_response_name="b9"
                      />
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        </Space>
        {[1, 2, 3, 4].includes(assigned_template_id) && (
          <>
            <B10
              readonly={readonly}
              data={{ ...defaultdata }}
              form={form}
              ques_id={"b10"}
            />
            <CommentsApp setCommentReload={setCommentReload}
              question_response_id={defaultdata.question_response_id}
              question_response_name="b10"
            />
          </>
        )}
        {[1, 2, 3, 4].includes(assigned_template_id) && (
          <>
            <B11
              readonly={readonly}
              data={{ ...defaultdata }}
              form={form}
              ques_id={"b11"}
            />
            <CommentsApp setCommentReload={setCommentReload}
              question_response_id={defaultdata.question_response_id}
              question_response_name="b11"
            />
          </>
        )}
        {[1, 2, 3, 4].includes(assigned_template_id) && (
          <>
            <B12
              readonly={readonly}
              data={{ ...defaultdata }}
              form={form}
              ques_id={"b12"}
            />
            <CommentsApp setCommentReload={setCommentReload}
              question_response_id={defaultdata.question_response_id}
              question_response_name="b12"
            />
          </>
        )}

        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          {[4].includes(assigned_template_id) && (
            <Card title="[I] Policy and management processes" size="small">
              <>
                <Form.Item label="B13a" className="title-header">
                  <Row>
                    <Col span={18}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`Describe the organization's policy commitments for
                    responsible business conduct, including:`}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`[i] The authoritative intergovernmental instruments that the commitments reference`}
                      </span>
                    </Col>
                    <Col span={14}>
                      <Form.Item name={["b13a", "r1c1"]}>
                        <Input
                          readOnly={readonly}
                          placeholder="Enter The authoritative"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col span={8} className="text-center">
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`[ii] Whether the commitments stipulate conducting due diligence;`}
                      </span>
                    </Col>
                    <Col span={14}>
                      <Form.Item name={["b13a", "r2c1"]}>
                        <Input
                          readOnly={readonly}
                          placeholder="Enter the commitments"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col span={8}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`[iii] Whether the commitments stipulate applying the precautionary principle;`}
                      </span>
                    </Col>
                    <Col span={14}>
                      <Form.Item name={["b13a", "r3c1"]}>
                        <Input
                          readOnly={readonly}
                          placeholder="Enter the commitments"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <CommentsApp setCommentReload={setCommentReload}
                  question_response_id={defaultdata.question_response_id}
                  question_response_name="b13a"
                />
                <Divider />
              </>
              <>
                <Form.Item label="B13b" className="title-header">
                  <Row>
                    <Col span={18}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`describe its specific policy commitment to respect human rights, including:`}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`[i] The internationally recognized human rights that the commitment covers`}
                      </span>
                    </Col>
                    <Col span={14}>
                      <Form.Item name={["b13b", "r1c1"]}>
                        <Input
                          readOnly={readonly}
                          placeholder="Enter commitment covers"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col span={8}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`[ii]  the categories of stakeholders, including at-risk or vulnerable groups, that the organization gives particular attention to in the commitment`}
                      </span>
                    </Col>
                    <Col span={14}>
                      <Form.Item name={["b13b", "r2c1"]}>
                        <Input
                          readOnly={readonly}
                          placeholder="Enter categories"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <CommentsApp setCommentReload={setCommentReload}
                  question_response_id={defaultdata.question_response_id}
                  question_response_name="b13b"
                />
                <Divider />
              </>
              <>
                <Form.Item label="B14" className="title-header">
                  <Row>
                    <Col span={18}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`Describe how it embeds each of its policy commitments for responsible business conduct throughout its activities and business relationships, including:`}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`[i] How it allocates responsibility to implement the commitments across different levels within the organization`}
                      </span>
                    </Col>
                    <Col span={14}>
                      <Form.Item name={["b14", "r1c1"]}>
                        <Input
                          readOnly={readonly}
                          placeholder="Enter allocates responsibility"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <CommentsApp setCommentReload={setCommentReload}
                  question_response_id={defaultdata.question_response_id}
                  question_response_name="b14"
                />
                <Divider />
              </>
              <>
                <Form.Item label="B15" className="title-header">
                  <Row>
                    <Col span={18}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`For each material topic described in Section A`}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`[A] Report whether the organization is involved with the negative impacts through its activities or as a result of its business relationships, and describe the activities or business relationships`}
                      </span>
                    </Col>
                    <Col span={14}>
                      <Form.Item name={["b15a", "r1c1"]}>
                        <Input
                          readOnly={readonly}
                          placeholder="Enter organization"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                </Form.Item>
                <Divider />

                <Form.Item label="B" className="title-header">
                  <Row>
                    <Col span={18}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`Report the following information about tracking the effectiveness of the actions taken:`}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`[i] Processes used to track the effectiveness of the actions`}
                      </span>
                    </Col>
                    <Col span={14}>
                      <Form.Item name={["b15b", "r1c1"]}>
                        <Input
                          readOnly={readonly}
                          placeholder="Enter actions"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col span={8}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`[ii] Lessons learned and how these have been incorporated into the organization’s operational policies and procedures`}
                      </span>
                    </Col>
                    <Col span={14}>
                      <Form.Item name={["b15b", "r2c1"]}>
                        <Input
                          readOnly={readonly}
                          placeholder="Enter lessons"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col span={8}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`[iii] describe how engagement with stakeholders has informed the actions taken and how it has informed whether the actions have been effective`}
                      </span>
                    </Col>
                    <Col span={14}>
                      <Form.Item name={["b15b", "r3c1"]}>
                        <Input
                          readOnly={readonly}
                          placeholder="Enter Paid up stackholder"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <CommentsApp setCommentReload={setCommentReload}
                  question_response_id={defaultdata.question_response_id}
                  question_response_name="b15b"
                />
              </>
            </Card>
          )}
        </Space>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          {[4].includes(assigned_template_id) && (
            <Card title="[II] Governance leadership and oversight" size="small">
              <>
                <Form.Item label="B16" className="title-header">
                  <Row>
                    <Col span={8}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`Describe its governance structure, including committees of the highest governance body`}
                      </span>
                    </Col>
                    <Col span={14}>
                      <Form.Item name={["b16", "r1c1"]}>
                        <Input
                          readOnly={readonly}
                          placeholder="Enter governance structure"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <CommentsApp setCommentReload={setCommentReload}
                  question_response_id={defaultdata.question_response_id}
                  question_response_name="b16"
                />
                <Divider />
              </>
              <>
                <Form.Item label="B17" className="title-header">
                  <Row>
                    <Col span={8}>
                      <span style={{ fontSize: "12px", fontWeight: "700" }}>
                        {`Describe its policy and practice for seeking external assurance, including whether and how the highest governance body and senior executives are involved`}
                      </span>
                    </Col>
                    <Col span={14}>
                      <Form.Item name={["b17", "r1c1"]}>
                        <Input
                          readOnly={readonly}
                          placeholder="Enter policy and practice"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <CommentsApp setCommentReload={setCommentReload}
                  question_response_id={defaultdata.question_response_id}
                  question_response_name="b17"
                />
              </>
            </Card>
          )}
        </Space>

        {readonly ? (
          ""
        ) : (
          <Space
            style={{
              //marginTop: "10px",
              marginBottom: "10px",
              justifyContent: "right",
              width: "100%",
            }}
          >
            <Button style={{ marginLeft: 10 }} htmlType="submit">
              Save and Continue
            </Button>
          </Space>
        )}
      </Form>
    </div>
  );
};

export default Section_B;
