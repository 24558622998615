import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { create_customer } from "../../../Config/actions";

const initialState = {
  loading: false,
  error: null,
  result: null,
};

const createcustomer = createAsyncThunk("create/customer", async (value) => {
  return await create_customer(value);
});

const adminsetupSlice = createSlice({
  name: "setup",
  initialState,
  reducers: {
    [createcustomer.fulfilled]: (state, action) => {
      state.loading = false;
      state.result = action.payload;
    },
  },
});

const { reducer } = adminsetupSlice;
export { createcustomer };
export default reducer;
