import React, { useEffect } from "react";
import { Row, Col, Form, Input } from "antd";
import * as common from "../../../../util/common";

const CP3_8 = (props) => {
  const formula_defination = {
    r1c3: { type: "per", cols: ["r1c2", "r1c1"] },
    r1c5: { type: "per", cols: ["r1c4", "r1c1"] },
    r1c8: { type: "per", cols: ["r1c7", "r1c6"] },
    r1c10: { type: "per", cols: ["r1c9", "r1c6"] },
    r2c3: { type: "per", cols: ["r2c2", "r2c1"] },
    r2c5: { type: "per", cols: ["r2c4", "r2c1"] },
    r2c8: { type: "per", cols: ["r2c7", "r2c6"] },
    r2c10: { type: "per", cols: ["r2c9", "r2c6"] },
    r3c1: { type: "sum", cols: ["r1c1", "r2c1"] },
    r3c2: { type: "sum", cols: ["r1c2", "r2c2"] },
    r3c3: { type: "per", cols: ["r3c2", "r3c1"] },
    r3c4: { type: "sum", cols: ["r1c4", "r2c4"] },
    r3c5: { type: "per", cols: ["r3c4", "r3c1"] },
    r3c6: { type: "sum", cols: ["r1c6", "r2c6"] },
    r3c7: { type: "sum", cols: ["r1c7", "r2c7"] },
    r3c8: { type: "per", cols: ["r3c7", "r3c6"] },
    r3c9: { type: "sum", cols: ["r1c9", "r2c9"] },
    r3c10: { type: "per", cols: ["r3c9", "r3c6"] },
    r4c3: { type: "per", cols: ["r4c2", "r4c1"] },
    r4c5: { type: "per", cols: ["r4c4", "r4c1"] },
    r4c8: { type: "per", cols: ["r4c7", "r4c6"] },
    r4c10: { type: "per", cols: ["r4c9", "r4c6"] },
    r5c3: { type: "per", cols: ["r5c2", "r5c1"] },
    r5c5: { type: "per", cols: ["r5c4", "r5c1"] },
    r5c8: { type: "per", cols: ["r5c7", "r5c6"] },
    r5c10: { type: "per", cols: ["r5c9", "r5c6"] },
    r6c1: { type: "sum", cols: ["r4c1", "r5c1"] },
    r6c2: { type: "sum", cols: ["r4c2", "r5c2"] },
    r6c3: { type: "per", cols: ["r6c2", "r6c1"] },
    r6c4: { type: "sum", cols: ["r4c4", "r5c4"] },
    r6c5: { type: "per", cols: ["r6c4", "r6c1"] },
    r6c6: { type: "sum", cols: ["r4c6", "r5c6"] },
    r6c7: { type: "sum", cols: ["r4c7", "r5c7"] },
    r6c8: { type: "per", cols: ["r6c7", "r6c6"] },
    r6c9: { type: "sum", cols: ["r4c9", "r5c9"] },
    r6c10: { type: "per", cols: ["r6c9", "r6c6"] },
  };

  const handleChange = (e) => {
    common.arthiFunc(props.ques_id, formula_defination, props.form);
  };
  useEffect(() => {
    handleChange();
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div id="tbl-cell">
      <Row>
        <Col span={5}>
          <span
            style={{ fontSize: "12px", fontWeight: "600" }}
          >{`Employees`}</span>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <span style={{ fontSize: "12px", fontWeight: "600" }}></span>
        </Col>
        <Col span={10} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            FY: {props?.data?.current_fy} Current Financial Year
          </span>
        </Col>
        <Col span={10} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            FY: {props?.data?.previous_fy} Previous Financial Year
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <span
            style={{ fontSize: "12px", fontWeight: "600" }}
          >{`Category`}</span>
        </Col>
        <Col span={2} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}></span>
        </Col>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            {`On Health and safety measures`}
          </span>
        </Col>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            {`On Skill upgradation`}
          </span>
        </Col>
        <Col span={2} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            {`Total (D)`}
          </span>
        </Col>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            {`On Health and safety measures`}
          </span>
        </Col>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            {`On Skill upgradation`}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <span style={{ fontSize: "12px", fontWeight: "600" }}></span>
        </Col>

        <Col span={2} className="text-center">
          <span
            style={{ fontSize: "12px", fontWeight: "600" }}
          >{`Total (A)`}</span>
        </Col>
        <Col span={2} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
            {`No. (B)`}
          </span>
        </Col>
        <Col span={2} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
            {`% (B /A)`}
          </span>
        </Col>
        <Col span={2} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
            {`No.(C)`}
          </span>
        </Col>
        <Col span={2} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
            {`% (C /A)`}
          </span>
        </Col>
        <Col span={2} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            {`Total (D)`}
          </span>
        </Col>
        <Col span={2} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
            {`No. (E)`}
          </span>
        </Col>
        <Col span={2} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
            {`% (E /D)`}
          </span>
        </Col>
        <Col span={2} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
            {`No. (F)`}
          </span>
        </Col>
        <Col span={2} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
            {`% (F /D)`}
          </span>
        </Col>
      </Row>

      <Row>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>{`Male`}</span>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r1c1"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r1c2"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r1c3"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r1c4"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r1c5"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        {/* Pre */}
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r1c6"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r1c7"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r1c8"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r1c9"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r1c10"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4} className="text-center">
          <span
            style={{ fontSize: "12px", fontWeight: "600" }}
          >{`Female`}</span>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r2c1"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r2c2"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r2c3"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r2c4"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r2c5"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        {/* Pre */}
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r2c6"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r2c7"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r2c8"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r2c9"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r2c10"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>{`Total`}</span>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r3c1"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r3c2"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r3c3"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r3c4"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r3c5"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        {/* Pre */}
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r3c6"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r3c7"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r3c8"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r3c9"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r3c10"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={5}>
          <span
            style={{ fontSize: "12px", fontWeight: "600" }}
          >{`Workers`}</span>
        </Col>
      </Row>
      <Row>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>{`Male`}</span>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r4c1"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r4c2"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r4c3"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r4c4"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r4c5"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        {/* Pre */}
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r4c6"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r4c7"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r4c8"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r4c9"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r4c10"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4} className="text-center">
          <span
            style={{ fontSize: "12px", fontWeight: "600" }}
          >{`Female`}</span>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r5c1"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r5c2"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r5c3"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r5c4"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r5c5"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        {/* Pre */}
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r5c6"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r5c7"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r5c8"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r5c9"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r5c10"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>{`Total`}</span>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r6c1"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r6c2"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r6c3"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r6c4"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r6c5"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        {/* Pre */}
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r6c6"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r6c7"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r6c8"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r6c9"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={[props.ques_id, "r6c10"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default CP3_8;
