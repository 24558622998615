import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Table,
  Modal,
  message,
  Row,
  Col,
  Select,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
const { Option } = Select;

const App = (props) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [product_name, setProduct_name] = useState("");
  const [reclaimed, setReclaimed] = useState("");
  const [modelOpen, setModelOpen] = useState(false);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item, index) => index !== key);
    setDataSource(newData);
    props.setdata(newData);
  };

  const Columns = [
    {
      title: 'SNo.',
      dataIndex: 'key',
      width: '10%',
      editable: true,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Indicate product category',
      dataIndex: 'r1c1',
      //width: "30%",
      editable: true,
    },
    {
      title:
        'Reclaimed products and their packaging materials as % of total products sold in respective category',
      dataIndex: 'r1c2',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record, index) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(index)}
          >
            <Button className="ant-btn-link" type="link">
              <DeleteOutlined twoToneColor="#eb2f96" />
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  useEffect(() => {
    setDataSource(props?.data?.cp2_9 || []);
  }, [props.data]);

  const btnAdd = () => {
    const newData = {
      r1c1: product_name,
      r1c2: reclaimed,
    };
    let updatedData = [...dataSource, newData];
    setDataSource([...dataSource, newData]);
    props.setdata([...dataSource, newData]);
    message.success("Processing complete!");
    setModelOpen(false);
  };

  return (
    <div>
      <Row>
        <Col span={24} offset={22}>
          <span>
            <Button
              onClick={() => {
                setModelOpen(true);
                setProduct_name('');
                setReclaimed('');
                form.resetFields();
              }}
              disabled={props.readonly}
            >
              Add
            </Button>
          </span>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={Columns}
        pagination={false}
        className="f-table-wrapper"
        scroll={{
          y: 240,
        }}
      />
      <Modal
        title="Add New Details"
        style={{ top: 20 }}
        open={modelOpen}
        onOk={btnAdd}
        okText="Add"
        onCancel={() => setModelOpen(false)}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ remember: true }}
          onFinish={btnAdd}
          autoComplete="off"
        >
          <div className="modalform">
            <Form.Item name="product_name" label="Indicate product category">
              <Input
                onChange={(e) => setProduct_name(e.target.value)}
                placeholder=""
              />
            </Form.Item>
            <Form.Item
              name="risk"
              label="Reclaimed products and their packaging materials as % of total products sold in respective category"
            >
              <Input.TextArea
                rows={2}
                onChange={(e) => setReclaimed(e.target.value)}
                placeholder=""
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default App;
