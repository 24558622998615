import React, { useEffect, useState } from "react";
import { Divider, Row, Col, Form, Radio, Input } from "antd";
import CommentsApp from "../../../../Components/Comments";

const CP3_20 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <>
      <Form.Item label="CP3-20" className="title-header">
        <Row>
          <Col span={24}>
            <span style={{ fontSize: "12px", fontWeight: "700" }}>
              {`Details on assessment of value chain partners:`}
            </span>
            <div id="tbl-cell">
              <Row>
                <Col span={9}>
                  <span style={{ fontSize: "12px", fontWeight: "600" }}></span>
                </Col>
                <Col span={15} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`% of value chain partners (by value of business done with such partners) that were assessed`}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col span={9}>
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`Health and safety practices`}
                  </span>
                </Col>
                <Col span={15}>
                  <Form.Item
                    name={[
                      props.ques_id,
                      "r1c1",
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={9}>
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`Working Conditions`}
                  </span>
                </Col>
                <Col span={15}>
                  <Form.Item
                    name={[
                      props.ques_id,
                      "r2c1",
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default CP3_20;
