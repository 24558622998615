import React, { useEffect, useState } from "react";
import {
  Divider,
  message,
  Button,
  Input,
  Row,
  Col,
  Form,
  Card,
  Space,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import {
  create_section_slice,
  get_section,
} from "../SectionSlice/sectionSlice";
import CP2_1 from "./CP2-1";
import CP2_5 from "./CP2-5";
import CP2_6 from "./CP2-6";
import CP2_7 from "./CP2-7";
import CP2_9 from "./CP2-9";
import CP28 from "./a22";
import CommentsApp from "../../../../Components/Comments";
import { useCustomer } from "../../../../hooks/useCustomer";
import { useFinancialYear } from "../../../../hooks/useFinancialYear";
import TextFieldToTextArea from "../../../../Components/Input/textfieldtoarea";
import CommentsNotified from "../../../../Components/Comments/comments-notification";

const { Option } = Select;
const Section_CP2 = (props) => {
  const [cp2_5a, setCP2_5a] = useState("No");
  const [cp2_5b, setCP2_5b] = useState([]);
  const [cp2_6a, setCP2_6a] = useState("No");
  const [cp2_6b, setCP2_6b] = useState([]);
  const [cp2_7, setCP2_7] = useState([]);
  const [cp2_9, setCP2_9] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [defaultdata, setDefaultdata] = useState({});
  const [readonly, setReadonly] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { current_fy, previous_fy } = useFinancialYear();
  const { customer_id, assigned_template_id } = useCustomer();
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const [commentlist, setCommentlist] = useState([]);
  const [commentreload, setCommentReload] = useState();

  useEffect(() => {
    if (sessionState?.roletype === "CUSTOMER") {
      setReadonly(false);
    } else {
      setReadonly(true);
    }
    let payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_cp2",
    };
    setSpinning(true);
    dispatch(get_section({ payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setDefaultdata(res.data?.section_cp2);
          setCommentlist(res.data?.comments);
          setSpinning(false);
        } else {
          if (res.code != 800000) {
            message.info(res.msg);
          }

          setSpinning(false);
        }
      })
      .catch((Error) => {
        console.log(Error);
      });
  }, [commentreload]);

  const onFinish = (datalist) => {
    setSpinning(true);
    const datalist1 = {
      ...datalist,
      cp2_5b,
      cp2_6b,
      cp2_7,
      cp2_9,
    };
    let Payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_cp2",
      question_response: datalist1,
    };
    dispatch(create_section_slice({ Payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setSpinning(false);
          message.info("Section CP 2 created succesfully!");
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  useEffect(() => {
    form.setFieldsValue(defaultdata);
    setCP2_5a(form.getFieldValue(["cp2_5a", "r1c1"]));
    setCP2_5b(defaultdata.cp2_5b || []);
    setCP2_6a(form.getFieldValue(["cp2_6a", "r1c1"]));
    setCP2_6b(defaultdata.cp2_6b || []);
    setCP2_7(defaultdata.cp2_7 || []);
    setCP2_9(defaultdata.cp2_9 || []);
  }, [form, defaultdata]);
  return (
    <>
      <div className="formcontainer">
        <CommentsNotified commentlist={commentlist} />
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={defaultdata}
          scrollToFirstError
          style={{ width: "100%" }}
        >
          <div
            style={{ display: assigned_template_id >= 1 ? "display" : "none" }}
          >
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Card title="Essential Indicators" size="small">
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <CP2_1
                      readonly={readonly}
                      form={form}
                      data={{ current_fy, previous_fy, ...defaultdata }}
                      ques_id={"cp2_1"}
                    />
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp2_1"
                    />
                    <Divider />
                  </>
                )}
                {[1, 2, 3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP2-2" className="title-header">
                      <Row style={{ width: "100%" }}>
                        <Col span={8}>
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "700",
                              lineHeight: "1px",
                            }}
                          >{`a. Does the entity have procedures in place for sustainable sourcing?`}</span>
                        </Col>
                        <Col span={16}>
                          <Form.Item name={["CP2-2", "r1c1"]}>
                            <Select
                              placeholder="Select Option"
                              disabled={readonly}
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row style={{ width: "100%" }}>
                        <Col span={8}>
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "700",
                              lineHeight: "1px",
                            }}
                          >{`b. If yes, what percentage of inputs were sourced sustainably?`}</span>
                        </Col>
                        <Col span={16}>
                          <div style={{ display: "flex" }}>
                            <Form.Item name={["CP2-2", "r2c1"]}>
                              <Input.TextArea
                                rows={3}
                                placeholder="Enter the percentage"
                                readOnly={readonly}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata?.question_response_id}
                        question_response_name="cp2_2"
                      />
                    </Form.Item>
                    <Divider />
                  </>
                )}
                {[1, 2, 3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP2-3" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Describe the processes in place to safely reclaim your products for reusing, recycling and disposing at the end of life, for`}
                          </span>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`(a)  Plastics (including packaging)`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP2-3", "r1c1"]}>
                              <TextFieldToTextArea props={props} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`b) E-waste`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP2-3", "r2c1"]}>
                              <TextFieldToTextArea props={props} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`(c) Hazardous waste`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP2-3", "r3c1"]}>
                              <TextFieldToTextArea props={props} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`(d) other waste`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP2-3", "r4c1"]}>
                              <TextFieldToTextArea props={props} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="CP2-3"
                    />
                    <Divider />
                  </>
                )}
                {[1, 2, 3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP2-4" className="title-header">
                      <Row>
                        <Col span={8}>
                          <div className="space-between">
                            <span>
                              {`Whether Extended Producer Responsibility (EPR) is applicable to the entity’s activities (Yes / No). If yes, whether the waste collection plan is in line with the Extended Producer Responsibility (EPR) plan submitted to Pollution Control Boards? If not, provide steps taken to address the same.`}
                            </span>
                          </div>
                        </Col>
                        <Col span={14}>
                          <div style={{ display: "flex" }}>
                            <Form.Item name={["CP2-4", "r1c1"]}>
                              <Select
                                disabled={readonly}
                                style={{ width: "200px", marginRight: "10px" }}
                                placeholder="Select Option"
                              >
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                              </Select>
                            </Form.Item>

                            <Form.Item name={["CP2-4", "r1c2"]}>
                              <Input.TextArea
                                readOnly={readonly}
                                rows={3}
                                // onChange={(e) => setRemark(e.target.value)}
                                placeholder="Enter details"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="CP2-4"
                    />
                  </>
                )}
              </Card>
            </Space>

            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              {[3, 4].includes(assigned_template_id) && (
                <Card title="Leadership Indicators" size="small">
                  {[3, 4].includes(assigned_template_id) && (
                    <>
                      <Form.Item label="CP2-5" className="title-header">
                        <Row>
                          <Col span={10}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`Has the entity conducted Life Cycle Perspective / Assessments (LCA) for any of its products (for manufacturing industry) or for its services (for service industry)? If yes, provide details in the following format?`}
                            </span>
                          </Col>
                          <Col span={10}>
                            <Form.Item name={["cp2_5a", "r1c1"]}>
                              <Select
                                disabled={readonly}
                                style={{ width: "200px", marginRight: "10px" }}
                                placeholder="Select Option"
                                onChange={(value) => setCP2_5a(value)}
                              >
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            {cp2_5a === "Yes" && (
                              <CP2_5
                                readonly={readonly}
                                data={{ cp2_5b }}
                                setdata={setCP2_5b}
                                form={form}
                                ques_id={"cp2_5b"}
                              />
                            )}
                          </Col>
                        </Row>
                      </Form.Item>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata?.question_response_id}
                        question_response_name="cp2_5"
                      />
                      <Divider />
                    </>
                  )}
                  {[3, 4].includes(assigned_template_id) && (
                    <>
                      <Form.Item label="CP2-6" className="title-header">
                        <Row>
                          <Col span={10}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`If there are any significant social or environmental concerns and/or risks arising from production or disposal of your products / services, as identified in the Life Cycle Perspective / Assessments (LCA) or through any other means, briefly describe the same along-with action taken to mitigate the same.`}
                            </span>
                          </Col>
                          <Col span={10}>
                            <Form.Item name={["cp2_6a", "r1c1"]}>
                              <Select
                                disabled={readonly}
                                style={{ width: "200px", marginRight: "10px" }}
                                placeholder="Select Option"
                                onChange={(value) => setCP2_6a(value)}
                              >
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            {cp2_6a === "Yes" && (
                              <CP2_6
                                readonly={readonly}
                                data={{ cp2_6b }}
                                setdata={setCP2_6b}
                                form={form}
                                ques_id={"cp2_6b"}
                              />
                            )}
                          </Col>
                        </Row>
                      </Form.Item>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata?.question_response_id}
                        question_response_name="cp2_6"
                      />
                      <Divider />
                    </>
                  )}
                  {[3, 4].includes(assigned_template_id) && (
                    <>
                      <Form.Item label="CP2-7" className="title-header">
                        <Row>
                          <Col span={22}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`Percentage of recycled or reused input material to total material (by value) used in production (for manufacturing industry) or providing services (for service industry).`}
                            </span>
                          </Col>
                          <Col span={24}>
                            <div className="space-between">
                              <span>
                                Recycled or re-used input material to total
                                material
                              </span>
                            </div>
                            <CP2_7
                              readonly={readonly}
                              data={{ cp2_7 }}
                              setdata={setCP2_7}
                              form={form}
                              ques_id={"cp2_7"}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata?.question_response_id}
                        question_response_name="cp2_7"
                      />
                      <Divider />
                    </>
                  )}
                  {[3, 4].includes(assigned_template_id) && (
                    <>
                      <CP28
                        readonly={readonly}
                        data={{ current_fy, previous_fy, ...cp2_7 }}
                        setdata={setCP2_7}
                        form={form}
                        ques_id={"CP2_8"}
                      />
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata?.question_response_id}
                        question_response_name="cp2_8"
                      />
                      <Divider />
                    </>
                  )}
                  {[3, 4].includes(assigned_template_id) && (
                    <>
                      <Form.Item label="CP2-9" className="title-header">
                        <Row>
                          <Col span={22}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`Reclaimed products and their packaging materials (as percentage of products sold) for each product category.`}
                            </span>
                          </Col>
                          <Col span={24}>
                            <CP2_9
                              readonly={readonly}
                              data={{ cp2_9 }}
                              setdata={setCP2_9}
                              form={form}
                              ques_id={"cp2_9"}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata?.question_response_id}
                        question_response_name="cp2_9"
                      />
                    </>
                  )}
                </Card>
              )}
            </Space>
          </div>
          {/* <h2>END OF LITE/EXTENDED</h2> */}

          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[4].includes(assigned_template_id) && (
              <Card title="Essential Indicators" size="small">
                <>
                  <Form.Item label="CP2-10" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Negative environmental impacts in the supply chain and actions taken`}
                        </span>
                      </Col>
                      <Row style={{ width: "100%" }}>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`(a)- Number of suppliers identified as having significant actual and potential negative environmental impacts.`}
                          </span>
                        </Col>
                        <Col span={16}>
                          <Form.Item name={["CP2-10", "r1c1"]}>
                            <Input
                              placeholder="Enter Details"
                              readOnly={readonly}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row style={{ width: "100%" }}>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`b)- Percentage of suppliers identified as having significant actual and potential negative environmental impacts with which relationships were terminated as a result of assessment, and why.`}
                          </span>
                        </Col>
                        <Col span={16}>
                          <Form.Item name={["CP2-10", "r2c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={3}
                              placeholder="Enter Details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="CP2-10"
                  />
                </>
              </Card>
            )}
          </Space>

          {readonly ? (
            ""
          ) : (
            <Space
              style={{
                //marginTop: "10px",
                marginBottom: "10px",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <Button style={{ marginLeft: 10 }} htmlType="submit">
                Save and Continue
              </Button>
            </Space>
          )}
        </Form>
      </div>
    </>
  );
};

export default Section_CP2;
