import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Table,
  Modal,
  message,
  Row,
  Col,
  Select,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
const { Option } = Select;

const App = (props) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [public_domain, setPublic_domain] = useState("");
  const [public_domain_link , setLink] = useState("");
  const [agency, setAgency] = useState("");
  const [perspective, setPerspective] = useState("");
  const [total_turnover, setTotal_turnover] = useState("");
  const [product_name, setProduct_name] = useState("");
  const [nic_code, setNic_code] = useState("");
  const [modelOpen, setModelOpen] = useState(false);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item, index) => index !== key);
    setDataSource(newData);
    props.setdata(newData);
  };

  const Columns = [
    {
      title: 'SNo.',
      dataIndex: 'key',
      width: '10%',
      editable: true,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'NIC Code',
      dataIndex: 'r1c1',
      //width: "30%",
      editable: true,
    },
    {
      title: 'Name of Product /Service',
      dataIndex: 'r1c2',
    },

    {
      title: '% of total Turnover contributed',
      dataIndex: 'r1c3',
    },
    {
      title:
        'Boundary for which the Life Cycle Perspective / Assessment was conducted',
      dataIndex: 'r1c4',
    },
    {
      title: 'Whether conducted by independent external agency (Yes/No)',
      dataIndex: 'r1c5',
    },
    {
      title:
        'Results communicated in public domain (Yes/No) If yes, provide the web-link.',
      dataIndex: 'r1c6',
    },
    {
      title: 'web link',
      dataIndex: 'r1c7',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record, index) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(index)}
          >
            <Button className="ant-btn-link" type="link">
              <DeleteOutlined twoToneColor="#eb2f96" />
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  useEffect(() => {
    setDataSource(props.data?.cp2_5b || []);
  }, [props.data]);

  const btnAdd = () => {
    const newData = {
      r1c1: nic_code,
      r1c2: product_name,
      r1c3: total_turnover,
      r1c4: perspective,
      r1c5: agency,
      r1c6: public_domain,
      r1c7: public_domain_link
    };
    setDataSource([...dataSource, newData]);
    props.setdata([...dataSource, newData]);
    message.success("Processing complete!");
    setModelOpen(false);
  };

  return (
    <div>
      <Row>
        <Col span={24} offset={23}>
          <span>
            <Button
              onClick={() => {
                setModelOpen(true);
                setAgency('');
                setLink('');
                setNic_code('');
                setPerspective('');
                setPublic_domain('');
                setTotal_turnover('');
                form.resetFields();
              }}
              disabled={props.readonly}
            >
              Add
            </Button>
          </span>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={Columns}
        pagination={false}
        className="f-table-wrapper"
        scroll={{
          y: 240,
        }}
      />
      <Modal
        title="Add New Details"
        style={{ top: 20 }}
        open={modelOpen}
        onOk={btnAdd}
        okText="Add"
        onCancel={() => setModelOpen(false)}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ remember: true }}
          onFinish={btnAdd}
          autoComplete="off"
        >
          <div className="modalform">
            <label>NIC Code</label>
            <Form.Item name="nic_code">
              <Input
                onChange={(e) => setNic_code(e.target.value)}
                placeholder="NIC Code"
              />
            </Form.Item>
            <label>Name of Product /Service</label>
            <Form.Item name="product_name">
              <Input
                onChange={(e) => setProduct_name(e.target.value)}
                placeholder="Enter Product Name"
              />
            </Form.Item>
            <label>% of total Turnover contributed</label>
            <Form.Item name="turnover">
              <Input
                onChange={(e) => setTotal_turnover(e.target.value)}
                placeholder="Enter Details"
              />
            </Form.Item>
            <label>
              Boundary for which the Life Cycle Perspective / Assessment was
              conducted
            </label>
            <Form.Item name="perspective">
              <Input
                onChange={(e) => setPerspective(e.target.value)}
                placeholder="Enter Details"
              />
            </Form.Item>
            <label>
              Whether conducted by independent external agency (Yes/No)
            </label>
            <Form.Item name="agency">
              <Select
                onChange={(e) => setAgency(e)}
                style={{ width: '200px', marginRight: '10px' }}
                placeholder="Select Option"
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
            <label>Results communicated in public domain</label>
            <Form.Item name="results">
              <Select
                onChange={(e) => setPublic_domain(e)}
                style={{ width: '200px', marginRight: '10px' }}
                placeholder="Select Option"
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
            {public_domain === 'Yes' && <><label>Web link</label>
            <Form.Item
              name="link"
            >
              <Input
                onChange={(e) => setLink(e.target.value)}
                placeholder="Enter Details"
              />
            </Form.Item></>}
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default App;
