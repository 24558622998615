import { createAsyncThunk } from "@reduxjs/toolkit";
import { forgotPassword } from "../../../Config/actions";

const forgotPasswordSlice = createAsyncThunk(
  "customers/forgotPassword",
  async (value) => {
    return await forgotPassword(value);
  }
);

// const { actions, reducer } = customerUserSlice;
export { forgotPasswordSlice };
// export default reducer;
