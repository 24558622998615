import React from "react";
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.scss";

const Example = (props) => {
  return (
    <div className="example">
      <ScrollBar>{props.children}</ScrollBar>
    </div>
  );
};

export default Example;
