import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createQuestionaireConversations,
  endQuestionaireConversations,
  getQuestionaireConversationsInfo,
} from "../../../Config/actions";

const initialState = {
  loading: false,
  error: null,
  result: null,
};

const create_questionare_comments_slice = createAsyncThunk(
  "create/questionare/comments",
  async (value) => {
    return await createQuestionaireConversations(value);
  }
);

const end_questionare_comments_slice = createAsyncThunk(
  'end/questionare/comments',
  async (value) => {
    return await endQuestionaireConversations(value);
  }
);

const get_questionare_comments = createAsyncThunk(
  "get/questionare/comments",
  async (value) => {
    return await getQuestionaireConversationsInfo(value);
  }
);

const questionairecommentsSlice = createSlice({
  name: "questionairecomments",
  initialState,
  reducers: {
    [create_questionare_comments_slice.fulfilled]: (state, action) => {
      state.loading = false;
      state.result = action.payload;
    },
    [get_questionare_comments.fulfilled]: (state, action) => {
      state.loading = false;
      state.result = action.payload;
    },
  },
});

const { reducer } = questionairecommentsSlice;
export {
  create_questionare_comments_slice,
  get_questionare_comments,
  end_questionare_comments_slice,
};
export default reducer;
