import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form } from "antd";
import * as common from "../../../../../util/common";

const T18 = (props) => {
  const formula_defination = {
    r1c3: { type: "sum", cols: ["r1c1", "r1c2"] },
    r2c3: { type: "sum", cols: ["r2c1", "r2c2"] },
  };

  const handleChange = (e) => {
    common.arthiFunc(props.ques_id, formula_defination, props.form);
  };
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <div id="tbl-cell">
          <Row>
            <Col span={6}>Location</Col>
            <Col span={6}>Number of plants</Col>
            <Col span={6}>Number of offices</Col>
            <Col span={6}>Total</Col>
          </Row>
          <Row>
            <Col span={6}>National</Col>
            <Col span={6}>
              <Form.Item name={[props.ques_id, "r1c1"]} className="ant-col1">
                <Input
                  placeholder="Enter"
                  onChange={handleChange}
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={[props.ques_id, "r1c2"]} className="ant-col1">
                <Input
                  placeholder="Enter"
                  onChange={handleChange}
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={[props.ques_id, "r1c3"]}>
                <Input readOnly={true} size="small" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>International</Col>
            <Col span={6}>
              <Form.Item name={[props.ques_id, "r2c1"]} className="ant-col1">
                <Input
                  placeholder="Enter"
                  onChange={handleChange}
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={[props.ques_id, "r2c2"]} className="ant-col1">
                <Input
                  placeholder="Enter"
                  onChange={handleChange}
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={[props.ques_id, "r2c3"]}>
                <Input readOnly={true} size="small" />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default T18;
