import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import store from "./store/store";
import { AuthProvider } from "./Context";
import { FinancialYearProvider } from "./Context/FinancialYearProvider";
import "./util/interceptor";
import { CustomerProvider } from "./Context/CustomerProvider";

ReactDOM.render(
  <ConfigProvider>
    <CustomerProvider>
      <Provider store={store}>
        <React.Suspense>
          <AuthProvider>
            <FinancialYearProvider>
              <App />
            </FinancialYearProvider>
          </AuthProvider>
        </React.Suspense>
      </Provider>
    </CustomerProvider>
  </ConfigProvider>,

  document.getElementById("root")
);
