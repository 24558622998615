import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form, Select } from "antd";

const T13 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div>
      <Form.Item label="" className="title-header">
        <Row>
          <Col span={24}>
            <div id="tbl-cell">
              <Row>
                <Col span={2}>S.No</Col>
                <Col span={6}>
                  Specify the law / regulation/ guidelines which was not
                  complied with
                </Col>
                <Col span={5}>Provide details of the noncompliance</Col>
                <Col span={6}>
                  Any fines/ penalties / action taken by regulatory agencies
                  such as pollution control boards or by courts
                </Col>
                <Col span={5}>Corrective action taken, if any</Col>
              </Row>
              <Row>
                <Col span={2}>
                  <Form.Item
                    name={[props.ques_id, "r1c1"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter"/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r1c2"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter"/>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name={[props.ques_id, "r1c3"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter"/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r1c4"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter"/>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name={[props.ques_id, "r1c5"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter"/>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

export default T13;
