import React, { useEffect ,useState} from "react";
import { Row, Col, Form, Card, Space, Input, Select } from "antd";

const { Option } = Select;


const Cont13 = (props) => {
  
  const [readonly_c1, setC1Readonly] = useState(false);
  const [readonly_c2, setC2Readonly] = useState(false);
  const [readonly_c3, setC3Readonly] = useState(false);
  const [readonly_c4, setC4Readonly] = useState(false);
  const [readonly_c5, setC5Readonly] = useState(false);
  const [readonly_c6, setC6Readonly] = useState(false);
  const [readonly_c7, setC7Readonly] = useState(false);
  const [readonly_c8, setC8Readonly] = useState(false);
  const [readonly_c9, setC9Readonly] = useState(false);

  useEffect(() => {
    props.form.setFieldsValue(props.data);
    props.form.getFieldValue(["b1a", "r1c1"])=="Yes"?setC1Readonly(true):setC1Readonly(false);
    props.form.getFieldValue(["b1a", "r1c2"])=="Yes"?setC2Readonly(true):setC2Readonly(false);
    props.form.getFieldValue(["b1a", "r1c3"])=="Yes"?setC3Readonly(true):setC3Readonly(false);
    props.form.getFieldValue(["b1a", "r1c4"])=="Yes"?setC4Readonly(true):setC4Readonly(false);
    props.form.getFieldValue(["b1a", "r1c5"])=="Yes"?setC5Readonly(true):setC5Readonly(false);
    props.form.getFieldValue(["b1a", "r1c6"])=="Yes"?setC6Readonly(true):setC6Readonly(false);
    props.form.getFieldValue(["b1a", "r1c7"])=="Yes"?setC7Readonly(true):setC7Readonly(false);
    props.form.getFieldValue(["b1a", "r1c8"])=="Yes"?setC8Readonly(true):setC8Readonly(false);
    props.form.getFieldValue(["b1a", "r1c9"])=="Yes"?setC9Readonly(true):setC9Readonly(false);

  }, [props.form, props.data]);
  return (
    <div>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Card size="small">
          <div style={{ overflow: "scroll" }}>
            <Form.Item label="B12" className="title-header">
              <Row style={{ width: "150%" }}>
                <Col span={28}>
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    If answer to question (1) above is '"No" i.e, not all
                    principles are covered by a policy, reasons to be stated.
                  </span>

                  <div id="tbl-cell">
                    <Row>
                      <Col span={6}>
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          Questions
                        </span>
                      </Col>
                      <Col span={2} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P1
                        </span>
                      </Col>
                      <Col span={2} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P2
                        </span>
                      </Col>
                      <Col span={2} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P3
                        </span>
                      </Col>
                      <Col span={2} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P4
                        </span>
                      </Col>
                      <Col span={2} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P5
                        </span>
                      </Col>
                      <Col span={2} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P6
                        </span>
                      </Col>
                      <Col span={2} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P7
                        </span>
                      </Col>
                      <Col span={2} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P8
                        </span>
                      </Col>
                      <Col span={2} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P9
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={6} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          The entity does not consider the principles material
                          to its business (Yes/No).
                        </span>
                      </Col>

                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r1c1"]}>
                          <Select
                            disabled={props.readonly || readonly_c1}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r1c2"]}>
                          <Select
                            disabled={props.readonly || readonly_c2 }
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r1c3"]}>
                          <Select
                            disabled={props.readonly || readonly_c3}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r1c4"]}>
                          <Select
                            disabled={props.readonly || readonly_c4}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r1c5"]}>
                          <Select
                            disabled={props.readonly || readonly_c5}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r1c6"]}>
                          <Select
                            disabled={props.readonly || readonly_c6}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r1c7"]}>
                          <Select
                            disabled={props.readonly || readonly_c7}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r1c8"]}>
                          <Select
                            disabled={props.readonly || readonly_c8}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r1c9"]}>
                          <Select
                            disabled={props.readonly || readonly_c9}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={6} className="text-left">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          The entity is not at a stage where it is in a position
                          to formulate and implement the policies on specified
                          principles (Yes/No).
                        </span>
                      </Col>

                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r2c1"]}>
                          <Select
                            disabled={props.readonly|| readonly_c1}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r2c2"]}>
                          <Select
                            disabled={props.readonly || readonly_c2}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r2c3"]}>
                          <Select
                            disabled={props.readonly || readonly_c3}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r2c4"]}>
                          <Select
                            disabled={props.readonly || readonly_c4}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r2c5"]}>
                          <Select
                            disabled={props.readonly || readonly_c5}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r2c6"]}>
                          <Select
                            disabled={props.readonly || readonly_c6}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r2c7"]}>
                          <Select
                            disabled={props.readonly || readonly_c7}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r2c8"]}>
                          <Select
                            disabled={props.readonly || readonly_c8}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r2c9"]}>
                          <Select
                            disabled={props.readonly || readonly_c9}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={6} className="text-left">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          The entity does not have the financial or/human and
                          technical resources available for the task(Yes/No).
                        </span>
                      </Col>

                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r3c1"]}>
                          <Select
                            disabled={props.readonly|| readonly_c1}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r3c2"]}>
                          <Select
                            disabled={props.readonly || readonly_c2}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r3c3"]}>
                          <Select
                            disabled={props.readonly || readonly_c3}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r3c4"]}>
                          <Select
                            disabled={props.readonly || readonly_c4}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r3c5"]}>
                          <Select
                            disabled={props.readonly || readonly_c5}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r3c6"]}>
                          <Select
                            disabled={props.readonly || readonly_c6}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r3c7"]}>
                          <Select
                            disabled={props.readonly || readonly_c7}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r3c8"]}>
                          <Select
                            disabled={props.readonly || readonly_c8}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r3c9"]}>
                          <Select
                            disabled={props.readonly || readonly_c9}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={6} className="text-left">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          It is planned to be done in the next financial year
                          (Yes/No).
                        </span>
                      </Col>

                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r4c1"]}>
                          <Select
                            disabled={props.readonly|| readonly_c1}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r4c2"]}>
                          <Select
                            disabled={props.readonly || readonly_c2}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r4c3"]}>
                          <Select
                            disabled={props.readonly || readonly_c3}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r4c4"]}>
                          <Select
                            disabled={props.readonly || readonly_c4}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r4c5"]}>
                          <Select
                            disabled={props.readonly || readonly_c5}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r4c6"]}>
                          <Select
                            disabled={props.readonly || readonly_c6}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r4c7"]}>
                          <Select
                            disabled={props.readonly || readonly_c7}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r4c8"]}>
                          <Select
                            disabled={props.readonly || readonly_c8}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r4c9"]}>
                          <Select
                            disabled={props.readonly || readonly_c9}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={6} className="text-left">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          Any other reason (please specify).
                        </span>
                      </Col>

                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r5c1"]}>
                          <Input readOnly={props.readonly} size="small" />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r5c2"]}>
                          <Input readOnly={props.readonly} size="small" />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r5c3"]}>
                          <Input readOnly={props.readonly} size="small" />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r5c4"]}>
                          <Input readOnly={props.readonly} size="small" />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r5c5"]}>
                          <Input readOnly={props.readonly} size="small" />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r5c6"]}>
                          <Input readOnly={props.readonly} size="small" />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r5c7"]}>
                          <Input readOnly={props.readonly} size="small" />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r5c8"]}>
                          <Input readOnly={props.readonly} size="small" />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[props.ques_id, "r5c9"]}>
                          <Input readOnly={props.readonly} size="small" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </div>
        </Card>
      </Space>
    </div>
  );
};

export default Cont13;
