import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./index.scss";
import { Select } from "antd";
import CommonCompbar from "../../../Components/BaseLayout";
import ScrollBar from "../../../Components/scroll/scroll";
import FinalSubmit from "./FinalSubmit";
import { useCustomer } from "../../../hooks/useCustomer";
import { fetchCustomer } from "../Customer-Details/dashboardSlice";
import { messageAlert } from "../../../Components/Alert";
import { useNavigate } from "react-router-dom";

let questComp = "";
let mainmenu = "";

const { Option } = Select;
const QuestionaireMain = () => {
  const {
    //notificationData,
    assigned_template_id,
    setAssignedTemplateId,
    setQuestionaire_status,
  } = useCustomer();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState("");
  const [customer_data, setCustomerData] = useState([]);
  const [customer_qstatus, setCustomer_QStatus] = useState({});
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const [cust_id, setCustId] = useState(null);
  const navigate = useNavigate();

  function onChange(value, key) {
    let assigned_template_id = 0;
    customer_data.map((item) => {
      if (item.id === value) {
        setQuestionaire_status(item.questionaire_status);
        assigned_template_id = item.assigned_template_id;
      }
    });
    if (sessionState?.roletype !== "CUSTOMER") {
      localStorage.setItem("assigned_template_id", assigned_template_id);
      setAssignedTemplateId(assigned_template_id);
    }
    setCustId(key.key);
    localStorage.setItem("curr_customer", key.key);
  }
  useEffect(() => {
    getFetchdetails();
  }, []);

  const getFetchdetails = () => {
    dispatch(fetchCustomer())
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setCustomerData(res.data);
          res.data.map((item) => {
            if (
              sessionState?.roletype === "CUSTOMER" ||
              sessionState?.roletype === "AUDITOR"
            ) {
              setQuestionaire_status(item.questionaire_status);
              setCustomer_QStatus(item.questionaire_status);
              setCustId(localStorage.getItem("curr_customer"));
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const btnClick = (key, sdt) => {
    setCurrent(key);
    if (cust_id === null && sessionState?.roletype === "AUDITOR") {
      if (sessionState?.roletype === "AUDITOR") {
        if (key === "finalsubmit") {
          return (questComp = <FinalSubmit customer_id={cust_id} />);
        } else {
          questComp = "";
        }
      }
      return messageAlert({
        title: "Customer",
        msg: "Please select the Customer first!..",
      });
    }
    if (cust_id === null && sessionState?.roletype === "ADMIN") {
      return messageAlert({
        title: "Customer",
        msg: "Please select the Customer first!..",
      });
    }
    if (sessionState?.roletype === "CUSTOMER") {
      setCustId(localStorage.getItem("customer_id"));
      navigate("/questionaire/main", {
        state: { key: key, cust_id: localStorage.getItem("customer_id") },
      });
    } else {
      navigate("/questionaire/main", {
        state: { key: key, cust_id: cust_id },
      });
    }
  };
  const items = [
    {
      label: `Basic Information`, //"Section A",
      key: "sectiona",
      path: "/questionaire",
      disabled: false,
    },
    {
      label: `Policies and Guidelines`, //"Section B",
      key: "sectionb",
      disabled: false,
    },
    {
      label: `Compliance`, //"Section CP1",
      key: "sectionc",
      disabled: false,
    },
    {
      label: `Footprint (General)`, //"Section CP2",
      key: "sectiond",
      disabled: false,
    },
    {
      label: `People-Practice`, //"Section CP3",
      key: "sectionep1",
      disabled: false,
    },
    {
      label: `Value chain`, //"Section CP4",
      key: "sectionep2",
      disabled: false,
    },
    {
      label: `Human rights`, //`Section CP5`,
      key: "sectionep3",
      disabled: false,
    },
    {
      label: "Footprint (Specific)", //`Section CP6`,
      key: "sectionep4",
      disabled: false,
    },

    {
      label: "Stakeholder-Peers", //"Section CP7",
      key: "sectionep7",
      disabled: assigned_template_id === 1 ? true : false,
    },
    {
      label: "Social responsibility", //"Section CP8",
      key: "sectionep8",
      disabled: false,
    },
    {
      label: "Consumers", //"Section CP9",
      key: "sectionep9",
      disabled: false,
    },
    {
      label: "Core human rights", //"Section D",
      key: "sectionep5",
      disabled:
        assigned_template_id === 1 || assigned_template_id === 2 ? true : false,
    },
    {
      label: "Risk mitigation", //"Section E",
      key: "sectionep6",
      disabled:
        assigned_template_id === 1 || assigned_template_id === 2 ? true : false,
    },
    {
      label: "Submit for Review",
      key: "finalsubmit",
      disabled: sessionState.roletype === "CUSTOMER" ? false : true,
    },
  ];

  const mainmenu = (
    <ScrollBar>
      <div className="flex-container1">
        {items.map((item, index) => {
          return (
            <div key={index}
              onClick={() => (item.disabled ? true : btnClick(item.key))}
              className={item.key === "finalsubmit" ? `finalsubmit` : ""}
            >
              <h2>{item.label}</h2>
              <Link
                // onClick={() => btnClick(item.key)}
                key={index}
                to={item.path}
                disabled={item.disabled ? true : false}
              >
                <button
                  disabled={item.disabled ? true : false}
                  className="mainbutton"
                >
                  click here
                </button>
              </Link>
            </div>
          );
        })}
      </div>
    </ScrollBar>
  );

  return (
    <CommonCompbar btnClick={btnClick} sidebar={true} current={current}>
      <div className="main-title-header">
        <div className="main-title-header">
          {sessionState?.roletype === "CUSTOMER" ? (
            <div>{mainmenu}</div>
          ) : (
            <>
              <div className="table-title-header-top flex">
                <div style={{ margin: "10px" }}>
                  <span>Select Customer</span>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select"
                    onChange={onChange}
                    value={cust_id == null ? "" : Number(cust_id)}
                  >
                    {customer_data.map((item) => {
                      return (
                        <>
                          {sessionState?.roletype === "ADMIN" ? (
                            item.questionaire_status === "Reviewed" ? (
                              <Option
                                key={Number(item.id)}
                                value={Number(item.id)}
                              >
                                {item.company_name}
                              </Option>
                            ) : (
                              ""
                            )
                          ) : item.questionaire_status === "Reviewed" ||
                            item.questionaire_status ===
                              "SubmittedForReview" ? (
                            // <Option
                            //   key={Number(item.id)}
                            //   value={Number(item.id)}
                            // >
                            <Option
                              //selected={item.company_name === "Customer1"}
                              key={Number(item.id)}
                              value={item.id}
                            >
                              {item.company_name}
                            </Option>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div>{mainmenu}</div>
            </>
          )}
        </div>
      </div>
    </CommonCompbar>
  );
};
export default QuestionaireMain;
