import React from "react";
import { Modal, Form, Input, Button, Space } from "antd";

const EditReviewer = (props) => {
  const [form] = Form.useForm();
  const { backoffice_id, row } = props.data;
  function handleCancel() {
    props.handleFlag(false);
  }
  return (
    <Modal
      title="Edit Back Office User"
      open={props.status}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        form={form}
        name="edit"
        onFinish={props.handleEdit}
        initialValues={{
          backoffice_id: backoffice_id,
          display_name: row.display_name,
        }}
        scrollToFirstError
      >
        <div className="modalform">
        <Form.Item name="backoffice_id" label="Back Office User ID">
          {backoffice_id}
        </Form.Item>
        <Form.Item
          name="display_name"
          label="Display Name"
          rules={[
            {
              required: true,
              message: "Please input display name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Space
          style={{
            marginTop: "10px",
            justifyContent: "right",
            width: "100%",
          }}
        >
          <Button type="primary" onClick={handleCancel} htmlType="button">
            Cancel
          </Button>
          <Button style={{ marginLeft: 10 }} type="primary" htmlType="submit">
            Save
          </Button>
        </Space>
        </div>
      </Form>
    </Modal>
  );
};
export default EditReviewer;
