import React, { useEffect } from "react";
import { Row, Col, Input, Form } from "antd";

const CP5Comp = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div>
      <Form.Item label="CP1-6" className="title-header">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <span style={{ fontSize: "12px", fontWeight: "700" }}>
              {`Details of complaints with regard to conflict of interest:`}
            </span>
            <div id="tbl-cell">
              <Row>
                <Col span={6}>
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`Details of complaint`}
                  </span>
                </Col>
                <Col span={9} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`FY (Current Financial Year)`}
                  </span>
                </Col>
                <Col span={9} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`FY (Previous Financial Year)`}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={4} offset={6}>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    {`Number`}
                  </span>
                </Col>

                <Col span={5} offset={0}>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    {`Remarks`}
                  </span>
                </Col>
                <Col span={4} offset={0}>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    {`Number`}
                  </span>
                </Col>
                <Col span={5} offset={0}>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    {`Remarks`}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <span className="ant-col-header">
                    {`Number of complaints received in relation to issues of Conflict of Interest of the Directors`}
                  </span>
                </Col>

                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r1c1"]}>
                    <Input
                      type="number"
                      min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name={[props.ques_id, "r1c2"]}>
                    <Input size="small" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r1c3"]}>
                    <Input
                      type="number"
                      min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name={[props.ques_id, "r1c4"]}>
                    <Input size="small" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    {`Number of complaints received in relation to issues of Conflict of Interest of the KMPs`}
                  </span>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r2c1"]}>
                    <Input
                      type="number"
                      min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name={[props.ques_id, "r2c2"]}>
                    <Input size="small" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[props.ques_id, "r2c3"]}>
                    <Input
                      type="number"
                      min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name={[props.ques_id, "r2c4"]}>
                    <Input size="small" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

export default CP5Comp;
