import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  create_section_data,
  get_section_data,
  get_questionare_comments_summary,
  createQuestionaireReviewedSubmit
} from "../../../../Config/actions";

const initialState = {
  loading: false,
  error: null,
  result: null,
};

const create_section_slice = createAsyncThunk(
  "create/section",
  async (value) => {
    return await create_section_data(value);
  }
);

const get_section = createAsyncThunk("get/section", async (value) => {
  return await get_section_data(value.payload);
});

const get_comments_summary = createAsyncThunk(
  "get/questionare_comments_summary",
  async (value) => {
    return await get_questionare_comments_summary();
  }
);

const questionaireReviewedSubmitSlice = createAsyncThunk(
  "create/section/submit",
  async (value) => {
    return await createQuestionaireReviewedSubmit(value);
  }
);

const generateTempReportSubmitSlice = createAsyncThunk(
  "create/section/draftreport",
  async (value) => {
    return await createQuestionaireReviewedSubmit({...value,status:'Draft In Progress'});
  }
);

const adminsetupSlice = createSlice({
  name: "setup",
  initialState,
  reducers: {
    [create_section_slice.fulfilled]: (state, action) => {
      state.loading = false;
      state.result = action.payload;
    },
    [get_section.fulfilled]: (state, action) => {
      state.loading = false;
      state.result = action.payload;
    },
    [get_comments_summary.fulfilled]: (state, action) => {
      state.loading = false;
      state.result = action.payload;
    },
  },
});

const { reducer } = adminsetupSlice;
export {
  create_section_slice,
  get_section,
  get_comments_summary,
  questionaireReviewedSubmitSlice,
  generateTempReportSubmitSlice,
};
export default reducer;
