import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Table,
  Modal,
  message,
  Select,
  Row,
  Col,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;
const App = (props) => {
  const [modelOpen, setModelOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [r1c1, setR1C1] = useState('');
  const [r1c2, setR1C2] = useState('');
  const [r1c3, setR1C3] = useState('');
  const [form] = Form.useForm();

  //props.handleAdd_8_1(dataSource);
  const handleDelete = (key) => {
    const newData = dataSource.filter((item, index) => index !== key);
    setDataSource(newData);
    props.setdata(newData);
    message.success('Row deleted!');
  };

  const Columns = [
    {
      title: 'SNo.',
      dataIndex: 'key',
      width: '10%',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'CSR Project',
      dataIndex: 'r1c1',
    },
    {
      title: 'No. of persons benefitted from CSR Projects',
      dataIndex: 'r1c2',
    },
    {
      title: '% of beneficiaries from vulnerable and marginalized groups',
      dataIndex: 'r1c3',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record, index) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(index)}
          >
            <Button className="ant-btn-link" type="link">
              <DeleteOutlined twoToneColor="#eb2f96" />
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  useEffect(() => {
    setDataSource(props.data.cp8_11 || []);
  }, [props.data]);

  const btnAdd = (values) => {
    const newData = {
      r1c1,
      r1c2,
      r1c3,
    };
    setDataSource([...dataSource, newData]);
    props.setdata([...dataSource, newData]);
    message.success('Processing complete!');
    setModelOpen(false);
  };
  return (
    <div>
      <Row>
        <Col span={24} offset={22}>
          <span>
            <Button
              onClick={() => {
                setModelOpen(true);
                setR1C1('');
                setR1C2('');
                setR1C3('');
                form.resetFields();
              }}
              disabled={props.readonly}
            >
              Add
            </Button>
          </span>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={Columns}
        pagination={false}
        className="f-table-wrapper"
        scroll={{
          y: 240,
        }}
      />

      <Modal
        title="Add"
        style={{ top: 20 }}
        open={modelOpen}
        onOk={btnAdd}
        okText="Add"
        onCancel={() => setModelOpen(false)}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 22 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <div className="modalform">
            <label>CSR Project</label>
            <Form.Item name="r1c1">
              <Input
                readOnly={props.readonly}
                onChange={(e) => setR1C1(e.target.value)}
                placeholder="CSR Project"
              />
            </Form.Item>
            <label>No. of persons benefitted from CSR Projects</label>
            <Form.Item name="r1c2">
              <Input
                readOnly={props.readonly}
                onChange={(e) => setR1C2(e.target.value)}
                placeholder="No. of persons benefitted from CSR Projects"
              />
            </Form.Item>
            <label>
              % of beneficiaries from vulnerable and marginalized groups
            </label>
            <Form.Item name="r1c3">
              <Input
                readOnly={props.readonly}
                onChange={(e) => setR1C3(e.target.value)}
                placeholder="% of beneficiaries from vulnerable and marginalized groups"
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default App;
