import React, { useEffect } from "react";
import { Row, Col, Form, Card, Space, Select,Input } from "antd";

const { Option } = Select;
const B11Comp = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Card size="small">
          <Form.Item label="B11" className="title-header">
            <Row style={{ width: "100%" }}>
              <Col span={28}>
                <span style={{ fontSize: "12px", fontWeight: "600" }}>
                  Has the entity carried out independent assessment/evaluation
                  of the working of its policies by an external agency?(Yes/No).
                  If yes, provide name of the agency.
                </span>

                <div id="tbl-cell">
                  <Row style={{ width: "100%" }}>
                    <Col span={6}>
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        Name of the Agency
                      </span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        P1
                      </span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        P2
                      </span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        P3
                      </span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        P4
                      </span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        P5
                      </span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        P6
                      </span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        P7
                      </span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        P8
                      </span>
                    </Col>
                    <Col span={2} className="text-center">
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        P9
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6} className="text-center">
                      <Form.Item name={[props.ques_id, "r1c0"]}>
                        <Input disabled={props.readonly} placeholder="Enter" />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item name={[props.ques_id, "r1c1"]}>
                        <Select
                          disabled={props.readonly}
                          placeholder="Select Option"
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item name={[props.ques_id, "r1c2"]}>
                        <Select
                          disabled={props.readonly}
                          placeholder="Select Option"
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item name={[props.ques_id, "r1c3"]}>
                        <Select
                          disabled={props.readonly}
                          placeholder="Select Option"
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item name={[props.ques_id, "r1c4"]}>
                        <Select
                          disabled={props.readonly}
                          placeholder="Select Option"
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item name={[props.ques_id, "r1c5"]}>
                        <Select
                          disabled={props.readonly}
                          placeholder="Select Option"
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item name={[props.ques_id, "r1c6"]}>
                        <Select
                          disabled={props.readonly}
                          placeholder="Select Option"
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item name={[props.ques_id, "r1c7"]}>
                        <Select
                          disabled={props.readonly}
                          placeholder="Select Option"
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item name={[props.ques_id, "r1c8"]}>
                        <Select
                          disabled={props.readonly}
                          placeholder="Select Option"
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item name={[props.ques_id, "r1c9"]}>
                        <Select
                          disabled={props.readonly}
                          placeholder="Select Option"
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form.Item>
        </Card>
      </Space>
    </div>
  );
};

export default B11Comp;
