import React, { useState, useEffect } from "react";
import {
  loginUser,
  useAuthState,
  useAuthDispatch,
  registerQRCode,
} from "../../Context";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Input, Form } from "antd";
import "./index.scss";
import { useNavigate, Link } from "react-router-dom";
import Verify2FA_QR from "./page2FA";
import logoPic from "../../static/images/nslogo-large.png";

const Login = () => {
  const [code2FA, setcode2FA] = useState("");
  const [status_2fa, setStatus_2fa] = useState("");
  const [verify2FA, setverify2FA] = useState(false);

  const navigate = useNavigate();
  const [values, setValues] = useState({
    role: "",
    email: "",
    password: "",
    buttonText: "Submit",
  });

  const dispatch = useAuthDispatch();
  const { loading } = useAuthState();

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
    setValues({
      ...values,
      role,
      email: "",
    });
  };

  const { role, email, password } = values;
  const onFinish = async (values) => {
    try {
      let response = await loginUser(dispatch, { values });
      const activated_2fa = response.data.data.activated_2fa;
      const param = {
        username: values.username,
        login_type: response.data.data.login_type,
      };
      let response1 = await registerQRCode(dispatch, { param });
      setcode2FA(response1.data.data);
      setStatus_2fa(activated_2fa);
      setverify2FA(true);
    } catch (error) {
      console.log(error);
    }
  };
  // const btnforgot = () => {
  //   navigate("/forgotpassword");
  // };

  const renderLogin = () => (
    <div className="m-login-wrap">
      <div>
        <Link>
          {" "}
          <img src={logoPic} className="logo-large" alt="logo" />
        </Link>
      </div>

      <div className={`m-login`}>
        <div className="m-login-title">Sign In Access your account</div>
        <div className="m-login-form">
          <Form
            layout="vertical"
            className=""
            name="normal_login"
            initialValues={{
              remember: true,
              username: email,
              password: password,
            }}
            onFinish={onFinish}
          >
            <div>
              <Form.Item
                label="Email Address"
                tooltip="Allow only for registered user email-id"
                name="username"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  className="m-login-input"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="email"
                  defaultValue={email}
                />
              </Form.Item>

              <Form.Item
                label="Password:"
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input
                  className="m-login-input"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  defaultValue={password}
                />
              </Form.Item>

              <Form.Item>
                <Link className="login-form-forgot" to="/forgotpassword">
                  Forgot password
                </Link>
              </Form.Item>
              <Button
                className="m-login-btn"
                //type="primary"
                htmlType="submit"
                //onClick={handleLogin}
                disabled={loading}
                block
              >
                Sign In
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {verify2FA === true ? (
        <Verify2FA_QR data={code2FA} status_2fa={status_2fa} />
      ) : (
        renderLogin()
      )}
    </>
  );
};

export default Login;
