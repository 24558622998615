import axios from "axios";
import { urlLogin, url2fa, urlverify2facode } from "../Config/url";
import { notification } from "antd";

export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: "REQUEST_LOGIN" });
    const response = await axios.post(urlLogin, loginPayload.values);
    const authUser = response.data.data;
    if (authUser !== undefined) {
      dispatch({ type: "LOGIN_SUCCESS", payload: authUser });
      localStorage.setItem("user", authUser.username);
      localStorage.setItem("currentUser", loginPayload.values.username);
      localStorage.setItem("login_type", authUser.login_type);
      localStorage.setItem("auth2fa", authUser.activated_2fa);
      localStorage.setItem("role", authUser.role_id);

      return response;
    } else {
      // message.info("Authentication failed! new");
      //msgustomer1(message = "Munna");
      //AlertMsg((message = "Munna"));
      notification.open({
        message: "Authentication failed!",
        description: "Authentication failed!.",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
    }
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
    console.log(error);
  }
}

export async function registerQRCode(dispatch, loginPayload) {
  try {
    dispatch({ type: "QRCODE_SUCCESS" });
    const response = await axios.post(url2fa, loginPayload.param);
    return response;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
    console.log(error);
  }
}
export async function very2FAQRCode(dispatch, loginPayload) {
  try {
    dispatch({ type: "VERIFY_QRCODE_SUCCESS" });
    const response = await axios.post(urlverify2facode, loginPayload.params);
    return response.data;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
    console.log(error);
  }
}
export async function logout(dispatch) {
  dispatch({ type: "LOGOUT" });
  localStorage.clear();
  localStorage.setItem("auth", false);
}
