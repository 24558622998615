import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form } from "antd";
import * as common from "../../../../../util/common";

const T20A = (props) => {
  const formula_defination = {
    r1c1: { type: "sum", cols: ["r1c2", "r1c4"] },
    r1c3: { type: "per", cols: ["r1c2", "r1c1"] },
    r1c5: { type: "per", cols: ["r1c4", "r1c1"] },
    r2c1: { type: "sum", cols: ["r2c2", "r2c4"] },
    r2c3: { type: "per", cols: ["r2c2", "r2c1"] },
    r2c5: { type: "per", cols: ["r2c4", "r2c1"] },
    r3c1: { type: "sum", cols: ["r1c1", "r2c1"] },
    r3c2: { type: "sum", cols: ["r1c2", "r2c2"] },
    r3c3: { type: "per", cols: ["r3c2", "r3c1"] },
    r3c4: { type: "sum", cols: ["r1c4", "r2c4"] },
    r3c5: { type: "per", cols: ["r3c4", "r3c1"] },
    r4c1: { type: "sum", cols: ["r4c2", "r4c4"] },
    r4c3: { type: "per", cols: ["r4c2", "r4c1"] },
    r4c5: { type: "per", cols: ["r4c4", "r4c1"] },
    r5c1: { type: "sum", cols: ["r5c2", "r5c4"] },
    r5c3: { type: "per", cols: ["r5c2", "r5c1"] },
    r5c5: { type: "per", cols: ["r5c4", "r5c1"] },
    r6c1: { type: "sum", cols: ["r4c1", "r5c1"] },
    r6c2: { type: "sum", cols: ["r4c2", "r5c2"] },
    r6c3: { type: "per", cols: ["r6c2", "r6c1"] },
    r6c4: { type: "sum", cols: ["r4c4", "r5c4"] },
    r6c5: { type: "per", cols: ["r6c4", "r6c1"] },
  };

  const handleChange = (e, inputField) => {
    common.arthiFunc(props.ques_id, formula_defination, props.form);
    if (props.ques_id == "a20a") {
      // Retrieve current data from localStorage
      let a20asavedData = JSON.parse(localStorage.getItem("a20a")) || {};
      // Update the value or add the new key-value pair
      a20asavedData[inputField] = e.target.value;
      // Store the updated values in localStorage
      localStorage.setItem("a20a", JSON.stringify(a20asavedData));
    }
  };
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <span className="tbl-title">
          <h5>Employees</h5>
        </span>
        <div id="tbl-cell">
          <Row>
            <Col span={2}>S.No</Col>
            <Col span={6}>Particulars</Col>
            <Col span={4}>Total (a)</Col>
            <Col span={6}>Male</Col>
            <Col span={6}>Female</Col>
          </Row>
          <Row>
            <Col span={3} offset={12}>
              No. (b)
            </Col>
            <Col span={3} offset={0}>
              % (b/a)
            </Col>
            <Col span={3} offset={0}>
              No. (b)
            </Col>
            <Col span={3} offset={0}>
              % (b/a)
            </Col>
          </Row>
          <Row>
            <Col span={2}>1</Col>
            <Col span={6}>Permanent (d)</Col>
            <Col span={4}>
              <Form.Item name={[props.ques_id, "r1c1"]}>
                <Input readOnly={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r1c2"]}>
                <Input
                  onChange={(event) => handleChange(event, "1Y")}
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r1c3"]}>
                <Input readOnly={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r1c4"]}>
                <Input
                  onChange={(event) => handleChange(event, "1W")}
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r1c5"]}>
                <Input readOnly={true} size="small" suffix="% (B/A)" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={2}>2</Col>
            <Col span={6}>Other than permanent (e)</Col>
            <Col span={4}>
              <Form.Item name={[props.ques_id, "r2c1"]}>
                <Input readOnly={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r2c2"]}>
                <Input
                  onChange={(event) => handleChange(event, "2Y")}
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r2c3"]}>
                <Input readOnly={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r2c4"]}>
                <Input
                  onChange={(event) => handleChange(event, "2W")}
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r2c5"]}>
                <Input readOnly={true} size="small" suffix="% (B/A)" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={2}>3</Col>
            <Col span={6}>Total employees (d+e)</Col>
            <Col span={4}>
              <Form.Item name={[props.ques_id, "r3c1"]}>
                <Input readOnly={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r3c2"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r3c3"]}>
                <Input readOnly={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r3c4"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r3c5"]}>
                <Input readOnly={true} size="small" suffix="% (B/A)" />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <span className="tbl-title">
          <h5>Workers</h5>
        </span>
        <div id="tbl-cell">
          <Row>
            <Col span={2}>S.No</Col>
            <Col span={6}>Particulars</Col>
            <Col span={4}>Total (a)</Col>
            <Col span={6}>Male</Col>
            <Col span={6}>Female</Col>
          </Row>
          <Row>
            <Col span={3} offset={12}>
              No. (b)
            </Col>
            <Col span={3} offset={0}>
              % (b/a)
            </Col>
            <Col span={3} offset={0}>
              No. (b)
            </Col>
            <Col span={3} offset={0}>
              % (b/a)
            </Col>
          </Row>
          <Row>
            <Col span={2}>1</Col>
            <Col span={6}>Permanent (d)</Col>
            <Col span={4}>
              <Form.Item name={[props.ques_id, "r4c1"]}>
                <Input readOnly={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r4c2"]}>
                <Input
                  onChange={(event) => handleChange(event, "1K")}
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r4c3"]}>
                <Input readOnly={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r4c4"]}>
                <Input
                  onChange={(event) => handleChange(event, "1M")}
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r4c5"]}>
                <Input readOnly={true} size="small" suffix="% (B/A)" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={2}>2</Col>
            <Col span={6}>Other than permanent (e)</Col>
            <Col span={4}>
              <Form.Item name={[props.ques_id, "r5c1"]}>
                <Input readOnly={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r5c2"]}>
                <Input
                  onChange={(event) => handleChange(event, "2K")}
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r5c3"]}>
                <Input readOnly={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r5c4"]}>
                <Input
                  onChange={(event) => handleChange(event, "2M")}
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r5c5"]}>
                <Input readOnly={true} size="small" suffix="% (B/A)" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={2}>3</Col>
            <Col span={6}>Total employees (d+e)</Col>
            <Col span={4}>
              <Form.Item name={[props.ques_id, "r6c1"]}>
                <Input readOnly={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r6c2"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r6c3"]}>
                <Input readOnly={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r6c4"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={3} offset={0}>
              <Form.Item name={[props.ques_id, "r6c5"]}>
                <Input readOnly={true} size="small" suffix="% (B/A)" />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default T20A;
