import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form } from "antd";
import * as common from "../../../../../util/common";

const T22 = (props) => {
  const formula_defination = {
    r1c3: { type: "sum", cols: ["r1c1", "r1c2"] },
    r1c6: { type: "sum", cols: ["r1c4", "r1c5"] },
    r1c9: { type: "sum", cols: ["r1c7", "r1c8"] },
    r2c3: { type: "sum", cols: ["r2c1", "r2c2"] },
    r2c6: { type: "sum", cols: ["r2c4", "r2c5"] },
    r2c9: { type: "sum", cols: ["r2c7", "r2c8"] },
  };

  const handleChange = (e) => {
    // common.arthiFunc(props.ques_id, formula_defination, props.form);
  };
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <Row>
      <Col span={24}>
        <div id="tbl-cell">
          <Row>
            <Col span={6}></Col>
            <Col span={6} className="text-center">
              FY {props?.data?.current_fy} (Current)
            </Col>
            <Col span={6} className="text-center">
              FY {props?.data?.previous_fy} (Previous)
            </Col>
            <Col span={6} className="text-center">
              FY{" "}
              {props?.data?.previous_previous_fy}
              (Year prior to previous)
            </Col>
          </Row>
          <Row>
            <Col span={2} offset={6}>
              Male
            </Col>
            <Col span={2} offset={0}>
              Female
            </Col>
            <Col span={2} offset={0}>
              Total
            </Col>
            <Col span={2} offset={0}>
              Male
            </Col>
            <Col span={2} offset={0}>
              Female
            </Col>
            <Col span={2} offset={0}>
              Total
            </Col>
            <Col span={2} offset={0}>
              Male
            </Col>
            <Col span={2} offset={0}>
              Female
            </Col>
            <Col span={2} offset={0}>
              Total
            </Col>
          </Row>
          <Row>
            <Col span={6}>Permanent employees</Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r1c1"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r1c2"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r1c3"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r1c4"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r1c5"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r1c6"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r1c7"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r1c8"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r1c9"]}>
              <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>Permanent Workers</Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r2c1"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r2c2"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r2c3"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r2c4"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r2c5"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r2c6"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r2c7"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r2c8"]}>
                <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name={[props.ques_id, "r2c9"]}>
                 <Input onChange={handleChange} readOnly={props.readonly} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default T22;
