import React, { useEffect } from "react";
import { Row, Col, Input, Form } from "antd";

const CP5Comp = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div>
      <Form.Item label="CP1-8" className="title-header">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <span style={{ fontSize: "12px", fontWeight: "700" }}>
              {`Number of days of accounts payables ((Accounts payable *365) / Cost of goods or services procured):`}
            </span>
            <div id="tbl-cell">
              <Row>
                <Col span={6}></Col>
                <Col span={9} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`FY (Current Financial Year)`}
                  </span>
                </Col>
                <Col span={9} className="text-center">
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    {`FY (Previous Financial Year)`}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <span className="ant-col-header">
                    {`Number of days of accounts payables`}
                  </span>
                </Col>
                <Col span={9}>
                  <Form.Item name={[props.ques_id, "r1c1"]}>
                    <Input size="small" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item name={[props.ques_id, "r1c2"]}>
                    <Input size="small" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <span className="ant-col-header">
                    {`Interest paid on late settlement`}
                  </span>
                </Col>
                <Col span={9}>
                  <Form.Item name={[props.ques_id, "r2c1"]}>
                    <Input size="small" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item name={[props.ques_id, "r2c2"]}>
                    <Input size="small" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Row>

            </div>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

export default CP5Comp;
