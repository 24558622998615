import React, { useEffect, useState } from "react";
import { Tooltip, Dropdown, Table, Menu } from "antd";
import Loader from "../../Components/loader";
import CommonCompbar from "../../Components/BaseLayout";
import { useDispatch } from "react-redux";
import {
  fetchCustomer,
  updateCustomerSlice,
} from "../BackofficeAdmin/Customer-Details/dashboardSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [custid, setCustid] = useState(false);
  const [customers_data, setCustomersData] = useState([]);

  useEffect(() => {
    dispatch(fetchCustomer())
      .unwrap()
      .then((res) => {
        setLoader(false);
        if (res.code === 0) {
          setCustomersData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  //const handleButtonClick = (e, name) => { };
  const handleMenuClick = async (e, row) => {
    let customer_id = row?.customer_id;
    if (e.key === "active" || e.key === "deactive") {
      try {
        let response = await dispatch(
          updateCustomerSlice({
            customer_id,
            is_active: true ? e.key === "active" : false,
          })
        );
        //let response = await createsetup(dispatch, { values });
        if (!response === undefined) return;
      } catch (error) {
        console.log(error);
      }
    }
    if (e.key === "edit") {
      const params = {
        customer_id,
        row,
      };
    } else if (e.key === "manage_users") {
    } else if (e.key === "view_details") {
      const prams = {
        customers_data: customers_data,
        selectedcustomer: row,
      };
      setCustid(true);
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "company_name",
      key: "company_name",
      render: (text) => <a onClick={showMsgModal}>{text}</a>,
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address ? "Active" : "Deactivated"}
        </Tooltip>
      ),
    },
    {
      title: "Customer ID",
      dataIndex: "customer_id",
      key: "customer_id",
      ellipsis: {
        showTitle: false,
      },
      render: (custid) => (
        <Tooltip placement="topLeft" title={custid}>
          {custid}
        </Tooltip>
      ),
    },
    {
      title: "Assigned Template",
      dataIndex: "template_name",
      key: "template_name",
      ellipsis: {
        showTitle: false,
      },
      render: (assigned) => (
        <Tooltip placement="topLeft" title={assigned}>
          {assigned}
        </Tooltip>
      ),
    },
    {
      title: "Users Provisoned",
      dataIndex: "provison",
      key: "provison",
      ellipsis: {
        showTitle: false,
      },
      render: (provison) => (
        <Tooltip placement="topLeft" title={provison}>
          {provison}
        </Tooltip>
      ),
    },
    {
      title: "Manage",
      dataIndex: "createdon",
      key: "createdon",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const menu = (
          <Menu onClick={(e) => handleMenuClick(e, record)} className="items">
            {record.is_active ? (
              <Menu.Item key={"deactive"}>Deactive</Menu.Item>
            ) : (
              <Menu.Item key={"active"}>Activate</Menu.Item>
            )}
            <Menu.Item key={"manage_users"}>Manage Users</Menu.Item>
            <Menu.Item key={"view_details"}>View Details</Menu.Item>
            <Menu.Item key={"manage_reviewer"}>Manage Reviewer</Menu.Item>
            <Menu.Item key={"edit"}>Edit</Menu.Item>
            <Menu.Item key={"assign_reviewer"}>Assign Reviewer</Menu.Item>
          </Menu>
        );
        return (
          <Dropdown.Button
            className="ant-btn1"
            //onClick={(e) => handleButtonClick(record)}
            overlay={menu}
          >
            Select
          </Dropdown.Button>
        );
      },
    },
  ];
  const showMsgModal = () => {};

  return (
    <div>
      <CommonCompbar sidebar={false}>
        <div className="formcontainer">
          <div className="table-title-header-top">
            <div>
              <h5>You are assigned to review the following customers</h5>
              <h2>Customers</h2>
            </div>
          </div>
          <Table
            columns={columns}
            bordered
            pagination={false}
            dataSource={customers_data}
            className="f-table-wrapper knowldge-action"
            scroll={{
              y: 240,
            }}
          />
        </div>
        <Loader flag={loader} />
      </CommonCompbar>
    </div>
  );
};

export default Dashboard;
