import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, message, Select, Button, Space, Input } from "antd";
import { customerReviewerMapperSlice } from "../../../Context/commonSlice";
const { Option } = Select;

const AssignReviewer = (props) => {
  const dispatch = useDispatch();
  const [selectedname, setSelectedname] = useState();
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    setSelectedname();
    setDisabled(false);
    props.reviewer_data.filter((item) => {
      if (item.is_assinged === true) {
        setSelectedname(item.id);
        setDisabled(true);
      }
    });
  }, [props.reviewer_data]);
  function handleCancel() {
    props.handleFlag(false);
  }
  const handleFinish = async (values) => {
    try {
      let response = await dispatch(
        customerReviewerMapperSlice({
          //...values,
          reviewer_id: selectedname,
          customer_id: props.data.id,
        })
      );
      if (!response === undefined) return;
      message.info(response?.payload?.data?.msg);
      if (response.payload.data.code === 0) {
        props.handleFlag(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (value) => {
    setSelectedname(value);
  };
  return (
    <Modal
      title="Assign Reviewer"
      open={props.status}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="modalform">
        <Space className="flex" style={{ marginBottom: "10px" }}>
          <span
            style={{ fontWeight: "bold", width: "150px", marginRight: "1px" }}
          >
            Selected Customer:
          </span>
          <Input
            readOnly={true}
            value={props.data.company_name}
            style={{ width: "230px" }}
          />
        </Space>
        <Space>
          <span style={{ fontWeight: "bold", marginRight: "20px" }}>
            Select Reviewer:
          </span>
          <Select
            disabled={disabled}
            showSearch
            placeholder="Select"
            value={selectedname}
            onChange={handleChange}
          >
            {props.reviewer_data &&
              props.reviewer_data.map((item) => {
                return (
                  <>
                    <Option key={item.id} value={item.id}>
                      {item.display_name}
                    </Option>
                  </>
                );
              })}
          </Select>
        </Space>

        <Space
          style={{
            marginTop: "10px",
            justifyContent: "right",
            width: "90%",
          }}
        >
          <Button type="primary" onClick={handleCancel} htmlType="button">
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            type="primary"
            onClick={handleFinish}
          >
            Save
          </Button>
        </Space>
      </div>
    </Modal>
  );
};
export default AssignReviewer;
