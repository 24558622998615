import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Table,
  Modal,
  message,
  Row,
  Col,
  Popconfirm,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const T17 = (props) => {
  const [form] = Form.useForm();
  const [modelOpen, setModelOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [r1c1, setR1C1] = useState('');
  const [r1c2, setR1C2] = useState('');
  const [r1c3, setR1C3] = useState('');
  const handleDelete = (key) => {
    const newData = dataSource.filter((item, index) => index !== key);
    setDataSource(newData);
    props.setdata(newData);
    message.success('Row deleted!');
  };
  const Columns = [
    {
      title: 'SNo.',
      dataIndex: 'key',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Initiative undertaken',
      dataIndex: 'r1c1',
    },
    {
      title:
        'Details of the initiative (Web- ink, if any, may be provided along-with summary)',
      dataIndex: 'r1c2',
    },
    {
      title: 'Outcome of the initiative',
      dataIndex: 'r1c3',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record, index) =>
        dataSource.length >= 1 ? (
          <>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(index)}
            >
              <Button className="ant-btn-link" type="link">
                <DeleteOutlined twoToneColor="#eb2f96" />
              </Button>
            </Popconfirm>
          </>
        ) : null,
    },
  ];

  useEffect(() => {
    setDataSource(props.data.cp6_17 || []);
  }, [props.data]);

  const btnAdd = (values) => {
    const newData = {
      r1c1,
      r1c2,
      r1c3,
    };
    setDataSource([...dataSource, newData]);
    props.setdata([...dataSource, newData]);
    message.success('Processing complete!');
    setModelOpen(false);
  };
  return (
    <>
      <div>
        <Row>
          <Col span={24} offset={22}>
            <span>
              <Button
                onClick={() => {
                  setModelOpen(true);
                  setR1C1('');
                  setR1C2('');
                  setR1C3('');
                  form.resetFields();
                }}
                disabled={props.readonly}
              >
                Add
              </Button>
            </span>
          </Col>
        </Row>
        <Table
          dataSource={dataSource}
          columns={Columns}
          pagination={false}
          className="f-table-wrapper"
          scroll={{
            y: 240,
          }}
        />

        <Modal
          title="Add"
          style={{ top: 20 }}
          open={modelOpen}
          onOk={btnAdd}
          okText="Add"
          onCancel={() => setModelOpen(false)}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 14 }}
            wrapperCol={{ span: 22 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <div className="modalform">
              <label>Initiative undertaken</label>
              <Form.Item name="r1c1">
                <Input
                  readOnly={props.readonly}
                  onChange={(e) => setR1C1(e.target.value)}
                />
              </Form.Item>
              <label>
                Details of the initiative (Web- ink, if any, may be provided
                along-with summary)
              </label>
              <Form.Item name="r1c2">
                <Input
                  readOnly={props.readonly}
                  onChange={(e) => setR1C2(e.target.value)}
                  placeholder="Details of the initiative (Web- ink, if any, may be provided along-with summary)"
                />
              </Form.Item>
              <label>Outcome of the initiative</label>
              <Form.Item name="r1c3">
                <Input
                  readOnly={props.readonly}
                  onChange={(e) => setR1C3(e.target.value)}
                  placeholder="Outcome of the initiative"
                />
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default T17;
