import React, { useEffect } from "react";
import { Row, Col, Form } from "antd";
import InputNumber from "../../../../../Components/Input/Number/number";

const T15 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div>
      <Row>
        <Col span={24}>
          <div id="tbl-cell">
            <Row>
              <Col span={8}></Col>
              <Col span={16}>
                Percentage of your plants and offices that were assessed (by
                entity or statutory authorities or third parties)
              </Col>
            </Row>
            <Row>
              <Col span={8}>Child labour</Col>
              <Col span={16}>
                <Form.Item name={[props.ques_id, "r1c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Forced/involuntary labour </Col>
              <Col span={16}>
                <Form.Item name={[props.ques_id, "r2c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Sexual harassment </Col>
              <Col span={16}>
                <Form.Item name={[props.ques_id, "r3c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Discrimination at workplace </Col>
              <Col span={16}>
                <Form.Item name={[props.ques_id, "r4c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Wages </Col>
              <Col span={16}>
                <Form.Item name={[props.ques_id, "r5c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Others – please specify </Col>
              <Col span={16}>
                <Form.Item name={[props.ques_id, "r6c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default T15;
