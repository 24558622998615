import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Divider,
  Badge,
  Card,
  message,
  Row,
  Col,
  Input,
  Select,
  Space,
  Form,
  Spin,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Table8_1 from "./Table8-1";
import Table8_10 from "./Table8-10";
import Table8_11 from "./Table8-11";
import CP8_4Comp from "./CP8_4";
import CP8_5Comp from "./CP8_5";
import { useDispatch } from "react-redux";
import {
  create_section_slice,
  get_section,
} from "../SectionSlice/sectionSlice";
import CommentsApp from "../../../../Components/Comments";
import { useFinancialYear } from "../../../../hooks/useFinancialYear";
import { useCustomer } from "../../../../hooks/useCustomer";
import CP8_6 from "./CP8_6";
import states from "../../../../util/city-state.json";
import CP8_9 from "./CP8_9";
import CommentsNotified from "../../../../Components/Comments/comments-notification";
import CP8_7 from "./CP8_7";

const { Option } = Select;
const App = (props) => {
  const { current_fy, previous_fy } = useFinancialYear();
  const { assigned_template_id } = useCustomer();
  const [cp8_1, setCP8_1] = useState([]);
  const [cp8_6, setCP8_6] = useState([]);
  const [cp8_7, setCP8_7] = useState([]);
  const [cp8_9, setCP8_9] = useState([]);
  const [cp8_10, setCP8_10] = useState([]);
  const [cp8_11, setCP8_11] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [defaultdata, setDefaultdata] = useState({});
  const [readonly, setReadonly] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const [commentlist, setCommentlist] = useState([]);
  const [commentreload, setCommentReload] = useState();
  const [districts_cp8_2, setDistrictsCP8_2] = useState();
  const [districts, setDistricts] = useState();
  const handleStateCP8_2 = (value) => {
    const stateList = states;
    const stateName = stateList.states.filter((item) => item.name === value);
    const { districts } = stateName[0];
    setDistrictsCP8_2(districts);
  };

  const handleState = (value) => {
    const stateList = states;
    const stateName = stateList.states.filter((item) => item.name === value);
    const { districts } = stateName[0];
    setDistricts(districts);
  };

  useEffect(() => {
    if (sessionState?.roletype === "CUSTOMER") {
      setReadonly(false);
    } else {
      setReadonly(true);
    }
    let payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_cp8",
    };
    setSpinning(true);
    dispatch(get_section({ payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setDefaultdata(res.data?.section_cp8);
          setCP8_1(res.data?.section_cp8?.cp8_1);
          setCP8_6(res.data?.section_cp8?.cp8_6);
          setCP8_7(res.data?.section_cp8?.cp8_7);
          setCP8_9(res.data?.section_cp8?.cp8_9);
          setCP8_10(res.data?.section_cp8?.cp8_10);
          setCP8_11(res.data?.section_cp8?.cp8_11);
          setCommentlist(res.data?.comments);
          setSpinning(false);
        } else {
          if (res.code != 800000) {
            message.info(res.msg);
          }

          setSpinning(false);
        }
      })
      .catch((Error) => {
        console.log(Error);
      });
  }, [commentreload]);

  const onFinish = (datalist) => {
    setSpinning(true);
    let Payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_cp8",
      question_response: {
        ...datalist,
        cp8_1,
        cp8_6,
        cp8_7,
        cp8_9,
        cp8_10,
        cp8_11,
      },
    };
    dispatch(create_section_slice({ Payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setSpinning(false);
          message.info("Section CP8 created succesfully!");
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };
  useEffect(() => {
    form.setFieldsValue(defaultdata);
  }, [form, defaultdata]);
  return (
    <>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 44, color: "white" }} spin />
        }
        tip="Loading..."
        spinning={spinning}
        fullscreen
        size="large"
      />
      <div className="formcontainer">
        <CommentsNotified commentlist={commentlist} />
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={defaultdata}
          scrollToFirstError
          style={{ width: "100%" }}
        >
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Card title="Essential Indicators" size="small">
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP8-1" className="title-header">
                    <Row>
                      <Col span={24}>
                        <div className="space-between">
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Details of Social Impact Assessments (SIA) of projects undertaken by the entity based on applicable laws, in the current financial year.`}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Table8_1
                          readonly={readonly}
                          data={{ cp8_1 }}
                          setdata={setCP8_1}
                          form={form}
                          ques_id={"cp8_1"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp8_1"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP8-2" className="title-header">
                    <Row style={{ width: "100%" }}>
                      <Col span={24}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Provide information on project(s) for which ongoing Rehabilitation and Resettlement (R&R) is being undertaken by your entity, in the following format.`}
                        </span>
                        <Row style={{ width: "100%" }}>
                          <Col span={24}>
                            <div id="tbl-cell">
                              <Row>
                                <Col span={2} className="text-center">
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >{`S. No.`}</span>
                                </Col>
                                <Col span={5} className="text-center">
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {`Name of Project for which R&R is ongoing`}
                                  </span>
                                </Col>
                                <Col span={4} className="text-center">
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {`State`}
                                  </span>
                                </Col>
                                <Col span={4} className="text-center">
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {`District`}
                                  </span>
                                </Col>
                                <Col span={3} className="text-center">
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {`No. of Project Affected Families (PAFs)`}
                                  </span>
                                </Col>
                                <Col span={3} className="text-center">
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {`% of PAFs covered by R&R`}
                                  </span>
                                </Col>
                                <Col span={3} className="text-center">
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {`Amounts paid to PAFs in the FY (In INR)`}
                                  </span>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={2} className="text-center">
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {`1`}
                                  </span>
                                </Col>
                                <Col span={5}>
                                  <Form.Item name={["cp8_2", "r1c1"]}>
                                    <Input readOnly={props.readonly} />
                                  </Form.Item>
                                </Col>
                                <Col span={4}>
                                  <Form.Item name={["cp8_2", "r1c2"]}>
                                    <Select
                                      disabled={props.readonly}
                                      placeholder="Select Option"
                                      onChange={(value) => {
                                        handleStateCP8_2(value);
                                      }}
                                    >
                                      {states?.states?.map((item, index) => {
                                        return (
                                          <Option
                                            key={item.code}
                                            value={item.name}
                                          >
                                            {item.name}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={4}>
                                  <Form.Item name={["cp8_2", "r1c3"]}>
                                    <Select
                                      disabled={props.readonly}
                                      placeholder="Select Option"
                                    >
                                      {districts_cp8_2?.map((item, index) => {
                                        return (
                                          <Option key={index} value={item.name}>
                                            {item.name}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3}>
                                  <Form.Item name={["cp8_2", "r1c4"]}>
                                    <Input
                                      readOnly={props.readonly}
                                      type="number"
                                      min="0"
                                      size="small"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={3}>
                                  <Form.Item name={["cp8_2", "r1c5"]}>
                                    <Input
                                      readOnly={props.readonly}
                                      size="small"
                                      suffix="%"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={3}>
                                  <Form.Item name={["cp8_2", "r1c6"]}>
                                    <Input
                                      readOnly={props.readonly}
                                      type="number"
                                      min="0"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp8_2"
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP8-3" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Describe the mechanisms to receive and redress grievances of the community.`}
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["cp8_3", "r1c1"]}>
                          <Input.TextArea
                            readOnly={readonly}
                            rows={2}
                            placeholder="Enter Describe the mechanisms"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp8_3"
                    />
                  </Form.Item>
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Row>
                    <Col span={24}>
                      <CP8_4Comp
                        readonly={readonly}
                        data={{
                          current_fy,
                          previous_fy,
                          ...defaultdata,
                        }}
                      />
                    </Col>
                  </Row>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp8_4"
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Row>
                    <Col span={24}>
                      <CP8_5Comp
                        readonly={readonly}
                        data={{
                          current_fy,
                          previous_fy,
                          ...defaultdata,
                        }}
                      />
                    </Col>
                  </Row>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp8_5"
                  />
                </>
              )}
            </Card>
          </Space>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[3, 4].includes(assigned_template_id) && (
              <Card title="Leadership Indicators" size="small">
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP8-6" className="title-header">
                      <Row>
                        <Col span={24}>
                          <div className="space-between">
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`Provide details of actions taken to mitigate any negative social impacts identified in the Social Impact Assessments (Reference: CP8-1 of Essential Indicators above).`}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <CP8_6
                            readonly={readonly}
                            data={{ cp8_6 }}
                            setdata={setCP8_6}
                            form={form}
                            ques_id={"cp8_6"}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp8_6"
                    />
                    <Divider />
                  </>
                )}
                {/* {[3, 4].includes(assigned_template_id) && ( */}
                <>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="CP8-7" className="title-header">
                        <Row>
                          <Col span={24}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`Provide the following information on CSR projects undertaken by your entity in designated aspirational districts as identified by government bodies.`}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <CP8_7
                              readonly={readonly}
                              data={{ cp8_7 }}
                              setdata={setCP8_7}
                              form={form}
                              ques_id={"cp8_7"}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>
                  </Row>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp8_7"
                  />
                  <Divider />
                </>
                {/* )} */}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP8-8" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: Do you have a preferential procurement policy where you give preference to purchase from suppliers comprising marginalized /vulnerable groups? (Yes/No)`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp8_8", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[B]: From which marginalized /vulnerable groups do you procure?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp8_8", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter marginalized groups"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`What percentage of total procurement (by value) does it constitute?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp8_8", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              type="number"
                              min="0"
                              suffix="%"
                              placeholder="Enter total procurement"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp8_8"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP8-9" className="title-header">
                      <Row>
                        <Col span={24}>
                          <div className="space-between">
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`Details of the benefits derived and shared from the intellectual properties owned or acquired by your entity (in the current financial year), based on traditional knowledge:`}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <CP8_9
                            readonly={readonly}
                            data={{ cp8_9 }}
                            setdata={setCP8_9}
                            form={form}
                            ques_id={"cp8_9"}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp8_9"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP8-10" className="title-header">
                      <Row>
                        <Col span={24}>
                          <div className="space-between">
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`Details of the benefits derived and shared from the intellectual properties owned or acquired by your entity (in the current financial year), based on traditional knowledge.`}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Table8_10
                            readonly={readonly}
                            data={{ cp8_10 }}
                            setdata={setCP8_10}
                            form={form}
                            ques_id={"cp8_10"}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp8_10"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP8-11" className="title-header">
                      <Row>
                        <Col span={24}>
                          <div className="space-between">
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`Details of beneficiaries of CSR Projects:`}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Table8_11
                            readonly={readonly}
                            data={{ cp8_11 }}
                            setdata={setCP8_11}
                            form={form}
                            ques_id={"cp8_11"}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp8_11"
                    />
                    <Divider />
                  </>
                )}
              </Card>
            )}
          </Space>
          {/* <h2>END OF LITE/EXTENDED</h2> */}
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[4].includes(assigned_template_id) && (
              <Card title="Leadership Indicators" size="small">
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP8-12" className="title-header">
                      <Row>
                        <Col span={22}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Direct economic value generated and distributed.`}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: Direct economic value generated and distributed (EVG&D) on an accruals basis, including the basic components for the organization’s global operations as listed below.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp8_12", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter economic value"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[B]: Economic value retained:‘direct economic value generated’ less ‘economic value distributed’.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp8_12", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter retained"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[C]: Where significant, report EVG&D separately at country, regional, or market levels, and the criteria used for defining significance.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp8_12", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter Details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp8_12"
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP8-13" className="title-header">
                      <Row>
                        <Col span={22}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Infrastructure investments and services supported`}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: Extent of development of significant infrastructure investments and services supported.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp8_13", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[B]: Current or expected impacts on communities and local economies, including positive and negative impacts where relevant.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp8_13", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[C]: Whether these investments and services are commercial, in-kind, or pro bono engagements.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp8_13", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter Details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp8_13"
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP8-14" className="title-header">
                      <Row>
                        <Col span={22}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Significant indirect economic impacts`}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: Examples of significant identified indirect economic impacts of the organization, including positive and negative impacts.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp8_14", "r1c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[B]: Significance of the indirect economic impacts in the context of external benchmarks and stakeholder priorities, such as national and international standards, protocols, and policy agendas.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp8_14", "r2c1"]}>
                            <Input readOnly={readonly} placeholder="Enter" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp8_14"
                    />
                  </>
                )}
              </Card>
            )}
          </Space>
          {readonly ? (
            ""
          ) : (
            <Space
              style={{
                //marginTop: "10px",
                marginBottom: "10px",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <Button style={{ marginLeft: 10 }} htmlType="submit">
                Save and Continue
              </Button>
            </Space>
          )}
        </Form>
      </div>
    </>
  );
};

export default App;
