import React, { useEffect } from "react";
import {  Row, Col, Form, Input } from "antd";

const Cont13 = (props) => {

  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div id="tbl-cell">
      <Row>
        <Col span={4}>
          <span style={{ fontSize: "12px", fontWeight: "600" }}></span>
        </Col>
        <Col span={10} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            FY: {props?.data?.current_fy} Current Financial Year
          </span>
        </Col>
        <Col span={10} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            FY: {props?.data?.previous_fy} Previous Financial Year
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={3} offset={4}>
          <span
            style={{ fontSize: "12px", fontWeight: "500" }}
          >{`Filed during the year`}</span>
        </Col>
        <Col span={3} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
            {`Pending resolution at the end of year`}
          </span>
        </Col>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
            {`Remarks`}
          </span>
        </Col>
        <Col span={3}>
          <span
            style={{ fontSize: "12px", fontWeight: "500" }}
          >{`Filed during the year`}</span>
        </Col>
        <Col span={3} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
            {`Pending resolution at the end of year`}
          </span>
        </Col>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
            {`Remarks`}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            {`Working Conditions`}
          </span>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c1"]}>
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c2"]}>
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={[props.ques_id, "r1c3"]}>
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c4"]}>
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c5"]}>
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={[props.ques_id, "r1c6"]}>
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            {`Health & Safety`}
          </span>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c1"]}>
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c2"]}>
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={[props.ques_id, "r2c3"]}>
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c4"]}>
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c5"]}>
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={[props.ques_id, "r2c6"]}>
            <Input readOnly={props.readonly} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Cont13;
