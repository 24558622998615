import axios from "axios";
import {
  urlAppState,
  createcustomerurl,
  urlCustomerList,
  urlProfile,
  urlUpdateCustomer,
  urlCustomerUserList,
  urlUpdateCustomerUser,
  urlBackoffice,
  urlUpdateBackoffice,
  urlResetPassword,
  urlReviewer,
  urlUserResetPassword,
  urlReset2fa,
  urlCustomerReviewerMapper,
  urlUpdateCustomerReviewerMapper,
  urlReviewerCustomerMapperList,
  urlCustomerReviewerMapperList,
  urlForgotPassword,
  urlBackOfficeRoleList,
  urlQuestionaireTemplate,
  urlQuestionaireSection,
  urlSection_a,
  urlTickets,
  urlUpdateTicket,
  urlTicketConversations,
  urlTicketConversationsInfo,
  urlQuestionaireConversations,
  urlQuestionaireConversationsInfo,
  urlQuestionaireCommentSuummary,
  urlNotification,
  urlNotificationUpdate,
  urlReports,
  urlTemplates,
  urlQuestionaireReviewedSubmit,
  urlQuestionaireSubmit,
  urlReportStatusUpdate,
  urlQuestionaireConversationsEnd,
} from "./url";

export async function createadmin({ ...data }) {
  const response = await axios.post(urlBackoffice, data.values);
  return await response;
}

export async function getsetupState() {
  const response = await axios.get(urlAppState);
  return await response;
}
export async function create_customer({ ...data }) {
  const response = await axios.post(createcustomerurl, data);
  return await response.data;
}
export async function create_section_a({ ...data }) {
  const response = await axios.post(urlSection_a, data.Payload);
  return await response.data;
}
export async function get_section_adata({ ...data }) {
  const url1 =
    "api/v1/questionaire/?customer_id=1&questionaire_template_id=1&financial_year=2023-2024";
  const response = await axios.get(url1);
  return await response.data;
}
export async function create_section_data({ ...data }) {
  const response = await axios.post(urlQuestionaireSection, data.Payload);
  return await response.data;
}
export async function get_section_data({ ...data }) {
  const response = await axios.get(urlQuestionaireSection, {
    params: data,
  });
  return await response.data;
}
export async function get_questionare_comments_summary() {
  const response = await axios.get(urlQuestionaireCommentSuummary);
  return await response.data;
}
///
// export async function getsetupState() {
//
//   const response = await axios.get(urlAppState);
//   return await response;
// }

export async function getCustomerList() {
  const response = await axios.get(urlCustomerList);
  return await response;
}
export async function getProfile() {
  const response = await axios.get(urlProfile);
  return await response;
}
export async function updateCustomer(data) {
  const response = await axios.put(urlUpdateCustomer, data);
  return await response;
}

export async function createCustomerUser(data) {
  const response = await axios.post(urlCustomerUserList, data);
  return await response;
}

export async function getCustomerUserList(data) {
  const response = await axios.get(urlCustomerUserList, {
    params: data,
  });
  return await response;
}

export async function updateCustomerUser(data) {
  const response = await axios.put(urlUpdateCustomerUser, data);
  return await response;
}

export async function resetPassword(data) {
  const response = await axios.put(urlResetPassword, data);
  return await response;
}

export async function getBackofficeUsersList() {
  const response = await axios.get(urlBackoffice);
  return await response;
}

export async function updateBackofficeUser(data) {
  const response = await axios.put(urlUpdateBackoffice, data);
  return await response;
}

export async function getReviewerUsersList(data) {
  const response = await axios.post(urlReviewer, data);
  return await response;
}

export async function userResetPassword(data) {
  const response = await axios.get(
    `${urlUserResetPassword}?token=${data.token}`,
    { headers: {} }
  );
  //const response = await axios.get(urlUserResetPassword`?${data}`);
  return await response;
}

export async function reset2fa(data) {
  const response = await axios.put(urlReset2fa, data);
  return await response;
}

export async function getcustomerReviewerMapper(data) {
  const response = await axios.get(urlCustomerReviewerMapperList, {
    params: data,
  });
  return await response;
}

export async function getreviewerCustomerMapper() {
  const response = await axios.get(urlReviewerCustomerMapperList);
  return await response;
}

export async function customerReviewerMapper(data) {
  const response = await axios.post(urlCustomerReviewerMapper, data);
  return await response;
}

export async function updateCustomerReviewerMapper(data) {
  const response = await axios.put(urlUpdateCustomerReviewerMapper, data);
  return await response;
}

export async function forgotPassword(data) {
  const response = await axios.post(urlForgotPassword, data);
  return await response;
}

export async function getbackOfficeRoleList(data) {
  const response = await axios.get(urlBackOfficeRoleList, {
    params: data,
  });
  return await response;
}

export async function getQuestionaireTemplate(data) {
  const response = await axios.get(urlQuestionaireTemplate, {
    params: data,
  });
  return await response;
}

export async function getTicketsList() {
  const response = await axios.get(urlTickets);
  return await response;
}

export async function createTicket(data) {
  const response = await axios.post(urlTickets, data);
  return await response;
}

export async function updateTicket(data) {
  const response = await axios.put(urlUpdateTicket, data);
  return await response;
}

export async function createTicketConversations(data) {
  const response = await axios.post(urlTicketConversations, data);
  return await response;
}

export async function getTicketConversationsInfo(data) {
  const response = await axios.get(urlTicketConversationsInfo, {
    params: data,
  });
  return await response;
}

export async function createQuestionaireConversations(data) {
  const response = await axios.post(urlQuestionaireConversations, data);
  return await response;
}

export async function endQuestionaireConversations(data) {
  const response = await axios.put(urlQuestionaireConversationsEnd, data);
  return await response;
}

export async function getQuestionaireConversationsInfo(data) {
  const response = await axios.get(urlQuestionaireConversationsInfo, {
    params: data,
  });
  return await response;
}

export async function getNotifications() {
  const response = await axios.get(urlNotification);
  return await response.data;
}

export async function updateNotifications(data) {
  const response = await axios.put(urlNotificationUpdate, data);
  return await response;
}

export async function getReports() {
  const response = await axios.get(urlReports);
  return await response;
}

export async function updateReportStatus(data) {
  const response = await axios.put(urlReportStatusUpdate, data);
  return await response;
}

export async function getTemplates() {
  const response = await axios.get(urlTemplates);
  return await response.data;
}

export async function createQuestionaireReviewedSubmit(data) {
  const response = await axios.post(urlQuestionaireReviewedSubmit, data);
  return await response;
}

export async function QuestionaireSubmit(data) {
  const response = await axios.post(urlQuestionaireSubmit, data);
  return await response;
}
