import React from "react";
import Status from "./Status";
import "./index.scss";
import QRCode from "./QRCode";

class Person2FA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alreadySet: false,
      checked: true,
      showQRCode: false,
    };
  }

  render() {
    const { showQRCode } = this.state;
    return (
      <div className="person-2fa-wrap">
        {showQRCode ? (
          <QRCode
            setShowQRCode={(val) => {
              this.setState({ showQRCode: val });
            }}
          />
        ) : (
          // <Status
          //   setShowQRCode={(val) => {
          //     this.setState({ showQRCode: val });
          //   }}
          // />
          <QRCode
            setShowQRCode={(val) => {
              this.setState({ showQRCode: val });
            }}
          />
        )}
      </div>
    );
  }
}

export default Person2FA;
