import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getReports,
  updateReportStatus,
} from "../../../Config/actions";


const getReportsList = createAsyncThunk(
  "reports/lsiting",
  async (customer_id, thunkAPI) => {
    const response = await getReports();
    return response.data;
  }
);

const putReportStatus = createAsyncThunk(
  'reports/lsiting',
  async (data, thunkAPI) => {
    const response = await updateReportStatus(data);
    return response.data;
  }
);
export { getReportsList, putReportStatus };
