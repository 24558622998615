import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Table,
  Modal,
  message,
  Select,
  Row,
  Col,
  Popconfirm,
} from "antd";
import { DeleteOutlined } from '@ant-design/icons';
const { Option } = Select;
const T12 = (props) => {
  const [form] = Form.useForm();
  const [modelOpen, setModelOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [notification, setNotification] = useState("");
  const [brief_details, setBrief_details] = useState("");
  const [notification_date, setNotificationDate] = useState("");
  const [independent, setIndependent] = useState("");
  const [communicated, setCommunicated] = useState("");
  const [web_link, setWeb_link] = useState("");
  const handleDelete = (key) => {
    const newData = dataSource.filter((item, index) => index !== key);
    setDataSource(newData);
    props.setdata(newData);
  };
  const Columns = [
    {
      title: 'Name and brief details of project	',
      dataIndex: 'r1c1',
    },
    {
      title: 'SIA Notification No.',
      dataIndex: 'r1c2',
    },
    {
      title: 'Date of notification',
      dataIndex: 'r1c3',
    },

    {
      title: 'Whether conducted by independent external agency (Yes / No)',
      dataIndex: 'r1c4',
    },
    {
      title: 'Results communicated in public domain (Yes / No)',
      dataIndex: 'r1c5',
    },
    {
      title: 'Relevant Web link',
      dataIndex: 'r1c6',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record, index) =>
        dataSource.length >= 1 ? (
          <>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(index)}
            >
              <Button className="ant-btn-link" type="link">
                <DeleteOutlined twoToneColor="#eb2f96" />
              </Button>
            </Popconfirm>
          </>
        ) : null,
    },
  ];

  useEffect(() => {
    if (props.data.cp6_12.constructor.name !== "Object") {
      setDataSource(props.data.cp6_12 || []);
    }
  }, [props.data]);

  const btnAdd = (values) => {
    let count = dataSource.length;
    const newData = {
      key: count + 1,
      r1c1: brief_details,
      r1c2: notification,
      r1c3: notification_date,
      r1c4: independent,
      r1c5: communicated,
      r1c6: web_link,
    };
    let updatedData = [...dataSource, newData];
    setDataSource([...dataSource, newData]);
    props.setdata([...dataSource, newData]);
    message.success("Processing complete!");
    setModelOpen(false);
  };
  return (
    <div>
      <Row>
        <Col span={24} offset={22}>
          <span>
            <Button
              onClick={() => {
                setModelOpen(true);
                setBrief_details('');
                setNotification('');
                setNotificationDate('');
                setIndependent('');
                setCommunicated('');
                setWeb_link();
                form.resetFields();
              }}
              disabled={props.readonly}
            >
              Add
            </Button>
          </span>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={Columns}
        pagination={false}
        className="f-table-wrapper"
        scroll={{
          y: 240,
        }}
      />

      <Modal
        title="Add"
        style={{ top: 20 }}
        open={modelOpen}
        onOk={btnAdd}
        okText="Add"
        onCancel={() => setModelOpen(false)}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 22 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <div className="modalform">
            <Form.Item
              name="brief_details"
              label="Name and brief details of project"
            >
              <Input
                readOnly={props.readonly}
                onChange={(e) => setBrief_details(e.target.value)}
              />
            </Form.Item>
            <Form.Item name="notification" label="SIA Notification No.">
              <Input
                readOnly={props.readonly}
                onChange={(e) => setNotification(e.target.value)}
                placeholder="SIA Notification No."
              />
            </Form.Item>
            <Form.Item name="notification_date" label="Date of notification">
              <Input
                readOnly={props.readonly}
                type="date"
                onChange={(e) => setNotificationDate(e.target.value)}
                placeholder="Date of notification"
              />
            </Form.Item>
            <Form.Item
              name="independent"
              label="Whether conducted by independent external agency (Yes / No)"
            >
              <Select
                disabled={props.readonly}
                onChange={(e) => setIndependent(e)}
                placeholder="Date of notification"
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="communicated"
              label="Results communicated in public domain (Yes / No)"
            >
              <Select
                disabled={props.readonly}
                onChange={(e) => setCommunicated(e)}
                placeholder="Date of notification"
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
            <Form.Item name="web_link" label="Relevant Web link">
              <Input
                readOnly={props.readonly}
                onChange={(e) => setWeb_link(e.target.value)}
                placeholder="Enter Relevant Web link"
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default T12;
