import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Card,
  message,
  Tooltip,
  Row,
  Col,
  Input,
  Select,
  Space,
  Form,
  Spin,
  Radio,
} from "antd";
import { QuestionCircleFilled, LoadingOutlined } from "@ant-design/icons";
import AddCP13 from "./Table4-2";
import { useDispatch } from "react-redux";
import {
  create_section_slice,
  get_section,
} from "../SectionSlice/sectionSlice";
import CommentsApp from "../../../../Components/Comments";
import { useCustomer } from "../../../../hooks/useCustomer";
import { useFinancialYear } from "../../../../hooks/useFinancialYear";
import CommentsNotified from "../../../../Components/Comments/comments-notification";

const { Option } = Select;
const App = (props) => {
  const { current_fy } = useFinancialYear();
  const { assigned_template_id } = useCustomer();
  const [cp7_2, setCP7_2] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [defaultdata, setDefaultdata] = useState({});
  const [readonly, setReadonly] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const [commentlist, setCommentlist] = useState([]);
  const [commentreload, setCommentReload] = useState();

  useEffect(() => {
    if (sessionState?.roletype === "CUSTOMER") {
      setReadonly(false);
    } else {
      setReadonly(true);
    }
    let payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_cp7",
    };
    setSpinning(true);
    dispatch(get_section({ payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          const { section_cp7 } = res.data;
          setCP7_2(section_cp7?.cp7_2);
          setDefaultdata(res.data);
          setCommentlist(res.data?.comments);
          setSpinning(false);
        } else {
          if (res.code != 800000) {
            message.info(res.msg);
          }

          setSpinning(false);
        }
      })
      .catch((Error) => {
        setSpinning(false);
        console.log(Error);
      });
  }, [dispatch, commentreload]);

  const onFinish = (datalist) => {
    setSpinning(true);
    let Payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_cp7",
      question_response: { ...datalist, cp7_2 },
    };
    dispatch(create_section_slice({ Payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setTimeout(() => {
            setSpinning(false);
            message.info("Section CP7 created succesfully!");
          }, 3000);
        }
      })
      .catch((Err) => {
        setSpinning(false);
        console.log(Err);
      });
  };
  const { section_cp7 } = defaultdata;
  useEffect(() => form.resetFields(), [form, section_cp7]);
  return (
    <>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 44, color: "white" }} spin />
        }
        tip="Loading..."
        spinning={spinning}
        fullscreen
        size="large"
      />
      <div className="formcontainer">
        <CommentsNotified commentlist={commentlist} />
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={section_cp7}
          scrollToFirstError
          style={{ width: "100%" }}
        >
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Card title="Essential Indicators" size="small">
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP7-1" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`[A]: Number of affiliations with trade and industry chambers/ associations.`}
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["cp7-1a", "r1c1"]}>
                          <Input.TextArea
                            readOnly={readonly}
                            rows={2}
                            placeholder="Enter details"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Row style={{ width: "100%" }}>
                          <Col span={24}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[B]: List the top 10 trade and industry chambers/ associations (determined based on the total members of such body) the entity is a member of/ affiliated to.`}
                            </span>
                            <Row style={{ width: "100%" }}>
                              <Col span={24}>
                                <div id="tbl-cell">
                                  <Row>
                                    <Col span={2} className="text-center">
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "600",
                                        }}
                                      >{`S. No.`}</span>
                                    </Col>
                                    <Col span={11} className="text-center">
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {`Name of the trade and industry chambers/ associations`}
                                      </span>
                                    </Col>
                                    <Col span={11} className="text-center">
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {`Reach of trade and industry chambers/ associations (State/National)`}
                                      </span>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col span={2} className="text-center">
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {`1`}
                                      </span>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r1c1"]}>
                                        <Input readOnly={readonly} />
                                      </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r1c2"]}>
                                        <Radio.Group disabled={readonly}>
                                          <Radio value="state">State</Radio>
                                          <Radio value="national">
                                            National
                                          </Radio>
                                          <Radio value="international">
                                            International
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={2} className="text-center">
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {`2`}
                                      </span>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r2c1"]}>
                                        <Input readOnly={readonly} />
                                      </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r2c2"]}>
                                        <Radio.Group disabled={readonly}>
                                          <Radio value="state">State</Radio>
                                          <Radio value="national">
                                            National
                                          </Radio>
                                          <Radio value="international">
                                            International
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={2} className="text-center">
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {`3`}
                                      </span>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r3c1"]}>
                                        <Input readOnly={readonly} />
                                      </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r3c2"]}>
                                        <Radio.Group disabled={readonly}>
                                          <Radio value="state">State</Radio>
                                          <Radio value="national">
                                            National
                                          </Radio>
                                          <Radio value="international">
                                            International
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col span={2} className="text-center">
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {`4`}
                                      </span>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r4c1"]}>
                                        <Input readOnly={readonly} />
                                      </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r4c2"]}>
                                        <Radio.Group disabled={readonly}>
                                          <Radio value="state">State</Radio>
                                          <Radio value="national">
                                            National
                                          </Radio>
                                          <Radio value="international">
                                            International
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={2} className="text-center">
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {`5`}
                                      </span>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r5c1"]}>
                                        <Input readOnly={readonly} />
                                      </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r5c2"]}>
                                        <Radio.Group disabled={readonly}>
                                          <Radio value="state">State</Radio>
                                          <Radio value="national">
                                            National
                                          </Radio>
                                          <Radio value="international">
                                            International
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={2} className="text-center">
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {`6`}
                                      </span>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r6c1"]}>
                                        <Input readOnly={readonly} />
                                      </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r6c2"]}>
                                        <Radio.Group disabled={readonly}>
                                          <Radio value="state">State</Radio>
                                          <Radio value="national">
                                            National
                                          </Radio>
                                          <Radio value="international">
                                            International
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={2} className="text-center">
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {`7`}
                                      </span>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r7c1"]}>
                                        <Input readOnly={readonly} />
                                      </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r7c2"]}>
                                        <Radio.Group disabled={readonly}>
                                          <Radio value="state">State</Radio>
                                          <Radio value="national">
                                            National
                                          </Radio>
                                          <Radio value="international">
                                            International
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col span={2} className="text-center">
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {`8`}
                                      </span>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r8c1"]}>
                                        <Input readOnly={readonly} />
                                      </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r8c2"]}>
                                        <Radio.Group disabled={readonly}>
                                          <Radio value="state">State</Radio>
                                          <Radio value="national">
                                            National
                                          </Radio>
                                          <Radio value="international">
                                            International
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={2} className="text-center">
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {`9`}
                                      </span>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r9c1"]}>
                                        <Input readOnly={readonly} />
                                      </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r9c2"]}>
                                        <Radio.Group disabled={readonly}>
                                          <Radio value="state">State</Radio>
                                          <Radio value="national">
                                            National
                                          </Radio>
                                          <Radio value="international">
                                            International
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={2} className="text-center">
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {`10`}
                                      </span>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r10c1"]}>
                                        <Input readOnly={readonly} />
                                      </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item name={["cp7-1b", "r10c2"]}>
                                        <Radio.Group disabled={readonly}>
                                          <Radio value="state">State</Radio>
                                          <Radio value="national">
                                            National
                                          </Radio>
                                          <Radio value="international">
                                            International
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_id={section_cp7?.question_response_id}
                    question_response_name="cp7-1"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP7-2" className="title-header">
                    <Row>
                      <Col span={24}>
                        <div className="space-between">
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Provide details of corrective action taken or underway on any issues related to anticompetitive conduct by the entity, based on adverse orders from regulatory authorities.`}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <AddCP13
                          readonly={readonly}
                          data={{ cp7_2 }}
                          setdata={setCP7_2}
                          form={form}
                          ques_id={"cp7_2"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_id={section_cp7?.question_response_id}
                    question_response_name="cp7_2"
                  />
                </>
              )}
            </Card>
          </Space>

          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[3, 4].includes(assigned_template_id) && (
              <Card title="Leadership Indicators" size="small">
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP7-3" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Provide the processes for consultation between stakeholders and the Board on economic, environmental, and social topics or if consultation is delegated, how is feedback from such consultations provided to the Board.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp7_3", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={section_cp7?.question_response_id}
                      question_response_name="cp7_3"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP7-4" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span>
                            Whether stakeholder consultation is used to support
                            the identification and management of environmental,
                            and social topics (Yes / No). If so, provide details
                            of instances as to how the inputs received from
                            stakeholders on these topics were incorporated into
                            policies and activities of the entity.
                          </span>
                        </Col>
                        <Col span={16}>
                          <div style={{ display: "flex" }}>
                            <Form.Item name={["cp7_4", "r1c1"]}>
                              <Select
                                disabled={readonly}
                                style={{
                                  width: "200px",
                                  marginRight: "10px",
                                }}
                                placeholder="Select Option"
                              >
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                              </Select>
                            </Form.Item>
                            <Form.Item name={["cp7_4", "r1c2"]}>
                              <Input
                                readOnly={readonly}
                                style={{ width: "300px" }}
                                placeholder="Enter Stakeholder consultation"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={section_cp7?.question_response_id}
                      question_response_name="cp7_4"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP7-5" className="title-header">
                      <Row>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Legal actions for anti-competitive behavior, anti-trust, and monopoly practices.`}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={11}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A:] Number of legal actions pending or completed during the reporting period regarding anti-competitive behavior and violations of anti-trust and monopoly legislation in which the organization has been identified as a participant.`}
                          </span>
                        </Col>
                        <Col span={8}>
                          <Form.Item name={["cp7_5", "r1c1"]}>
                            <Input readOnly={readonly} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={11}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[B:] Main outcomes of completed legal actions, including any decisions or judgements.`}
                          </span>
                        </Col>
                        <Col span={8}>
                          <Form.Item name={["cp7_5", "r2c1"]}>
                            <Input readOnly={readonly} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={section_cp7?.question_response_id}
                      question_response_name="cp7_5"
                    />
                  </>
                )}
              </Card>
            )}
          </Space>
          {readonly ? (
            ""
          ) : (
            <Space
              style={{
                //marginTop: "10px",
                marginBottom: "10px",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <Button style={{ marginLeft: 10 }} htmlType="submit">
                Save and Continue
              </Button>
            </Space>
          )}
        </Form>
      </div>
    </>
  );
};

export default App;
