import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Card,
  message,
  Row,
  Col,
  Input,
  Space,
  Form,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  create_section_slice,
  get_section,
} from "../SectionSlice/sectionSlice";
import CommentsApp from "../../../../Components/Comments";
import { useCustomer } from "../../../../hooks/useCustomer";
import { useFinancialYear } from "../../../../hooks/useFinancialYear";
import CommentsNotified from "../../../../Components/Comments/comments-notification";

const App = (props) => {
  const { current_fy } = useFinancialYear();
  const { customer_id, assigned_template_id } = useCustomer();
  const [spinning, setSpinning] = useState(false);
  const [defaultdata, setDefaultdata] = useState({});
  const [readonly, setReadonly] = useState(false);
  const [template_id, setTemplate_id] = useState({});
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const [commentlist, setCommentlist] = useState([]);
  const [commentreload, setCommentReload] = useState();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (sessionState?.roletype === "CUSTOMER") {
      setReadonly(false);
    } else {
      setReadonly(true);
    }
    let payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_e",
    };
    setSpinning(true);
    dispatch(get_section({ payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setDefaultdata(res.data);
          setTemplate_id(res.data.section_e?.question_response_id);
          setCommentlist(res.data?.comments);
          setSpinning(false);
        } else {
          if (res.code != 800000) {
            message.info(res.msg);
          }

          setSpinning(false);
        }
      })
      .catch((Error) => {
        setSpinning(false);
        console.log(Error);
      });
  }, [commentreload]);

  const onFinish = (datalist) => {
    setSpinning(true);
    const datalist1 = {
      ...datalist,
    };
    let Payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_e",
      question_response: datalist1,
    };
    dispatch(create_section_slice({ Payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setSpinning(false);
          message.info("Section E created succesfully!");
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const { section_e } = defaultdata;
  useEffect(() => form.resetFields(), [form, section_e]);
  return (
    <>
      <CommentsNotified commentlist={commentlist} />
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 44, color: "white" }} spin />
        }
        tip="Loading..."
        spinning={spinning}
        fullscreen
        size="large"
      />
      <div className="formcontainer">
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={section_e}
          scrollToFirstError
          style={{ width: "100%" }}
        >
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[3, 4].includes(assigned_template_id) && (
              <Card title="Particular" size="small">
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="E-1" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Supply chain mapping: How does the organization Identify risk hot spots in the supply chain in accordance with base and audit practices for compliance with local and international legislations.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["e-1", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter Details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={section_e?.question_response_id}
                        question_response_name="e-1"
                      />
                    </Form.Item>
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="E-2" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Whistleblowing procedure: How does the organization ensure that it has appropriate whistleblowing and non-retaliation procedures that allows workers to report anonymously or to talk with someone other than theirsupervisor or HR.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["e-2", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter Details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={section_e?.question_response_id}
                      question_response_name="e-2"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="E-3" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Risk assessment: Risk assessment details to determine which parts of the business and which of your supply chains are most at risk. This should include a review of any existing compliance policies,contractual provisions and supply chain relationships`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["e-3", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter Details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={section_e?.question_response_id}
                      question_response_name="e-3"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="E-4" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Action: nature of the organization's work with legal counsel to determine what further actions and resources are required to prevent,monitor and mitigate against any risks that are identified in the business and/or its supply chain.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["e-4", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter Details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={section_e?.question_response_id}
                      question_response_name="e-4"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="E-5" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Contractual risk mitigation: How does the organization ensure that supply contracts contain adequate contractual protectionincluding warranties, reporting requirements and audit rights.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["e-5", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter Details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={section_e?.question_response_id}
                      question_response_name="e-5"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="E-6" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Site inspection: Description of site inspections (both scheduled and spot checks) to the extent notalready undertaken.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["e-6", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter Details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={section_e?.question_response_id}
                      question_response_name="e-6"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="E-7" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Engagement and capability building:How does the organization engage with the supplier management to increase awareness andunderstanding of health and safety risks in the supply chain at all levels.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["e-7", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter Details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={section_e?.question_response_id}
                      question_response_name="e-7"
                    />
                  </>
                )}
              </Card>
            )}
          </Space>

          {readonly ? (
            ""
          ) : (
            <Space
              style={{
                //marginTop: "10px",
                marginBottom: "10px",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <Button style={{ marginLeft: 10 }} htmlType="submit">
                Save and Continue
              </Button>
            </Space>
          )}
        </Form>
      </div>
    </>
  );
};

export default App;
