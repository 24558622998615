import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Input,
  Divider,
  Select,
  Form,
  Button,
  message,
  Row,
  Col,
  Space,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import CommonCompbar from "../../../Components/BaseLayout";
import { useDispatch } from "react-redux";
import { createReviewerUserSlice } from "./reviewerSlice/reviewerSlice";
import "./dashboard.scss";

const { Option } = Select;
const CreateReviewerUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onFinishFailed = async (values) => {
    navigate("/reviewers/users");
  };
  const onFinish = async (values) => {
    try {
      let response = await dispatch(
        createReviewerUserSlice({
          values,
        })
      );
      if (!response === undefined) return;
      message.info(response?.payload?.data?.msg);
      if (response?.payload?.data?.code === 0) {
        navigate("/reviewers/users");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <CommonCompbar sidebar={false}>
      <h3>Add Reviewer</h3>
      <Divider />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form
          layout="vertical"
          className="ant-form1"
          name="backoffice_user_registration"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={[16, 16]}>
            <Col span={10}>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  className="m-login-input"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="email"
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Name of Reviewer"
                name="display_name"
                rules={[
                  {
                    type: "text",
                    required: true,
                    message: "Please input your display name!",
                  },
                ]}
              >
                <Input
                  //className="m-login-input"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Display name"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={10}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name="confirm_password"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={10}>
              <Form.Item
                name="role_id"
                label="Role"
                rules={[
                  {
                    required: true,
                    message: "Please select Role!",
                  },
                ]}
              >
                <Select showSearch placeholder="Select">
                  <Option value="3">In House Reviewer</Option>
                  <Option value="4">ThirdParty Reviewer</Option>
                  <Option value="5">Customer Source Reviewer</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Name of Accounting Firm"
                name="accounting_firm_name"
                rules={[
                  {
                    type: "text",
                    required: true,
                    message: "Please input your Accounting Firm!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Name of Accounting Firm"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={20}>
              <Form.Item
                label="Council Membership Number"
                name="council_membership_number"
                rules={[
                  {
                    type: "text",
                    required: true,
                    message: "Please input your Council Membership Number!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Council Membership Number"
                />
              </Form.Item>
            </Col>
            <Space
              style={{
                marginTop: "10px",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button htmlType="cancel" danger>
                Cancel
              </Button>
              <Button htmlType="submit">Register</Button>
            </Space>
          </Row>
        </Form>
      </div>
    </CommonCompbar>
  );
};
export default CreateReviewerUser;
