import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateBackofficeUser } from "../../../../Config/actions";
import { updateNotifications } from "../../../../Config/actions";

const updateReviewerUsersSlice = createAsyncThunk(
  "reviewer/updatereviewerUsers",
  async (value) => {
    return await updateBackofficeUser(value);
  }
);
const readNotification = createAsyncThunk("/notification/update", async () => {
  return await updateNotifications();
});
export { readNotification, updateReviewerUsersSlice };
