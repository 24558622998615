import {  Button, Input } from "antd";
import intl from "react-intl-universal";
import "./reset.scss";

const ResetPassword = () => {
  let isFirstLogin = window.sessionStorage.getItem("isFirstLogin") === "true";
  return (
    <div className="m-reset-passowrd-wrap">
      <div className="m-reset-passowrd-inner">
        <div className="m-reset-passowrd-title">
          {/* <Icon type="jinggao" className="m-reset-passowrd-title-icon" /> */}
          <span className="m-reset-passowrd-title-text">
            {intl.get(isFirstLogin ? "api.first_reset" : "resetPassword.title")}
          </span>
        </div>
        <div className="m-reset-passowrd-row-wrap">
          <div className="m-reset-passowrd-row">
            <span className="m-reset-passowrd-label">
              {intl.get("resetPassword.oldPassword")}
            </span>
            <Input
              type="password"
              value={'oldpassword'}
              //onChange={handleChange}
              //ref={(Input) => (this.oldpassword = Input)}
              maxLength={30}
              placeholder={intl.get("resetPassword.oldPassword$placeholder")}
              className="m-reset-passowrd-Input"
              spellCheck="false"
              autoComplete="off"
            />
          </div>
          <div className="m-reset-passowrd-row">
            <span className="m-reset-passowrd-label">
              {intl.get("resetPassword.password")}
            </span>
            <Input
              type="password"
              value={'password'}
              //onChange={this.handleChange.bind(this, "password")}
              maxLength={30}
              placeholder={intl.get("resetPassword.password$placeholder")}
              className="m-reset-passowrd-Input"
              spellCheck="false"
              autoComplete="off"
            />
          </div>
          <div className="m-reset-passowrd-row">
            <span className="m-reset-passowrd-label">
              {intl.get("resetPassword.confirmPassword")}
            </span>
            <Input
              type="password"
              value={'confirm_password'}
              //onKeyDown={this.handleEnter.bind(this)}
              //onChange={this.handleChange.bind(this, "confirm_password")}
              maxLength={30}
              placeholder={intl.get(
                "resetPassword.confirmPassword$placeholder"
              )}
              className="m-reset-passowrd-Input"
              spellCheck="false"
              autoComplete="off"
            />
          </div>
          <div className="m-reset-passowrd-footer">
            <Button
              type="primary"
              //onClick={this.handleSubmit.bind(this)}
              className="m-reset-passowrd-btn"
            >
              <span>Save</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
