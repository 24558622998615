import React, { useEffect } from "react";
import {
  Tooltip,
  message,
  Dropdown,
  Table,
  Button,
  Menu,
  Select,
  Space,
} from "antd";
import "./customer_user.scss";
import Loader from "../../../Components/loader";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import CommonCompbar from "../../../Components/BaseLayout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  fetchCustomerUser,
  updateCustomerUserSlice,
} from "./customerUserSlice/customerUserSlice";
import {
  updateUserResetPassword,
  updateReset2fa,
} from "../../../Context/commonSlice";
import { fetchCustomer } from "../Customer-Details/dashboardSlice";
import EditCustomerUser from "./edit_customer_user";

const { Option } = Select;
const CustomerUser = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [custid, setCustid] = useState(false);
  const [editflag, setEditflag] = useState(false);
  const [customer_id, setCustomerId] = useState(
    location?.state?.customer_id || ""
  );
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const [customer_users_data, setCustomerUsersData] = useState([]);
  const [open, setOpen] = useState(false);
  const [row_data, setRowData] = useState([]);
  const [customer_data, setCustomerData] = useState([]);

  const navigate = useNavigate();
  const customerUserList = (customer_id) => {
    setLoader(true);
    dispatch(fetchCustomerUser(customer_id))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setLoader(false);
          setCustomerUsersData(res.data);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  useEffect(() => {
    customerUserList(customer_id);
    dispatch(fetchCustomer())
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setCustomerData(res.data);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);

  const handleButtonClick = (e) => {
    console.log("click left button", e);
  };
  const handleMenuClick = async (e, row) => {
    let customeruser_id = row?.id;
    if (e.key === "active" || e.key === "deactive") {
      try {
        let response = await dispatch(
          updateCustomerUserSlice({
            customeruser_id,
            is_active: true ? e.key === "active" : false,
          })
        );

        if (!response === undefined) return;
        message.info(response?.payload?.data?.msg);
      } catch (error) {
        console.log(error);
      }
    } else if (e.key === "edit") {
      const params = {
        customeruser_id,
        row,
      };

      setRowData(params);
      setEditflag(true);
      setOpen(true);
    } else if (e.key === "reset_password") {
      try {
        let response = await dispatch(
          updateUserResetPassword({
            token: sessionState.token,
          })
        );
        if (!response === undefined) return;
        message.info(response?.payload?.data?.msg);
      } catch (error) {
        console.log(error);
      }
    } else if (e.key === "reset_2fa") {
      try {
        let response = await dispatch(
          updateReset2fa({
            login_type: "customer_login",
            username: row.email,
          })
        );
        if (!response === undefined) return;
        message.info(response?.payload?.data?.msg);
      } catch (error) {
        console.log(error);
      }
    } else {
      setCustid(true);
    }
  };
  const showMsgModal = () => {};
  const btncreateCustomerUser = () => {
    navigate("/createcustomeruser", {
      state: { customer_id: customer_id },
    });
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleEdit = async (values) => {
    try {
      let response = await dispatch(updateCustomerUserSlice(values));
      if (response.payload.status === 200) {
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  function onChange(value) {
    setCustomerId(value);
    customerUserList(value);
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'display_name',
      key: 'display_name',
      render: (text) => <a onClick={showMsgModal}>{text}</a>,
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address ? 'Active' : 'Deactivated'}
        </Tooltip>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: {
        showTitle: false,
      },
      render: (email) => (
        <Tooltip placement="topLeft" title={email}>
          {email}
        </Tooltip>
      ),
    },
    {
      title: 'Onboard Date',
      dataIndex: 'created_date',
      key: 'created_date',
      ellipsis: {
        showTitle: false,
      },
      render: (created_date) => (
        <Tooltip placement="topLeft" title={created_date}>
          {created_date}
        </Tooltip>
      ),
    },
    {
      title: 'Customer Type',
      dataIndex: 'user_type',
      key: 'user_type',
      ellipsis: {
        showTitle: false,
      },
      render: (provison) => (
        <Tooltip placement="topLeft" title={provison}>
          {provison}
        </Tooltip>
      ),
    },
    {
      title: 'Manage',
      dataIndex: 'createdon',
      key: 'createdon',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const menu = (
          <Menu onClick={(e) => handleMenuClick(e, record)} className="items">
            {record.is_active ? (
              <Menu.Item key={'deactive'}>Deactive</Menu.Item>
            ) : (
              <Menu.Item key={'active'}>Activate</Menu.Item>
            )}
            <Menu.Item key={'edit'}>Edit</Menu.Item>
            <Menu.Item key={'reset_password'}>Reset Password</Menu.Item>
            <Menu.Item key={'reset_2fa'}>Reset 2FA</Menu.Item>
          </Menu>
        );
        return (
          <Dropdown.Button
            className="ant-btn1"
            onClick={(e) => handleButtonClick(record)}
            overlay={menu}
          >
            Select
          </Dropdown.Button>
        );
      },
    },
  ];
  const handleBack = () => {
    navigate("/dashboard");
  };
  return (
    <div>
      <CommonCompbar sidebar={false}>
        <div className="formcontainer">
          <div className="table-title-header-top">
            <h2>Customer Users</h2>
            <div style={{ margin: "10px" }}>
              <span>Select Customer</span>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select"
                onChange={onChange}
              >
                {customer_data.map((item) => {
                  return (
                    <>
                      <Option key={item.id} value={item.id}>
                        {item.company_name}
                      </Option>
                    </>
                  );
                })}
              </Select>
            </div>
          </div>

          <Table
            columns={columns}
            bordered
            pagination={false}
            dataSource={customer_users_data}
            className="f-table-wrapper knowldge-action"
            scroll={{
              y: 240,
            }}
          />
          <Space
            style={{
              marginTop: "10px",
              justifyContent: "right",
              width: "100%",
            }}
          >
            <Button
              className="ant-btn danger"
              onClick={handleBack}
              htmlType="button"
            >
              Go Back
            </Button>
            <Button className="ant-btn" onClick={btncreateCustomerUser}>
              Add Customer User
            </Button>
          </Space>
        </div>
      </CommonCompbar>
      {editflag && (
        <EditCustomerUser
          handleEdit={handleEdit}
          handleCancel={handleCancel}
          data={row_data}
          status={open}
        />
      )}
      <Loader flag={loader} />
    </div>
  );
};

export default CustomerUser;
