import React from "react";
import { Input } from "antd";

const FloatInput = (props) => {
  const { value, onChange } = props;
  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    var expr = /^\d+(\.\d{0,2})?$/;
    if (inputValue === "" || expr.test(inputValue)) {
      if (onChange) onChange(inputValue);
    }
  };

  return (
    <Input type="number" {...props} onChange={handleChange} placeholder="Enter" />
  );
};

export default FloatInput;
