import React, { useEffect } from "react";
import { Row, Col, Input, Form } from "antd";
import CommentsApp from "../../../../Components/Comments";
import TextFieldToTextArea from "../../../../Components/Input/textfieldtoarea"

const CP1 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div>
      <Form.Item label="CP1-1" className="title-header">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <span>
              Percentage coverage by training and awareness programmes on any of
              the Principles during the financial year:
            </span>
            <div id="tbl-cell">
              <Row>
                <Col span={6}>
                  <span style={{ fontSize: '12px', fontWeight: '600' }}>
                    Segment
                  </span>
                </Col>
                <Col span={6}>
                  <span style={{ fontSize: '12px', fontWeight: '600' }}>
                    Total number of training and awareness programmes held
                  </span>
                </Col>
                <Col span={6}>
                  <span style={{ fontSize: '12px', fontWeight: '600' }}>
                    Topics / principles covered under the training and its
                    impact
                  </span>
                </Col>
                <Col span={6}>
                  <span style={{ fontSize: '12px', fontWeight: '600' }}>
                    Percentage of persons in respective category covered by the
                    awareness programmes
                  </span>
                </Col>
              </Row>

              <Row>
                <Col
                  span={6}
                  style={{
                    fontSize: '12px',
                    fontWeight: '400',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: '400',
                    }}
                  >
                    Board of Directors
                  </span>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, 'r1c1']}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                      pattern="\d+"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, 'r1c2']}>
                  <TextFieldToTextArea props={props}/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, 'r1c3']}>
                    <Input size="small" suffix="%" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <span style={{ fontSize: '12px', fontWeight: '400' }}>
                    Key Managerial Personnel
                  </span>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, 'r2c1']}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, 'r2c2']}>
                  <TextFieldToTextArea props={props}/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, 'r2c3']}>
                    <Input size="small" suffix="%" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <span style={{ fontSize: '12px', fontWeight: '400' }}>
                    Employees other than BoD and KMPs
                  </span>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, 'r3c1']}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, 'r3c2']}>
                  <TextFieldToTextArea props={props}/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, 'r3c3']}>
                    <Input size="small" suffix="%" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <span style={{ fontSize: '12px', fontWeight: '400' }}>
                    Workers
                  </span>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, 'r4c1']}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, 'r4c2']}>
                  <TextFieldToTextArea props={props}/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, 'r4c3']}>
                    <Input size="small" suffix="%" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

export default CP1;
