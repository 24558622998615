const current_month = new Date().getMonth();
const current_year = new Date().getFullYear();

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const arthiFunc = (ques_id, formula_defination, form) => {
  Object.keys(formula_defination).forEach(function (key) {
    let values = formula_defination[key];
    if (values.type === "sum") {
      const sumWithInitial = values.cols.reduce(
        (accumulator, currentValue) =>
          accumulator +
          (!isNumeric(form.getFieldValue([ques_id, currentValue]))
            ? 0
            : parseFloat(form.getFieldValue([ques_id, currentValue]))),
        0
      );
      form.setFieldValue([ques_id, key], sumWithInitial);
    } else if (values.type === "per") {
      let dividend = !isNumeric(form.getFieldValue([ques_id, values.cols[0]]))
        ? 0
        : parseFloat(form.getFieldValue([ques_id, values.cols[0]]));
      let divisor = !isNumeric(form.getFieldValue([ques_id, values.cols[1]]))
        ? 0
        : parseFloat(form.getFieldValue([ques_id, values.cols[1]]));
      form.setFieldValue(
        [ques_id, key],
        divisor === 0 ? 0 : Number(((dividend / divisor) * 100).toFixed(0))
      );
    }else if (values.type === "perfloat") {
      let dividend = !isNumeric(form.getFieldValue([ques_id, values.cols[0]]))
        ? 0
        : parseFloat(form.getFieldValue([ques_id, values.cols[0]]));
      let divisor = !isNumeric(form.getFieldValue([ques_id, values.cols[1]]))
        ? 0
        : parseFloat(form.getFieldValue([ques_id, values.cols[1]]));
      form.setFieldValue(
        [ques_id, key],
        divisor === 0 ? 0 : Number(((dividend / divisor) * 100).toFixed(2))
      );
    }else if (values.type === "sumfloat") {
      const sumWithInitial = values.cols.reduce(
        (accumulator, currentValue) =>
          accumulator +
          (!isNumeric(form.getFieldValue([ques_id, currentValue]))
            ? 0
            : parseFloat(form.getFieldValue([ques_id, currentValue]))),
        0
      );
      form.setFieldValue([ques_id, key], parseFloat(sumWithInitial).toFixed(2));
    }
  });
};
export const current_fy = () => {
  return current_month > 3
    ? current_year + "-" + (current_year + 1)
    : current_year - 1 + "-" + current_year;
};
