import React, { useEffect } from "react";
import { useAuthState } from "../../../Context";
import {
  Tooltip,
  Dropdown,
  Table,
  Button,
  Menu,
  message,
  Form,
  Modal,
  Input,
  Space,
  Row,
  Col,
} from "antd";
import "./index.scss";
import Loader from "../../../Components/loader";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonCompbar from "../../../Components/BaseLayout";
import Customers from "./customer-views";
import Edit_Customer from "./create-customer/edit_customer";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { fetchCustomer, updateCustomerSlice } from "./dashboardSlice";
import AssignReviewer from "./assign_reviewer";
import { fetchReviewerUsers } from "../Reviewers/reviewerSlice/reviewerSlice";
import logoPic from "../../../static/images/customer-dashboard/logo.png";
import gridImage1 from "../../../static/images/customer-dashboard/image1.jpg";
import gridImage2 from "../../../static/images/customer-dashboard/image2.jpg";
import gridImage3 from "../../../static/images/customer-dashboard/image3.jpg";
import gridImage4 from "../../../static/images/customer-dashboard/image4.jpg";

const Dashboard = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userDetails = useAuthState();
  const [loader, setLoader] = useState(true);
  const [custid, setCustid] = useState(false);
  const [editflag, setEditflag] = useState(false);
  const [assignreviewerflag, setAssignReviewerflag] = useState(false);
  const [open, setOpen] = useState(false);
  const [customers_data, setCustomersData] = useState([]);
  const [filter, setFilter] = useState([]);

  const [row_data, setRowData] = useState([]);
  const [reviewer_data, setReviewerData] = useState([]);
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const navigate = useNavigate();

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const getReviewerUsers = (data) => {
    setLoader(true);
    dispatch(fetchReviewerUsers(data))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setLoader(false);
          setReviewerData(res.data);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  useEffect(() => {
    dispatch(fetchCustomer())
      .unwrap()
      .then((res) => {
        setLoader(false);
        if (res.code === 0) {
          setCustomersData(res.data);
          setFilter(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    btnsearch("");
  }, []);

  const handleMenuClick = async (e, row) => {
    let customer_id = row?.customer_id;
    if (e.key === "active" || e.key === "deactive") {
      try {
        let response = await dispatch(
          updateCustomerSlice({
            customer_id,
            is_active: true ? e.key === "active" : false,
          })
        );
        if (!response === undefined) return;
        if (response.payload.status === 200) {
          setCustomersData(
            customers_data.map((user) =>
              user.customer_id === customer_id
                ? { ...user, is_active: true ? e.key === "active" : false }
                : user
            )
          );
          message.info("Customer Status has changed!...");
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (e.key === "edit") {
      setEmail(row.company_email);
      setWebsite(row.website);
      const params = {
        customer_id,
        row,
      };

      setRowData(params);
      setEditflag(true);
      setOpen(true);
    } else if (e.key === "manage_users") {
      navigate("/managuser", { state: { customer_id: row?.id } });
    } else if (e.key === "view_details") {
      const prams = {
        customers_data: customers_data,
        selectedcustomer: row,
      };
      setRowData(prams);
      setCustid(true);
    } else if (e.key === "assign_reviewer") {
      getReviewerUsers({ customer_id: row?.id });
      setRowData(row);
      setAssignReviewerflag(true);
    } else if (e.key === "manage_reviewer") {
      navigate("/manage_reviewer", { state: { customer_id: row?.id } });
    } else {
      //navigate("/reset");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "company_name",
      key: "company_name",
      // render: (text) => <a onClick={showMsgModal}>{text}</a>,
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address ? "Active" : "Deactivated"}
        </Tooltip>
      ),
    },
    {
      title: "Customer ID",
      dataIndex: "customer_id",
      key: "customer_id",
      ellipsis: {
        showTitle: false,
      },
      render: (custid) => (
        <Tooltip placement="topLeft" title={custid}>
          {custid}
        </Tooltip>
      ),
    },
    {
      title: "Assigned Template",
      dataIndex: "template_name",
      key: "template_name",
      ellipsis: {
        showTitle: false,
      },
      render: (assigned) => (
        <Tooltip placement="topLeft" title={assigned}>
          {assigned}
        </Tooltip>
      ),
    },
    {
      title: "Onboarding Date",
      dataIndex: "onboarding_date",
      key: "onboarding_date",
      ellipsis: {
        showTitle: false,
      },
      render: (obDate) => (
        <Tooltip placement="topLeft" title={obDate}>
          {obDate}
        </Tooltip>
      ),
    },
    {
      title: "Users Provisoned",
      dataIndex: "user_provisioned",
      key: "user_provisioned",
      ellipsis: {
        showTitle: false,
      },
      render: (user_provisioned) => (
        <Tooltip placement="topLeft" title={user_provisioned}>
          {user_provisioned}
        </Tooltip>
      ),
    },
    {
      title: "Manage",
      dataIndex: "createdon",
      key: "createdon",
      ellipsis: {
        showTitle: false,
      },

      render: (text, record, index) => {
        const menu = (
          <Menu onClick={(e) => handleMenuClick(e, record)} className="items">
            {record.is_active ? (
              <Menu.Item key={"deactive"}>Deactive</Menu.Item>
            ) : (
              <Menu.Item key={"active"}>Activate</Menu.Item>
            )}

            <Menu.Item key={"manage_users"}>Manage Users</Menu.Item>
            <Menu.Item key={"view_details"}>View Details</Menu.Item>
            <Menu.Item key={"manage_reviewer"}>Manage Reviewer</Menu.Item>
            <Menu.Item key={"edit"}>Edit</Menu.Item>
            <Menu.Item key={"assign_reviewer"}>Assign Reviewer</Menu.Item>
          </Menu>
        );
        return `${sessionState?.roletype}` !== "ADMIN" ? (
          ""
        ) : (
          <Dropdown.Button
            //onClick={(e) => handleButtonClick(record)}
            overlay={menu}
          >
            Select
          </Dropdown.Button>
        );
      },
    },
  ];
  const showMsgModal = () => {};
  const btncreateCustomer = () => {
    navigate("/create");
  };

  const handleCancel = () => {
    setRowData("");
    form.resetFields();
    setOpen(false);
  };
  const handleEdit = async (values) => {
    try {
      let response = await dispatch(updateCustomerSlice(values));
      if (response.payload.status === 200) {
        setOpen(false);
        message.info("Details Updated succesfully!..");
        form.resetFields();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const btnsearch = (value) => {
    const data = customers_data;
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilter([...filterTable]);
  };
  const firstItem = Array.isArray(customers_data) ? customers_data[0] : null;

  return (
    <div>
      <CommonCompbar sidebar={false}>
        {custid ? (
          <Customers data={row_data} />
        ) : sessionState?.roletype === "CUSTOMER" ? (
          <div className="">
            <div class="">
                  
              <div className="container-customer-dashboard">
                <div className="column left-column">
                  <div className="logo-section">
                    
                    <p className="logo-text">
                      Comply, improve & save on your sustainability transition
                    </p>
                  </div>
                  {firstItem && (
                    <div className="labels-section">
                      <p className="label">
                        Onboard Date {firstItem.onboarding_date}
                      </p>
                      <p className="label">
                        No. of users {firstItem.user_provisioned}
                      </p>
                      <p className="label">Expiry: {firstItem.expired_date}</p>
                      <button
                        className="label button-label"
                        onClick={togglePopup}
                      >
                        Helpdesk
                      </button>
                    </div>
                  )}
                </div>
                
                {isPopupVisible && (
                  <div className="popup">
                    <div className="popup-content">
                      <span className="close-button" onClick={togglePopup}>
                        &times;
                      </span>

                      <h2>Helpdesk Information</h2>
                      <p>
                        You can reach out to our helpdesk team by raising a
                        ticket in the Tickets page.
                      </p>

                      <ol>
                        <li>
                          <strong>Navigate to the Tickets Page:</strong> Use the
                          top-level menu to go to the Tickets section.
                        </li>
                        <li>
                          <strong>Click on the "New Ticket" Button:</strong> On
                          the Tickets page, you will find a "New Ticket" button.
                        </li>
                        <li>
                          <strong>Fill Out the Ticket Form:</strong> Provide the
                          necessary details about your issue or request.
                        </li>
                        <li>
                          <strong>Submit Your Ticket:</strong> Once you have
                          filled out all the required information, submit the
                          ticket.
                        </li>
                      </ol>
                      <p>
                        Our helpdesk team will review your ticket and get back
                        to you as soon as possible. Thank you for reaching out
                        to us!
                      </p>
                      <Button onClick={togglePopup}>Close</Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="formcontainer">
            <div className="table-title-header-top">
              <h2>Customers List</h2>
              {`${sessionState?.roletype}` !== "ADMIN" ? (
                ""
              ) : (
                <Space>
                  <Input.Search
                    style={{ width: "240px" }}
                    placeholder="Search by..."
                    enterButton
                    onSearch={btnsearch}
                  />
                  <Button onClick={btncreateCustomer} icon={<PlusOutlined />}>
                    Add Customer
                  </Button>
                </Space>
              )}
            </div>
            <Table
              columns={columns}
              bordered
              pagination={false}
              dataSource={filter}
              className="f-table-wrapper knowldge-action"
              scroll={{
                y: 240,
              }}
            />
          </div>
        )}
        {editflag && (
          // <Edit_Customer
          //   handleEdit={handleEdit}
          //   handleCancel={handleCancel}
          //   form={form}
          //   data={row_data}
          //   status={open}
          // />
          <Modal
            title="Edit Customer"
            open={open}
            onCancel={handleCancel}
            footer={null}
          >
            <div className="modalform">
              <Row>
                <Col span={8}>Customer ID:</Col>
                <Col>{row_data.customer_id}</Col>
              </Row>
              <Row>
                <Col span={8}>Onboarding Date:</Col>
                <Col span={12}>{row_data?.row?.onboarding_date}</Col>
              </Row>
              <Row>
                <Col span={8}>Website:</Col>
                <Col span={14}>
                  <Input
                    value={website}
                    // setWebsite={(e) => e.target.value}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: "10px" }}>
                <Col span={8}>Company email:</Col>
                <Col span={14}>
                  <Input value={email} />
                </Col>
              </Row>
              <div className="modal-footer">
                <Space
                  style={{
                    marginTop: "10px",
                    justifyContent: "right",
                    width: "100%",
                  }}
                >
                  <Button onClick={handleCancel} htmlType="button">
                    Cancel
                  </Button>

                  <Button style={{ marginLeft: 10 }} onClick={handleEdit}>
                    Save
                  </Button>
                </Space>
              </div>
            </div>
          </Modal>
        )}
        {assignreviewerflag && (
          <AssignReviewer
            handleEdit={handleEdit}
            handleFlag={setAssignReviewerflag}
            data={row_data}
            reviewer_data={reviewer_data}
            status={assignreviewerflag}
          />
        )}
        <Loader flag={loader} />
      </CommonCompbar>
    </div>
  );
};

export default Dashboard;
