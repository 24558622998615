import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Row, message } from "antd";
import {
  createTicketConversationsSlice,
  fetchticketConversationsInfo,
} from "./ticketSlice/ticketSlice";
import { updateNotificationSlice } from "../../Context/commonSlice";

const Converations = (props) => {
  const dispatch = useDispatch();
  const [conversation_data, setConversationData] = useState([]);
  const [conversation_text, setConversationText] = useState();
  const [ticket_id, setTicketID] = useState("");
  const [ticket_title, setTicketTitle] = useState("");
  const [ticket_status, setTicketStatus] = useState("");

  useEffect(() => {
    

    dispatch(
      fetchticketConversationsInfo({
        ticket_id: props.ticket_data.id,
      })
    ).then((res) => {
      if (res?.payload?.code !== 0) {
        props.setOpen(false);
        message.info(res?.payload?.msg);
      } else {
        setTicketStatus(res.payload.data.ticketinfo.ticket_status);
        setConversationData(res.payload.data.conversations);
        setTicketID(res.payload.data.ticketinfo.id);
        setTicketTitle(res.payload.data.ticketinfo.ticket_description);
      }
    });
    dispatch(updateNotificationSlice())
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          message.info(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, props]);
  const handleCancel = () => {
    props.setOpen(false);
  };
  const handleConversation = () => {
    try {
      let response = dispatch(
        createTicketConversationsSlice({
          ticket_id: props.ticket_data.id,
          conversations: conversation_text,
        })
      );

      if (!response === undefined) return;
      setConversationText("");
      dispatch(
        fetchticketConversationsInfo({
          ticket_id: props.ticket_data.id,
        })
      ).then((res) => {
        setConversationData(res.payload.data.conversations);
        handleCancel();
      });
    } catch (error) {
      console.log(error);
      handleCancel();
    }
  };
  const chat_template = (data) => {
    return data.responsed_by ? (
      <div className="msg right-msg">
        <div className="msg-bubble">
          <div className="msg-info">
            <div className="msg-info-name">Admin</div>
            <div className="msg-info-time">{data.created_date}</div>
          </div>
          <div className="msg-text">{data.conversations}</div>
        </div>
      </div>
    ) : (
      <div className="msg left-msg">
        <div className="msg-bubble">
          <div className="msg-info">
            <div className="msg-info-name">{data.created_name}</div>
            <div className="msg-info-time">{data.created_date}</div>
          </div>
          <div className="msg-text">{data.conversations}</div>
        </div>
      </div>
    );
  };
  return (
    <Modal
      title="Conversations" 
      open={props.open}
      onCancel={handleCancel}
      footer={null}
    >
      <Row gutter={[16, 16]}>
        <section className="msger">
          <p><b>Ticket description: </b> {ticket_title}</p>
        

          <main className="msger-chat">
            {conversation_data &&
              conversation_data.map((data) => chat_template(data))}
          </main>
          {ticket_status !== "Completed" && (
            <div className="msger-inputarea">
              <input
                type="text"
                className="msger-input"
                placeholder="Enter your message..."
                onChange={(e) => setConversationText(e.target.value)}
                value={conversation_text}
              />
              <button
                type="submit"
                className="msger-send-btn"
                onClick={handleConversation}
              >
                Send
              </button>
            </div>
          )}
        </section>
      </Row>
    </Modal>
  );
};

export default Converations;
