import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createadmin,
  getReviewerUsersList,
  updateBackofficeUser,
  getreviewerCustomerMapper,
  getcustomerReviewerMapper,
} from "../../../../Config/actions";

const createReviewerUserSlice = createAsyncThunk(
  "reviewer/createReviewerUser",
  async (value) => {
    return await createadmin(value);
  }
);
const fetchReviewerUsers = createAsyncThunk(
  "reviewer/reviewerUsers",
  async (data, thunkAPI) => {
    const response = await getReviewerUsersList(data);
    return response.data;
  }
);

const fetchReviewerCustomerUsersList = createAsyncThunk(
  "reviewer/reviewerUsers",
  async (reviewer_id, thunkAPI) => {
    const response = await getreviewerCustomerMapper(reviewer_id);
    return response.data;
  }
);
const fetchCustomerReviewerUsers = createAsyncThunk(
  "reviewer/reviewerUsers",
  async (customer_id, thunkAPI) => {
    const response = await getcustomerReviewerMapper(customer_id);
    return response.data;
  }
);
const updateReviewerUsersSlice = createAsyncThunk(
  "reviewer/updatereviewerUsers",
  async (value) => {
    return await updateBackofficeUser(value);
  }
);
export {
  createReviewerUserSlice,
  fetchReviewerUsers,
  updateReviewerUsersSlice,
  fetchReviewerCustomerUsersList,
  fetchCustomerReviewerUsers,
};
