import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form, Select } from "antd";
const { Option } = Select;

const T15 = (props) => {
  const [r5c1, setR5c1] = useState("No");
  
  const handleChange = (e) => {
  

    let divisor = parseFloat(props.form.getFieldValue(["cp6_0", "r1c1"]));
    let r1c2value = parseFloat(props.form.getFieldValue([props.ques_id, "r1c2"]));

    props.form.setFieldValue(
      [props.ques_id, "r3c2"],
      divisor === 0 ? 0 : Number(((r1c2value / divisor)).toFixed(2))
    );

  };

  useEffect(() => {
    props.form.setFieldsValue(props.data);
    setR5c1(props.form.getFieldValue([props.ques_id, "r5c1"]));
  }, [props.ques_id, props.form, props.data]);
  return (
    <div>
      <Form.Item label="" className="title-header">
        <Row>
          <Col span={24}>
            <div id="tbl-cell">
              <Row>
                <Col span={6}>Parameter</Col>
                <Col
                  span={6}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  Please specify unit
                </Col>
                <Col
                  span={6}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  FY {props?.data?.current_fy} (Current Financial Year)
                </Col>
                <Col
                  span={6}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  FY {props?.data?.previous_fy} (Previous Financial Year)
                </Col>
              </Row>
              <Row>
                <Col span={6}>Total Scope 3 emissions </Col>
                <Col span={6}>Metric tonnes of CO<sub>2</sub> equivalent</Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r1c2"]}
                    className="ant-col1"
                    onChange={handleChange}
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r1c3"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  Break-up of the GHG into CO<sub>2</sub>, CH<sub>4</sub>, N<sub>2</sub>O, HFCs, PFCs, SF6, NF3,
                  if available (Provide a weblink)
                </Col>
                <Col span={18}>
                  <Form.Item
                    name={[props.ques_id, "r2c1"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  Total Scope 3 emissions per rupee of turnover
                </Col>
                <Col span={6}>Metric tonnes of CO<sub>2</sub> eq/crores</Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r3c2"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r3c3"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  Total Scope 3 emission intensity (optional) – the relevant
                  metric may be selected by the entity -input text field to
                  enter metric
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r4c1"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r4c2"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r4c3"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  Indicate if any independent assessment/ evaluation/assurance
                  has been carried out by an external agency? (Y/N) If yes, name
                  of the external agency.
                </Col>
                <Col span={18}>
                  <Row>
                    <Col span={4}>
                      <Form.Item
                        name={[props.ques_id, "r5c1"]}
                        noStyle
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Select disabled={props.readonly}
                          placeholder="Select Option"
                          onChange={(value) => setR5c1(value)}
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {r5c1 === "Yes" && (
                      <Col span={20}>
                        <Form.Item
                          name={[props.ques_id, "r5c2"]}
                          noStyle
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input readOnly={props.readonly} placeholder="" />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

export default T15;
