import React, { useEffect, useState } from "react";
import {
  Divider,
  message,
  Button,
  Input,
  Spin,
  Row,
  Col,
  Form,
  Card,
  Space,
  Select,
  Radio,
} from "antd";
import { useDispatch } from "react-redux";
import {
  create_section_slice,
  get_section,
} from "../SectionSlice/sectionSlice";
import { LoadingOutlined } from "@ant-design/icons";
import CP3_5 from "./CP3_5";
import CP3_7 from "./CP3_7";
import CP3_11 from "./CP3_11";
import CP3_13 from "./CP3_13";
import CP3_18 from "./CP3_18";
import CommentsApp from "../../../../Components/Comments";
import { useCustomer } from "../../../../hooks/useCustomer";
import { useFinancialYear } from "../../../../hooks/useFinancialYear";
import CommentsNotified from "../../../../Components/Comments/comments-notification";
import CP3_20 from "./CP3_20";
import CP3_1A from "./CP3_1A";
import CP3_1B from "./CP3_1B";
import CP3_1C from "./CP3_1C";
import CP3_2 from "./CP3_2";
import CP3_6 from "./CP3_6";
import CP3_8 from "./CP3_8";
import CP3_9 from "./CP3_9";
import CP3_14 from "./CP3_14";

const { Option } = Select;
const Section_C = (props) => {
  const { current_fy, previous_fy } = useFinancialYear();
  const { customer_id, assigned_template_id } = useCustomer();
  const [spinning, setSpinning] = useState(false);
  const [defaultdata, setDefaultdata] = useState({});
  const [commentlist, setCommentlist] = useState([]);
  const [commentreload, setCommentReload] = useState();
  const [form] = Form.useForm();
  const [cp10, setCP10] = useState();
  const [readonly, setReadonly] = useState(false);
  const dispatch = useDispatch();
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );

  useEffect(() => {
    let section_update = "";
    let a20asavedData = JSON.parse(localStorage.getItem("a20a")) || {};
    if (Object.keys(a20asavedData).length !== 0) {
      section_update = {
        cp3_1a: {
          r1c1: a20asavedData["1Y"],
          r2c1: a20asavedData["1W"],
          r4c1: a20asavedData["2Y"],
          r5c1: a20asavedData["2W"],
        },
        cp3_1b: {
          r1c1: a20asavedData["1K"],
          r2c1: a20asavedData["1M"],
          r4c1: a20asavedData["2K"],
          r5c1: a20asavedData["2M"],
        },
        cp3_7: {
          r1c1: a20asavedData["1Y"],
          r2c1: a20asavedData["1W"],
          r4c1: a20asavedData["1K"],
          r5c1: a20asavedData["1M"],
        },
        cp3_8: {
          r1c1: a20asavedData["1Y"],
          r2c1: a20asavedData["1W"],
          r4c1: a20asavedData["1K"],
          r5c1: a20asavedData["1M"],
        },
        cp3_9: {
          r1c1: a20asavedData["1Y"],
          r2c1: a20asavedData["1W"],
          r4c1: a20asavedData["1K"],
          r5c1: a20asavedData["1M"],
        },
      };
    }
    if (sessionState?.roletype === "CUSTOMER") {
      setReadonly(false);
    } else {
      setReadonly(true);
    }
    let payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_cp3",
    };
    setSpinning(true);
    dispatch(get_section({ payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          if (Object.keys(a20asavedData).length !== 0) {
            setDefaultdata({ ...res.data.section_cp3, ...section_update });
            setCommentlist(res.data?.comments);
            setSpinning(false);
          } else {
            setDefaultdata(res?.data?.section_cp3);
            setCommentlist(res.data?.comments);
            setSpinning(false);
          }
        } else {
          if (res.code != 800000) {
            message.info(res.msg);
          }
          if (Object.keys(a20asavedData).length !== 0) {
            setDefaultdata({ ...section_update });
          }
          setSpinning(false);
        }
      })
      .catch((Error) => {
        setSpinning(false);
        console.log(Error);
      });
  }, [commentreload]);

  // useEffect(() => {
  //   let a20asavedData = JSON.parse(localStorage.getItem("a20a")) || {};
  //   setDefaultdata({
  //     cp3_1a: {
  //       r1c1: a20asavedData["1Y"],
  //       r2c1: a20asavedData["1W"],
  //       r4c1: a20asavedData["2Y"],
  //       r5c1: a20asavedData["2W"],
  //     },
  //     cp3_1b: {
  //       r1c1: a20asavedData["1K"],
  //       r2c1: a20asavedData["1M"],
  //       r4c1: a20asavedData["2K"],
  //       r5c1: a20asavedData["2M"],
  //     },
  //     cp3_7: {
  //       r1c1: a20asavedData["1Y"],
  //       r2c1: a20asavedData["1W"],
  //       r4c1: a20asavedData["1K"],
  //       r5c1: a20asavedData["1M"],
  //     },
  //     cp3_8: {
  //       r1c1: a20asavedData["1Y"],
  //       r2c1: a20asavedData["1W"],
  //       r4c1: a20asavedData["1K"],
  //       r5c1: a20asavedData["1M"],
  //     },
  //     cp3_9: {
  //       r1c1: a20asavedData["1Y"],
  //       r2c1: a20asavedData["1W"],
  //       r4c1: a20asavedData["1K"],
  //       r5c1: a20asavedData["1M"],
  //     },
  //   });
  // }, []);
  const onFinish = (datalist) => {
    setSpinning(true);
    const datalist1 = {
      ...datalist,
    };
    let Payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_cp3",
      question_response: datalist1,
    };
    dispatch(create_section_slice({ Payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setTimeout(() => {
            setSpinning(false);
            message.info("Section CP3 created succesfully!");
          }, 3000);
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };
  const rdocp10 = (e) => {
    if (e.target.value === "Yes") {
      setCP10();
    } else {
      setCP10("none");
    }
  };
  return (
    <>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 44, color: "white" }} spin />
        }
        tip="Loading..."
        spinning={spinning}
        fullscreen
        size="large"
      />
      <div className="formcontainer">
      <CommentsNotified commentlist={commentlist} />
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={defaultdata}
          scrollToFirstError
          style={{ width: "100%" }}
        >
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Card title="Essential Indicators" size="small">
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP3-1" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          a. Details of measures for the well-being of
                          employees:
                        </span>
                        <CP3_1A
                          readonly={readonly}
                          data={{ ...defaultdata }}
                          form={form}
                          ques_id={"cp3_1a"}
                        />
                        <Divider />
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          b. Details of measures for the well-being of workers:
                        </span>
                        <CP3_1B
                          readonly={readonly}
                          data={{ ...defaultdata }}
                          form={form}
                          ques_id={"cp3_1b"}
                        />
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          c. Spending on measures towards well-being of
                          employees and workers (including permanent and other
                          than permanent) in the following format
                        </span>
                        <CP3_1C
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"cp3_1c"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp3_1"
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP3-2" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          Details of retirement benefits, for Current FY and
                          Previous Financial Year.
                        </span>
                        <CP3_2
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"cp3_2"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp3_2"
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP3-3" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Are the premises / offices of the entity accessible to differently abled employees and workers, as per the requirements of the Rights of Persons with Disabilities Act, 2016? If not, whether any steps are being taken by the entity in this regard.`}
                        </span>
                      </Col>
                      <Col span={16}>
                        <div style={{ display: "flex" }}>
                          <Form.Item name={["cp3_3", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              style={{ width: "250px", marginRight: "10px" }}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item name={["cp3_3", "r1c2"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={3}
                              // onChange={(e) => setRemark(e.target.value)}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp3_3"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP3-4" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Does the entity have an equal opportunity policy as per the Rights of Persons with Disabilities Act, 2016? If so, provide a web-link to the policy.`}
                        </span>
                      </Col>
                      <Col span={16}>
                        <div style={{ display: "flex" }}>
                          <Form.Item name={["cp3_4", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              style={{ width: "250px", marginRight: "10px" }}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item name={["cp3_4", "r1c2"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={3}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp3_4"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP3-5" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          Return to work and Retention rates of permanent
                          employees and workers that took parental leave.
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <CP3_5
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"cp3_5"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp3_5"
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP3-6" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          Is there a mechanism available to receive and redress
                          grievances for the following categories of employees
                          and worker? If yes, give details of the mechanism in
                          brief.
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <CP3_6
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"cp3_6"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp3_6"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP3-7" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          Membership of employees and worker in association(s)
                          or Unions recognised by the listed entity:
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <CP3_7
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"cp3_7"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp3_7"
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP3-8" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          Details of training given to employees and workers:
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <CP3_8
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"cp3_8"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp3_8"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP3-9" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          Details of performance and career development reviews
                          of employees and worker:
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <CP3_9
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"cp3_9"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp3_9"
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP3-10" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Health and safety management system:`}
                        </span>
                      </Col>
                      <Row style={{ width: "100%" }}>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: Whether an occupational health and safety management system has been implemented by the entity? (Yes/ No). If yes, the coverage such system?`}
                          </span>
                        </Col>
                        <Col span={16}>
                          <Row id="tbl-cell">
                            <Col span={8}>
                              <Form.Item name={["cp3_10", "r1c1"]}>
                                <Radio.Group
                                  onChange={rdocp10}
                                  disabled={readonly}
                                >
                                  <Radio value="Yes">Yes</Radio>
                                  <Radio value="No">No</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                            <Col span={16}>
                              <Form.Item name={["cp3_10", "r1c2"]}>
                                <Input.TextArea
                                  readOnly={readonly}
                                  style={{ display: cp10 }}
                                  rows={2}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Divider />
                      <Row style={{ width: "100%" }}>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[B]: What are the processes used to identify work-related hazards and assess risks on a routine and non-routine basis by the entity?`}
                          </span>
                        </Col>
                        <Col span={16}>
                          <Form.Item name={["cp3_10", "r2c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={3}
                              placeholder="Enter assess risks"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row style={{ width: "100%" }}>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[C]: Whether you have processes for workers to report the work related hazards and to remove themselves from such risks. (Y/N)`}
                          </span>
                        </Col>
                        <Col span={16}>
                          <Form.Item name={["cp3_10", "r3c1"]}>
                            <Radio.Group disabled={readonly}>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row style={{ width: "100%" }}>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[D]: Do the employees/ worker of the entity have access to non-occupational medical and healthcare services? (Yes/ No)`}
                          </span>
                        </Col>
                        <Col span={16}>
                          <Form.Item name={["cp3_10", "r4c1"]}>
                            <Radio.Group disabled={readonly}>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name={"cp3_10"}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <CP3_11
                    readonly={readonly}
                    data={{ current_fy, previous_fy, ...defaultdata }}
                    form={form}
                    ques_id={"cp3_11"}
                  />
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name={"cp3_11"}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP3-12" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Describe the measures taken by the entity to ensure a safe and healthy work place.`}
                        </span>
                      </Col>
                      <Col span={16}>
                        <Form.Item name={["cp3_12", "r1c1"]}>
                          <Input.TextArea
                            readOnly={readonly}
                            rows={3}
                            placeholder="Enter work place"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"cp3_12"}
                    />
                  </Form.Item>
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP3-13" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          Number of Complaints on the following made by
                          employees and workers:
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <CP3_13
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"cp3_13"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name={"cp3_13"}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP3-14" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          Assessments for the year:
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <CP3_14
                          readonly={readonly}
                          data={{ current_fy, previous_fy, ...defaultdata }}
                          form={form}
                          ques_id={"cp3_14"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name={"cp3_14"}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP3-15" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Provide details of any corrective action taken or underway to address safety-related incidents (if any) and on significant risks / concerns arising from assessments of health & safety practices and working conditions.`}
                        </span>
                      </Col>
                      <Col span={16}>
                        <Form.Item name={["cp3_15", "r1c1"]}>
                          <Input.TextArea
                            disabled={readonly}
                            rows={3}
                            placeholder="Enter work place"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name={"cp3_15"}
                  />
                </>
              )}
            </Card>
          </Space>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[3, 4].includes(assigned_template_id) && (
              <Card title="Leadership Indicators" size="small">
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-16" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Does the entity extend any life insurance or any compensatory package in the event of death of`}
                          </span>
                        </Col>

                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`Employees`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_16", "r1c1"]}>
                              <Radio.Group>
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`Workman`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_16", "r2c1"]}>
                              <Radio.Group>
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata?.question_response_id}
                        question_response_name={"cp3-16"}
                      />
                    </Form.Item>
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-17" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Provide the measures undertaken by the entity to ensure that statutory dues have been deducted and deposited by the value chain partners.`}
                          </span>
                        </Col>
                        <Col span={16}>
                          <Form.Item name={["cp3_17", "r1c1"]}>
                            <Input.TextArea
                              rows={3}
                              placeholder="Enter work place"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata?.question_response_id}
                        question_response_name={"cp3-17"}
                      />
                    </Form.Item>
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                   <>
                  <CP3_18
                    readonly={readonly}
                    form={form}
                    data={{ current_fy, previous_fy, ...defaultdata }}
                    ques_id={"cp3_18"}
                  />
                   <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata?.question_response_id}
                        question_response_name={"cp3-18"}
                      />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-19" className="title-header">
                      <Row style={{ width: "100%" }}>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Does the entity provide transition assistance programs to facilitate continued employability and the management of career endings resulting from retirement or termination of employment? (Yes/ No)`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp3_19", "r1c1"]}>
                            <Radio.Group>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata?.question_response_id}
                        question_response_name={"cp3-19"}
                      />
                    </Form.Item>
                    
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <CP3_20
                      readonly={readonly}
                      form={form}
                      data={{ ...defaultdata }}
                      ques_id={"cp3_20"}
                    />
                     <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata?.question_response_id}
                        question_response_name={"cp3-20"}
                      />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-21" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Provide details of any corrective actions taken or underway to address significant risks / concerns arising from assessments of health and safety practices and working conditions of value chain partners.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp3_21", "r1c1"]}>
                            <Input.TextArea
                              rows={3}
                              placeholder="Enter corrective actions"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                        question_response_id={defaultdata?.question_response_id}
                        question_response_name={"cp3-21"}
                      />
                  </>
                )}
              </Card>
            )}
          </Space>

          {/* <h2>END OF LITE/EXTENDED</h2> */}
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[4].includes(assigned_template_id) && (
              <Card title="Essential Indicators" size="small">
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-22" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Describe the details of benefits provided to full-time employees that are not provided to temporary or partime employees`}
                          </span>
                        </Col>
                        <Col span={16}>
                          <Form.Item name={["cp3_22", "r1c1"]}>
                            <Input.TextArea
                              rows={3}
                              placeholder="Total number"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-22"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-23" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Describe significant locations of operation that are prone to safety incidents`}
                          </span>
                        </Col>
                        <Col span={16}>
                          <Form.Item name={["cp3_23", "r1c1"]}>
                            <Input.TextArea
                              rows={3}
                              placeholder="Total number"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-23"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-24" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Details of defined benefit plan obligations and other retirement plans`}
                          </span>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`(a)- If the plan’s liabilities are met by the organization’s general resources, the estimated value of those liabilities.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <div style={{ display: "flex" }}>
                              <Form.Item name={["cp3_24", "r1c1"]}>
                                <Select
                                  style={{
                                    width: "250px",
                                    marginRight: "10px",
                                  }}
                                  placeholder="Select Option"
                                >
                                  <Option value="Yes">Yes</Option>
                                  <Option value="No">No</Option>
                                </Select>
                              </Form.Item>

                              <Form.Item name={["cp3_24", "r1c2"]}>
                                <Input.TextArea
                                  rows={3}
                                  // onChange={(e) => setRemark(e.target.value)}
                                  placeholder="Name of the regulatory"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-24"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-25" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Collective bargaining agreements`}
                          </span>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`(a)- For employees not covered by collective bargaining agreements, report whether the organization determines their working conditions and terms of employment based on collective bargaining agreements that cover its other employees or based on collective bargaining agreements from other organizations.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <div style={{ display: "flex" }}>
                              <Form.Item name={["cp3_25", "r1c1"]}>
                                <Select
                                  style={{
                                    width: "250px",
                                    marginRight: "10px",
                                  }}
                                  placeholder="Select Option"
                                >
                                  <Option value="Yes">Yes</Option>
                                  <Option value="No">No</Option>
                                </Select>
                              </Form.Item>

                              <Form.Item name={["cp3_25", "r1c2"]}>
                                <Input.TextArea
                                  rows={3}
                                  // onChange={(e) => setRemark(e.target.value)}
                                  placeholder="Name of the regulatory"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-25"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-26" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Occupational health and safety management system`}
                          </span>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`The reporting organization shall report the following information for employees and for workers who are not employees but whose work and/or workplace is controlled by the organization:`}
                          </div>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: A statement of whether an occupational health and safety management system has been implemented, including whether:`}
                          </div>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <div
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[i]: The system has been implemented because of legal requirements and, if so, a list of the requirements`}
                            </div>
                          </Col>
                          <Col span={16}>
                            <div style={{ display: "flex" }}>
                              <Form.Item name={["cp3_26", "r1c1"]}>
                                <Select
                                  style={{
                                    width: "250px",
                                    marginRight: "10px",
                                  }}
                                  placeholder="Select Option"
                                >
                                  <Option value="Yes">Yes</Option>
                                  <Option value="No">No</Option>
                                </Select>
                              </Form.Item>

                              <Form.Item name={["cp3_26", "r1c2"]}>
                                <Input.TextArea
                                  rows={2}
                                  // onChange={(e) => setRemark(e.target.value)}
                                  placeholder="Name of the regulatory"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <div
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[ii]: The system has been implemented based on recognized risk management and/or management system standards/guidelines and, if so, a list of the standards/guidelines.`}
                            </div>
                          </Col>
                          <Col span={16}>
                            <div style={{ display: "flex" }}>
                              <Form.Item name={["cp3_26", "r2c1"]}>
                                <Select
                                  style={{
                                    width: "250px",
                                    marginRight: "10px",
                                  }}
                                  placeholder="Select Option"
                                >
                                  <Option value="Yes">Yes</Option>
                                  <Option value="No">No</Option>
                                </Select>
                              </Form.Item>

                              <Form.Item name={["cp3_26", "r2c2"]}>
                                <Input.TextArea
                                  rows={2}
                                  // onChange={(e) => setRemark(e.target.value)}
                                  placeholder="Name of the regulatory"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-26"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-27" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Hazard identification, risk assessment, and incident investigation`}
                          </span>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`The reporting organization shall report the following information for employees and for workers who are not employees but whose work and/or workplace is controlled by the organization:`}
                          </div>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <div
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[D]: A description of the processes used to investigate work-related incidents, including the processes to identify hazards and assess risks relating to the incidents, to determine
 corrective actions using the hierarchy of controls, and to determine improvements needed in the occupational health and safety management system.`}
                            </div>
                          </Col>
                          <Col span={16}>
                            <div style={{ display: "flex" }}>
                              <Form.Item name={["cp3_27", "r1c1"]}>
                                <Input.TextArea
                                  rows={2}
                                  // onChange={(e) => setRemark(e.target.value)}
                                  placeholder="Name of the regulatory"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-27"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-28" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Promotion of worker health`}
                          </span>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`The reporting organization shall report the following information for employees and for workers who are not employees but whose work and/or workplace is controlled by the organization:`}
                          </div>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <div
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`A description of any voluntary health promotion services and programs offered to workers to address major non-work-related health risks, including the specific health
 risks addressed, and how the organization facilitates workers’ access to these services and programs.`}
                            </div>
                          </Col>
                          <Col span={16}>
                            <div style={{ display: "flex" }}>
                              <Form.Item name={["cp3_28", "r1c1"]}>
                                <Input.TextArea
                                  rows={2}
                                  // onChange={(e) => setRemark(e.target.value)}
                                  placeholder="Name of the regulatory"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-28"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-29" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Work-related injuries`}
                          </span>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`The reporting organization shall report the following information:`}
                          </div>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <div
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[A]: Whether the rates have been calculated based on 200,000 or 1,000,000 hours worked.`}
                            </div>
                          </Col>
                          <Col span={16}>
                            <div style={{ display: "flex" }}>
                              <Form.Item name={["cp3_29", "r1c1"]}>
                                <Input.TextArea
                                  rows={2}
                                  // onChange={(e) => setRemark(e.target.value)}
                                  placeholder="Name of the regulatory"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <div
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[B]: Whether and, if so, why any workers have been excluded from this disclosure, including the types of worker excluded.`}
                            </div>
                          </Col>
                          <Col span={16}>
                            <div style={{ display: "flex" }}>
                              <Form.Item name={["cp3_29", "r2c1"]}>
                                <Input.TextArea
                                  rows={2}
                                  // onChange={(e) => setRemark(e.target.value)}
                                  placeholder="Name of the regulatory"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <div
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[C]: Any contextual information necessary to understand how the data have been compiled, such as any standards, methodologies, and assumptions used.`}
                            </div>
                          </Col>
                          <Col span={16}>
                            <div style={{ display: "flex" }}>
                              <Form.Item name={["cp3_29", "r3c1"]}>
                                <Input.TextArea
                                  rows={2}
                                  // onChange={(e) => setRemark(e.target.value)}
                                  placeholder="Name of the regulatory"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-29"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-30" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div>{`Work-related ill health`}</div>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`The reporting organization shall report the following information:`}
                          </div>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[A]: For all employees the main types of work-related ill health.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_30", "r1c1"]}>
                              <Input.TextArea
                                rows={2}
                                placeholder="Enter ill health"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={22}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[B]: For all workers who are not employees but whose work and/or workplace is controlled by the organization:`}
                            </span>
                          </Col>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[i]: The number of fatalities as a result of work-related ill health`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_30", "r2c1"]}>
                              <Input placeholder="Enter ill health" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[ii]: The main types of work-related ill health`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_30", "r3c1"]}>
                              <Input placeholder="Enter ill health" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              c. The work-related hazards that pose a risk of
                              ill health, including:
                              <br />
                              i. how these hazards have been determined;
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_30", "r4c1"]}>
                              <Input placeholder="Enter ill health" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              d. Whether and, if so, why any workers have been
                              excluded from this disclosure, including the types
                              of worker excluded.
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_30", "r5c1"]}>
                              <Input placeholder="Enter ill health" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              e. Any contextual information necessary to
                              understand how the data have been compiled, such
                              as any standards, methodologies, and assumptions
                              used.
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_30", "r6c1"]}>
                              <Input placeholder="Enter ill health" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-30"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-31" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Row style={{ width: "100%" }}>
                          <Col span={22}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {` Defined benefit plan obligations and other retirement plans`}
                            </span>
                          </Col>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`If the plan’s liabilities are met by the organization’s general resources, the estimated value of those liabilities.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_31", "r1c1"]}>
                              <Input.TextArea
                                rows={2}
                                placeholder="Enter ill health"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-31"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-32" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div>{`Financial assistance received from government`}</div>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: Total monetary value of financial assistance received by the organization from any government during the reporting period, including:`}
                          </div>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[i]: Tax relief and tax credits`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_32", "r1c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter tax credits"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[ii]: Subsidies`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_32", "r2c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter Subsidies"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[iii]: Investment grants, research and development grants, and other relevant types of grant`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_32", "r3c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter Investment grants"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[iv]: Awards`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_32", "r4c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter awards"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[v]: Royalty holidays`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_32", "r5c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter royalty holidays"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[vi]: Financial assistance from Export Credit Agencies (ECAs)`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_32", "r6c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter financial assistance"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[vii]: Financial incentives`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_32", "r7c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter financial incentives"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[viii]: Other financial benefits received or receivable from any government for any operation.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_32", "r8c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter Other financial benefits"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />

                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[B]: The information disclosed countrywise`}
                            </span>
                          </Col>

                          <Col span={16}>
                            <Form.Item name={["cp3_32", "r9c1"]}>
                              <Input.TextArea
                                rows={2}
                                placeholder="Enter countrywise"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[C]: Whether, and the extent to which, any government is present in the shareholding structure.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_32", "r10c1"]}>
                              <Input.TextArea
                                rows={2}
                                placeholder="Enter shareholding"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-32"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-33" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Occupational health services`}
                          </div>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`A description of the occupational health services’ functions that contribute to the identification and elimination of hazards and minimization of risks, and an explanation of how the organization ensures the quality of these services and facilitates workers’ access to them.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_33", "r1c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter occupational health"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-33"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-34" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Worker participation, consultation, and communication on occupational health and safety`}
                          </div>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[A]: A description of the processes for worker participation and consultation in the development, implementation, and evaluation of the occupational health and safety management system, and for providing access to and communicating relevant information on occupational health and safety to workers.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_34", "r1c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter worker participation"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[B]: Where formal joint management-worker health and safety committees exist, a description of their responsibilities, meeting frequency, decision-making authority, and whether and, if so, why any workers are not represented by these committees.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_34", "r2c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter management-worker"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-34"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-35" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Prevention and mitigation of occupational health and safety impacts directly linked by business relationships`}
                          </div>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`A description of the organization’s approach to preventing or mitigating significant negative occupational health and safety impacts that are directly linked to its operations, products, or services by its business relationships, and the related hazards and risks.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_35", "r1c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter organization"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-35"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-36" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div>{`Workers covered by an occupational health and safety management system`}</div>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: If the organization has implemented an occupational health and safety management system based on legal requirements and/or recognized standards/guidelines:`}
                          </div>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[i]: The number and percentage of all employees and workers who are not employees but whose work and/or workplace is controlled by the organization, who are covered by such a system`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_36", "r1c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter tax credits"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[ii]: The number and percentage of all employees and workers who are not employees but whose work and/or workplace is controlled by the organization, who are covered by such a system that has been internally audited`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_36", "r2c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter number and percentage"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[iii]: The number and percentage of all employees and workers who are not employees but whose work and/or workplace is controlled by the organization, who are covered by such a system that has been audited or certified by an external party.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_36", "r3c1"]}>
                              <Input
                                type="number"
                                min="0"
                                placeholder="Enter Investment grants"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />

                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[B]: Whether and, if so, why any workers have been excluded from this disclosure, including the types of worker excluded.`}
                            </span>
                          </Col>

                          <Col span={16}>
                            <div style={{ display: "flex" }}>
                              <Form.Item name={["cp3_36", "r4c1"]}>
                                <Select
                                  style={{
                                    width: "250px",
                                    marginRight: "10px",
                                  }}
                                  placeholder="Select Option"
                                >
                                  <Option value="Yes">Yes</Option>
                                  <Option value="No">No</Option>
                                </Select>
                              </Form.Item>
                              <Form.Item name={["cp3_36", "r4c2"]}>
                                <Input.TextArea
                                  rows={2}
                                  placeholder="Enter excluded worker"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[C]: Any contextual information necessary to understand how the data have been compiled, such as any standards, methodologies, and assumptions used.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_36", "r5c1"]}>
                              <Input.TextArea
                                rows={2}
                                placeholder="Enter contextual"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-36"}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP3-37" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div>{`Operations and suppliers in which the right to freedom of association and collective bargaining may be at risk`}</div>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: Operations and suppliers in which workers’ rights to exercise freedom of association or collective bargaining may be violated or at significant risk either in terms of:`}
                          </div>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[i]: Type of operation (such as manufacturing plant) and supplier`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_37", "r1c1"]}>
                              <Input.TextArea
                                rows={2}
                                placeholder="Enter tax credits"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[ii]: Countries or geographic areas with operations and suppliers considered at risk.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_37", "r2c1"]}>
                              <Input.TextArea
                                rows={2}
                                placeholder="Enter number and percentage"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              {`[B]: Measures taken by the organization in the reporting period intended to support rights to exercise freedom of association and collective bargaining`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["cp3_37", "r3c1"]}>
                              <Input.TextArea
                                rows={2}
                                placeholder="Enter number and percentage"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP3-37"}
                    />
                  </>
                )}
              </Card>
            )}
          </Space>

          {readonly ? (
            ""
          ) : (
            <Space
              style={{
                //marginTop: "10px",
                marginBottom: "10px",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <Button htmlType="submit">Save and Continue</Button>
            </Space>
          )}
        </Form>
      </div>
    </>
  );
};

export default Section_C;
