import React, { useEffect } from "react";
import { Row, Col, Input, Form, Divider, Select } from "antd";
import TextFieldToTextArea from "../../../../Components/Input/textfieldtoarea"

const { Option } = Select;
const CP2 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <Form.Item label="CP1-2" className="title-header">
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <span style={{ fontSize: "12px", fontWeight: "700" }}>
            {`Details of fines / penalties /punishment/ award/ compounding fees/
              settlement amount paid in proceedings (by the entity or by
              directors / KMPs) with regulators/ law enforcement agencies/
              judicial institutions, in the financial year, in the following
              format (Note: the entity shall make disclosures on the basis of
              materiality as specified in Regulation 30 of SEBI (Listing
              Obligations and Disclosure Obligations) Regulations, 2015 and as
              disclosed on the entity’s website):`}
          </span>
          <div id="tbl-cell">
            <Row>
              <Col span={12}>
                <span className="ant-col-header">Monetary</span>
              </Col>
            </Row>
            <Row>
              <Col span={4}></Col>
              <Col span={4}>
                <span className="ant-col-header">{`NGRBC Principle`}</span>
              </Col>
              <Col span={4}>
                <span className="ant-col-header">
                  {`Name of the regulatory/ enforcement agencies/judicial
                    institutions`}
                </span>
              </Col>
              <Col span={4}>
                <span className="ant-col-header">{`Amount (In INR)`}</span>
              </Col>
              <Col span={4}>
                <span className="ant-col-header">{`Brief of the Case`}</span>
              </Col>
              <Col span={4}>
                <span className="ant-col-header">
                  {`Has an appeal been preferred? (Yes/No)`}
                </span>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <span className="ant-col-header">{`Penalty/ Fine`}</span>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r1c1"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r1c2"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r1c3"]}>
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r1c4"]}>
                <TextFieldToTextArea props={props}/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r1c5"]}>
                  <Select placeholder="Select Option" disabled={props.readonly}>
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <span className="ant-col-header">Settlement</span>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r2c1"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r2c2"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r2c3"]}>
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r2c4"]}>
                <TextFieldToTextArea props={props}/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r2c5"]}>
                  <Select placeholder="Select Option" disabled={props.readonly}>
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <span className="ant-col-header">Compounding fee</span>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r3c1"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r3c2"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r3c3"]}>
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r3c4"]}>
                <TextFieldToTextArea props={props}/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r3c5"]}>
                  <Select placeholder="Select Option" disabled={props.readonly}>
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Divider />
          <div id="tbl-cell">
            <Row>
              <Col span={12}>
                <span className="ant-col-header">{`Non-monetary`}</span>
              </Col>
            </Row>
            <Row>
              <Col span={4}></Col>
              <Col span={4}>
                <span className="ant-col-header">{`NGRBC Principle`}</span>
              </Col>
              <Col span={4}>
                <span className="ant-col-header">
                  {`Name of the regulatory/ enforcement agencies/judicial
                      institutions`}
                </span>
              </Col>
              <Col span={4}>
                <span className="ant-col-header">{`Amount (In INR)`}</span>
              </Col>
              <Col span={4}>
                <span className="ant-col-header">{`Brief of the Case`}</span>
              </Col>
              <Col span={4}>
                <span className="ant-col-header">
                  {`Has an appeal been preferred? (Yes/No)`}
                </span>
              </Col>
            </Row>

            <Row>
              <Col span={4}>
                <span className="ant-col-header">Imprisonment</span>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r4c1"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r4c2"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r4c3"]}>
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r4c4"]}>
                  <Input.TextArea
                    rows={5}
                    size="small"
                    readOnly={props.readonly}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r4c5"]}>
                  <Select placeholder="Select Option" disabled={props.readonly}>
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <span className="ant-col-header">Punishment</span>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r5c1"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r5c2"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r5c3"]}>
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r5c4"]}>
                  <Input.TextArea
                    rows={5}
                    size="small"
                    readOnly={props.readonly}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r5c5"]}>
                  <Select placeholder="Select Option" disabled={props.readonly}>
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default CP2;
