import React, { useEffect } from "react";
import { Row, Col, Input, Form } from "antd";

const CP5Comp = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div>
      <Form.Item label="CP1-10" className="title-header">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <span style={{ fontSize: "12px", fontWeight: "700" }}>
              {`Awareness programmes conducted for value chain partners on any of the Principles during the financial year:`}
            </span>
            <div id="tbl-cell">
              <Row>
                <Col span={6}>
                  <span className="ant-col-header">
                    {`Total number of awareness programmes held`}
                  </span>
                </Col>
                <Col span={9} className="text-center">
                  <span className="ant-col-header">
                    {`Topics / principles covered under the training`}
                  </span>
                </Col>
                <Col span={9} className="text-center">
                  <span className="ant-col-header">
                    {`Percentage of value chain partners covered (by value of business done with such partners) under the awareness programmes`}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, "r1c1"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item name={[props.ques_id, "r1c2"]}>
                    <Input size="small" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item name={[props.ques_id, "r1c3"]}>
                    <Input
                      type="number" min="0"
                      size="small"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

export default CP5Comp;
