import React, { useState, useEffect } from "react";
import { Badge, Form, Card, Space, Divider, Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { fetchProfile } from "./userSlice";
import { useCustomer } from "../../hooks/useCustomer";

const Customers = () => {
  const dispatch = useDispatch();
  const { profile } = useCustomer();
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  const [customers_data, setCustomersData] = useState([]);

  useEffect(() => {
    dispatch(fetchProfile())
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setCustomersData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch]);
  return (
    <div className="formcontainer1">
      <div className="table-title-header-top">
        <h2>User Profile</h2>
      </div>
      <div>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Badge.Ribbon text={sessionState.roletype}>
            <Card title="Details" size="small">
              {/* <Row gutter={[16, 16]}>
                <Col span={5}>
                  <div className="title1">
                    <span>Customer Name</span>
                  </div>
                </Col>
                <Col span={10}>{customers_data?.display_name}</Col>
                <Col span={4}>
                  <div className="title1">
                    <span>Assigned Template</span>
                  </div>
                </Col>
                <Col span={5}>{profile?.msic?.assigned_template_name}</Col>
              </Row> */}
              <Row gutter={[16, 16]}>
                <Col span={5}>
                  <div className="title1">
                    <span>Communication email</span>
                  </div>
                </Col>
                <Col span={14}>{customers_data.email}</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={5}>
                  <div className="title1">
                    <span>Display Name</span>
                  </div>
                </Col>
                <Col span={14}>{customers_data.display_name}</Col>
              </Row>
              {customers_data?.meta_data?.employee_id && (
                <Row gutter={[16, 16]}>
                  <Col span={5}>
                    <div className="title1">
                      <span>Employee Id</span>
                    </div>
                  </Col>
                  <Col span={14}>{customers_data?.meta_data?.employee_id}</Col>
                </Row>
              )}
              {customers_data?.meta_data?.mobilenumber && (
                <Row gutter={[16, 16]}>
                  <Col span={5}>
                    <div className="title1">
                      <span>Mobile Number</span>
                    </div>
                  </Col>
                  <Col span={14}>{customers_data?.meta_data?.mobilenumber}</Col>
                </Row>
              )}
              {customers_data?.meta_data?.location && (
                <Row gutter={[16, 16]}>
                  <Col span={5}>
                    <div className="title1">
                      <span>Location</span>
                    </div>
                  </Col>
                  <Col span={14}>{customers_data?.meta_data?.location}</Col>
                </Row>
              )}
              {customers_data?.meta_data?.residential_address && (
                <Row gutter={[16, 16]}>
                  <Col span={5}>
                    <div className="title1">
                      <span>Residential Address</span>
                    </div>
                  </Col>
                  <Col span={14}>
                    {customers_data?.meta_data?.residential_address}
                  </Col>
                </Row>
              )}
              <Row gutter={[16, 16]}>
                <Col span={5}>
                  <div className="title1">
                    <span>Onboarding Date</span>
                  </div>
                </Col>
                <Col span={14}>{customers_data.created_date}</Col>
              </Row>
              <Divider />
              <Form
                name="validate_other"
                {...formItemLayout}
                //onFinish={onFinish}
                initialValues={{
                  'input-number': 3,
                  'checkbox-group': ['A', 'B'],
                  rate: 3.5,
                  'color-picker': null,
                }}
                style={{ maxWidth: 600 }}
              ></Form>
            </Card>
          </Badge.Ribbon>
        </Space>
      </div>
    </div>
  );
};
export default Customers;
