import React, { useEffect } from "react";
import { Row, Col, Form, Card, Space, Select } from "antd";

const { Option } = Select;
const Cont13 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div>
      <Space direction="vertical" size="middle">
        <Card title="Details" size="small">
          <div style={{ overflow: "scroll" }}>
            <Form.Item label="B10" className="title-header">
              <Row style={{ width: "300%" }}>
                <Col span={24}>
                  <span style={{ fontSize: "12px", fontWeight: "600" }}>
                    Details of review of NGRBCs by the company:
                  </span>
                  <div id="tbl-cell">
                    <Row>
                      <Col span={2}></Col>
                      <Col span={9} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          Indicate whether review was undertaken by
                          Director/Committee of the board/any other committee
                        </span>
                      </Col>
                      <Col span={9}>
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          Frequency (Annually/Half-yearly/Quarterly/Any
                          other-please specify)
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={2}>
                        <span
                          style={{ fontSize: "12px", fontWeight: "600" }}
                        ></span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P1
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P2
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P3
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P4
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P5
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P6
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P7
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P8
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P9
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P1
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P2
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P3
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P4
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P5
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P6
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P7
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P8
                        </span>
                      </Col>
                      <Col span={1} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          P9
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={2} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          Performance against above policies and follow up
                          action
                        </span>
                      </Col>

                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c1"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c2"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                             <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c3"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                             <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c4"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                             <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c5"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c6"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                             <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c7"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                             <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c8"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                             <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c9"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                             <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c10"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c11"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c12"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c13"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c14"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c15"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c16"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c17"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r1c18"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={2} className="text-center">
                        <span style={{ fontSize: "12px", fontWeight: "600" }}>
                          Compliance with statutory requirements of relevance to
                          the principles, and, rectification of any
                          non-compliances
                        </span>
                      </Col>

                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c1"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                             <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c2"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                             <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c3"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                             <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c4"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                             <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c5"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                             <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c6"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                             <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c7"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c8"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                             <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c9"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="Director"> Director</Option>
                            <Option value="Board_Committee">Board Committee</Option>
                            <Option value="Other_committee">Other committee</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c10"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c11"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c12"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c13"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c14"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c15"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c16"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c17"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Form.Item name={[props.ques_id, "r2c18"]}>
                          <Select
                            disabled={props.readonly}
                            placeholder="Select Option"
                          >
                            <Option value="annual">Annual</Option>
                            <Option value="half_yearly">Half Yearly</Option>
                            <Option value="quaterly">Quaterly</Option>
                            <Option value="any_other">Any other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </div>
        </Card>
      </Space>
    </div>
  );
};

export default Cont13;
