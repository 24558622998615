import React, { useState } from "react";
import {
  Badge,
  Form,
  Card,
  Select,
  Space,
  Divider,
  Row,
  Col,
  Button,
} from "antd";

const { Option } = Select;
const Customers = (props) => {
  const alldata = props.data.customers_data;
  const [client, setClient] = useState("Empty");
  const [rowdata, setRowdata] = useState(props.data.selectedcustomer);

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  function onChange(value) {
    var filterdata = alldata;
    var result = filterdata.filter(
      (customer) => customer.customer_id === value
    );
    const { company_name } = result[0];
    setClient(company_name !== "" ? company_name : "Empty");
    setRowdata(result[0]);
  }

  function onBlur() {
    //console.log("blur");
  }

  function onFocus() {
    //console.log("focus");
  }

  function onSearch(val) {
    //console.log("search:", val);
  }

  return (
    <div className="formcontainer1">
      <div className="table-title-header-top">
        <h2>Customer View Details</h2>
        <div>
          <span>Select Customer</span>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onSearch={onSearch}
          >
            {alldata.map((item) => {
              return (
                <>
                  <Option key={item.customer_id} value={item.customer_id}>
                    {item.company_name}
                  </Option>
                </>
              );
            })}
          </Select>
        </div>
      </div>
      <div>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Badge.Ribbon text={rowdata.company_name}>
            <Card title="Details" size="small">
              <Row gutter={[16, 16]}>
                <Col span={5}>
                  <div className="title1">
                    <span>Customer Name</span>
                  </div>
                </Col>
                <Col span={10}>{rowdata.company_name}</Col>
                <Col span={4}>
                  <div className="title1">
                    <span>Assigned Template</span>
                  </div>
                </Col>
                <Col span={5}>{rowdata.template_name}</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={5}>
                  <div className="title1">
                    <span>CIN</span>
                  </div>
                </Col>
                <Col span={14}>{rowdata.cin_number}</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={5}>
                  <div className="title1">
                    <span>Website</span>
                  </div>
                </Col>
                <Col span={14}>{rowdata.website}</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={5}>
                  <div className="title1">
                    <span>Sector</span>
                  </div>
                </Col>
                <Col span={14}>{rowdata.sector}</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={5}>
                  <div className="title1">
                    <span>Communication email</span>
                  </div>
                </Col>
                <Col span={14}>{rowdata.owner_email}</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={5}>
                  <div className="title1">
                    <span>Registered Address</span>
                  </div>
                </Col>
                <Col span={14}>{rowdata.registered_address}</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={5}>
                  <div className="title1">
                    <span>Expiry On</span>
                  </div>
                </Col>
                <Col span={14}>{rowdata.expired_date}</Col>
              </Row>
              <Divider />
              <Form
                name="validate_other"
                {...formItemLayout}
                //onFinish={onFinish}
                initialValues={{
                  'input-number': 3,
                  'checkbox-group': ['A', 'B'],
                  rate: 3.5,
                  'color-picker': null,
                }}
                style={{ maxWidth: 600 }}
              ></Form>
            </Card>
          </Badge.Ribbon>
          <Button type="link" href="/dashboard">
            Go Back
          </Button>
        </Space>
      </div>
    </div>
  );
};
export default Customers;
