import React, { useState, useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Button, Input, Form } from "antd";
import "./index.scss";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createsetup, fetchUserById } from "./setupSlice/setupSlice";
import logoPic from "../../static/images/nslogo-large.png";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    role: "",
    display_name: "",
    email: "",
    password: "",
    confirm_password: "",
    buttonText: "Submit",
  });

  useEffect(() => {
    loadProfile();
    dispatch(fetchUserById())
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          const { app_initialized } = res.data;
          if (app_initialized) {
            navigate("/");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, navigate]);

  const loadProfile = () => {
    setValues({
      ...values,
      role,
      email: "",
    });
  };

  const { role, display_name, email, password } = values;
  const handleCreateSetup = async (values) => {
    setLoading(true);
    try {
      let response = await dispatch(createsetup({ values }));
      if (!response === undefined) return;
      setLoading(false);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const renderSetup = () => (
    <div className="m-login-wrap">
      <div>
        <Link>
          <img src={logoPic} className="logo-large" alt="logo" />
        </Link>
      </div>

      <div className="m-login">
        <div className="m-login-title">
          Welcome to NesGauge
          <div className="subtitle">
            To start using this application we will need to setup the first
            admin of this application. Enter the following details to start
          </div>
        </div>
        <div className="m-login-form">
          <Form
            layout="vertical"
            className="loginform"
            name="normal_login"
            initialValues={{
              remember: true,
              display_name: display_name,
              email: email,
              password: password,
            }}
            onFinish={handleCreateSetup}
          >
            <Form.Item
              label="Display Name"
              name="display_name"
              rules={[
                {
                  type: "text",
                  required: true,
                  message: "Please input your display name!",
                },
              ]}
            >
              <Input
                className="m-login-input"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Display name"
                defaultValue={display_name}
              />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input
                className="m-login-input"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="email"
                defaultValue={email}
              />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Button htmlType="submit" block loading={loading}>
              {loading ? `Loading...` : `Sign Up`}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );

  return <>{renderSetup()}</>;
};

export default Login;
