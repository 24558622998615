import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  userResetPassword,
  reset2fa,
  customerReviewerMapper,
  updateCustomerReviewerMapper,
  getbackOfficeRoleList,
  getQuestionaireTemplate,
  getNotifications,
  updateNotifications,
  getTemplates,
} from "../Config/actions";

const updateUserResetPassword = createAsyncThunk(
  "backoffice/userResetPassword",
  async (value) => {
    return await userResetPassword(value);
  }
);

const updateReset2fa = createAsyncThunk(
  "backoffice/userResetPassword",
  async (value) => {
    return await reset2fa(value);
  }
);

const customerReviewerMapperSlice = createAsyncThunk(
  "backoffice/userResetPassword",
  async (value) => {
    return await customerReviewerMapper(value);
  }
);

const updateCustomerReviewerMapperSlice = createAsyncThunk(
  "customerreviewermapper/unassign",
  async (value) => {
    return await updateCustomerReviewerMapper(value);
  }
);

const getbackOfficeRoleListSlice = createAsyncThunk(
  "backoffice/userrolelist",
  async (value) => {
    return await getbackOfficeRoleList(value);
  }
);

const getQuestionaireTemplateSlice = createAsyncThunk(
  "backoffice/userrolelist",
  async (value) => {
    return await getQuestionaireTemplate(value);
  }
);

const getNotificationSlice = createAsyncThunk(
  "notification/list",
  async (value) => {
    return await getNotifications();
  }
);

const updateNotificationSlice = createAsyncThunk(
  "notification/update",
  async (value) => {
    return await updateNotifications(value);
  }
);

const getTemplateSlice = createAsyncThunk(
  "template/list",
  async (value) => {
    return await getTemplates();
  }
);

export {
  updateUserResetPassword,
  updateReset2fa,
  customerReviewerMapperSlice,
  getbackOfficeRoleListSlice,
  getQuestionaireTemplateSlice,
  getNotificationSlice,
  updateNotificationSlice,
  getTemplateSlice,
  updateCustomerReviewerMapperSlice
};
