import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Select } from "antd";
// import InputNumber from "../../../../../Components/Input/Number/number";
import * as common from "../../../../../util/common";
const { Option } = Select;
const T1 = (props) => {
  const [r13c1, setR13c1] = useState("No");
  const formula_defination = {
    r4c1: { type: "sum", cols: ["r1c1", "r2c1", "r3c1"] },
    r4c2: { type: "sum", cols: ["r1c2", "r2c2", "r3c2"] },
    r8c1: { type: "sum", cols: ["r5c1", "r6c1", "r7c1"] },
    r8c2: { type: "sum", cols: ["r5c2", "r6c2", "r7c2"] },
    r9c1: {
      type: "sum",
      cols: ["r1c1", "r2c1", "r3c1", "r5c1", "r6c1", "r7c1"],
    },
    r9c2: {
      type: "sum",
      cols: ["r1c2", "r2c2", "r3c2", "r5c2", "r6c2", "r7c2"],
    },
  };

  const handleChange = (e) => {
    common.arthiFunc(props.ques_id, formula_defination, props.form);

    if (props.ques_id == "CP6-1") {
      let dividend = parseFloat(props.form.getFieldValue([props.ques_id, "r9c1"]));
      let divisor = parseFloat(props.form.getFieldValue(["cp6_0", "r1c1"]));

      props.form.setFieldValue(
        [props.ques_id, "r10c1"],
        divisor === 0 ? 0 : Number(((dividend / divisor)).toFixed(2))
      );
    }
      
  };
  useEffect(() => {
    props.form.setFieldsValue(props.data);
    setR13c1(props.form.getFieldValue([props.ques_id, "r13c1"]));
  }, [props.ques_id, props.form, props.data]);
  return (
    <div>
      <Row>
        <Col span={24}>
          <div id="tbl-cell">
            <Row>
              <Col span={8}>Parameter</Col>
              <Col span={8} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                FY {props?.data?.current_fy} (Current Financial Year)
              </Col>
              <Col span={8} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                FY {props?.data?.previous_fy} (Previous Financial Year)
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{ textAlign: 'center', fontWeight: 'bold' }}
              >
                From renewable sources
              </Col>
            </Row>
            <Row>
              <Col span={8}>Total electricity consumption (A)</Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r1c1']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r1c2']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Total fuel consumption (B)</Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r2c1']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r2c2']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Energy consumption through other sources (C)</Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r3c1']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r3c2']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ fontWeight: 'bold' }}>
                Total energy consumed from renewable sources (A+B+C)
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r4c1']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    readonly={true}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r4c2']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    readonly={true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{ textAlign: 'center', fontWeight: 'bold' }}
              >
                From non-renewable sources
              </Col>
            </Row>
            <Row>
              <Col span={8}>Total electricity consumption (D)</Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r5c1']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r5c2']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Total fuel consumption (E)</Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r6c1']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r6c2']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Energy consumption through other sources (F)</Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r7c1']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r7c2']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ fontWeight: 'bold' }}>
                Total energy consumed from nonrenewable sources (D+E+F)
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r8c1']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    readonly={true}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r8c2']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    readonly={true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ fontWeight: 'bold' }}>
                Total energy consumed (A+B+C+D+E+F)
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r9c1']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    readonly={true}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r9c2']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    readonly={true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ fontWeight: 'bold' }}>
                Energy intensity per rupee of turnover (Total energy consumed /
                Revenue from operations) - gigajoules/crore
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r10c1']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r10c2']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ fontWeight: 'bold' }}>
                Energy intensity per rupee of turnover adjusted for Purchasing
                Power Parity (PPP) (Total energy consumed / Revenue from
                operations adjusted for PPP)
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r11c1']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r11c2']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{ textAlign: 'center', fontWeight: 'bold' }}
              >
                Energy intensity in terms of physical output
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                Energy intensity (optional) – the relevant metric may be
                selected by the entity
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r12c1']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, 'r12c2']} className="ant-col1">
                  <Input
                    type="number"
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                Indicate if any independent assessment/ evaluation/assurance has
                been carried out by an external agency? (Y/N) If yes, name of
                the external agency.
              </Col>
              <Col span={16}>
                <Row>
                  <Col span={4}>
                    <Form.Item
                      name={[props.ques_id, 'r13c1']}
                      noStyle
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                        disabled={props.readonly}
                        placeholder="Select Option"
                        onChange={(value) => setR13c1(value)}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {r13c1 === 'Yes' && (
                    <Col span={20}>
                      <Form.Item
                        name={[props.ques_id, 'r13c2']}
                        noStyle
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default T1;
