import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createadmin, getsetupState } from "../../../Config/actions";

const initialState = {
  loading: false,
  error: null,
  result: null,
};

const createsetup = createAsyncThunk("setup/createsetup", async (value) => {
  return await createadmin(value);
});
const fetchUserById = createAsyncThunk(
  "users/fetchUserById",
  async (userId, thunkAPI) => {
    const response = await getsetupState(userId);
    return response.data;
  }
);
const adminsetupSlice = createSlice({
  name: "setup",
  initialState,
  reducers: {
    [createsetup.fulfilled]: (state, action) => {
      state.loading = false;
      state.result = action.payload;
    },
    fetchUserById: (state, action) => {
      state.loading = false;
      state.result = action.payload;
    },
  },
});

const { reducer } = adminsetupSlice;
export { createsetup, fetchUserById };
export default reducer;
