import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form, Select } from "antd";
const { Option } = Select;
const T6 = (props) => {
  const [r8c1, setR8c1] = useState("No");
  useEffect(() => {
    props.form.setFieldsValue(props.data);
    setR8c1(props.form.getFieldValue([props.ques_id, "r8c1"]));
  }, [props.ques_id, props.form, props.data]);
  return (
    <div>
      <Form.Item label="" className="title-header">
        <Row>
          <Col span={24}>
            <div id="tbl-cell">
              <Row>
                <Col span={6}>Parameter</Col>
                <Col
                  span={6}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  Specify unit
                </Col>
                <Col
                  span={6}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  FY {props?.data?.current_fy} (Current Financial Year)
                </Col>
                <Col
                  span={6}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  FY {props?.data?.previous_fy} (Previous Financial Year)
                </Col>
              </Row>
              <Row>
                <Col span={6}>Nox</Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r1c1"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r1c2"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r1c3"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>Sox</Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r2c1"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r2c2"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r2c3"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>Particulate matter (PM) </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r3c1"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r3c2"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r3c3"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>Persistent organic pollutants (POP)</Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r4c1"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r4c2"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r4c3"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>Volatile organic compounds (VOC) </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r5c1"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r5c2"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r5c3"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>Hazardous air pollutants (HAP)</Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r6c1"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r6c2"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r6c3"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>Others – please specify (input text field) </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r7c1"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r7c2"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, "r7c3"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  Indicate if any independent assessment/ evaluation/assurance
                  has been carried out by an external agency? (Y/N) If yes, name
                  of the external agency.
                </Col>
                <Col span={16}>
                  <Row>
                    <Col span={4}>
                      <Form.Item
                        name={[props.ques_id, "r8c1"]}
                        noStyle
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Select
                          disabled={props.readonly}
                          placeholder="Select Option"
                          onChange={(value) => setR8c1(value)}
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {r8c1 === "Yes" && (
                      <Col span={20}>
                        <Form.Item
                          name={[props.ques_id, "r8c2"]}
                          noStyle
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input readOnly={props.readonly} placeholder="" />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

export default T6;
