import React, { useEffect } from "react";
import { Row, Col, Input, Form, Select } from "antd";
import CommentsApp from "../../../../Components/Comments";
import FloatInput from "../../../../Components/Input/numbertofloat";

const { Option } = Select;
const CP2_1 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <>
      <Form.Item label="CP2-1" className="title-header">
        <Row>
          <Col span={24}>
            <span style={{ fontSize: "12px", fontWeight: "700" }}>
              {`Percentage of R&D and capital expenditure (capex) investments in specific technologies to improve the environmental and social impacts of product and processes to total R&D and capex investments made by the entity, respectively.`}
            </span>
            <div id="tbl-cell">
              <Row>
                <Col span={6}></Col>
                <Col span={6}>
                  <span className="ant-col-header">
                    Current Financial Year :{props?.data?.current_fy}
                  </span>
                </Col>
                <Col span={6}>
                  <span className="ant-col-header">
                    Previous Financial Year :{props?.data?.previous_fy}
                  </span>
                </Col>
                <Col span={6}>
                  <span className="ant-col-header">
                    {`Details of improvements in environmental and social impacts`}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <span className="ant-col-header">{`R & D`}</span>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, "r1c1"]}>
                    {/* <Input
                      type="number" min="0"
                      size="small"
                      suffix="%"
                      readOnly={props.readonly}
                    /> */}
                    <FloatInput
                      size="small"
                      suffix="%"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, "r1c2"]}>
                    <FloatInput
                      size="small"
                      suffix="%"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, "r1c3"]}>
                    <Input size="small" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <span className="ant-col-header">{`Capex`}</span>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, "r2c1"]}>
                    <FloatInput
                      size="small"
                      suffix="%"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, "r2c2"]}>
                    <FloatInput
                      size="small"
                      suffix="%"
                      readOnly={props.readonly}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[props.ques_id, "r2c3"]}>
                    <Input size="small" readOnly={props.readonly} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    
    </>
  );
};

export default CP2_1;
