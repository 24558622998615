import React, { useEffect } from "react";
//import { useAuthState } from "../../Context";
import { Tooltip, Table } from "antd";
import "./dashboard.scss";
import Loader from "../../../Components/loader";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import CommonCompbar from "../../../Components/BaseLayout";
import { fetchReviewerCustomerUsersList } from "./reviewerSlice/reviewerSlice";

const Reviewers = (props) => {
  const location = useLocation();
  console.log(location.state);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [backoffice_data, setBackofficeData] = useState([]);

  const ReviewerUsers = () => {
    setLoader(true);
    dispatch(fetchReviewerCustomerUsersList())
      .unwrap()
      .then((res) => {
        setLoader(false);
        if (res.code === 0) {
          setBackofficeData(res.data);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  useEffect(() => {
    ReviewerUsers();
  }, []);
  const columns = [
    {
      title: "Name",
      dataIndex: "company_name",
      key: "company_name",
      render: (text) => <a onClick={showMsgModal}>{text}</a>,
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address ? "Active" : "Deactivated"}
        </Tooltip>
      ),
    },
    {
      title: "Customer id",
      dataIndex: "customer_id",
      key: "customer_id",
      ellipsis: {
        showTitle: false,
      },
      render: (customer_id) => (
        <Tooltip placement="topLeft" title={customer_id}>
          {customer_id}
        </Tooltip>
      ),
    },
  ];
  const showMsgModal = () => {};
  return (
    <div>
      <CommonCompbar sidebar={false}>
        <div className="formcontainer">
          <div className="table-title-header-top">
            <h2>Welcome !</h2>
          </div>
          <div>You are assigned to review the following customers</div>
          <Table
            columns={columns}
            bordered
            pagination={false}
            dataSource={backoffice_data}
            scroll={{
              y: 240,
            }}
          />
        </div>
      </CommonCompbar>
      <Loader flag={loader} />
    </div>
  );
};

export default Reviewers;
