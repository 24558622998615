import React, { useEffect } from "react";
import { Row, Col, Form } from "antd";
import InputNumber from "../../../../../Components/Input/Number/number";
import * as common from "../../../../../util/common";

const T1 = (props) => {
  const formula_defination = {
    r1c3: { type: "per", cols: ["r1c2", "r1c1"] },
    r1c6: { type: "per", cols: ["r1c5", "r1c4"] },
    r2c3: { type: "per", cols: ["r2c2", "r2c1"] },
    r2c6: { type: "per", cols: ["r2c5", "r2c4"] },
    r3c1: { type: "sum", cols: ["r1c1", "r2c1"] },
    r3c2: { type: "sum", cols: ["r1c2", "r2c2"] },
    r3c3: { type: "per", cols: ["r3c2", "r3c1"] },
    r3c4: { type: "sum", cols: ["r1c4", "r2c4"] },
    r3c5: { type: "sum", cols: ["r1c5", "r2c5"] },
    r3c6: { type: "per", cols: ["r3c5", "r3c4"] },
    r4c3: { type: "per", cols: ["r4c2", "r4c1"] },
    r4c6: { type: "per", cols: ["r4c5", "r4c4"] },
    r5c3: { type: "per", cols: ["r5c2", "r5c1"] },
    r5c6: { type: "per", cols: ["r5c5", "r5c4"] },
    r6c1: { type: "sum", cols: ["r4c1", "r5c1"] },
    r6c2: { type: "sum", cols: ["r4c2", "r5c2"] },
    r6c3: { type: "per", cols: ["r6c2", "r6c1"] },
    r6c4: { type: "sum", cols: ["r4c4", "r5c4"] },
    r6c5: { type: "sum", cols: ["r4c5", "r5c5"] },
    r6c6: { type: "per", cols: ["r6c5", "r6c4"] },
  };

  const handleChange = (e) => {
    common.arthiFunc(props.ques_id, formula_defination, props.form);
  };
  useEffect(() => {
    handleChange();
    props.form.setFieldsValue(props.data);
  }, [props.data]);
  return (
    <div>
      <Row>
        <Col span={24}>
          <div id="tbl-cell">
            <Row>
              <Col span={4}>Category</Col>
              <Col
                span={10}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                FY {props?.data?.current_fy} (Current Financial Year)
              </Col>
              <Col
                span={10}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                FY {props?.data?.previous_fy} (Previous Financial Year)
              </Col>
            </Row>
            <Row>
              <Col span={4}></Col>
              <Col span={3} style={{ textAlign: "center" }}>
                Total (A)
              </Col>
              <Col span={4} style={{ textAlign: "center" }}>
                No. of employees / workers covered (B)
              </Col>
              <Col span={3} style={{ textAlign: "center" }}>
                % (B / A)
              </Col>
              <Col span={3} style={{ textAlign: "center" }}>
                Total (C)
              </Col>
              <Col span={4} style={{ textAlign: "center" }}>
                No. of employees / workers covered (D)
              </Col>
              <Col span={3} style={{ textAlign: "center" }}>
                % (D / C)
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Employees
              </Col>
            </Row>
            <Row>
              <Col span={4}>Permanent</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r1c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter the details"
                    onChange={handleChange}
                    type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r1c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter the details"
                    onChange={handleChange}
                    type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r1c3"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true} type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r1c4"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                    type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r1c5"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r1c6"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Other than permanent</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r2c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r2c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r2c3"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r2c4"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r2c5"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r2c6"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Total Employees</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r3c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r3c2"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r3c3"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r3c4"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r3c5"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r3c6"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Workers
              </Col>
            </Row>
            <Row>
              <Col span={4}>Permanent</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r4c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r4c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r4c3"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r4c4"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r4c5"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r4c6"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Other than permanent</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r5c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r5c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r5c3"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r5c4"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r5c5"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                     type="number" min="0"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r5c6"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Total Employees</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r6c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r6c2"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true} type="number" min="0" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r6c3"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r6c4"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r6c5"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r6c6"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} readonly={true}  type="number" min="0"/>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default T1;
