import React, { useEffect, useRef, useState } from "react";
import { Layout, Menu, Avatar, Space, Badge, Popover, message } from "antd";
import { UserOutlined, BellOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ScrollBar from "../../../Components/Comments/scroll";
import { useDispatch } from "react-redux";
import { useAuthDispatch, logout } from "../../../Context";
import { readNotification } from "./Slice/notificationReadSlice";
import { useCustomer } from "../../../hooks/useCustomer";
import { getNotificationSlice } from "../../../Context/commonSlice";
import { get_comments_summary } from "../../BackofficeAdmin/Questionaire/SectionSlice/sectionSlice";
import "./index.scss";
import logoPic from "../../../static/images/nslogo.jpg";

const { Header } = Layout;
function Content() {
  const comp = useRef();
  const dispatch = useAuthDispatch();
  const handleLogout = (props) => {
    logout(dispatch);
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("curr_customer");
    window.location.href = "/";
  };
  return (
    <div className="content" style={{ maxHeight: 200 }} ref={comp}>
      <div className="profilefile">
        <Link to={"/profile"}>Profile</Link>
      </div>
      {/* <div className="profilefile">
        <Link to={"#"}>Settings</Link>
      </div>
      <div className="profilefile">
        <Link to={"#"}>Messages</Link>
      </div> */}
      <div className="profilefile">
        <span style={{ cursor: "pointer" }} onClick={handleLogout}>
          LogOut
        </span>
      </div>
    </div>
  );
}

function HeaderMenu(props) {
  const dispatch = useDispatch();
  const comp = useRef();
  const { notificationData, setNotificationData } = useCustomer();
  const [headerMenu, setHeaderMenu] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getNotificationSlice())
        .unwrap()
        .then((res) => {
          if (res.code === 0) {
            setNotificationData(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 5 * 60 * 1000); // 300000 ms = 5 minutes

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch, setNotificationData]);

  useEffect(
    (item) => {
      const role = localStorage.getItem("role");
      if (role === "1") {
        setHeaderMenu(props.navdata.backoffice);
      }
      if (role === "4" || role === "3") {
        setHeaderMenu(props.navdata.backoffice_reviewer);
      }
      if (role === "2") {
        setHeaderMenu(props.navdata.customer);
      }
    },
    [props]
  );
  useEffect(() => {
    dispatch(get_comments_summary())
      .unwrap()
      .then((res) => {
        //console.log('res.data');
      })
      .catch((Error) => {
        console.log(Error);
      });
    dispatch(getNotificationSlice())
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setNotificationData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, setNotificationData]);
  const handleReadComments = async (value) => {
    if (notificationData?.total_notifications === 0) {
      return;
    }
    try {
      setTimeout(() => {
        let response = dispatch(readNotification());
        if (response.payload !== undefined && response.payload.status === 200) {
          message.info(response.payload.data.data.msg);
        }
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  const leftMenu = [
    {
      icon: (
        <Space size={24}>
          <Popover
            content={
              <div className="content" style={{ maxHeight: 200 }} ref={comp}>
                {notificationData?.total_notifications > 0 ? (
                  notificationData?.summary.map((item, index) => {
                    return (
                      <ScrollBar>
                        <div className="profilefile">
                          <Link key={index} to={"#"}>
                            {item?.notification_message}
                          </Link>
                        </div>
                      </ScrollBar>
                    );
                  })
                ) : (
                  <ScrollBar>
                    <div className="profilefile">
                      <Link to={"#"}>Empty</Link>
                    </div>
                  </ScrollBar>
                )}
              </div>
            }
            title="Comments List"
            trigger="click"
          >
            <Badge count={notificationData?.total_notifications}>
              <Avatar
                className="nav-rightmenu"
                shape="square"
                icon={<BellOutlined onClick={handleReadComments} />}
              />
            </Badge>
          </Popover>
        </Space>
      ),
    },
    {
      icon: (
        <Space>
          <Popover
            content={<Content />}
            title={localStorage.getItem("user")}
            trigger="click"
          >
            <Badge size="small">
              <Avatar
                className="nav-rightmenu"
                shape="square"
                icon={<UserOutlined />}
              />
            </Badge>
          </Popover>
        </Space>
      ),
    },
  ];
  return (
    <Header className="nav-wrapper">
      <div>
        <Link>
          {" "}
          <img src={logoPic} className="logo" alt="logo" />
        </Link>
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "700px",
            justifyContent: "flex-start",
          }}
        >
          <Menu mode="horizontal" className="ant-navbar">
            {props.navdata &&
              headerMenu.map((item, index) => {
                return (
                  <Menu.Item key={index}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = item.path;
                      }}
                      icon={item.icon}
                    >
                      {item.label}
                    </Link>
                  </Menu.Item>
                );
              })}
          </Menu>
        </div>
        <ScrollBar>
          <Menu mode="horizontal" items={leftMenu} />
        </ScrollBar>
      </div>
    </Header>
  );
}

export default HeaderMenu;
