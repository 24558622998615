import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Table,
  Modal,
  message,
  Select,
  Row,
  Col,
  Popconfirm,
} from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;
const T23 = (props) => {
  const [form] = Form.useForm();
  const [modelOpen, setModelOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [c2, setC2] = useState("");
  const [c3, setC3] = useState("");
  const [c4, setC4] = useState("");
  const [c5, setC5] = useState("");
  const handleDelete = (key) => {
    const newData = dataSource.filter((item, index) => index !== key);
    setDataSource(newData);
    props.setdata(newData);
  };
  const Columns = [
    {
      title: 'SNo.',
      dataIndex: 'c1',
      render: (text, record, index) => index + 1,
    },
    {
      title:
        'Name of the holding/subsidiary/associate companies/joint ventures (a)',
      dataIndex: 'r1c1',
    },
    {
      title: 'Indicate whether holding/subsidiary/associate/joint venture',
      dataIndex: 'r1c2',
    },
    {
      title: 'Percentage of shares held by listed entity',
      dataIndex: 'r1c3',
    },
    {
      title:
        'Does the entity indicated in column (a) participate in the Business Responsibility initiatives of the listed entity',
      dataIndex: 'r1c4',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record, index) =>
        dataSource.length >= 1 ? (
          <>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(index)}
            >
              <Button className="ant-btn-link" type="link">
                <DeleteOutlined twoToneColor="#eb2f96" />
              </Button>
            </Popconfirm>
          </>
        ) : null,
    },
  ];

  useEffect(() => {
    setDataSource(props.data.a23 || []);
  }, [props.data]);

  const btnAdd = (values) => {
    const newData = {
      r1c1: c2,
      r1c2: c3,
      r1c3: c4,
      r1c4: c5,
    };
    let updatedData = [...dataSource, newData];
    setDataSource([...dataSource, newData]);
    props.setdata([...dataSource, newData]);
    message.success("Processing complete!");
    setModelOpen(false);
  };
  return (
    <div>
      <Row>
        <Col span={24} offset={22}>
          <span>
            <Button
              onClick={() => {
                setModelOpen(true);
                setC2('');
                setC3('');
                setC4('');
                setC5('');
                form.resetFields();
              }}
              disabled={props.readonly}
            >
              Add
            </Button>
          </span>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={Columns}
        pagination={false}
        className="f-table-wrapper"
        scroll={{
          y: 240,
        }}
      />

      <Modal
        title="Add"
        style={{ top: 20 }}
        open={modelOpen}
        onOk={btnAdd}
        okText="Add"
        onCancel={() => setModelOpen(false)}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 22 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <div className="modal-popup">
            <label>
              Name of the holding/subsidiary/associate companies/joint ventures
              (a)
            </label>
            <Form.Item name="c2">
              <TextArea rows={4} onChange={(e) => setC2(e.target.value)} />
            </Form.Item>
            <label>
              Indicate whether holding/subsidiary/associate/joint venture
            </label>
            <Form.Item name="c3">
              <Select
                placeholder="Select Option"
                onChange={(value) => {
                  setC3(value);
                }}
              >
                <Option value="holding">holding</Option>
                <Option value="subsidiary">subsidiary</Option>
                <Option value="associate">associate</Option>
                <Option value="joint venture">joint venture</Option>
              </Select>
            </Form.Item>
            <label>Percentage of shares held by listed entity</label>
            <Form.Item name="c4">
              <TextArea rows={4} onChange={(e) => setC4(e.target.value)} />
            </Form.Item>
            <label>
              Does the entity indicated in column (a) participate in the
              Business Responsibility initiatives of the listed entity
            </label>
            <Form.Item name="c5">
              <Select
                placeholder="Select Option"
                onChange={(value) => {
                  setC5(value);
                }}
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default T23;
