import React, { useEffect } from "react";
import { Row, Col, Form } from "antd";
import InputNumber from "../../../../../Components/Input/Number/number";

const T6 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div>
      <Row>
        <Col span={24}>
          <div id="tbl-cell">
            <Row>
              <Col span={4}>Category</Col>
              <Col span={10}>
                FY {props?.data?.current_fy} (Current Financial Year)
              </Col>
              <Col span={10}>
                FY {props?.data?.previous_fy} (Previous Financial Year)
              </Col>
            </Row>
            <Row>
              <Col span={4}></Col>
              <Col span={3}>Filed during the year </Col>
              <Col span={4}>Pending resolution at the end of year </Col>
              <Col span={3}>Remarks</Col>
              <Col span={3}>Filed during the year </Col>
              <Col span={4}>Pending resolution at the end of year </Col>
              <Col span={3}>Remarks</Col>
            </Row>
            <Row>
              <Col span={4}>Sexual Harassment</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r1c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r1c2"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r1c3"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r1c4"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r1c5"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r1c6"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Discrimination at workplace</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r2c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r2c2"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r2c3"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r2c4"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r2c5"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r2c6"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Child Labour</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r3c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r3c2"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r3c3"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r3c4"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r3c5"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r3c6"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Forced Labour/ Involuntary Labour</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r4c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r4c2"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r4c3"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r4c4"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r4c5"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r4c6"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Wages</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r5c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r5c2"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r5c3"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r5c4"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r5c5"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r5c6"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Other human rights related issues</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r6c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r6c2"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r6c3"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r6c4"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[props.ques_id, "r6c5"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r6c6"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default T6;
