import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Layout } from "antd";
//import { useAuthState, loginUser } from "../Context";
import Login from "../Pages/Login";
import Setup from "../Pages/Login/setup";
import Reset from "../Pages/Login/resetPassword";
import CustomerDetails from "../Pages/BackofficeAdmin/Customer-Details";

//import ILang from "../lg/LangType";
import Userlist from "../Pages/user";
import Reports from "../Pages/Reports";
import QRCode from "../Components/2FA/Person2FA";
import QuestBox from "../Pages/BackofficeAdmin/Questionaire";
import Ticketscomp from "../Pages/Tickets";
import BackOfficeUsers from "../Pages/BackofficeAdmin/backoffice-users";
import CreateCustomer from "../Pages/BackofficeAdmin/Customer-Details/create-customer/create_customer";
import CreateCustomerUser from "../Pages/BackofficeAdmin/Manage-user/create_customer_user";
import CustomerUser from "../Pages/BackofficeAdmin/Manage-user/";
import Reviewers from "../Pages/BackofficeAdmin/Reviewers";
import ReviewerUsers from "../Pages/BackofficeAdmin/Reviewers/manage_users";
import CustomerReviewerUsers from "../Pages/BackofficeAdmin/Reviewers/manage_customer_reviewer";
import CreateBackOfficeUser from "../Pages/BackofficeAdmin/backoffice-users/create_backoffice_user";
import CreateReviewerUser from "../Pages/BackofficeAdmin/Reviewers/create_reviewer_user";
import ForgotPassword from "../Pages/ForgotPassword";
import BckofficeReviewer from "../Pages/Back-Office-Reviewer/";
import { Main } from "../Pages/BackofficeAdmin/Questionaire/main";

const { Content, Footer } = Layout;

const Root = () => {
  return (
    <Router>
      <Layout className="site-layout">
        <Content className="ant-content">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/setup" exact element={<Setup />} />
            <Route path="/reset" exact element={<Reset />} />
            <Route path="/forgotpassword" exact element={<ForgotPassword />} />
            {/* ======Admin======= */}
            <Route path="/dashboard" exact element={<CustomerDetails />} />
            <Route path="/create" exact element={<CreateCustomer />} />
            <Route path="/managuser" exact element={<CustomerUser />} />
            <Route
              path="/createrevieweruser"
              exact
              element={<CreateReviewerUser />}
            />
            <Route
              path="/manage_reviewer"
              exact
              element={<CustomerReviewerUsers />}
            />
            {/* ===========Reviewer============== */}

            <Route
              path="/BckofficeReviewer"
              exact
              element={<BckofficeReviewer />}
            />

            {/* ============================== */}
            <Route path="/questionaire" exact element={<QuestBox />} />
            <Route path="/questionaire/main" exact element={<Main />} />
            <Route path="/reports" exact element={<Reports />} />
            <Route path="/tickets" exact element={<Ticketscomp />} />
            <Route
              path="/backoffice/users"
              exact
              element={<BackOfficeUsers />}
            />
            <Route path="/reviewers/users" exact element={<ReviewerUsers />} />
            <Route path="/reviewers" exact element={<Reviewers />} />
            {/* <Route path="/qrauth" exact element={<QRCode />} /> */}
            <Route path="/profile" exact element={<Userlist />} />
            {/* ============= */}
            <Route
              path="/createcustomeruser"
              exact
              element={<CreateCustomerUser />}
            />
            <Route
              path="/createbackofficeuser"
              exact
              element={<CreateBackOfficeUser />}
            />
          </Routes>
        </Content>
      </Layout>
    </Router>
  );
};

export default Root;
