import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Table,
  Row,
  Col,
  Modal,
  message,
} from 'antd';
import { DeleteOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const App = (props) => {
  const [form] = Form.useForm();
  const [modelOpen, setModelOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [authority, setAuthority] = useState("");
  const [action_taken, setAction_taken] = useState("");
  const [brief_case, setBrief_case] = useState("");

  //props.handleAdd1(dataSource);
  const handleDelete = (key) => {
    const newData = dataSource.filter((item, index) => index !== key);
    setDataSource(newData);
    props.setdata(newData);
    message.success('Row deleted!');
  };

  const Columns = [
    {
      title: 'SNo.',
      dataIndex: 'key',
      width: '10%',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Name of authority',
      dataIndex: 'r1c1',
      //width: "30%",
      editable: true,
    },
    {
      title: 'Brief of the case',
      dataIndex: 'r1c2',
    },
    {
      title: 'Corrective action taken',
      dataIndex: 'r1c3',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record, index) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(index)}
          >
            <Button className="ant-btn-link" type="link">
              <DeleteOutlined twoToneColor="#eb2f96" />
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  useEffect(() => {
    setDataSource(props.data.cp7_2 || []);
  }, [props.data]);

  const btnAdd = (values) => {
    const newData = {
      r1c1: authority,
      r1c2: brief_case,
      r1c3: action_taken,
    };
    setDataSource([...dataSource, newData]);
    props.setdata([...dataSource, newData]);
    message.success('Processing complete!');
    setModelOpen(false);
  };
  return (
    <div>
      <Row>
        <Col span={24} offset={22}>
          <span>
            <Button
              onClick={() => {
                setModelOpen(true);
                setAuthority('');
                setBrief_case('');
                setAction_taken('');
                form.resetFields();
              }}
              disabled={props.readonly}
            >
              Add
            </Button>
          </span>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={Columns}
        pagination={false}
        className="f-table-wrapper"
        scroll={{
          y: 240,
        }}
      />

      <Modal
        title="Add"
        style={{ top: 20 }}
        open={modelOpen}
        onOk={btnAdd}
        okText="Add"
        onCancel={() => setModelOpen(false)}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 22 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <div className="modalform">
            <label>Name of authority</label>
            <Form.Item name="r1c1">
              <Input
                readOnly={props.readonly}
                onChange={(e) => setAuthority(e.target.value)}
                placeholder="Name of authority"
              />
            </Form.Item>
            <label>Brief of the case</label>
            <Form.Item name="r1c2">
              <TextArea
                rows={4}
                readOnly={props.readonly}
                onChange={(e) => setBrief_case(e.target.value)}
                placeholder="Enter Brief of the case"
              />
            </Form.Item>
            <label>Corrective action taken</label>
            <Form.Item name="r1c3">
              <TextArea
                rows={4}
                readOnly={props.readonly}
                onChange={(e) => setAction_taken(e.target.value)}
                placeholder="Corrective action taken"
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default App;
