import React, { useEffect, useState } from "react";
import {
  Button,
  InputNumber,
  message,
  Divider,
  Card,
  Row,
  Col,
  Input,
  Spin,
  Select,
  Space,
  Form,
  Typography,
} from "antd";
import { useDispatch } from "react-redux";
import {
  create_section_slice,
  get_section,
} from "../SectionSlice/sectionSlice";
import { LoadingOutlined } from "@ant-design/icons";
import FloatInput from "../../../../Components/Input/numbertofloat";
import { useFinancialYear } from "../../../../hooks/useFinancialYear";
import { useCustomer } from "../../../../hooks/useCustomer";
import CommentsApp from "../../../../Components/Comments";
import { messageAlert } from "../../../../Components/Alert";
import T16 from "./Tables/t16";
import T17 from "./Tables/t17";
import T26 from "./Tables/t26";
import T18 from "./Tables/t18";
import T19 from "./Tables/t19";
import T20A from "./Tables/t20a";
import T21 from "./Tables/t21";
import T22 from "./Tables/t22";
import T23 from "./Tables/t23";
import T25 from "./Tables/t25";
import CommentsNotified from "../../../../Components/Comments/comments-notification";

const { Text } = Typography;
const { Option } = Select;
const App = (props) => {
  const { current_fy, previous_fy, previous_previous_fy } = useFinancialYear();
  const { profile, assigned_template_id, questionaire_status } = useCustomer();
  const [a10value, setA10] = useState(true);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [spinning, setSpinning] = useState(false);
  const [defaultdata, setDefaultdata] = useState({});
  const [readonly, setReadonly] = useState(false);
  const [commentlist, setCommentlist] = useState([]);
  const [commentreload, setCommentReload] = useState();
  const [a16, setA16] = useState([]);
  const [a17, setA17] = useState([]);
  const [a23, setA23] = useState([]);
  const [a26, setA26] = useState([]);
  const [a40, setA40] = useState("");
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );

  useEffect(() => {
    setDefaultdata({});
    let payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_A",
    };
    setSpinning(true);
    dispatch(get_section({ payload }))
      .unwrap()
      .then((res) => {
        const section_readonly = {
          a1: { r1c1: localStorage.getItem("cin_number") },
          a2: { r1c1: localStorage.getItem("company_name") },
          a3: { r1c1: localStorage.getItem("year_of_incorporation") },
          a4: { r1c1: localStorage.getItem("registered_address") },
          a5: { r1c1: localStorage.getItem("registered_address") },
          a6: { r1c1: localStorage.getItem("company_email") },
          a7: { r1c1: localStorage.getItem("company_phone") },
          a8: { r1c1: localStorage.getItem("website") },
          a9: { r1c1: current_fy },
        };
        if (res.code === 0) {
          if (sessionState?.roletype === "CUSTOMER") {
            setDefaultdata({ ...res.data.section_A, ...section_readonly });
            setCommentlist(res.data?.comments);
            if (questionaire_status === "Reviewed") {
              setReadonly(true);
            } else {
              setReadonly(false);
            }
          } else {
            setDefaultdata({ ...res.data.section_A });
            setCommentlist(res.data?.comments);
            setReadonly(true);
          }
          setSpinning(false);
        } else {
          form.resetFields();
          setSpinning(false);
          setDefaultdata(section_readonly);
          setCommentlist(res.data?.comments);
          if (res.code != 800000) {
            messageAlert({ title: "Alert!", msg: res.msg });
          }
        }
      })
      .catch((Error) => {
        console.log(Error);
      });
  }, [commentreload]);

  useEffect(() => {
    form.setFieldsValue(defaultdata);
    setA16(defaultdata.a16 || []);
    setA17(defaultdata.a17 || []);
    setA23(defaultdata.a23 || []);
    setA26(defaultdata.a26 || []);
    setA40(defaultdata.a40 || "");
  }, [form, defaultdata]);

  const onFinish = (datalist) => {
    setSpinning(true);
    const datalist1 = {
      ...datalist,
      a16,
      a17,
      a23,
      a26,
    };
    let Payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_A",
      question_response: datalist1,
    };
    dispatch(create_section_slice({ Payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setTimeout(() => {
            setSpinning(false);
            message.info("Section A created succesfully!");
          }, 3000);
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };
  const handleSelectA10 = (value) => {
    setA10(value.toUpperCase() === "YES" ? false : true);
  };
  return (
    <>
      <CommentsNotified commentlist={commentlist} />
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 44, color: "white" }} spin />
        }
        tip="Loading..."
        spinning={spinning}
        fullscreen
        size="large"
      />
      <div className="formcontainer">
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={defaultdata}
          scrollToFirstError
          style={{ width: "100%" }}
        >
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Card title="[I]: Details of the entity" size="small">
              <Form.Item label="A1" className="title-header">
                <Row>
                  <Col span={8}>
                    <span>CIN</span>
                  </Col>
                  <Col span={14}>
                    <Form.Item name={["a1", "r1c1"]}>
                      <Input readOnly={true} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Divider />
              <Form.Item label="A2" className="title-header">
                <Row>
                  <Col span={8}>
                    <span>
                      Name of the
                      company/Proprietorship/Partnership/Trustees/Others
                    </span>
                  </Col>
                  <Col span={14}>
                    <Form.Item name={["a2", "r1c1"]}>
                      <Input readOnly={true} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Divider />
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="A3" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>Year of incorporation</span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a3", "r1c1"]}>
                          <Input readOnly={true} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="A4" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>Registered Office Address</span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a4", "r1c1"]}>
                          <Input readOnly={true} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="A5" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>Corporate Address</span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a5", "r1c1"]}>
                          <Input readOnly={true} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="A6" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span>Email</span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a6", "r1c1"]}>
                          <Input type="email" readOnly={true} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="A7" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span>Telephone</span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a7", "r1c1"]}>
                          <Input readOnly={true} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="A8" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>Website</span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a8", "r1c1"]}>
                          <Input readOnly={true} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="A9" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span>
                          Financial year for which reporting is being done
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a9", "r1c1"]}>
                          <Input readOnly={true} value={current_fy} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="A10" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span>
                          Whether company is listed in recognised Stock exchange
                        </span>
                      </Col>
                      <Col span={14}>
                        <div style={{ display: "flex" }}>
                          <Form.Item name={["a10", "r1c1"]}>
                            <Select
                              onChange={(value) => handleSelectA10(value)}
                              disabled={readonly}
                              style={{ width: "200px", marginRight: "10px" }}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                          <Form.Item name={["a10", "r1c2"]}>
                            <Select
                              placeholder="Select Option"
                              disabled={a10value}
                            >
                              <Option value="NSE">NSE</Option>
                              <Option value="BSE">BSE</Option>
                              <Option value="Both">Both</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A10"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="A11" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>Paid up capital in crores INR</span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a11", "r1c1"]}>
                          <FloatInput
                            readOnly={readonly}
                            type="number"
                            placeholder="Enter Paid up capital"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A11"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="A12" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          Name and contact details of the person to contact in
                          case of queries on BRSR report
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a12", "r1c1"]}>
                          <Input.TextArea rows={4} readOnly={readonly} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A12"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="A13" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          Reporting boundary - Are the disclosures under this
                          report made on a standalone basis (i.e. only for the
                          entity) or on a consolidated basis (i.e. for the
                          entity and all the entities which form a part of its
                          consolidated financial statements, taken together).
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a13", "r1c1"]}>
                          <Input.TextArea rows={6} readOnly={readonly} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A13"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="A14" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span>Name of assurance provider</span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a14", "r1c1"]}>
                          <Input
                            placeholder="Enter assurance provider"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A14"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="A15" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span>Type of assurance obtained</span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a15", "r1c1"]}>
                          <Select placeholder="Select Option">
                            <Option value="Limited Assurance">
                              Limited Assurance
                            </Option>
                            <Option value="Reasonable Assurance">
                              Reasonable Assurance
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A15"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              )}
            </Card>
          </Space>
          {[1, 2, 3, 4].includes(assigned_template_id) && (
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Card title="[II]: Products/Services" size="small">
                <>
                  <Form.Item label="A16" className="title-header">
                    <Row>
                      <Col span={22}>
                        <div className="space-between">
                          <span>
                            Details of the business activity (accounting for 90%
                            of turnover)
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T16
                          readonly={readonly}
                          data={{ a16 }}
                          setdata={setA16}
                          form={form}
                          ques_id={"A-16"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A16"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>

                <>
                  <Form.Item label="A17" className="title-header">
                    <Row>
                      <Col span={22}>
                        <div className="space-between">
                          <span>
                            Products/Services sold by the entity (accounting for
                            90% of turnover)
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T17
                          readonly={readonly}
                          data={{ a17 }}
                          setdata={setA17}
                          form={form}
                          ques_id={"A-17"}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <a
                        href="https://www.ncs.gov.in/Documents/NIC_Sector.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        National Industrial Classification Reference
                      </a>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A17"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            </Space>
          )}
          {[1, 2, 3, 4].includes(assigned_template_id) && (
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Card title="[III]: Operations" size="small">
                <>
                  <Form.Item label="A18" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={22}>
                        <span>
                          Number of locations where plants and/or
                          operations/offices of the entity are situated:
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T18
                          readonly={readonly}
                          data={{ ...defaultdata }}
                          form={form}
                          ques_id={"a18"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A18"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>

                <>
                  <Form.Item label="A19" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={22}>
                        <span>Markets served by the entity:</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T19
                          data={{ ...defaultdata }}
                          form={form}
                          ques_id={"a19"}
                          readonly={readonly}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A19"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            </Space>
          )}
          {[1, 2, 3, 4].includes(assigned_template_id) && (
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Card title="[IV]: Employees" size="small">
                <>
                  <Form.Item label="A20" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={22}>
                        <span>Details at the end of financial year</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <span>
                          {`a. Employees and workers (including differently abled)`}
                        </span>
                        <T20A
                          readonly={readonly}
                          data={{ ...defaultdata }}
                          form={form}
                          ques_id={"a20a"}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <span>{`b. Differently abled employees and workers`}</span>
                        <T20A
                          readonly={readonly}
                          data={{ ...defaultdata }}
                          form={form}
                          ques_id={"a20b"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A20"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>

                <>
                  <Form.Item label="A21" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={22}>
                        <span>
                          Participation/inclusion/representation of women
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T21
                          readonly={readonly}
                          data={{ ...defaultdata }}
                          form={form}
                          ques_id={"a21"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A21"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>

                <>
                  <Form.Item label="A22" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={22}>
                        <span>
                          Turnover rate for permanent employees and workers
                          (Disclose trends for past 3 years in percentage)
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T22
                          readonly={readonly}
                          data={{
                            current_fy,
                            previous_fy,
                            previous_previous_fy,
                            ...defaultdata,
                          }}
                          form={form}
                          ques_id={"a22"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A22"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            </Space>
          )}
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[3, 4].includes(assigned_template_id) && (
              <Card
                title="[V]: Holding, Subsidiary and associate companies (including joint ventures)"
                size="small"
              >
                <>
                  <Form.Item label="A23" className="title-header">
                    <Row>
                      <Col span={22}>
                        <div className="space-between">
                          <span>
                            Names of holding/subsidiary/associate
                            companies/joint ventures
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T23
                          readonly={readonly}
                          data={{ a23 }}
                          setdata={setA23}
                          form={form}
                          ques_id={"A-23"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A23"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            )}
          </Space>

          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[3, 4].includes(assigned_template_id) && (
              <Card title="[VI]: CSR Details" size="small">
                <>
                  <Form.Item label="A24" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span>
                          a. Whether CSR is applicable as per section 135 of
                          Companies Act, 2013:
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a24", "r1c1"]}>
                          <Select
                            disabled={readonly}
                            style={{ width: "200px", marginRight: "10px" }}
                            placeholder="Select Option"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={8}>
                        <span>b. Turnover in rupees</span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a24", "r2c1"]}>
                          <InputNumber
                            placeholder="Enter"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={8}>
                        <span>c. Net worth in rupees</span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a24", "r3c1"]}>
                          <InputNumber
                            placeholder="Enter"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A24"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            )}
          </Space>
          {[1, 2, 3, 4].includes(assigned_template_id) && (
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Card
                title="[VII]: Transparency and disclosure compliances"
                size="small"
              >
                <>
                  <Form.Item label="A25" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={22}>
                        <span>
                          Complaints/Grievances on any of the principles (P1-P9)
                          under the national guidelines on responsible business
                          conduct
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T25
                          readonly={readonly}
                          data={{
                            current_fy,
                            previous_fy,
                            previous_previous_fy,
                            ...defaultdata,
                          }}
                          form={form}
                          ques_id={"a25"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A25"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            </Space>
          )}
          {[1, 2, 3, 4].includes(assigned_template_id) && (
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Card title="[II]: Products/Services" size="small">
                <>
                  <Form.Item label="A26" className="title-header">
                    <Row>
                      <Col span={22}>
                        <div className="space-between">
                          <span>
                            Please indicate material responsible business
                            conduct and sustainability issues pertaining to
                            environmental and social matters that present a risk
                            or an opportunity to your business, rationale for
                            identifying the same, approach to adapt or mitigate
                            the risk alongwith its financial implications as per
                            the following format.
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <T26
                          readonly={readonly}
                          data={{ a26 }}
                          setdata={setA26}
                          form={form}
                          ques_id={"A-26"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A26"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            </Space>
          )}
          {/* END OF LITE/EXTENDED */}
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[4].includes(assigned_template_id) && (
              <Card title="[I]: Details of the entity" size="small">
                <>
                  <Form.Item label="A27" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span>Nature of ownership and legal form</span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a27", "r1c1"]}>
                          <Input placeholder="Enter" readOnly={readonly} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A27"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                  <Form.Item label="A28" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span>
                          Publication date of the report or reported information
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a28", "r1c1"]}>
                          <Input
                            type="Date"
                            placeholder="Input Option"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A28"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            )}
          </Space>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[4].includes(assigned_template_id) && (
              <Card title="[II]: Products/Services" size="small">
                <>
                  <Form.Item label="A29" className="title-header">
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          Sector in which the company is engaged
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a29", "r1c1"]}>
                          <Select
                            disabled={readonly}
                            style={{ width: "200px", marginRight: "10px" }}
                            placeholder="Select Option"
                          >
                            <Option value="Section-A Agriculture, Forestry and Fishing">
                              Section-A Agriculture, Forestry and Fishing
                            </Option>
                            <Option value="Section-B Mining and Quarrying">
                              Section-B Mining and Quarrying
                            </Option>
                            <Option value="Section-C Manufacturing">
                              Section-C Manufacturing
                            </Option>
                            <Option value="Section-D Electricity, gas, steam and air conditoning supply">
                              Section-D Electricity, gas, steam and air
                              conditoning supply
                            </Option>
                            <Option value="Section-E Water supply, sewarage, waste management and remediation activities">
                              Section-E Water supply, sewarage, waste management
                              and remediation activities
                            </Option>
                            <Option value="Section-F Construction">
                              Section-F Construction
                            </Option>
                            <Option value="Section-G Wholesale and retail trade;repair of motor vehicles">
                              Section-G Wholesale and retail trade;repair of
                              motor vehicles
                            </Option>
                            <Option value="Section-H Transportation and Storage">
                              Section-H Transportation and Storage
                            </Option>
                            <Option value="Section-I Accomodation and Food services">
                              Section-I Accomodation and Food services
                            </Option>
                            <Option value="Section-J Information and Communication">
                              Section-J Information and Communication
                            </Option>
                            <Option value="Section-K Financial and Insurance activities">
                              Section-K Financial and Insurance activities
                            </Option>
                            <Option value="Section-L Real estate activities">
                              Section-L Real estate activities
                            </Option>
                            <Option value="Section-M Professional scientific and technical activites">
                              Section-M Professional scientific and technical
                              activites
                            </Option>
                            <Option value="Section-N Administrative and support service activities">
                              Section-N Administrative and support service
                              activities
                            </Option>
                            <Option value="Section-O Public administration and defence; compulsory social security">
                              Section-O Public administration and defence;
                              compulsory social security
                            </Option>
                            <Option value="Section-P Education">
                              Section-P Education
                            </Option>
                            <Option value="Section-Q Human health and social work activities">
                              Section-Q Human health and social work activities
                            </Option>
                            <Option value="Section-R Arts, entertainment and recreation">
                              Section-R Arts, entertainment and recreation
                            </Option>
                            <Option value="Section-S Other service activities">
                              Section-S Other service activities
                            </Option>
                            <Option value="Section-T Activities of households as employers; undifferentiated goods- andservices producing activities of households for own use">
                              Section-T Activities of households as employers;
                              undifferentiated goods- andservices producing
                              activities of households for own use
                            </Option>
                            <Option value="Section-U Activities of extraterritorial organizations and bodies">
                              Section-U Activities of extraterritorial
                              organizations and bodies
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A29"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            )}
          </Space>

          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[4].includes(assigned_template_id) && (
              <Card title="[III]: Operations" size="small">
                <>
                  <Form.Item label="A30" className="title-header">
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          Report relevant business relationships
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a30", "r1c1"]}>
                          <Input
                            placeholder="Enter Report"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A30"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>
                {/* {[4].includes(assigned_template_id) && ( */}
                <>
                  <Form.Item label="A31" className="title-header">
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          Significant changes to the organization’s size,
                          structure, ownership, or supply chain, including:{" "}
                        </div>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          [A] : Changes in the location of, or changes in,
                          operations, including facility openings, closings, and
                          expansions
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a31", "r2c1"]}>
                          <Input placeholder="Enter" readOnly={readonly} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          [B] : Changes in the share capital structure and other
                          capital formation, maintenance, and alteration
                          operations (for private sector organizations);
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a31", "r3c1"]}>
                          <InputNumber
                            placeholder="Enter"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          [C] : Changes in the location of suppliers, the
                          structure of the supply chain, or relationships with
                          suppliers, including selection and termination.
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a31", "r4c1"]}>
                          <Input placeholder="Enter" readOnly={readonly} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A31"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            )}
          </Space>
          {[4].includes(assigned_template_id) && (
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Card title="[IV]: Employees" size="small">
                <>
                  <Form.Item label="A32" className="title-header">
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          Report A20a based on region
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a32", "r1c1"]}>
                          <Input
                            placeholder="Enter Report"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A32"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>

                <>
                  <Form.Item label="A33" className="title-header">
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          [C] : Describe the methodologies and assumptions used
                          to compile the data, including whether the numbers are
                          reported:
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a33", "r1c1"]}>
                          <Input.TextArea
                            rows={4}
                            placeholder="Enter Paid up capital"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          [i] : In head count, full-time equivalent (FTE), or
                          using another methodology.
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a33", "r2c1"]}>
                          <Input.TextArea
                            rows={4}
                            placeholder="Enter head count"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          [ii] : At the end of the reporting period, as an
                          average across the reporting period, or using another
                          methodology.
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a33", "r3c1"]}>
                          <Input.TextArea
                            rows={4}
                            placeholder="Enter reporting period"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A33"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>

                <>
                  <Form.Item label="A34" className="title-header">
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          Any contexual information avaialble to understand A20
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a34", "r1c1"]}>
                          <Input.TextArea
                            rows={4}
                            placeholder="Enter Paid up capital"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A34"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>

                <>
                  <Form.Item label="A35" className="title-header">
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          describe significant fluctuations in the number of
                          employees during the reporting period and between
                          reporting periods.
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a35", "r1c1"]}>
                          <Input.TextArea
                            rows={4}
                            placeholder="Enter Paid up capital"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A35"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />

                  <Form.Item label="A36" className="title-header">
                    {/* <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          Percentage of individuals within the organization’s
                          governance bodies in each of the following diversity
                          categories:
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a36", "r1c1"]}>
                          <Input readOnly={true} size="small" suffix="%" />
                        </Form.Item>
                      </Col>
                    </Row> */}
                    <Divider />
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          [i] : Age group: under 30 years old, 30-50 years old,
                          over 50 years old.
                        </div>
                      </Col>
                      <Col span={14}>
                        <div style={{ display: "flex" }}>
                          <Form.Item
                            name={["a36", "r2c1"]}
                            style={{
                              width: "calc(50% - 8px)",
                            }}
                            rules={[
                              {
                                type: "number",
                                min: 0,
                                max: 100,
                                message: "$Must be between ${min} and ${max}",
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="Enter age under 30 years"
                              readOnly={readonly}
                            />
                          </Form.Item>

                          <Form.Item
                            name={["a36", "r2c2"]}
                            style={{
                              width: "calc(50% - 8px)",
                              margin: "0 8px",
                            }}
                            rules={[
                              {
                                type: "number",
                                min: 0,
                                max: 100,
                                message: "$Must be between ${min} and ${max}",
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="Enter age under 30-50 years"
                              readOnly={readonly}
                            />
                          </Form.Item>

                          <Form.Item
                            name={["a36", "r2c3"]}
                            style={{ width: "calc(50% - 8px)" }}
                            rules={[
                              {
                                type: "number",
                                min: 0,
                                max: 100,
                                message: "$Must be between ${min} and ${max}",
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="Enter age under 50 years"
                              readOnly={readonly}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          [ii] : Other indicators of diversity where relevant
                          (such as minority or vulnerable groups).
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a36", "r3c1"]}>
                          <Input.TextArea
                            rows={4}
                            placeholder="Enter reporting period"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          [B] : Percentage of employees Age group: under 30
                          years old, 30-50 years old, over 50 years old.
                        </div>
                      </Col>
                      <Col span={14}>
                        <div style={{ display: "flex" }}>
                          <Form.Item
                            name={["a36", "r3c2"]}
                            style={{
                              width: "calc(50% - 8px)",
                            }}
                          >
                            <Input
                              placeholder="Enter details"
                              readOnly={readonly}
                            />
                          </Form.Item>

                          <Form.Item
                            name={["a36", "r3c3"]}
                            style={{
                              width: "calc(50% - 8px)",
                              margin: "0 8px",
                            }}
                          >
                            <Input
                              placeholder="Enter details"
                              readOnly={readonly}
                            />
                          </Form.Item>

                          <Form.Item
                            name={["a36", "r3c4"]}
                            style={{ width: "calc(50% - 8px)" }}
                          >
                            <Input
                              placeholder="Enter details"
                              readOnly={readonly}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A36"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            </Space>
          )}
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[4].includes(assigned_template_id) && (
              <Card
                title="[V]: Holding, Subsidiary and associate companies (including joint ventures)"
                size="small"
              >
                <>
                  <Form.Item label="A37a" className="title-header">
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          [A]: describe its commitments to provide for or
                          cooperate in the remediation of negative impacts that
                          the organization identifies it has caused or
                          contributed to;
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a37a", "r1c1"]}>
                          <Input.TextArea
                            row={4}
                            placeholder="Enter"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          [B] : describe other processes by which the
                          organization provides for or cooperates in the
                          remediation of negative impacts that it identifies it
                          has caused or contributed to;
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a37a", "r2c1"]}>
                          <Input.TextArea
                            row={4}
                            placeholder="Enter"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A37a"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            )}
          </Space>

          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[4].includes(assigned_template_id) && (
              <Card
                title="[VI]: Transparency and disclosure compliances"
                size="small"
              >
                <>
                  <Form.Item label="A38" className="title-header">
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          Report changes to the list of material topics compared
                          to the previous reporting period.
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a38", "r1c1"]}>
                          <Input placeholder="Enter" readOnly={readonly} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A38"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            )}
          </Space>

          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[4].includes(assigned_template_id) && (
              <Card title="[VII]: Reporting framewok" size="small">
                <Form.Item label="A39" className="title-header">
                  <Row>
                    <Col span={22}>
                      <div style={{ fontSize: "12px" }}>
                        Details on previously reported information on.
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <div style={{ fontSize: "12px" }}>
                        [A]: Whether sustainability report was done last year or
                        any previous report.
                      </div>
                    </Col>
                    <Col span={14}>
                      <Form.Item name={["a39", "r1c1"]}>
                        <Select placeholder="Select Option" disabled={readonly}>
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <div style={{ fontSize: "12px" }}>
                        [B]: Whether sustainability report was done last year or
                        any previous report.
                      </div>
                    </Col>
                    <Col span={14}>
                      <Form.Item name={["a39", "r2c1"]}>
                        <Input
                          type="Date"
                          placeholder="Input Option"
                          readOnly={readonly}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <CommentsApp
                  setCommentReload={setCommentReload}
                  question_response_name="A39"
                  question_response_id={defaultdata.question_response_id}
                />
                <>
                  <Form.Item label="A40" className="title-header">
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          The claim made by the organization, if it has prepared
                          a report in accordance with the GRI.
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a40", "r1c1"]}>
                          <Select
                            placeholder="Select Option"
                            disabled={readonly}
                            onChange={(value) => setA40(value)}
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    {a40 && a40.toUpperCase() === "YES" && (
                      <Row>
                        <Col span={8}></Col>
                        <Col span={14}>
                          <div style={{ display: "flex" }}>
                            <Form.Item name={["a40", "r2c1"]}>
                              <Select
                                disabled={readonly}
                                placeholder="Select Option"
                                style={{
                                  width: "calc(100% - 20px)",
                                }}
                              >
                                <Option value="i. ‘This report has been prepared in accordance with the GRI Standards: Core option’">
                                  i. ‘This report has been prepared in
                                  accordance with the GRI Standards: Core
                                  option’
                                </Option>
                                <Option
                                  value={`ii. ‘This report has been prepared in accordance with
                          the GRI Standards: Comprehensive option’`}
                                >
                                  ii. ‘This report has been prepared in
                                  accordance with the GRI Standards:
                                  Comprehensive option’
                                </Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A40"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>

                <>
                  <Form.Item label="A41" className="title-header">
                    <Row>
                      <Col span={22}>
                        <div style={{ fontSize: "12px" }}>
                          Report restatements of information made from previous
                          reporting periods and explain:
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          [A]: The reasons for the restatements.
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a41", "r1c1"]}>
                          <Input.TextArea
                            row={4}
                            placeholder="Enter"
                            readOnly={readonly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={8}>
                        <div style={{ fontSize: "12px" }}>
                          [B] : The effect of the restatements.
                        </div>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a41", "r2c1"]}>
                          <Input.TextArea
                            readOnly={readonly}
                            row={4}
                            placeholder="Enter"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A41"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            )}
          </Space>

          {/* <div
            style={{ display: assigned_template_id >= 3 ? "display" : "none" }}
          > */}
          {[4].includes(assigned_template_id) && (
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Card title="VIII. Other related questions" size="small">
                <>
                  <Form.Item label="A42" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={20}>
                        <span>
                          Risks and opportunities posed by climate change that
                          have the potential to generate substantive changes in
                          operations, revenue, or expenditure, including:
                        </span>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          i. a description of the risk or opportunity and its
                          classification as either physical, regulatory, or
                          other;
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a42", "r1c1"]}>
                          <Input
                            readOnly={readonly}
                            placeholder="Enter details"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          ii. a description of the impact associated with the
                          risk or opportunity;
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a42", "r2c1"]}>
                          <Input readOnly={readonly} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          iii. the financial implications of the risk or
                          opportunity before action is taken;
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a42", "r3c1"]}>
                          <Input readOnly={readonly} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          iv. the methods used to manage the risk or
                          opportunity;
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a42", "r4c1"]}>
                          <Input readOnly={readonly} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          v. the costs of actions taken to manage the risk or
                          opportunity.
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a42", "r5c1"]}>
                          <Input readOnly={readonly} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A42"
                    question_response_id={defaultdata.question_response_id}
                  />
                  <Divider />
                </>

                <>
                  <Form.Item label="A43" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={20}>
                        <span>Workers who are not employees</span>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={16}>
                        <span>
                          a. report the total number of workers who are not
                          employees and whose work is controlled by the
                          organization and describe:
                        </span>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          i. the most common types of worker and their
                          contractual relationship with the organization;
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a43", "r1c1"]}>
                          <Input readOnly={readonly} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>ii. the type of work they perform;</span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a43", "r2c1"]}>
                          <Input readOnly={readonly} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={16}>
                        <span>
                          b. describe the methodologies and assumptions used to
                          compile the data, including whether the number of
                          workers who are not employees is reported:
                        </span>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          i. in head count, full-time equivalent (FTE), or using
                          another methodology
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a43", "r3c1"]}>
                          <Input readOnly={readonly} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          ii. at the end of the reporting period, as an average
                          across the reporting period, or using another
                          methodology
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a43", "r4c1"]}>
                          <Input readOnly={readonly} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <span>
                          c. describe significant fluctuations in the number of
                          workers who are not employees during the reporting
                          period and between reporting periods
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["a43", "r5c1"]}>
                          <Input readOnly={readonly} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_name="A43"
                    question_response_id={defaultdata.question_response_id}
                  />
                </>
              </Card>
            </Space>
          )}

          <Space
            style={{
              //marginTop: "10px",
              marginBottom: "10px",
              justifyContent: "right",
              width: "100%",
            }}
          >
            <Button style={{ marginLeft: 10 }} htmlType="submit">
              Save and Continue
            </Button>
          </Space>
        </Form>
      </div>
    </>
  );
};

export default App;
