import React from "react";
import { Row, Col, Popconfirm, Badge, Avatar } from "antd";
import ScrollBar from "../../Components/Comments/scroll";
import { CommentOutlined } from "@ant-design/icons";
import "./index.scss";

const CommentsNotification = (props) => {
  const { commentlist } = props;

  return (
    <div>
      <Popconfirm id="sticky-chat"
        title="Unresolved Comments"
        placement="left"
        description={
          <div>
            {commentlist !== undefined &&
              Object.keys(commentlist).length !== 0 &&
              commentlist?.map((item, index) => {
                return (
                  <ScrollBar>
                    <Row
                      to={"/profile"}
                      key={index}
                      style={{ borderBottom: "1px solid gray" }}
                    >
                      <Col
                        style={{
                          marginRight: "10px",
                          fontWeight: "bold",
                          color: "#008B8B",
                        }}
                      >
                        Question ID:
                      </Col>
                      <Col>{item.question_response_name}</Col>
                    </Row>
                  </ScrollBar>
                );
              })}
          </div>
        }
      >
        {/* <Button className="float">New Comments</Button> */}
        <Badge
          className="float"
          count={commentlist !== undefined && commentlist.length !== undefined ? commentlist.length : 0}
        >
          <Avatar
            shape="square"
            size="large"
            style={{
              fontSize: "19px",
              color: "black",
              background: "orange",
              width: "60px",
            }}
            icon={<CommentOutlined />}
          >
            Comments
          </Avatar>
        </Badge>
      </Popconfirm>
    </div>
  );
};

export default CommentsNotification;
