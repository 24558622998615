import React from "react";
import { Button, message, Space } from "antd";
import { useDispatch } from "react-redux";
import { useCustomer } from "../../../../hooks/useCustomer";
import { useFinancialYear } from "../../../../hooks/useFinancialYear";
import { QuestionaireSubmitSlice } from "../../../BackofficeAdmin/Questionaire/FinalSubmit/finalSubmitSlice";
import "./index.scss";

const App = () => {
  const {
    notificationData,
    customer_id,
    assigned_template_id,
    questionaire_status,
  } = useCustomer();

  const { current_fy } = useFinancialYear();
  const dispatch = useDispatch();
  const handleSubmitReview = () => {
    if (notificationData.total_notifications > 0) {
      return message.info(
        "Please Review the Comments first: " +
          notificationData.total_notifications
      );
    }
    let Payload = {
      customer_id: customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
    };
    dispatch(QuestionaireSubmitSlice({ ...Payload }))
      .unwrap()
      .then((res) => {
        if (res?.data?.code === 0) {
          message.info(
            'thank you for submission , admin/reviewer will contact you soon'
          );
        } else {
          message.info(res?.data?.msg);
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };
  return (
    <div className="center">
      {questionaire_status === "Reviewed" ||
      questionaire_status === "SubmittedForReview" ? (
        <h2>{`Submission under review. Kindly wait for reviewer’s comments`}</h2>
      ) : (
        <>
          <h2>{`Click on finish to submit the questionaire for review.`}</h2>
          <Space
            style={{
              marginTop: "10px",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {questionaire_status !== "Reviewed" && (
              <Button size="large" onClick={handleSubmitReview}>
                Finish
              </Button>
            )}
          </Space>
        </>
      )}
    </div>
  );
};

export default App;
