import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Select } from "antd";
import InputNumber from "../../../../../Components/Input/Number/number";
import * as common from "../../../../../util/common";
const { Option } = Select;
const T14 = (props) => {
  const [r21c1, setR21c1] = useState("No");
  const formula_defination = {

    r6c1: {
      type: "sum",
      cols: [
        "r1c1",
        "r2c1",
        "r3c1",
        "r4c1",
        "r5c1",
      ],
    },
    r6c2: {
      type: "sum",
      cols: [
        "r1c2",
        "r2c2",
        "r3c2",
        "r4c2",
        "r5c2",
      ],
    },
    r20c1: {
      type: "sum",
      cols: [
        "r10c1",
        "r11c1",
        "r12c1",
        "r13c1",
        "r14c1",
        "r15c1",
        "r16c1",
        "r17c1",
        "r18c1",
        "r19c1",
      ],
    },
    r20c2: {
      type: "sum",
      cols: [
        "r10c2",
        "r11c2",
        "r12c2",
        "r13c2",
        "r14c2",
        "r15c2",
        "r16c2",
        "r17c2",
        "r18c2",
        "r19c2",
      ],
    },
  };

  const handleChange = (e) => {
    common.arthiFunc(props.ques_id, formula_defination, props.form);

    let divisor = parseFloat(props.form.getFieldValue(["cp6_0", "r1c1"]));
    let r6c1value = parseFloat(props.form.getFieldValue([props.ques_id, "r6c1"]));

    props.form.setFieldValue(
      [props.ques_id, "r8c1"],
      divisor === 0 ? 0 : Number(((r6c1value / divisor)).toFixed(2))
    );

  };
  useEffect(() => {
    props.form.setFieldsValue(props.data);
    setR21c1(props.form.getFieldValue([props.ques_id, "r21c1"]));
  }, [props.ques_id, props.form, props.data]);
  return (
    <div>
      <Row>
        <Col span={24}>
          <div id="tbl-cell">
            <Row>
              <Col span={8}>Parameter</Col>
              <Col span={8} style={{ textAlign: "center", fontWeight: "bold" }}>
                FY {props?.data?.current_fy} (Current Financial Year)
              </Col>
              <Col span={8} style={{ textAlign: "center", fontWeight: "bold" }}>
                FY {props?.data?.previous_fy} (Previous Financial Year)
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Water withdrawal by source (in kilolitres)
              </Col>
            </Row>
            <Row>
              <Col span={8}>(i) Surface water</Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r1c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r1c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>(ii) Groundwater </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r2c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r2c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>(iii) Third party water </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r3c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r3c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>(iv) Seawater / desalinated water</Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r4c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r4c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>(v) Others </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r5c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r5c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                Total volume of water withdrawal (in kilolitres)
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r6c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r6c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                Total volume of water consumption (in kilolitres)
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r7c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r7c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ fontWeight: "bold" }}>
                Water intensity per rupee of turnover (Water consumed /
                turnover) - kilolitres/crore
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r8c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r8c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ fontWeight: "bold" }}>
                Water intensity (optional) - the relevent metric may be selected
                by the entity input field to enter the metric
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r9c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r9c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ fontWeight: "bold" }}>
                Water discharge by destination and level of treatment (in
                kilolitres)
              </Col>
            </Row>
            <Row>
              <Col span={24}>(i) To Surface water</Col>
            </Row>
            <Row>
              <Col span={8}>No treatment</Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r10c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r10c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                With treatment
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r11c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r11c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>(ii) To Groundwater</Col>
            </Row>
            <Row>
              <Col span={8}>No treatment</Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r12c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r12c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                With treatment
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r13c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r13c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>(iii) To Seawater </Col>
            </Row>
            <Row>
              <Col span={8}>No treatment</Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r14c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r14c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                With treatment
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r15c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r15c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>(iv) Sent to third-parties </Col>
            </Row>
            <Row>
              <Col span={8}>No treatment</Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r16c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r16c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                With treatment
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r17c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r17c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>(v) Others </Col>
            </Row>
            <Row>
              <Col span={8}>No treatment</Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r18c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r18c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                With treatment
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r19c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r19c2"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ fontWeight: "bold" }}>
                Total water discharged (in kilolitres)
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r20c1"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r20c2"]} className="ant-col1">
                  <Input
                    type="number"
                    min="0"
                    size="small"
                    readOnly={props.readonly}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                Indicate if any independent assessment/ evaluation/assurance has
                been carried out by an external agency? (Y/N) If yes, name of
                the external agency.
              </Col>
              <Col span={16}>
                <Row>
                  <Col span={4}>
                    <Form.Item
                      name={[props.ques_id, "r21c1"]}
                      noStyle
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                        disabled={props.readonly}
                        placeholder="Select Option"
                        onChange={(value) => setR21c1(value)}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {r21c1 === "Yes" && (
                    <Col span={20}>
                      <Form.Item name={[props.ques_id, "r21c2"]} noStyle>
                        <Input readOnly={props.readonly} placeholder="" />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default T14;
