import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { QuestionaireSubmit } from "../../../../Config/actions";

const QuestionaireSubmitSlice = createAsyncThunk(
  "create/section/submit",
  async (value) => {
    return await QuestionaireSubmit(value);
  }
);

// const adminsetupSlice = createSlice({
//   name: "setup",
//   initialState,
//   reducers: {
//     [create_section_slice.fulfilled]: (state, action) => {
//       state.loading = false;
//       state.result = action.payload;
//     },
//     [get_section.fulfilled]: (state, action) => {
//       state.loading = false;
//       state.result = action.payload;
//     },
//     [get_comments_summary.fulfilled]: (state, action) => {
//       state.loading = false;
//       state.result = action.payload;
//     },
//   },
// });
//const { reducer } = adminsetupSlice;
export { QuestionaireSubmitSlice };
//export default reducer;
