import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form } from "antd";

const T19 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <div id="tbl-cell">
          <span>Number of locations</span>
          <Row style={{ width: "100%" }}>
            <Col span={8}>
              <span>Locations</span>
            </Col>
            <Col span={16}>
              <span>Numbers</span>
            </Col>
          </Row>
          <Row>
            <Col span={8}>National (No. of states)</Col>
            <Col span={16}>
              <Form.Item name={[props.ques_id, "r1c1"]}>
                <Input
                  placeholder="National (No. of states)"
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>International (No. of countries)</Col>
            <Col span={16}>
              <Form.Item name={[props.ques_id, "r2c1"]}>
                <Input
                  placeholder="International (No. of countries)"
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
          </Row>

          <span>
            B: What is the contribution of exports as a percentage of the total
            turnover of the entity?
          </span>
          <Row>
            <Col span={24}>
              <Form.Item name={[props.ques_id, "r3c1"]}>
                <Input placeholder="Turnover" readOnly={props.readonly} />
              </Form.Item>
            </Col>
          </Row>
          <span>C: A brief type of customers</span>
          <Row>
            <Col span={24}>
              <Form.Item name={[props.ques_id, "r4c1"]}>
                <Input
                  placeholder="Type of customers"
                  readOnly={props.readonly}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default T19;
