import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Table,
  Modal,
  message,
  Tooltip,
  Select,
  Row,
  Col,
} from "antd";

import {
  DeleteOutlined,
  QuestionCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";

const { Option } = Select;
const App = (props) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [marginalized_group, setMarginalized_group] = useState("");
  const [stakeholder_group, setStakeholder_group] = useState("");
  const [channels, setChannels] = useState("");
  const [frequency, setFrequency] = useState("");
  const [other_frequency, setOtherFrequency] = useState('');
  const [scope, setScope] = useState("");
  const [modelOpen, setModelOpen] = useState(false);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item, index) => index !== key);
    setDataSource(newData);
    props.setdata(newData);
  };

  const Columns = [
    {
      title: 'SNo.',
      dataIndex: 'key',
      width: '10%',
      editable: true,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Stakeholder Group',
      dataIndex: 'r1c1',
      //width: "30%",
      editable: true,
    },
    {
      title: 'Whether identified as Vulnerable & Marginalized Group (Yes/No)',
      dataIndex: 'r1c2',
    },

    {
      title: 'Channels of communication',
      dataIndex: 'r1c3',
    },
    {
      title: 'Frequency of engagement',
      dataIndex: 'r1c4',
      render: (_, record) => record.r1c4 + ' ' + record.r1c4a,
    },
    {
      title: (
        <span style={{ color: '#fff' }}>
          {`Purpose and scope of engagement`}
          <Tooltip
            title={`Purpose and scope of engagement including key topics and concerns raised during such engagement`}
            placement="top"
          >
            <QuestionCircleFilled style={{ color: '#fff' }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'r1c5',
      width: 150,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record, index) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(index)}
          >
            <Button className="ant-btn-link" type="link">
              <DeleteOutlined twoToneColor="#eb2f96" />
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const btnAdd = (values) => {
    const newData = {
      r1c1: stakeholder_group,
      r1c2: marginalized_group,
      r1c3: channels,
      r1c4: frequency,
      r1c4a: other_frequency,
      r1c5: scope,
    };
    setDataSource([...dataSource, newData]);
    props.setdata([...dataSource, newData]);
    message.success("Processing complete!");
    setModelOpen(false);
  };
  useEffect(() => {
    setDataSource(props.data?.cp4_2 || []);
  }, [props.data]);
  return (
    <div>
      <Row>
        <Col span={24} offset={22}>
          <span>
            <Button
              onClick={() => {
                setModelOpen(true);
                setChannels('');
                setFrequency('');
                setMarginalized_group('');
                setOtherFrequency('');
                setScope('');
                setStakeholder_group('');
                form.resetFields();
              }}
              disabled={props.readonly}
            >
              Add
            </Button>
          </span>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={Columns}
        pagination={false}
        className="f-table-wrapper"
        scroll={{
          y: 240,
        }}
      />

      <Modal
        title="Add"
        style={{ top: 20 }}
        open={modelOpen}
        onOk={btnAdd}
        okText="Add"
        onCancel={() => setModelOpen(false)}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <div className="modalform">
            <Form.Item name="Stakeholder_group" label="Stakeholder Group">
              <Input
                onChange={(e) => setStakeholder_group(e.target.value)}
                placeholder="Stakeholder Group"
              />
            </Form.Item>
            <Form.Item
              name="vulnerable"
              label="Vulnerable & Marginalized Group"
            >
              <Select
                onChange={(e) => setMarginalized_group(e)}
                style={{ width: '200px', marginRight: '10px' }}
                placeholder="Select Option"
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
            <Form.Item name="channels" label="Channels of communication">
              <Select
                onChange={(e) => setChannels(e)}
                style={{ width: '200px', marginRight: '10px' }}
                placeholder="Select Option"
                mode="multiple"
              >
                <Option value=" Email">Email</Option>
                <Option value=" SMS">SMS</Option>
                <Option value=" Newspaper">Newspaper</Option>
                <Option value=" Pamphlets">Pamphlets</Option>
                <Option value=" Advertisement">Advertisement</Option>
                <Option value=" Community Meetings">Community Meetings</Option>
                <Option value=" Notice Board">Notice Board</Option>
                <Option value=" Website">Website</Option>
              </Select>
            </Form.Item>
            <Form.Item name="frequency" label="Frequency of engagement">
              <Select
                onChange={(e) => setFrequency(e)}
                style={{ width: '200px', marginRight: '10px' }}
                placeholder="Select Option"
              >
                <Option value="Annually">Annually</Option>
                <Option value="Half yearly">Half yearly</Option>
                <Option value="Quarterly">Quarterly</Option>
                <Option value="Others">Others – please specify</Option>
              </Select>
              {frequency === 'Others' && (
                <Input
                  placeholder="Enter"
                  onChange={(e) => setOtherFrequency(e.target.value)}
                />
              )}
            </Form.Item>
            <Form.Item
              name="scope"
              label={
                <span>
                  {`Purpose and scope of engagement`}
                  <Tooltip
                    title={`Purpose and scope of engagement including key topics and concerns raised during such engagement`}
                    placement="top"
                  >
                    <QuestionCircleFilled />
                  </Tooltip>
                </span>
              }
            >
              <Input
                onChange={(e) => setScope(e.target.value)}
                placeholder="Stakeholder Group"
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default App;
