import React, { useEffect } from "react";
import { Row, Col, Form, Input } from "antd";
import * as common from "../../../../util/common";

const CP3_7 = (props) => {
  const formula_defination = {
    r1c3: { type: "per", cols: ["r1c2", "r1c1"] },
    r1c6: { type: "per", cols: ["r1c5", "r1c4"] },
    r2c3: { type: "per", cols: ["r2c2", "r2c1"] },
    r2c6: { type: "per", cols: ["r2c5", "r2c4"] },
    r3c1: { type: "sum", cols: ["r1c1", "r2c1"] }, //==
    r3c2: { type: "sum", cols: ["r1c2", "r2c2"] },
    r3c3: { type: "per", cols: ["r3c2", "r3c1"] },
    r3c4: { type: "sum", cols: ["r1c4", "r2c4"] },
    r3c5: { type: "sum", cols: ["r1c5", "r2c5"] },
    r3c6: { type: "per", cols: ["r3c5", "r3c4"] },

    r6c1: { type: "sum", cols: ["r5c1", "r4c1"] },
    r6c2: { type: "sum", cols: ["r4c2", "r5c2"] },
    r6c3: { type: "per", cols: ["r6c2", "r6c1"] },
    r6c4: { type: "sum", cols: ["r4c4", "r5c4"] },
    r6c5: { type: "sum", cols: ["r4c5", "r5c5"] },

    r4c3: { type: "per", cols: ["r4c2", "r4c1"] },
    r5c3: { type: "per", cols: ["r5c2", "r5c1"] },

    r4c6: { type: "per", cols: ["r4c5", "r4c4"] },
    r5c6: { type: "per", cols: ["r5c5", "r5c4"] },
    r6c6: { type: "per", cols: ["r6c5", "r6c4"] },
  };

  const handleChange = (e) => {
    common.arthiFunc(props.ques_id, formula_defination, props.form);
  };
  useEffect(() => {
    handleChange();
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div id="tbl-cell">
      <Row>
        <Col span={6} className="text-center">
          <span
            style={{ fontSize: "12px", fontWeight: "600" }}
          >{`Category`}</span>
        </Col>
        <Col span={9} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            FY: {props?.data?.current_fy} Current Financial Year
          </span>
        </Col>
        <Col span={9} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            FY: {props?.data?.previous_fy} Previous Financial Year
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <span style={{ fontSize: "12px", fontWeight: "600" }}></span>
        </Col>
        <Col span={3} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`Number of employees / workers in respective category (A)`}
          </span>
        </Col>
        <Col span={3} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`No. of employees /workers in respective category, who are part of association(s) or Union (B)	`}
          </span>
        </Col>
        <Col span={3} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`% (B / A)`}
          </span>
        </Col>
        <Col span={3} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`Number of employees / workers in respective category (C)`}
          </span>
        </Col>
        <Col span={3} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`No. of employees / workers in respective category, who are part of association(s) or Union (D)`}
          </span>
        </Col>
        <Col span={3} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`% (D / C)`}
          </span>
        </Col>
      </Row>

      <Row>
        <Col span={6} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>{`Male`}</span>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c1"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c2"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c3"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c4"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c5"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c6"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>Female</span>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c1"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c2"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c3"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c4"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c5"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c6"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            Total Permanent Employees
          </span>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r3c1"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r3c2"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r3c3"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r3c4"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r3c5"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r3c6"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>Male</span>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r4c1"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r4c2"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r4c3"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r4c4"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r4c5"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r4c6"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>Female</span>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r5c1"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r5c2"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r5c3"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r5c4"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r5c5"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r5c6"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            Total Permanent Workers
          </span>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r6c1"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r6c2"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r6c3"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r6c4"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r6c5"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r6c6"]}>
            <Input
              onChange={handleChange}
              type="number"
              min="0"
              readOnly={props.readonly}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default CP3_7;
