import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Divider,
  Card,
  Row,
  Col,
  Input,
  Select,
  Space,
  Form,
  message,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CommentsApp from "../../../../Components/Comments";
import {
  create_section_slice,
  get_section,
} from "../SectionSlice/sectionSlice";
import T1 from "./Tables/t1";
import T3 from "./Tables/t3";
import T4 from "./Tables/t4";
import T6 from "./Tables/t6";
import T7 from "./Tables/t7";
import T9 from "./Tables/t9";
import T13 from "./Tables/t13";
import T11 from "./Tables/t11";
import T12 from "./Tables/t12";
import T14 from "./Tables/t14";
import T15 from "./Tables/t15";
import T17 from "./Tables/t17";
import { useFinancialYear } from "../../../../hooks/useFinancialYear";
import { useCustomer } from "../../../../hooks/useCustomer";
import FloatInput from "../../../../Components/Input/numbertofloat";
import CommentsNotified from "../../../../Components/Comments/comments-notification";

const { Option } = Select;
const App = (props) => {
  const { current_fy, previous_fy } = useFinancialYear();
  const { customer_id, assigned_template_id } = useCustomer();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [spinning, setSpinning] = useState(false);
  const [defaultdata, setDefaultdata] = useState({});
  const [cp6_2, setCp6_2] = useState("No");
  const [cp6_5, setCp6_5] = useState("No");
  const [cp6_13, setCp6_13] = useState("Yes");
  const [readonly, setReadonly] = useState(false);
  const [cp6_11, setCp6_11] = useState([]);
  const [cp6_12, setCp6_12] = useState([]);
  const [cp6_17, setCp6_17] = useState([]);
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const [commentlist, setCommentlist] = useState([]);
  const [commentreload, setCommentReload] = useState();

  useEffect(() => {
    if (sessionState?.roletype === "CUSTOMER") {
      setReadonly(false);
    } else {
      setReadonly(true);
    }
    let payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_C_P6",
    };
    setSpinning(true);
    dispatch(get_section({ payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setDefaultdata(res.data.section_C_P6);
          setCommentlist(res.data?.comments);
          setSpinning(false);
        } else {
          setSpinning(false);
          if (res.code != 800000) {
            message.info(res.msg);
          }
        }
      })
      .catch((Error) => {
        console.log(Error);
      });
  }, [dispatch, commentreload]);

  useEffect(() => {
    form.setFieldsValue(defaultdata);
    setCp6_11(defaultdata?.cp6_11 || []);
    setCp6_12(defaultdata?.cp6_12 || []);
    setCp6_17(defaultdata?.cp6_17 || []);
    setCp6_2(form.getFieldValue(["cp6_2", "r1c1"]));
    setCp6_5(form.getFieldValue(["cp6_5", "r1c1"]));
    setCp6_13(form.getFieldValue(["cp6_13", "r1c1"]));
  }, [form, defaultdata]);

  const onFinish = (datalist) => {
    setSpinning(true);
    let Payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_C_P6",
      question_response: { ...datalist, cp6_11, cp6_12, cp6_17 },
    };
    dispatch(create_section_slice({ Payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setTimeout(() => {
            setSpinning(false);
            message.info("Section C P6 created succesfully!");
          }, 3000);
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };
  return (
    <>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 44, color: "white" }} spin />
        }
        tip="Loading..."
        spinning={spinning}
        fullscreen
        size="large"
      />
      <div className="formcontainer">
        <CommentsNotified commentlist={commentlist} />
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={defaultdata}
          scrollToFirstError
          style={{ width: "100%" }}
        >
          <div
            style={{ display: assigned_template_id >= 1 ? "display" : "none" }}
          >
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Card title="Essential Indicators" size="small">
                {[1, 2, 3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-0" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span>Total revenue/Turnover in crores INR:</span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_0", "r1c1"]}>
                            {/* <InputNumber
                              readOnly={props.readonly}
                              placeholder="Enter"
                            /> */}
                            <FloatInput
                              size="small"
                              readOnly={props.readonly}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name={"CP6-0"}
                    />

                    <Form.Item label="CP6-1" className="title-header">
                      <Row>
                        <Col span={20}>
                          <span>
                            Details of total energy consumption (in gigajoules
                            or multiples) and energy intensity, in the following
                            format:
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <T1
                            readonly={readonly}
                            data={{ current_fy, previous_fy, ...defaultdata }}
                            form={form}
                            ques_id={"CP6-1"}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-1"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-2" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={20}>
                          <span>
                            Does the entity have any sites / facilities
                            identified as designated consumers (DCs) under the
                            Performance, Achieve and Trade (PAT) Scheme of the
                            Government of India? (Y/N) If yes, disclose whether
                            targets set under the PAT scheme have been achieved.
                            In case targets have not been achieved, provide the
                            remedial action taken, if any.
                          </span>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={4}>
                          <Form.Item
                            name={["cp6_2", "r1c1"]}
                            noStyle
                            rules={[
                              {
                                required: false,
                              },
                            ]}
                          >
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                              onChange={(value) => setCp6_2(value)}
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        {cp6_2 === "Yes" && (
                          <Col span={20}>
                            <Form.Item
                              name={["cp6_2", "r1c2"]}
                              noStyle
                              rules={[
                                {
                                  required: false,
                                },
                              ]}
                            >
                              <Input
                                readOnly={readonly}
                                placeholder="Enter details"
                              />
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-2"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[1, 2, 3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-3" className="title-header">
                      <Row>
                        <Col span={20}>
                          <span>
                            Provide details of the following disclosures related
                            to water, in the following format:
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <T3
                            readonly={readonly}
                            data={{ current_fy, previous_fy, ...defaultdata }}
                            form={form}
                            ques_id={"CP6-3"}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-3"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[1, 2, 3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-4" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={20}>
                          <span>
                            Provide the following details related to water
                            discharged:
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <T4
                            readonly={readonly}
                            data={{ current_fy, previous_fy, ...defaultdata }}
                            form={form}
                            ques_id={"CP6-4"}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-4"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-5" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={20}>
                          <span>
                            Has the entity implemented a mechanism for Zero
                            Liquid Discharge? If yes, provide details of its
                            coverage and implementation.
                          </span>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={4}>
                          <Form.Item name={["cp6_5", "r1c1"]} noStyle>
                            <Select
                              placeholder="Select Option"
                              onChange={(value) => setCp6_5(value)}
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        {cp6_5 === "Yes" && (
                          <Col span={20}>
                            <Form.Item name={["cp6_5", "r1c2"]} noStyle>
                              <Input
                                readOnly={readonly}
                                placeholder="Enter details"
                              />
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-5"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-6" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={20}>
                          <span>
                            Please provide details of air emissions (other than
                            GHG emissions) by the entity, in the following
                            format:
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <T6
                            readonly={readonly}
                            data={{ current_fy, previous_fy, ...defaultdata }}
                            form={form}
                            ques_id={"CP6-6"}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-6"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[1, 2, 3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-7" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={20}>
                          <span>
                            Provide details of greenhouse gas emissions (Scope 1
                            and Scope 2 emissions) & its intensity, in the
                            following format:
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <T7
                            readonly={readonly}
                            data={{ current_fy, previous_fy, ...defaultdata }}
                            form={form}
                            ques_id={"CP6-7"}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <a
                          href="https://smeclimatehub.org/start-measuring"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          GHG Emissions Calculation Free of Cost Tool
                        </a>
                      </Row>
                    </Form.Item>

                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-7"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[2, 3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-8" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <span>
                            Does the entity have any project related to reducing
                            Green House Gas emission? If Yes, then provide
                            details.
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_8", "r1c1"]} noStyle>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-8"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[2, 3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-9" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={20}>
                          <span>
                            Provide details related to waste management by the
                            entity, in the following format:
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <T9
                            readonly={readonly}
                            data={{ current_fy, previous_fy, ...defaultdata }}
                            form={form}
                            ques_id={"CP6-9"}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-9"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[1, 2, 3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-10" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <span>
                            Briefly describe the waste management practices
                            adopted in your establishments. Describe the
                            strategy adopted by your company to reduce usage of
                            hazardous and toxic chemicals in your products and
                            processes and the practices adopted to manage such
                            wastes.
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_10", "r1c1"]} noStyle>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-10"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-11" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div className="space-between">
                            <span
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              If the entity has operations/offices in/around
                              ecologically sensitive areas (such as national
                              parks, wildlife sanctuaries, biosphere reserves,
                              wetlands, biodiversity hotspots, forests, coastal
                              regulation zones etc.) where environmental
                              approvals / clearances are required, please
                              specify details in the following format:
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <T11
                            readonly={readonly}
                            data={{ cp6_11 }}
                            setdata={setCp6_11}
                            form={form}
                            ques_id={"CP6-11"}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-11"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-12" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <span>
                            Details of environmental impact assessments of
                            projects undertaken by the entity based on
                            applicable laws, in the current financial year:
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <T12
                            readonly={readonly}
                            data={{ cp6_12 }}
                            setdata={setCp6_12}
                            form={form}
                            ques_id={"CP6-12"}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-12"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[1, 2, 3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-13" className="title-header">
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <span>
                            Is the entity compliant with the applicable
                            environmental law/ regulations/ guidelines in India;
                            such as the Water (Prevention and Control of
                            Pollution) Act, Air (Prevention and Control of
                            Pollution) Act, Environment protection act and rules
                            thereunder (Y/N). If not, provide details of all
                            such non-compliances, in the following format:
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_13", "r1c1"]} noStyle>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                              onChange={(value) => setCp6_13(value)}
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>

                          {cp6_13 === "No" && (
                            <T13
                              readonly={readonly}
                              data={{ current_fy, previous_fy, ...defaultdata }}
                              form={form}
                              ques_id={"CP6-13"}
                            />
                          )}
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-13"
                      question_response_id={defaultdata?.question_response_id}
                    />
                  </>
                )}
              </Card>
              {[3, 4].includes(assigned_template_id) && (
                <Card title="Leadership Indicators" size="small">
                  {[3, 4].includes(assigned_template_id) && (
                    <>
                      <Form.Item label="CP6-14" className="title-header">
                        <Row gutter={[16, 16]}>
                          <Col span={20}>
                            <span>
                              Water withdrawal, consumption and discharge in
                              areas of water stress (in kilolitres):
                              <br />
                              For each facility / plant located in areas of
                              water stress, provide the following information:
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <span>(i) Name of the area</span>
                          </Col>
                          <Col span={14}>
                            <Form.Item name={["cp6_14", "r1c1"]} noStyle>
                              <Input
                                readOnly={readonly}
                                placeholder="Enter details"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <span>(ii) Nature of operations</span>
                          </Col>
                          <Col span={14}>
                            <Form.Item name={["cp6_14", "r2c1"]} noStyle>
                              <Input
                                readOnly={readonly}
                                placeholder="Enter details"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={20}>
                            <span>
                              (iii) Water withdrawal, consumption and discharge
                              in the following format:
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <T14
                            readonly={readonly}
                            data={{ current_fy, previous_fy, ...defaultdata }}
                            form={form}
                            ques_id={"CP6-14"}
                          />
                        </Row>
                      </Form.Item>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_name="CP6-14"
                        question_response_id={defaultdata?.question_response_id}
                      />
                      <Divider />
                    </>
                  )}
                  {[3, 4].includes(assigned_template_id) && (
                    <>
                      <Form.Item label="CP6-15" className="title-header">
                        <Row gutter={[16, 16]}>
                          <Col span={20}>
                            <span>
                              Please provide details of total Scope 3 emissions
                              & its intensity, in the following format:
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <T15
                            readonly={readonly}
                            data={{ current_fy, previous_fy, ...defaultdata }}
                            form={form}
                            ques_id={"CP6-15"}
                          />
                        </Row>
                      </Form.Item>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_name="CP6-15"
                        question_response_id={defaultdata?.question_response_id}
                      />
                      <Divider />
                    </>
                  )}
                  {[3, 4].includes(assigned_template_id) && (
                    <>
                      <Form.Item label="CP6-16" className="title-header">
                        <Row gutter={[16, 16]}>
                          <Col span={8}>
                            <span>
                              With respect to the ecologically sensitive areas
                              reported in question in Essential Indicators
                              above, provide details of significant direct &
                              indirect impact of the entity on biodiversity in
                              such areas along-with prevention and remediation
                              activities.
                            </span>
                          </Col>
                          <Col span={14}>
                            <Form.Item name={["cp6_16", "r1c1"]} noStyle>
                              <Input
                                readOnly={readonly}
                                placeholder="Enter details"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_name="CP6-16"
                        question_response_id={defaultdata?.question_response_id}
                      />
                      <Divider />
                    </>
                  )}
                  {[3, 4].includes(assigned_template_id) && (
                    <>
                      <Form.Item label="CP6-17" className="title-header">
                        <Row gutter={[16, 16]}>
                          <Col span={20}>
                            <span>
                              If the entity has undertaken any specific
                              initiatives or used innovative technology or
                              solutions to improve resource efficiency, or
                              reduce impact due to emissions / effluent
                              discharge / waste generated, please provide
                              details of the same as well as outcome of such
                              initiatives, as per the following format:
                            </span>
                          </Col>
                        </Row>
                        <Row span={22}>
                          <T17
                            readonly={readonly}
                            data={{ cp6_17 }}
                            setdata={setCp6_17}
                            form={form}
                            ques_id={"cp6_17"}
                          />
                        </Row>
                      </Form.Item>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_name="cp6_17"
                        question_response_id={defaultdata?.question_response_id}
                      />
                      <Divider />
                    </>
                  )}
                  {[3, 4].includes(assigned_template_id) && (
                    <>
                      <Form.Item label="CP6-18" className="title-header">
                        <Row gutter={[16, 16]}>
                          <Col span={8}>
                            <span>
                              Does the entity have a business continuity and
                              disaster management plan? Give details in 100
                              words/ web link.
                            </span>
                          </Col>
                          <Col span={14}>
                            <Form.Item name={["cp6_18", "r1c1"]} noStyle>
                              <Input
                                readOnly={readonly}
                                placeholder="Enter details"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_name="CP6-18"
                        question_response_id={defaultdata?.question_response_id}
                      />
                      <Divider />
                    </>
                  )}
                  {[3, 4].includes(assigned_template_id) && (
                    <>
                      <Form.Item label="CP6-19" className="title-header">
                        <Row gutter={[16, 16]}>
                          <Col span={8}>
                            <span>
                              Disclose any significant adverse impact to the
                              environment, arising from the value chain of the
                              entity. What mitigation or adaptation measures
                              have been taken by the entity in this regard.
                            </span>
                          </Col>
                          <Col span={14}>
                            <Form.Item name={["cp6_19", "r1c1"]} noStyle>
                              <Input
                                readOnly={readonly}
                                placeholder="Enter details"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_name="CP6-19"
                        question_response_id={defaultdata?.question_response_id}
                      />
                      <Divider />
                    </>
                  )}
                  {[3, 4].includes(assigned_template_id) && (
                    <>
                      <Form.Item label="CP6-20" className="title-header">
                        <Row gutter={[16, 16]}>
                          <Col span={8}>
                            <span>
                              Percentage of value chain partners (by value of
                              business done with such partners) that were
                              assessed for environmental impacts.
                            </span>
                          </Col>
                          <Col span={14}>
                            <Form.Item name={["cp6_20", "r1c1"]} noStyle>
                              <Input
                                readOnly={readonly}
                                placeholder="Enter details"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                      <CommentsApp setCommentReload={setCommentReload}
                        question_response_name="CP6-20"
                        question_response_id={defaultdata?.question_response_id}
                      />
                    </>
                  )}
                </Card>
              )}
            </Space>
          </div>
          {/* <h1>END OF LITE/EXTENDED </h1> */}
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[4].includes(assigned_template_id) && (
              <Card title="" size="small">
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-21" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Energy consumption within the organization
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            a. In joules, watt-hours or multiples, the total:
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. heating consumption
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_21", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. cooling consumption
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_21", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iii. steam consumption
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_21", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            b. In joules, watt-hours or multiples, the total:
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. electricity sold
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_21", "r4c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. heating sold
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_21", "r5c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iii. cooling sold
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_21", "r6c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>iv. steam sold</div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_21", "r7c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. Standards, methodologies, assumptions, and/or
                            calculation tools used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_21", "r8c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            d. Source of the conversion factors used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_21", "r9c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-21"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-22" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Energy consumption outside of the organization
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Energy consumption outside of the organization,
                            in joules or multiples.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_22", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Standards, methodologies, assumptions, and/or
                            calculation tools used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_22", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. Source of the conversion factors used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_22", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-22"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-23" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Energy intensity
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Types of energy included in the intensity ratio;
                            whether fuel, electricity, heating, cooling, steam,
                            or all.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_23", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Whether the ratio uses energy consumption within
                            the organization, outside of it, or both.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_23", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-23"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-24" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Reduction of energy consumption
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Amount of reductions in energy consumption
                            achieved as a direct result of conservation and
                            efficiency initiatives, in joules or multiples.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_24", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Types of energy included in the reductions;
                            whether fuel, electricity, heating, cooling, steam,
                            or all.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_24", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. Basis for calculating reductions in energy
                            consumption, such as base year or baseline,
                            including the rationale for choosing it.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_24", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            d. Standards, methodologies, assumptions, and/or
                            calculation tools used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_24", "r4c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-24"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-25" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Reductions in energy requirements of products and
                            services
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. reductions in energy requirements of sold
                            products and services achieved during the reporting
                            period, in joules or multiples.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_25", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Basis for calculating reductions in energy
                            consumption, such as base year or baseline,
                            including the rationale for choosing it.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_2", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. Standards, methodologies, assumptions, and/or
                            calculation tools used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_25", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-25"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-26" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Interactions with water as a shared resource
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. A description of how water-related impacts are
                            addressed, including how the organization works with
                            stakeholders to steward water as a shared resource,
                            and how it engages with suppliers or customers with
                            significant water-related impacts
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_26", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. An explanation of the process for setting any
                            water-related goals and targets that are part of the
                            organization’s approach to managing water and
                            effluents, and how they relate to public policy and
                            the local context of each area with water stress.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_26", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-26"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-27" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Management of water discharge related impacts
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            A description of any minimum standards set for the
                            quality of effluent discharge, and how these minimum
                            standards were determined, including:
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_27", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. how standards for facilities operating in
                            locations with no local discharge requirements were
                            determined;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_27", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. any internally developed water quality standards
                            or guidelines;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_27", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iii. any internally developed water quality
                            standards or guidelines;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_27", "r4c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iv. whether the profile of the receiving waterbody
                            was considered.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_27", "r5c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-27"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-28" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Water withdrawal
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Total water withdrawal from all areas with water
                            stress in kiloliters, and a breakdown of this total
                            by the following sources, if applicable:
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_28", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            Third-party water, and a breakdown of this total by
                            the withdrawal sources
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_28", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. A breakdown of total water withdrawal from each
                            of the sources in kiloliters by the following
                            categories:
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. Freshwater (≤1,000 mg/L Total Dissolved Solids);
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_28", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. Other water ({">"}1,000 mg/L Total Dissolved
                            Solids).
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_28", "r4c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. Any contextual information necessary to
                            understand how the data have been compiled, such as
                            any standards, methodologies, and assumptions used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_28", "r5c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-28"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-29" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Water discharge
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. A breakdown of total water discharge to all areas
                            in kiloliters by the following categories:
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. freshwater (≤1,000 mg/L Total Dissolved Solids);
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_29", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. Other water ({">"}1,000 mg/L Total Dissolved
                            Solids).
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_29", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Total water discharge to all areas with water
                            stress in kiloliters, and a breakdown of this total
                            by the following categories:
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. Freshwater (≤1,000 mg/L Total Dissolved Solids);
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_29", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. Other water ({">"}1,000 mg/L Total Dissolved
                            Solids).
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_29", "r4c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. Priority substances of concern for which
                            discharges are treated, including:
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. how priority substances of concern were defined,
                            and any international standard, authoritative list,
                            or criteria used;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_29", "r5c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. the approach for setting discharge limits for
                            priority substances of concern;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_29", "r6c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iii. number of incidents of non-compliance with
                            discharge limits.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_29", "r7c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-29"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-30" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Water consumption
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Total water consumption from all areas with water
                            stress in kiloliters.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_30", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Change in water storage in kiloliters, if water
                            storage has been identified as having a significant
                            water-related impact.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_30", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. Any contextual information necessary to
                            understand how the data have been compiled, such as
                            any standards, methodologies, and assumptions used,
                            including whether the information is calculated,
                            estimated, modeled, or sourced from direct
                            measurements, and the approach taken for this, such
                            as the use of any sector-specific factors
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_30", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-30"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-31" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Operational sites owned, leased, managed in, or
                            adjacent to, protected areas and areas of high
                            biodiversity value outside protected areas
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. For each operational site owned, leased, managed
                            in, or adjacent to, protected areas and areas of
                            high biodiversity value outside protected areas, the
                            following information:
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i.Biodiversity value characterized by the attribute
                            of the protected area or area of high biodiversity
                            value outside the protected area (terrestrial,
                            freshwater, or maritime ecosystem);
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_31", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. Biodiversity value characterized by listing of
                            protected status (such as IUCN Protected Area
                            Management Categories, Ramsar Convention, national
                            legislation).
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_31", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-31"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-32" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Habitats protected or restored
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Whether partnerships exist with third parties to
                            protect or restore habitat areas distinct from where
                            the organization has overseen and implemented
                            restoration or protection measures
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_32", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Status of each area based on its condition at the
                            close of the reporting period
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_32", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. Standards, methodologies, and assumptions used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_32", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            IUCN Red List species and national conservation list
                            species with habitats in areas affected by
                            operations
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            d. Total number of IUCN Red List species and
                            national conservation list species with habitats in
                            areas affected by the operations of the
                            organization, by level of extinction risk:
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. Critically endangered
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_32", "r4c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>ii. Endangered</div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_32", "r5c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iii. Vulnerable
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_32", "r6c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iv. Near threatened
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_32", "r7c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            v. Least concern
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_32", "r8c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-32"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-33" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Scope 1 GHG emissions
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Biogenic CO2 emissions in metric tons of CO2
                            equivalent.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_33", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. base year for the calculation, if applicable,
                            including:
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_33", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. the rationale for choosing it;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_33", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. emissions in the base year;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_33", "r4c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iii. the context for any significant changes in
                            emissions that triggered recalculations of base year
                            emissions.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_33", "r5c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. Source of the emission factors and the global
                            warming potential (GWP) rates used, or a reference
                            to the GWP source.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_33", "r6c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            d. Consolidation approach for emissions; whether
                            equity share, financial control, or operational
                            control.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_33", "r7c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            e. Standards, methodologies, assumptions, and/or
                            calculation tools used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_33", "r8c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-33"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-34" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Scope 2 GHG emissions
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. base year for the calculation, if applicable,
                            including:
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_34", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. the rationale for choosing it;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_34", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. emissions in the base year;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_34", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iii. the context for any significant changes in
                            emissions that triggered recalculations of base year
                            emissions.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_34", "r4c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Source of the emission factors and the global
                            warming potential (GWP) rates used, or a reference
                            to the GWP source.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_34", "r5c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. Consolidation approach for emissions; whether
                            equity share, financial control, or operational
                            control.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_34", "r6c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            d. Standards, methodologies, assumptions, and/or
                            calculation tools used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_34", "r7c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-34"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-35" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Other indirect (Scope 3) GHG emissions
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Biogenic CO2 emissions in metric tons of CO2
                            equivalent.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_35", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Other indirect (Scope 3) GHG emissions categories
                            and activities included in the calculation.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_35", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. base year for the calculation, if applicable,
                            including:
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_35", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. the rationale for choosing it;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_35", "r4c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. emissions in the base year;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_35", "r5c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iii. the context for any significant changes in
                            emissions that triggered recalculations of base year
                            emissions.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_35", "r6c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            d. Source of the emission factors and the global
                            warming potential (GWP) rates used, or a reference
                            to the GWP source.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_35", "r7c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            e. Standards, methodologies, assumptions, and/or
                            calculation tools used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_35", "r8c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-35"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-36" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            GHG emissions intensity
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            Gases included in the calculation; whether CO2 , CH4
                            , N2O, HFCs, PFCs, SF6 , NF3 , or all.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_36", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-36"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-37" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Reduction of GHG emissions
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            Standards, methodologies, assumptions, and/or
                            calculation tools used in efforts mentioned in
                            previous questions for reduction in GHG emissions.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_37", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-37"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-38" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Emissions of ozone-depleting substances (ODS)
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Production, imports, and exports of ODS in metric
                            tons of CFC-11 (trichlorofluoromethane) equivalent.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Row>
                            <Col span={8}>
                              <div style={{ fontSize: "12px" }}>Production</div>
                            </Col>
                            <Col span={14}>
                              <Form.Item name={["cp6_38", "r1c1"]}>
                                <Input
                                  readOnly={readonly}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={8}>
                              <div style={{ fontSize: "12px" }}>Imports</div>
                            </Col>
                            <Col span={14}>
                              <Form.Item name={["cp6_38", "r1c2"]}>
                                <Input
                                  readOnly={readonly}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={8}>
                              <div style={{ fontSize: "12px" }}>Exports</div>
                            </Col>
                            <Col span={14}>
                              <Form.Item name={["cp6_38", "r1c3"]}>
                                <Input
                                  readOnly={readonly}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Substances included in the calculation.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_38", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            c. Source of the emission factors used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_38", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            d. Standards, methodologies, assumptions, and/or
                            calculation tools used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_38", "r4c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-38"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-39" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Nitrogen oxides (NOx), sulfur oxides (SOx), and
                            other significant air emissions
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. Source of the emission factors used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_39", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Standards, methodologies, assumptions, and/or
                            calculation tools used.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_39", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-39"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-40" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Significant spills
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            a. The following additional information for each
                            spill that was reported in the organization’s
                            financial statements:
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. Location of spill;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_40", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. Volume of spill;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_40", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            iii. Material of spill, categorized by: oil spills
                            (soil or water surfaces), fuel spills (soil or water
                            surfaces), spills of wastes (soil or water
                            surfaces), spills of chemicals (mostly soil or water
                            surfaces), and other (to be specified by the
                            organization).
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_40", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Impacts of significant spills.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_40", "r4c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-40"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-41" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Waste generation and significant waste-related
                            impacts
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            a. For the organization’s significant actual and
                            potential waste-related impacts, a description of:
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. the inputs, activities, and outputs that lead or
                            could lead to these impacts;
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_41", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. whether these impacts relate to waste generated
                            in the organization’s own activities or to waste
                            generated upstream or downstream in its value chain.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_41", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-41"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-42" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Management of significant waste related impacts
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            a. If the waste generated by the organization in its
                            own activities is managed by a third party, a
                            description of the processes used to determine
                            whether the third party manages the waste in line
                            with contractual or legislative obligations.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_42", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. The processes used to collect and monitor
                            waste-related data.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_42", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-42"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-43" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Waste generated
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            Contextual information necessary to understand the
                            data and how the data has been compiled.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_43", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-43"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-44" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Waste diverted from disposal
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            For each recovery operation disclosed provide, a
                            breakdown of the total weight in metric tons of
                            hazardous waste and of non-hazardous waste diverted
                            from disposal:
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4}>
                          <div style={{ fontSize: "12px" }}>i. onsite</div>
                        </Col>
                        <Col span={20}>
                          <Row>
                            <Col span={3}>
                              <div style={{ fontSize: "12px" }}>Hazardous</div>
                            </Col>
                            <Col span={6}>
                              <Form.Item name={["cp6_44", "r1c1"]}>
                                <Input
                                  readOnly={readonly}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <div style={{ fontSize: "12px" }}>
                                Non-hazardous
                              </div>
                            </Col>
                            <Col span={6}>
                              <Form.Item name={["cp6_44", "r1c2"]}>
                                <Input
                                  readOnly={readonly}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4}>
                          <div style={{ fontSize: "12px" }}>ii. offsite.</div>
                        </Col>
                        <Col span={20}>
                          <Row>
                            <Col span={3}>
                              <div style={{ fontSize: "12px" }}>Hazardous</div>
                            </Col>
                            <Col span={6}>
                              <Form.Item name={["cp6_44", "r2c1"]}>
                                <Input
                                  readOnly={readonly}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <div style={{ fontSize: "12px" }}>
                                Non-hazardous
                              </div>
                            </Col>
                            <Col span={6}>
                              <Form.Item name={["cp6_44", "r2c2"]}>
                                <Input
                                  readOnly={readonly}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-44"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-45" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Waste directed to disposal
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            a. For each disposal operation disclosed provide, a
                            breakdown of the total weight in metric tons of
                            hazardous waste and of non-hazardous waste diverted
                            to disposal:
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4}>
                          <div style={{ fontSize: "12px" }}>i. onsite</div>
                        </Col>
                        <Col span={20}>
                          <Row>
                            <Col span={3}>
                              <div style={{ fontSize: "12px" }}>Hazardous</div>
                            </Col>
                            <Col span={6}>
                              <Form.Item name={["cp6_45", "r1c1"]}>
                                <Input
                                  readOnly={readonly}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <div style={{ fontSize: "12px" }}>
                                Non-hazardous
                              </div>
                            </Col>
                            <Col span={6}>
                              <Form.Item name={["cp6_45", "r1c2"]}>
                                <Input
                                  readOnly={readonly}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4}>
                          <div style={{ fontSize: "12px" }}>ii. offsite.</div>
                        </Col>
                        <Col span={20}>
                          <Row>
                            <Col span={3}>
                              <div style={{ fontSize: "12px" }}>Hazardous</div>
                            </Col>
                            <Col span={6}>
                              <Form.Item name={["cp6_45", "r2c1"]}>
                                <Input
                                  readOnly={readonly}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <div style={{ fontSize: "12px" }}>
                                Non-hazardous
                              </div>
                            </Col>
                            <Col span={6}>
                              <Form.Item name={["cp6_45", "r2c2"]}>
                                <Input
                                  readOnly={readonly}
                                  placeholder="Enter details"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            b. Contextual information necessary to understand
                            the data and how the data has been compiled.
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_45", "r3c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-45"
                      question_response_id={defaultdata?.question_response_id}
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP6-46" className="title-header">
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            Materials used by weight or volume
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={20}>
                          <div style={{ fontSize: "12px" }}>
                            a. Total weight or volume of materials that are used
                            to produce and package the organization’s primary
                            products and services during the reporting period,
                            by:
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            i. non- renewable materials used
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_46", "r1c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <div style={{ fontSize: "12px" }}>
                            ii. Renewable materials used
                          </div>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp6_46", "r2c1"]}>
                            <Input
                              readOnly={readonly}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_name="CP6-46"
                      question_response_id={defaultdata?.question_response_id}
                    />
                  </>
                )}
              </Card>
            )}
          </Space>

          {readonly ? (
            ""
          ) : (
            <Space
              style={{
                //marginTop: "10px",
                marginBottom: "10px",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <Button style={{ marginLeft: 10 }} htmlType="submit">
                Save and Continue
              </Button>
            </Space>
          )}
        </Form>
      </div>
    </>
  );
};

export default App;
