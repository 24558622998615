import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form, Select } from "antd";

const { Option } = Select;

const T7 = (props) => {
  const [r10c1, setR10c1] = useState("No");


  const handleChange = (e) => {
  
    
    if (props.ques_id == "CP6-7") {

      let r1c2value = parseFloat(props.form.getFieldValue([props.ques_id, "r1c2"]));
      let r4c2value = parseFloat(props.form.getFieldValue([props.ques_id, "r4c2"]));

      let dividend = 0;

      if (!isNaN(r1c2value)) {
        dividend += r1c2value
      }
     
      if (!isNaN(r4c2value)) {
        dividend += r4c2value
      }


      let divisor = parseFloat(props.form.getFieldValue(["cp6_0", "r1c1"]));

      props.form.setFieldValue(
        [props.ques_id, "r7c2"],
        divisor === 0 ? 0 : Number(((dividend / divisor)).toFixed(2))
      );
    }
      
  };


  useEffect(() => {
    props.form.setFieldsValue(props.data);
    setR10c1(props.form.getFieldValue([props.ques_id, "r10c1"]));
  }, [props.ques_id, props.form, props.data]);
  return (
    <div>
      <Form.Item label="" className="title-header">
        <Row>
          <Col span={24}>
            <div id="tbl-cell">
              <Row>
                <Col span={6}>Parameter</Col>
                <Col
                  span={6}
                  style={{ textAlign: 'center', fontWeight: 'bold' }}
                >
                  Please specify unit
                </Col>
                <Col
                  span={6}
                  style={{ textAlign: 'center', fontWeight: 'bold' }}
                >
                  FY {props?.data?.current_fy} (Current Financial Year)
                </Col>
                <Col
                  span={6}
                  style={{ textAlign: 'center', fontWeight: 'bold' }}
                >
                  FY {props?.data?.previous_fy} (Previous Financial Year)
                </Col>
              </Row>
              <Row>
                <Col span={6}>Total Scope 1 emissions </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r1c1']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r1c2']}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r1c3']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  Remarks (number of gases measured, change in number of gases
                  measured between two reporting years)
                </Col>
                <Col span={18}>
                  <Form.Item
                    name={[props.ques_id, 'r2c1']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  Break-up of the GHG into CO<sub>2</sub>, CH<sub>4</sub>, N
                  <sub>2</sub>O, HFCs, PFCs, SF<sub>6</sub>, NF<sub>3</sub>, if
                  available (Provide a weblink)
                </Col>
                <Col span={18}>
                  <Form.Item
                    name={[props.ques_id, 'r3c1']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>Total Scope 2 emissions</Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r4c1']}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                    
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r4c2']}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r4c3']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3,
                  if available (Provide a weblink)
                </Col>
                <Col span={18}>
                  <Form.Item
                    name={[props.ques_id, 'r5c1']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  Remarks (number of gases measured, change in number of gases
                  measured between two reporting years)
                </Col>
                <Col span={18}>
                  <Form.Item
                    name={[props.ques_id, 'r6c1']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6} style={{ fontWeight: 'bold' }}>
                  Total Scope 1 and Scope 2 emission intensity per rupee of
                  turnover (Total Scope 1 and Scope 2 GHG emissions / Revenue
                  from operations) - kilo tonnes of CO2 eq./crore
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r7c1']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r7c2']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r7c3']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6} style={{ fontWeight: 'bold' }}>
                  Total Scope 1 and Scope 2 emission intensity per rupee of
                  turnover adjusted for Purchasing Power Parity (PPP) (Total
                  Scope 1 and Scope 2 GHG emissions / Revenue from operations
                  adjusted for PPP)
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r8c1']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r8c2']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r8c3']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  Total Scope 1 and Scope 2 emission intensity in terms of
                  physical output
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  "Total Scope 1 and Scope 2 emission intensity (optional) – the
                  relevant metric may be selected by the entity"
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r9c1']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r9c2']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={[props.ques_id, 'r9c3']}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  Indicate if any independent assessment/ evaluation/assurance
                  has been carried out by an external agency? (Y/N) If yes, name
                  of the external agency.
                </Col>
                <Col span={18}>
                  <Row>
                    <Col span={4}>
                      <Form.Item name={[props.ques_id, 'r10c1']} noStyle>
                        <Select
                          disabled={props.readonly}
                          placeholder="Select Option"
                          onChange={(value) => setR10c1(value)}
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {r10c1 === 'Yes' && (
                      <Col span={20}>
                        <Form.Item
                          name={[props.ques_id, 'r10c2']}
                          noStyle
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input readOnly={props.readonly} placeholder="" />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

export default T7;
