import React from "react";
import CommonCompbar from "../../Components/BaseLayout";
import Profile from "./profile";

const Users = () => {
  return (
    <CommonCompbar sidebar={false}>
      <Profile data={"row_data"} />
    </CommonCompbar>
  );
};

export default Users;
