import { createContext, useState, useEffect } from "react";

const CustomerContext = createContext({});
export const CustomerProvider = ({ children }) => {
  const [customer_id, setCustomerId] = useState(null);
  const [assigned_template_id, setAssignedTemplateId] = useState(
    localStorage.getItem("assigned_template_id")
  );
  const [notificationData, setNotificationData] = useState([]);
  const [profile, setProfile] = useState();
  const [questionaire_status, setQuestionaire_status] = useState();

  useEffect(() => {
    setCustomerId(localStorage.getItem("customer_id"));
    setAssignedTemplateId(
      parseInt(localStorage.getItem("assigned_template_id"))
    );
  }, [customer_id, assigned_template_id, setCustomerId, setAssignedTemplateId]);
  return (
    <CustomerContext.Provider
      value={{
        customer_id,
        assigned_template_id,
        setCustomerId,
        setAssignedTemplateId,
        notificationData,
        setNotificationData,
        profile,
        setProfile,
        questionaire_status, setQuestionaire_status
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerContext;
