import React, { useEffect } from "react";
import { Row, Col, Form, Input } from "antd";

const T2 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <Form.Item label="CP9-2" className="title-header">
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <span style={{ fontSize: "12px", fontWeight: "700" }}>
            {`Turnover of products and/ services as a percentage of turnover from all products/service that carry information about.`}
          </span>
          <Row style={{ width: "100%" }}>
            <Col span={24}>
              <div id="tbl-cell">
                <Row>
                  <Col span={8}>
                    <span
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >{``}</span>
                  </Col>
                  <Col span={16}>
                    <span style={{ fontSize: "12px", fontWeight: "600" }}>
                      {`As a percentage to total turnover			`}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col span={8}>
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      {`Environmental and social parameters relevant to the product`}
                    </span>
                  </Col>
                  <Col span={16}>
                    <Form.Item name={[props.ques_id, "r1c1"]}>
                      <Input
                        readOnly={props.readonly}
                        type="number" min="0"
                        size="small"
                        suffix={"%"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      {`Safe and responsible usage`}
                    </span>
                  </Col>
                  <Col span={16}>
                    <Form.Item name={[props.ques_id, "r2c1"]}>
                      <Input
                        readOnly={props.readonly}
                        type="number" min="0"
                        size="small"
                        suffix={"%"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      {`Recycling and/or safe disposal`}
                    </span>
                  </Col>
                  <Col span={16}>
                    <Form.Item name={[props.ques_id, "r3c1"]}>
                      <Input
                        readOnly={props.readonly}
                        type="number" min="0"
                        size="small"
                        suffix={"%"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default T2;
