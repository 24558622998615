import React, { useEffect } from "react";
import { Row, Col, Form } from "antd";
import InputNumber from "../../../../../Components/Input/Number/number";

const T3B = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.data]);
  return (
    <div>
      <Row>
        <Col span={24}>
          <div id="tbl-cell">
            <Row>
              <Col span={4}>Category</Col>
              <Col span={10}>
                FY {props?.data?.current_fy} (Current Financial Year)
              </Col>
              <Col span={10}>
                FY {props?.data?.previous_fy} (Previous Financial Year)
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                Gross wages paid to women as percentage of total wages
              </Col>
              <Col span={10}>
                <Form.Item name={[props.ques_id, "r1c1"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter"  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item name={[props.ques_id, "r1c2"]} className="ant-col1">
                  <InputNumber readOnly={props.readonly} placeholder="Enter"  type="number" min="0" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default T3B;
