import React from "react";
import { Input } from "antd";

const formatNumber = (value) => new Intl.NumberFormat().format(value);

const NumericInput = (props) => {
  const { value, onChange } = props;
  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    //const reg = /^-?\d*(\.\d*)?$/;
    var expr = /^[+]?[-]?[0-9]*$/;
    if (expr.test(inputValue) || inputValue === "+" || inputValue === "-") {
      if (onChange) onChange(inputValue);
    }
    //if (reg.test(inputValue) || inputValue === "" || inputValue === "-" || inputValue === "+") {
    //   if (onChange) onChange(inputValue);
    // }
  };

  const title = value ? (
    <span className="numeric-input-title">
      {value !== "-" ? formatNumber(Number(value)) : "-"}
    </span>
  ) : (
    "Input a number"
  );

  return (
    <Input
      {...props}
      onChange={handleChange}
      placeholder="Enter details"
      maxLength={props.maxLength}
    />
  );
};

export default NumericInput;
