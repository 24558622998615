import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { fetchProfile } from "../Pages/user/userSlice";
import { fetchUserById } from "../Pages/Login/setupSlice/setupSlice";
import {
  create_questionare_comments_slice,
  get_questionare_comments,
} from "../Components/Comments/CommentsSlice/CommentsSlice";
import { get_section } from "../Pages/BackofficeAdmin/Questionaire/SectionSlice/sectionSlice";

const rootReducer = combineReducers({
  fetchProfile: fetchProfile,
  fetchUserById: fetchUserById,
  get_questionare_comments: get_questionare_comments,
  create_questionare_comments_slice: create_questionare_comments_slice,
  get_section: get_section,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;