import React, { useState } from 'react';
import { Row, Col, Input, Form } from "antd";

const TextFieldToTextArea = ({ value, onChange,props }) => {
  const [isTextAreaVisible, setIsTextAreaVisible] = useState(false);

  const handleTextFieldClick = () => {
    setIsTextAreaVisible(true);
  };

  const handleTextAreaChange = (event) => {
    onChange(event.target.value);
  };

  const handleTextAreaBlur = () => {
    setIsTextAreaVisible(false);
  };

  return (
    <div>
      {!isTextAreaVisible ? (
         <Input value={value} readOnly={props.readonly } onClick={handleTextFieldClick}/>
      ) : (
        <textarea
          value={value}
          onChange={handleTextAreaChange}
          onBlur={handleTextAreaBlur}
          style={{
            width: '100%',
            height: '100px',
            padding: '10px',
            boxSizing: 'border-box',
          }}
          autoFocus
        />
      )}
    </div>
  );
};

export default TextFieldToTextArea;
