import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, Col, Row } from "antd";
import { updateTicketsSlice } from "./ticketSlice/ticketSlice";

const ViewTicket = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateTicketsSlice({
        ticket_id: props.ticket_data.id,
        ticket_status: "Viewed",
      })
    );
  }, [dispatch,props.ticket_data.id]);
  const handleUpdateOk = async () => {
    props.setOpen(false);
  };
  return (
    <Modal
      title="View Ticket"
      open={props.open}
      onOk={handleUpdateOk}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={handleUpdateOk}
    >
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <div>
            <span>Title:</span>
          </div>
        </Col>
        <Col span={14}>{props.ticket_data.ticket_title}</Col>
      </Row>
      <div style={{ margin: "24px 0" }} />
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <div>
            <span>Description:</span>
          </div>
        </Col>
        <Col span={14}>{props.ticket_data.ticket_description}</Col>
      </Row>
      <div style={{ margin: "24px 0" }} />
      {/* <Row gutter={[16, 16]}>
        <Col span={6}>
          <div>
            <span>Customer:</span>
          </div>
        </Col>
        <Col span={14}>{props.ticket_data.customer_name}</Col>
      </Row> */}
      <div style={{ margin: "24px 0" }} />
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <div>
            <span>Ticket Status:</span>
          </div>
        </Col>
        <Col span={14}>{props.ticket_data.ticket_status}</Col>
      </Row>
    </Modal>
  );
};

export default ViewTicket;
