import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createCustomerUser,
  getCustomerUserList,
  updateCustomerUser,
} from "../../../../Config/actions";

const initialState = {
  loading: false,
  error: null,
  result: null,
};

const createCustomerUserSlice = createAsyncThunk(
  "customers/createCustomerUser",
  async (value) => {
    return await createCustomerUser(value);
  }
);
const fetchCustomerUser = createAsyncThunk(
  "customers/fetchCustomerUser",
  async (customer_id, thunkAPI) => {
    const response = await getCustomerUserList({ customer_id });
    return response.data;
  }
);
const updateCustomerUserSlice = createAsyncThunk(
  "customers/updateCustomerUser",
  async (value) => {
    return await updateCustomerUser(value);
  }
);
const customerUserSlice = createSlice({
  name: "customeruser",
  initialState,
  reducers: {
    fetchCustomerUser: (state, action) => {
      state.loading = false;
      state.result = action.payload;
    },
  },
});

const { actions, reducer } = customerUserSlice;
export { createCustomerUserSlice, fetchCustomerUser, updateCustomerUserSlice };
export default reducer;
