import React from "react";
import { Modal, Form, Input, Button, Space } from "antd";

const EditCustomerUser = (props) => {
  const [form] = Form.useForm();

  const { customeruser_id, row } = props.data;
  return (
    <Modal title="Edit Customer User" open={props.status} footer={null}>
      <Form
        form={form}
        name="edit"
        onFinish={props.handleEdit}
        initialValues={{
          customeruser_id: customeruser_id,
          display_name: row.display_name,
        }}
        scrollToFirstError
      >
        <div className="modalform">
          <Form.Item name="customeruser_id" label="Customer User ID">
            {customeruser_id}
          </Form.Item>
          <Form.Item
            name="display_name"
            label="Display Name"
            rules={[
              {
                required: true,
                message: "Please input display name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Space
            style={{
              marginTop: "10px",
              justifyContent: "right",
              width: "100%",
            }}
          >
            <Button onClick={props.handleCancel} htmlType="button">
              Cancel
            </Button>
            <Button
              className="ant-btn"
              style={{ marginLeft: 10 }}
              htmlType="submit"
            >
              Save
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};
export default EditCustomerUser;
