import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Card,
  message,
  Tooltip,
  Row,
  Col,
  Input,
  Select,
  Space,
  Form,
  Spin,
} from "antd";
import { QuestionCircleFilled, LoadingOutlined } from "@ant-design/icons";
import AddCP13 from "./Table4-2";
import { useDispatch } from "react-redux";
import {
  create_section_slice,
  get_section,
} from "../SectionSlice/sectionSlice";
import CommentsApp from "../../../../Components/Comments";
import { useCustomer } from "../../../../hooks/useCustomer";
import { useFinancialYear } from "../../../../hooks/useFinancialYear";
import CommentsNotified from "../../../../Components/Comments/comments-notification";

const { Option } = Select;
const App = (props) => {
  const { customer_id, assigned_template_id } = useCustomer();
  const [cp4_2, setCP4_2] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [defaultdata, setDefaultdata] = useState({});
  const { current_fy } = useFinancialYear();
  const [readonly, setReadonly] = useState(false);
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const [commentlist, setCommentlist] = useState([]);
  const [commentreload, setCommentReload] = useState();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (sessionState?.roletype === "CUSTOMER") {
      setReadonly(false);
    } else {
      setReadonly(true);
    }
    let payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_cp4",
    };
    setSpinning(true);
    dispatch(get_section({ payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setDefaultdata(res.data?.section_cp4);
          setCommentlist(res.data?.comments);
          setSpinning(false);
        } else {
          if (res.code != 800000) {
            message.info(res.msg);
          }

          setSpinning(false);
        }
      })
      .catch((Error) => {
        console.log(Error);
      });
  }, [commentreload]);

  const onFinish = (datalist) => {
    setSpinning(true);
    const datalist1 = {
      ...datalist,
      cp4_2,
    };
    let Payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_cp4",
      question_response: datalist1,
    };
    dispatch(create_section_slice({ Payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setTimeout(() => {
            setSpinning(false);
            message.info("Section CP4 created succesfully!");
          }, 3000);
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };
  useEffect(() => {
    form.setFieldsValue(defaultdata);
    setCP4_2(defaultdata.cp4_2 || []);
  }, [form, defaultdata]);
  return (
    <>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 44, color: "white" }} spin />
        }
        tip="Loading..."
        spinning={spinning}
        fullscreen
        size="large"
      />
      <div className="formcontainer">
        <CommentsNotified commentlist={commentlist} />
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={defaultdata}
          scrollToFirstError
          style={{ width: "100%" }}
        >
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Card title="Essential Indicators" size="small">
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP4-1" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Describe the processes for identifying key stakeholder groups of the entity`}
                        </span>
                      </Col>
                      <Col span={14}>
                        <Form.Item name={["cp4_1", "r1c1"]}>
                          <Input.TextArea
                            readOnly={readonly}
                            rows={2}
                            placeholder="Enter key stakeholder groups"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp4_1"
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP4-2" className="title-header">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <div className="space-between">
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`List stakeholder groups identified as key for your entity and the frequency of engagement with each stakeholder group.`}
                          </span>
                        </div>
                        <AddCP13
                          readonly={readonly}
                          data={{ cp4_2 }}
                          setdata={setCP4_2}
                          form={form}
                          ques_id={"cp4_2"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp
                    setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="cp4_2"
                  />
                </>
              )}
            </Card>
          </Space>

          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[3, 4].includes(assigned_template_id) && (
              <Card title="Leadership Indicators" size="small">
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP4-3" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Provide the processes for consultation between stakeholders and the Board on economic, environmental, and social topics or if consultation is delegated, how is feedback from such consultations provided to the Board.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp4_3", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter key stakeholder groups"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp4_3"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP4-4" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span>
                            Whether stakeholder consultation is used to support
                            the identification and management of environmental,
                            and social topics (Yes / No). If so, provide details
                            of instances as to how the inputs received from
                            stakeholders on these topics were incorporated into
                            policies and activities of the entity.
                          </span>
                        </Col>
                        <Col span={16}>
                          <div style={{ display: "flex" }}>
                            <Form.Item name={["cp4_4", "r1c1"]}>
                              <Select
                                disabled={readonly}
                                style={{ width: "200px", marginRight: "10px" }}
                                placeholder="Select Option"
                              >
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                              </Select>
                            </Form.Item>
                            <Form.Item name={["cp4_4", "r1c2"]}>
                              <Input
                                readOnly={readonly}
                                style={{ width: "300px" }}
                                placeholder="Enter Stakeholder consultation"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp4_4"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP4-5" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Provide details of instances of engagement with, and actions taken to, address the concerns of vulnerable/ marginalized stakeholder groups.`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp4_5", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter key engagement"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp4_5"
                    />
                  </>
                )}
              </Card>
            )}
          </Space>
          {/* <h2>END OF LITE/EXTENDED</h2> */}
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[4].includes(assigned_template_id) && (
              <Card title="Leadership Indicators" size="small">
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP4-6" className="title-header">
                      <Row>
                        <Col span={22}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Approach to stakeholder engagement`}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: Describe the entity's approach to engaging with stakeholders`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp4_6", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter key stakeholder groups"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[B]: The purpose of the stakeholder engagement`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp4_6", "r2c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter key stakeholder groups"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp4_6"
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP4-7" className="title-header">
                      <Row>
                        <Col span={22}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Role of the highest governance body in overseeing the management of impacts`}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: Describe the role of the highest governance body and of senior executives in developing, approving, and updating the organization’s purpose, value or mission statements, strategies, policies, and goals related to sustainable development`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp4_7", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter highest governance"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[B]: Describe the role of the highest governance body in reviewing the effectiveness of the organization’s processes as described in 2-12-b, and report the frequency of this review`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["cp4_7", "r2c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter key stakeholder groups"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="cp4_7"
                    />
                  </>
                )}
              </Card>
            )}
          </Space>
          {readonly ? (
            ""
          ) : (
            <Space
              style={{
                //marginTop: "10px",
                marginBottom: "10px",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <Button style={{ marginLeft: 10 }} htmlType="submit">
                Save and Continue
              </Button>
            </Space>
          )}
        </Form>
      </div>
    </>
  );
};

export default App;
