import React, { useEffect, useState } from "react";
import { Divider, Row, Col, Form, Input, Select, Radio } from "antd";
import CommentsApp from "../../../../Components/Comments";

const { Option } = Select;
const CP3_6 = (props) => {
  const [disabilities, setdisabilities] = useState();
  const [txtCategories, setTxtCategories] = useState();

  const rdopw = (e) => {
    if (e.target.value === "No") {
      setdisabilities("hidden");
    } else {
      setdisabilities();
    }
  };

  const rdoCategories = (e) => {
    if (e.target.value === "No") {
      setTxtCategories("none");
    } else {
      setTxtCategories();
    }
  };
  return (
    <div>
      <div id="tbl-cell">
        <Row>
          <Col span={4}>
            <Form.Item name={[props.ques_id, "r1c1a"]}>
              <Radio.Group onChange={rdoCategories} disabled={props.readonly}>
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <div id="tbl-cell">
            <Row style={{ width: "100%" }}>
              <Col span={6} className="text-center">
                <span style={{ fontSize: "12px", fontWeight: "400" }}>
                  {`Permanent Workers`}
                </span>
              </Col>
              <Col span={5}>
                <Form.Item name={[props.ques_id, "r1c1"]}>
                  <Radio.Group onChange={rdopw} disabled={props.readonly}>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item name={[props.ques_id, "r1c2"]}>
                  <Input.TextArea rows={2} readOnly={props.readonly} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col span={6} className="text-center">
                <span style={{ fontSize: "12px", fontWeight: "400" }}>
                  Other than Permanent Workers
                </span>
              </Col>
              <Col span={5}>
                <Form.Item name={[props.ques_id, "r2c1"]}>
                  <Radio.Group onChange={rdopw} disabled={props.readonly}>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item name={[props.ques_id, "r2c2"]}>
                  <Input.TextArea rows={2} readOnly={props.readonly} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col span={6} className="text-center">
                <span style={{ fontSize: "12px", fontWeight: "400" }}>
                  Permanent Employees
                </span>
              </Col>
              <Col span={5}>
                <Form.Item name={[props.ques_id, "r3c1"]}>
                  <Radio.Group onChange={rdopw} disabled={props.readonly}>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item name={[props.ques_id, "r3c2"]}>
                  <Input.TextArea rows={2} readOnly={props.readonly} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col span={6} className="text-center">
                <span style={{ fontSize: "12px", fontWeight: "400" }}>
                  Other than Permanent Employees
                </span>
              </Col>
              <Col span={5}>
                <Form.Item name={[props.ques_id, "r4c1"]}>
                  <Radio.Group onChange={rdopw} disabled={props.readonly}>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item name={[props.ques_id, "r4c2"]}>
                  <Input.TextArea rows={2} readOnly={props.readonly} />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CP3_6;
