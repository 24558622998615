import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Table,
  Modal,
  message,
  Row,
  Col,
  Popconfirm,
} from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";

const T26 = (props) => {
  const [form] = Form.useForm();
  const [modelOpen, setModelOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [material_issue, setMaterial_issue] = useState("");
  const [indicate_ro, setIndicate_ro] = useState("");
  const [rationale_ro, setRationale_ro] = useState("");
  const [financial_ro, setFinancial_ro] = useState("");
  const [mitigate, setMitigate] = useState("");
  const handleDelete = (key) => {
    const newData = dataSource.filter((item, index) => index !== key);
    setDataSource(newData);
    props.setdata(newData);
  };
  const Columns = [
    {
      title: 'SNo.',
      dataIndex: 'key',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Material issue identified',
      dataIndex: 'r1c1',
      width: '20%',
      editable: true,
    },
    {
      title: 'Indicate whether risk or opportunity (R/O)',
      dataIndex: 'r1c2',
    },
    {
      title: 'Rationale for identifying the risk/opportunity',
      dataIndex: 'r1c3',
    },
    {
      title: 'Incase of risk, approach to adapt or mitigate',
      dataIndex: 'r1c4',
    },
    {
      title:
        'Financial implications of the risk or opportunity (indicate positive or negative implications)',
      dataIndex: 'r1c5',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record, index) =>
        dataSource.length >= 1 ? (
          <>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(index)}
            >
              <Button className="ant-btn-link" type="link">
                <DeleteOutlined twoToneColor="#eb2f96" />
              </Button>
            </Popconfirm>
          </>
        ) : null,
    },
  ];

  useEffect(() => {
    setDataSource(props.data.a26 || []);
  }, [props.data]);

  const btnAdd = () => {
    const newData = {
      r1c1: material_issue,
      r1c2: indicate_ro,
      r1c3: rationale_ro,
      r1c4: mitigate,
      r1c5: financial_ro,
    };
    setDataSource([...dataSource, newData]);
    props.setdata([...dataSource, newData]);
    message.success("Processing complete!");
    setModelOpen(false);
  };
  return (
    <div>
      <Row>
        <Col span={24} offset={22}>
          <Button
            onClick={() => {
              setModelOpen(true);
              setFinancial_ro('');
              setIndicate_ro('');
              setMaterial_issue('');
              setRationale_ro('');
              form.resetFields();
            }}
            disabled={props.readonly}
          >
            Add
          </Button>
        </Col>
      </Row>
      <Table
        disabled={true}
        dataSource={dataSource}
        columns={Columns}
        pagination={false}
        className="f-table-wrapper"
        scroll={{
          y: 240,
        }}
      />

      <Modal
        title="Add"
        style={{ top: 20 }}
        open={modelOpen}
        onOk={btnAdd}
        okText="Add"
        onCancel={() => setModelOpen(false)}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 22 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <div className="modalform">
            <label>Material issue identified</label>
            <Form.Item name="r1c1" label="">
              <TextArea
                rows={4}
                onChange={(e) => setMaterial_issue(e.target.value)}
              />
            </Form.Item>
            <label>Indicate whether risk or opportunity (R/O)</label>
            <Form.Item name="r1c2" label="">
              <TextArea
                rows={4}
                onChange={(e) => setIndicate_ro(e.target.value)}
              />
            </Form.Item>
            <label>Rationale for identifying the risk/opportunity</label>
            <Form.Item name="r1c3" label="">
              <TextArea
                rows={4}
                onChange={(e) => setRationale_ro(e.target.value)}
              />
            </Form.Item>
            <label>Incase of risk, approach to adapt or mitigate</label>
            <Form.Item name="r1c4" label="">
              <TextArea
                rows={4}
                onChange={(e) => setMitigate(e.target.value)}
              />
            </Form.Item>
            <label>Financial implications of the risk or opportunity</label>
            <Form.Item name="r1c5" label="">
              <TextArea
                rows={4}
                onChange={(e) => setFinancial_ro(e.target.value)}
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default T26;
