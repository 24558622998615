import React from "react";
import { Row, Col, Form, Input } from "antd";

const CP8_5Comp = (props) => {
  return (
    <Form.Item label="CP8-5" className="title-header">
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <span style={{ fontSize: "12px", fontWeight: "700" }}>
            {`Job creation in smaller towns – Disclose wages paid to persons employed (including employees or workers employed on a permanent or non-permanent / on contract basis) in the following locations, as % of total wage cost.`}
          </span>
          <Row style={{ width: "100%" }}>
            <Col span={24}>
              <div id="tbl-cell">
                <Row>
                  <Col span={8} className="text-center">
                    <span
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >{`Location`}</span>
                  </Col>
                  <Col span={8} className="text-center">
                    <span style={{ fontSize: "12px", fontWeight: "600" }}>
                      {/* {`FY _______Current Financial Year`} */}
                      FY {props?.data?.current_fy} (Current)
                    </span>
                  </Col>
                  <Col span={8} className="text-center">
                    <span style={{ fontSize: "12px", fontWeight: "600" }}>
                      FY {props?.data?.previous_fy} (Previous)
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col span={8} className="text-center">
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      {`Rural`}
                    </span>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={[
                        "cp8_5",
                        "r1c1",
                      ]}
                    >
                      <Input
                        readOnly={props.readonly}
                        type="number" min="0"
                        size="small"
                        suffix={"%"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={[
                        "cp8_5",
                        "r1c2",
                      ]}
                    >
                      <Input
                        readOnly={props.readonly}
                        type="number" min="0"
                        size="small"
                        suffix={"%"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className="text-center">
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      {`Semi-urban`}
                    </span>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={[
                        "cp8_5",
                        "r2c1",                   
                      ]}
                    >
                      <Input
                        readOnly={props.readonly}
                        type="number" min="0"
                        size="small"
                        suffix={"%"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={[
                        "cp8_5",
                        "r2c2",
                      ]}
                    >
                      <Input
                        readOnly={props.readonly}
                        type="number" min="0"
                        size="small"
                        suffix={"%"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className="text-center">
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      {`Urban`}
                    </span>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={[
                        "cp8_5",
                        "r3c1",
                     
                      ]}
                    >
                      <Input
                        readOnly={props.readonly}
                        type="number" min="0"
                        size="small"
                        suffix={"%"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={[
                        "cp8_5",
                        "r3c2",
                      ]}
                    >
                      <Input
                        readOnly={props.readonly}
                        type="number" min="0"
                        size="small"
                        suffix={"%"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className="text-center">
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      {`Metropolitan`}
                    </span>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={[
                        "cp8_5",
                        "r4c1",
                      ]}
                    >
                      <Input
                        readOnly={props.readonly}
                        type="number" min="0"
                        size="small"
                        suffix={"%"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={[
                        "cp8_5",
                        "r4c2",
                      ]}
                    >
                      <Input
                        readOnly={props.readonly}
                        type="number" min="0"
                        size="small"
                        suffix={"%"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default CP8_5Comp;
