import React, { useEffect } from "react";
import { Row, Col, Input, Form } from "antd";

const CP5Comp = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <Form.Item label="CP1-5" className="title-header">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <span className="ant-col-header">
            {`Number of Directors/KMPs/employees/workers against whom disciplinary action was taken by any law enforcement agency for the charges of bribery/ corruption:`}
          </span>
          <div id="tbl-cell">
            <Row>
              <Col span={8}>
                <span className="ant-col-header">{`Leadership position`}</span>
              </Col>
              <Col span={8}>
                <span className="ant-col-header">
                  {`FY (Current Financial Year)`}
                </span>
              </Col>
              <Col span={8}>
                <span className="ant-col-header">
                  {`FY (Previous Financial Year)`}
                </span>
              </Col>
            </Row>

            <Row>
              <Col span={8}>
                <span className="ant-col-header">{`Directors`}</span>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r1c1"]}>
                  {/* <DatePicker
                      picker="year"
                      onChange={(e) => e.$y}
                      size="small"
                    /> */}
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r1c2"]}>
                  {/* <DatePicker
                      picker="year"
                      onChange={(e) => e.$y}
                      size="small"
                    /> */}
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <span className="ant-col-header">{`KMPs`}</span>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r2c1"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r2c2"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <span className="ant-col-header">{`Employees`}</span>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r3c1"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r3c2"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <span className="ant-col-header">{`Workers`}</span>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r4c1"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={[props.ques_id, "r4c2"]}>
                  <Input size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default CP5Comp;
