import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form, Select } from "antd";
import * as common from "../../../../../util/common";
import FloatInput from "../../../../../Components/Input/numbertofloat";
const { Option } = Select;

const T9 = (props) => {
  const [r22c1, setR22c1] = useState("No");
  const formula_defination = {
    r10c1: {
      type: "sumfloat",
      cols: [
        "r1c1",
        "r2c1",
        "r3c1",
        "r4c1",
        "r5c1",
        "r6c1",
        "r7c1",
        "r8c1",
        "r9c1",
      ],
    },
    r10c2: {
      type: "sumfloat",
      cols: [
        "r1c2",
        "r2c2",
        "r3c2",
        "r4c2",
        "r5c2",
        "r6c2",
        "r7c2",
        "r8c2",
        "r9c2",
      ],
    },
    r17c1: {
      type: "sumfloat",
      cols: ["r14c1", "r15c1", "r16c1"],
    },
    r17c2: {
      type: "sumfloat",
      cols: ["r14c2", "r15c2", "r16c2"],
    },
    r21c1: {
      type: "sumfloat",
      cols: ["r18c1", "r19c1", "r20c1"],
    },
    r21c2: {
      type: "sumfloat",
      cols: ["r18c2", "r19c2", "r20c2"],
    },
  };

  const handleChange = (e) => {
    common.arthiFunc(props.ques_id, formula_defination, props.form);

    let divisor = parseFloat(props.form.getFieldValue(["cp6_0", "r1c1"]));
    let r10c1value = parseFloat(props.form.getFieldValue([props.ques_id, "r10c1"]));

    props.form.setFieldValue(
      [props.ques_id, "r11c1"],
      divisor === 0 ? 0 : Number(((r10c1value / divisor)).toFixed(2))
    );

  };
  useEffect(() => {
    props.form.setFieldsValue(props.data);
    setR22c1(props.form.getFieldValue([props.ques_id, "r22c1"]));
  }, [props.ques_id, props.form, props.data]);
  return (
    <div>
      <Form.Item label="" className="title-header">
        <Row>
          <Col span={24}>
            <div id="tbl-cell">
              <Row>
                <Col span={8}>Please specify unit</Col>
                <Col
                  span={8}
                  style={{ textAlign: 'center', fontWeight: 'bold' }}
                >
                  FY {props?.data?.current_fy} (Current Financial Year)
                </Col>
                <Col
                  span={8}
                  style={{ textAlign: 'center', fontWeight: 'bold' }}
                >
                  FY {props?.data?.previous_fy} (Previous Financial Year)
                </Col>
              </Row>
              <Row>
                <Col
                  span={24}
                  style={{ textAlign: 'center', fontWeight: 'bold' }}
                >
                  Total Waste generated (in metric tonnes)
                </Col>
              </Row>
              <Row>
                <Col span={8}>Plastic waste (A) </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r1c1']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r1c2']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>E-waste (B)</Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r2c1']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r2c2']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>Bio-medical waste (C) </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r3c1']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r3c2']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>Construction and demolition waste (D)</Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r4c1']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r4c2']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>Battery waste (E) </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r5c1']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r5c2']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>Radioactive waste (F)</Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r6c1']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r6c2']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  Other Hazardous waste. Please specify, if any input text
                  field. (G)
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r7c1']}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r7c2']}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  Other Non-hazardous waste generated (H). Please specify, if
                  any input text field.
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r8c1']}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r8c2']}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  Break-up of non-hazardous waste generated by composition i.e.
                  by materials relevant to the sector Input text field add rows
                  if necessary
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r9c1']}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r9c2']}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>
                  Total (A + B + C + D + E + F + G+ H)
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r10c1']}
                    className="ant-col1"
                  >
                    <FloatInput readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r10c2']}
                    className="ant-col1"
                  >
                    <FloatInput readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>
                  Waste intensity per rupee of turnover (Total waste generated
                  /Revenue from operations) - tonnes/crore
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r11c1']}
                    className="ant-col1"
                  >
                    <FloatInput readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r11c2']}
                    className="ant-col1"
                  >
                    <FloatInput readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>
                  Waste intensity per rupee of turnover adjusted for Purchasing
                  Power Parity (PPP) (Total waste generated / Revenue from
                  operations adjusted for PPP)
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r12c1']}
                    className="ant-col1"
                  >
                    <FloatInput readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r12c2']}
                    className="ant-col1"
                  >
                    <FloatInput readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ fontWeight: 'bold' }}>
                  Waste intensity in terms of physical output
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>
                  Waste intensity (optional) – the relevant metric may be
                  selected by the entity
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r13c1']}
                    className="ant-col1"
                  >
                    <FloatInput readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r13c2']}
                    className="ant-col1"
                  >
                    <FloatInput readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  For each category of waste generated, total waste recovered
                  through recycling, re-using or other recovery operations (in
                  metric tonnes)
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ fontWeight: 'bold' }}>
                  Category of waste
                </Col>
              </Row>
              <Row>
                <Col span={8}>(i) Recycled</Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r14c1']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r14c2']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>(ii) Re-used</Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r15c1']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r15c2']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>(iii) Other recovery operations </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r16c1']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r16c2']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>
                  Total
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r17c1']}
                    className="ant-col1"
                  >
                    <FloatInput readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r17c2']}
                    className="ant-col1"
                  >
                    <FloatInput readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  For each category of waste generated, total waste disposed by
                  nature of disposal method (in metric tonnes)
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ fontWeight: 'bold' }}>
                  Category of waste
                </Col>
              </Row>
              <Row>
                <Col span={8}>(i) Incineration </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r18c1']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r18c2']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>(ii) Landfilling </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r19c1']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r19c2']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>(iii) Other disposal operations </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r20c1']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r20c2']}
                    className="ant-col1"
                  >
                    <FloatInput
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>
                  Total
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r21c1']}
                    className="ant-col1"
                  >
                    <FloatInput readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, 'r21c2']}
                    className="ant-col1"
                  >
                    <FloatInput readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  Indicate if any independent assessment/ evaluation/assurance
                  has been carried out by an external agency? (Y/N) If yes, name
                  of the external agency
                </Col>
                <Col span={16}>
                  <Row>
                    <Col span={4}>
                      <Form.Item
                        name={[props.ques_id, 'r22c1']}
                        noStyle
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Select
                          disabled={props.readonly}
                          placeholder="Select Option"
                          onChange={(value) => setR22c1(value)}
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {r22c1 === 'Yes' && (
                      <Col span={20}>
                        <Form.Item
                          name={[props.ques_id, 'r22c2']}
                          noStyle
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <FloatInput
                            readOnly={props.readonly}
                            placeholder=""
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

export default T9;
