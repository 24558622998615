import React, { useEffect } from "react";
import { Row, Col, Input, Form } from "antd";

const T4 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <Form.Item label="CP9-4" className="title-header">
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <span style={{ fontSize: "12px", fontWeight: "700" }}>
            {`Details of instances of product recalls on account of safety issues:`}
          </span>
          <Row style={{ width: "100%" }}>
            <Col span={24}>
              <div id="tbl-cell">
                <Row>
                  <Col span={8} className="text-center">
                    <span
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >{``}</span>
                  </Col>
                  <Col span={8} className="text-center">
                    <span style={{ fontSize: "12px", fontWeight: "600" }}>
                      {`Number`}
                    </span>
                  </Col>
                  <Col span={8} className="text-center">
                    <span style={{ fontSize: "12px", fontWeight: "600" }}>
                      {`Reasons for recall`}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col span={8} className="text-center">
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      {`Voluntary recalls`}
                    </span>
                  </Col>

                  <Col span={8}>
                    <Form.Item name={[props.ques_id, "r1c1"]}>
                      <Input
                        readOnly={props.readonly}
                        type="number"
                        min="0"
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={[props.ques_id, "r1c2"]}>
                      <Input.TextArea
                        readOnly={props.readonly}
                        rows={2}
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className="text-center">
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      {`Forced recalls`}
                    </span>
                  </Col>

                  <Col span={8}>
                    <Form.Item name={[props.ques_id, "r2c1"]}>
                      <Input
                        readOnly={props.readonly}
                        type="number"
                        min="0"
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={[props.ques_id, "r2c2"]}>
                      <Input.TextArea
                        readOnly={props.readonly}
                        rows={2}
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default T4;
