import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getProfile } from "../../Config/actions";

const fetchProfile = createAsyncThunk(
  "customers/fetchCustomer",
  async (userId, thunkAPI) => {
    const response = await getProfile();
    return response.data;
  }
);

const { reducer } = fetchProfile;
export { fetchProfile };
export default reducer;
