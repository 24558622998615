import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Select } from "antd";
import * as common from "../../../../../util/common";
const { Option } = Select;

const T4 = (props) => {
  const [r12c1, setR12c1] = useState("No");
  const formula_defination = {
    r11c1: {
      type: "sum",
      cols: [
        "r1c1",
        "r2c1",
        "r3c1",
        "r4c1",
        "r5c1",
        "r6c1",
        "r7c1",
        "r8c1",
        "r9c1",
        "r10c1",
      ],
    },
    r11c2: {
      type: "sum",
      cols: [
        "r1c2",
        "r2c2",
        "r3c2",
        "r4c2",
        "r5c2",
        "r6c2",
        "r7c2",
        "r8c2",
        "r9c2",
        "r10c2",
      ],
    },
  };

  const handleChange = (e) => {
    common.arthiFunc(props.ques_id, formula_defination, props.form);
  };
  useEffect(() => {
    props.form.setFieldsValue(props.data);
    setR12c1(props.form.getFieldValue([props.ques_id, "r12c1"]));
  }, [props.ques_id, props.form, props.data]);
  return (
    <div>
      <Form.Item label="" className="title-header">
        <Row>
          <Col span={24}>
            <div id="tbl-cell">
              <Row>
                <Col span={8}>Parameter</Col>
                <Col
                  span={8}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  FY {props?.data?.current_fy} (Current Financial Year)
                </Col>
                <Col
                  span={8}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  FY {props?.data?.previous_fy} (Previous Financial Year)
                </Col>
              </Row>
              <Row>
                <Col
                  span={24}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  Water discharge by destination and level of treatment (in
                  kilolitres)
                </Col>
              </Row>
              <Row>
                <Col span={24}>(i) To Surface water </Col>
              </Row>
              <Row>
                <Col span={8}>No treatment </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r1c1"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r1c2"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  With treatment
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r2c1"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r2c2"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>(ii) To Groundwater </Col>
              </Row>
              <Row>
                <Col span={8}>No treatment </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r3c1"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r3c2"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  With treatment
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r41c1"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r4c2"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>(iii) To Seawater </Col>
              </Row>
              <Row>
                <Col span={8}>No treatment </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r5c1"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r5c2"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  With treatment
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r6c1"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r6c2"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>(iv) Sent to third-parties </Col>
              </Row>
              <Row>
                <Col span={8}>No treatment </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r7c1"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r7c2"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  With treatment
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r8c1"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r8c2"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>(v) Others </Col>
              </Row>
              <Row>
                <Col span={8}>No treatment </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r9c1"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r9c2"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  With treatment
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r10c1"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r10c2"]}
                    className="ant-col1"
                  >
                    <Input
                      readOnly={props.readonly}
                      placeholder="Enter"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: "bold" }}>
                  Total water discharged (in kilolitres)
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r11c1"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[props.ques_id, "r11c2"]}
                    className="ant-col1"
                  >
                    <Input readOnly={props.readonly} placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  Indicate if any independent assessment/ evaluation/assurance
                  has been carried out by an external agency? (Y/N) If yes, name
                  of the external agency.
                </Col>
                <Col span={16}>
                  <Row>
                    <Col span={4}>
                      <Form.Item
                        name={[props.ques_id, "r12c1"]}
                        noStyle
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Select
                          disabled={props.readonly}
                          placeholder="Select Option"
                          onChange={(value) => setR12c1(value)}
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {r12c1 === "Yes" && (
                      <Col span={20}>
                        <Form.Item
                          name={[props.ques_id, "r12c2"]}
                          noStyle
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input readOnly={props.readonly} placeholder="" />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

export default T4;
