import React, { useEffect } from "react";
import { Row, Col, Input, Form } from "antd";

const CP5Comp = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);
  return (
    <div>
      <Form.Item label="CP1-9" className="title-header">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <span style={{ fontSize: "12px", fontWeight: "700" }}>
              {`Provide details of concentration of purchases and sales with trading houses, dealers, and related parties along-with loans and advances & investments, with related parties, in the following format:`}
            </span>
            <div id="tbl-cell">
              <Row>
                <Col span={4}>
                  <span className="ant-col-header">{`Parameter`}</span>
                </Col>
                <Col span={8}>
                  <span className="ant-col-header">{`Metrics`}</span>
                </Col>
                <Col span={6} className="text-center">
                  <span className="ant-col-header">
                    {`FY (Current Financial Year)`}
                  </span>
                </Col>
                <Col span={6} className="text-center">
                  <span className="ant-col-header">
                    {`FY (Previous Financial Year)`}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col span={4}>
                  <span className="ant-col-header">
                    {`Concentration of Purchases`}
                  </span>
                </Col>
                <Col span={20}>
                  <Row>
                    <Col span={10}>
                      <span style={{ fontSize: "12px", fontWeight: "400" }}>
                        {`A. Purchases from trading houses  (or SMEs) as % of total purchases`}
                      </span>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r1c1"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r1c2"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <span style={{ fontSize: "12px", fontWeight: "400" }}>
                        {`B. Number of trading houses  (or SMEs) where purchases are made from`}
                      </span>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r2c1"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r2c2"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <span style={{ fontSize: "12px", fontWeight: "400" }}>
                        {`C. Purchases from top 10 trading houses  (or SMEs) as % of total purchases from trading houses`}
                      </span>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r3c1"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r3c2"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col span={4}>
                  <span className="ant-col-header">
                    {`Concentration of Sales`}
                  </span>
                </Col>
                <Col span={20}>
                  <Row>
                    <Col span={10}>
                      <span style={{ fontSize: "12px", fontWeight: "400" }}>
                        {`A. Sales to dealers/distributors/OEMS/Processing as % of total sales`}
                      </span>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r4c1"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r4c2"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <span style={{ fontSize: "12px", fontWeight: "400" }}>
                        {`B. Number of dealers/distributors/OEMS/Processing to whom sales are made`}
                      </span>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r5c1"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r5c2"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <span style={{ fontSize: "12px", fontWeight: "400" }}>
                        {`C. Sales to top 10 dealers/distributors/OEMS/Processing as % of total sales to dealers/distributors/OEMS/Processing`}
                      </span>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r6c1"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r6c2"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col span={4}>
                  <span className="ant-col-header">{`Share of RPTs in`}</span>
                </Col>
                <Col span={20}>
                  <Row>
                    <Col span={10}>
                      <span style={{ fontSize: "12px", fontWeight: "400" }}>
                        {`A. Purchases (Purchases with related parties / Total Purchases)`}
                      </span>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r7c1"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r7c2"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <span style={{ fontSize: "12px", fontWeight: "400" }}>
                        {`B. Sales (Sales to related parties / Total Sales)`}
                      </span>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r8c1"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r8c2"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <span style={{ fontSize: "12px", fontWeight: "400" }}>
                        {`C. Loans & advances (Loans & advances given to related parties / Total loans & advances)`}
                      </span>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r9c1"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r9c2"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={10}>
                      <span style={{ fontSize: "12px", fontWeight: "400" }}>
                        {`D. Investments ( Investments in related parties / Total Investments made)`}
                      </span>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r10c1"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={[props.ques_id, "r10c2"]}>
                        <Input size="small" readOnly={props.readonly} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

export default CP5Comp;
