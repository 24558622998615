import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Card,
  message,
  Row,
  Col,
  Input,
  Select,
  Space,
  Form,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  create_section_slice,
  get_section,
} from "../SectionSlice/sectionSlice";
import CommentsApp from "../../../../Components/Comments";
import { useFinancialYear } from "../../../../hooks/useFinancialYear";
import { useCustomer } from "../../../../hooks/useCustomer";
import CommentsNotified from "../../../../Components/Comments/comments-notification";

const { Option } = Select;
const App = (props) => {
  const { current_fy } = useFinancialYear();
  const { customer_id, assigned_template_id } = useCustomer();
  const [spinning, setSpinning] = useState(false);
  const [defaultdata, setDefaultdata] = useState({});
  const [readonly, setReadonly] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const [commentlist, setCommentlist] = useState([]);
  const [commentreload, setCommentReload] = useState();

  useEffect(() => {
    if (sessionState?.roletype === "CUSTOMER") {
      setReadonly(false);
    } else {
      setReadonly(true);
    }
    let payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "Section_D",
    };
    setSpinning(true);
    dispatch(get_section({ payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setDefaultdata(res.data?.Section_D);
          setCommentlist(res.data?.comments);
          setSpinning(false);
        } else {
          if (res.code != 800000) {
            message.info(res.msg);
          }

          setSpinning(false);
        }
      })
      .catch((Error) => {
        console.log(Error);
      });
  }, [commentreload]);
  useEffect(() => {
    form.setFieldsValue(defaultdata);
  }, [form, defaultdata]);

  const onFinish = (datalist) => {
    setSpinning(true);
    let Payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "Section_D",
      question_response: datalist,
    };
    dispatch(create_section_slice({ Payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setSpinning(false);
          message.info("Section D created succesfully!");
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  return (
    <>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 44, color: "white" }} spin />
        }
        tip="Loading..."
        spinning={spinning}
        fullscreen
        size="large"
      />
      <div className="formcontainer">
        <CommentsNotified commentlist={commentlist} />
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={defaultdata}
          scrollToFirstError
          style={{ width: "100%" }}
        >
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[3, 4].includes(assigned_template_id) && (
              <Card
                title="[A.] Hotspots indicative of human trafficking"
                size="small"
              >
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D1" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Are you aware of the composition of your work force?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d1", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp
                        setCommentReload={setCommentReload}
                        question_response_id={defaultdata.question_response_id}
                        question_response_name="d1"
                      />
                    </Form.Item>
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D2" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`How are the workers in your supply chain recruited?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d2", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d2"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D3" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Has your supplier maintained a complete and accurate data bank containing the details of theworkers and benefits paid to them?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d3", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d3"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D4" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Are the workers living in temporary, unsafe and unhygienic accommodation?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d4", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d4"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D5" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Are there ‘high security’ measures at the supplier’s facility?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d5", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d5"
                    />
                  </>
                )}
              </Card>
            )}
          </Space>

          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[3, 4].includes(assigned_template_id) && (
              <Card
                title="[B.] Hotspots indicative of forced labour"
                size="small"
              >
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D6" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Do the workers have alternate employment opportunity in the area where the facility is located?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d6", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp
                        setCommentReload={setCommentReload}
                        question_response_id={defaultdata.question_response_id}
                        question_response_name="d6"
                      />
                    </Form.Item>
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D7" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Are the workers contracted (as opposed to employed) in large numbers throughout the supplychain ?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d7", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d7"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D8" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Is there a formal complaint procedure and who is responsible for handling the complaint procedure?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d8", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d8"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D9" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Is the system of payment cash-based or bank/digital?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d9", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d9"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D10" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Are you told that workers are suffering from persistent injury/illness?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d10", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d10"
                    />
                  </>
                )}
              </Card>
            )}
          </Space>

          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[3, 4].includes(assigned_template_id) && (
              <Card
                title="[C.] Hotspots indicative of child labour"
                size="small"
              >
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D11" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Do you see a large number of young workers in the facility?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d11", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp
                        setCommentReload={setCommentReload}
                        question_response_id={defaultdata.question_response_id}
                        question_response_name="d11"
                      />
                    </Form.Item>
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D12" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Is a certain section of workers paid very low wages?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d12", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d12"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D13" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Are the young workers employed in hazardous/labour intensive profession?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d13", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d13"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D14" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Have inspections been conducted by the regulatory authority under child labour preventionregulations, and are you aware of their findings?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d14", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d14"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D15" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Are there schools close to the supplier’s facility?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d15", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d15"
                    />
                  </>
                )}
              </Card>
            )}
          </Space>

          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[3, 4].includes(assigned_template_id) && (
              <Card
                title="[D.] Hotspots indicative of discrimination"
                size="small"
              >
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D16" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Are female workers being paid lower wages for equivalent hours of work compared to maleworkers?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d16", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp
                        setCommentReload={setCommentReload}
                        question_response_id={defaultdata.question_response_id}
                        question_response_name="d16"
                      />
                    </Form.Item>
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D17" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Are some workers on fixed term contracts while others are permanently employed?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d17", "r1c1"]}>
                            <Input.TextArea
                              readOnly={readonly}
                              rows={2}
                              placeholder="Enter details"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d17"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D18" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Are there practices to keep certain workers ‘separate’?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d18", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d18"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D19" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Is there unfair treatment to women in case of pregnancy?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d19", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d19"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D20" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Is there a robust process to prevent and investigate harassment?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d20", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d20"
                    />
                  </>
                )}
              </Card>
            )}
          </Space>

          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {[3, 4].includes(assigned_template_id) && (
              <Card
                title="[E.] Hotspots indicative of substandard health and safety facilities"
                size="small"
              >
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D21" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Are accidents common in your suppliers’ facilities?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d21", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <CommentsApp
                        setCommentReload={setCommentReload}
                        question_response_id={defaultdata.question_response_id}
                        question_response_name="d21"
                      />
                    </Form.Item>
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D22" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Are the workers exposed to occupational hazards and suffering from occupational disease?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d22", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d22"
                    />
                    <Divider />
                  </>
                )}
                {[3, 4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="D23" className="title-header">
                      <Row>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Are the facilities using upgraded technology / equipment as part of their safety procedures?`}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Form.Item name={["d23", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <CommentsApp
                      setCommentReload={setCommentReload}
                      question_response_id={defaultdata.question_response_id}
                      question_response_name="d23"
                    />
                    <Divider />
                  </>
                )}
              </Card>
            )}
          </Space>
          {readonly ? (
            ""
          ) : (
            <Space
              style={{
                //marginTop: "10px",
                marginBottom: "10px",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <Button style={{ marginLeft: 10 }} htmlType="submit">
                Save and Continue
              </Button>
            </Space>
          )}
        </Form>
      </div>
    </>
  );
};

export default App;
