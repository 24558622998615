import React from "react";
import { useNavigate } from "react-router-dom";
import { Input, Divider, Form, Button, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import CommonCompbar from "../../../Components/BaseLayout";
import { useDispatch } from "react-redux";
import { createBackofficeUserSlice } from "./backofficeSlice";

const CreateBackOfficeUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onFinishFailed = async (values) => {
    navigate("/backoffice/users");
  };
  const onFinish = async (values) => {
    try {
      let response = await dispatch(
        createBackofficeUserSlice({
          values,
        })
      );
      if (!response === undefined) return;
      message.info(response?.payload?.data?.msg);
      if (response?.payload?.data?.code === 0) {
        navigate("/backoffice/users");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <CommonCompbar sidebar={false}>
      <h3>Back Office User Onboarding</h3>
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Form
          layout="vertical"
          name="backoffice_user_registration"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ maxWidth: 500 }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Item
              label="Display Name"
              name="display_name"
              style={{ paddingRight: '5px' }}
              rules={[
                {
                  type: 'text',
                  required: true,
                  message: 'Please input your display name!',
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Enter details"
              />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input
                className="m-login-input"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="email"
              />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Item
              name="password"
              label="Password"
              style={{ paddingRight: '5px' }}
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The new password that you entered do not match!'
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Item
              label="Employee Id"
              name="employee_id"
              style={{ paddingRight: '5px' }}
            >
              <Input
                className="m-login-input"
                placeholder="Employee Id"
              />
            </Form.Item>
            <Form.Item label="Mobile Number" name="mobilenumber">
              <Input className="m-login-input" placeholder="Mobile Number" />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Item
              label="Location"
              name="location"
              style={{ paddingRight: '5px' }}
            >
              <Input className="m-login-input" placeholder="Location" />
            </Form.Item>
            <Form.Item label="Residential Address" name="residential_address">
              <Input
                className="m-login-input"
                placeholder="Residential Address"
              />
            </Form.Item>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              marginTop: '15px',
            }}
          >
            <Form.Item>
              <Button htmlType="cancel">Cancel</Button>
            </Form.Item>
            <Form.Item>
              <Button
                className="ant-btn"
                style={{ marginLeft: 10 }}
                htmlType="submit"
              >
                Register
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </CommonCompbar>
  );
};
export default CreateBackOfficeUser;
