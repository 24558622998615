import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  create_section_slice,
  get_section,
} from "../SectionSlice/sectionSlice";
import { LoadingOutlined } from "@ant-design/icons";
import { useFinancialYear } from "../../../../hooks/useFinancialYear";
import { useCustomer } from "../../../../hooks/useCustomer";
import {
  Divider,
  Spin,
  message,
  Button,
  Input,
  Row,
  Col,
  Form,
  Card,
  Space,
  Select,
} from "antd";
import CP1 from "./CP1";
import CP2 from "./CP1-2";
import CP5 from "./CP1-5";
import CP6 from "./CP1-6";
import CP8 from "./CP1-8";
import CP9 from "./CP1-9";
import CP10 from "./CP1-10";
import AddCP13 from "./CP1-3";
import CommentsApp from "../../../../Components/Comments";
import TextFieldToTextArea from "../../../../Components/Input/textfieldtoarea";
import CommentsNotified from "../../../../Components/Comments/comments-notification";

const { Option } = Select;
const Section_C = (props) => {
  const { current_fy } = useFinancialYear();
  const { assigned_template_id } = useCustomer();
  const [defaultdata, setDefaultdata] = useState({});
  const [spinning, setSpinning] = useState(false);
  const [readonly, setReadonly] = useState(false);
  const [cp1_3, setCP1_3] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [sessionState] = useState(
    JSON.parse(localStorage.getItem("sessionObject"))
  );
  const [commentreload, setCommentReload] = useState();
  const [commentlist, setCommentlist] = useState([]);

  useEffect(() => {
    if (sessionState?.roletype === "CUSTOMER") {
      setReadonly(false);
    } else {
      setReadonly(true);
    }
    let payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      section_name: "section_cp1",
    };
    setSpinning(true);
    dispatch(get_section({ payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setDefaultdata(res.data?.section_cp1);
          setCommentlist(res.data?.comments);
          setSpinning(false);
        } else {
          if (res.code != 800000) {
            message.info(res.msg);
          }

          setSpinning(false);
        }
      })
      .catch((Error) => {
        console.log(Error);
      });
  }, [dispatch,commentreload]);

  useEffect(() => {
    form.setFieldsValue(defaultdata);
    setCP1_3(defaultdata.cp1_3 || []);
  }, [form, defaultdata]);

  const onFinish = (datalist) => {
    setSpinning(true);
    const datalist1 = {
      ...datalist,
      cp1_3,
    };
    let Payload = {
      customer_id: props.customer_id,
      questionaire_template_id: assigned_template_id,
      financial_year: current_fy,
      question_response: datalist1,
      section_name: "section_cp1",
    };
    dispatch(create_section_slice({ Payload }))
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          setSpinning(false);
          message.info("Section CP1 created succesfully!");
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  return (
    <>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 44, color: "white" }} spin />
        }
        tip="Loading..."
        spinning={spinning}
        fullscreen
        size="large"
      />
      <div className="formcontainer">
        <CommentsNotified commentlist={commentlist} />
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={defaultdata}
          scrollToFirstError
          style={{ width: "100%" }}
        >
          {/* {[2, 3, 4].includes(assigned_template_id) && ( */}
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Card title="Essential Indicators,," size="small">
              <CP1
                readonly={readonly}
                data={{ ...defaultdata }}
                form={form}
                ques_id={"CP1-1"}
              />
              <CommentsApp setCommentReload={setCommentReload}
                question_response_id={defaultdata?.question_response_id}
                question_response_name="CP1-1"
              />
            </Card>
          </Space>
          {/* )} */}
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Card title="Essential Indicators" size="small">
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <CP2
                    readonly={readonly}
                    data={{ ...defaultdata }}
                    form={form}
                    ques_id={"CP1-2"}
                  />
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="CP1-2"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP1-3" className="title-header">
                    <Row style={{ width: "100%" }}>
                      <Col span={24}>
                        <div className="space-between">
                          <span>
                            {`Of the instances disclosed in Question above, details of the Appeal/ Revision preferred in cases where monetary or non-monetary action has been appealed.`}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <AddCP13
                          readonly={readonly}
                          data={{ cp1_3 }}
                          setdata={setCP1_3}
                          form={form}
                          ques_id={"cp1_3"}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="CP1-3"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP1-4" className="title-header">
                    <Row style={{ width: "100%" }}>
                      <Col span={8}>
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "700",
                            lineHeight: "1px",
                          }}
                        >{`Does the entity have an anti-corruption or anti-bribery policy? If yes, provide details in brief and if available, provide a web-link to the policy.`}</span>
                      </Col>
                      <Col span={16}>
                        <div style={{ display: "flex" }}>
                          <Form.Item name={["CP1-4", "r1c1"]}>
                            <Select
                              disabled={readonly}
                              style={{ width: "250px", marginRight: "10px" }}
                              placeholder="Select Option"
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item name={["CP1-4", "r1c2"]}>
                            <TextFieldToTextArea props={props} />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="CP1-4"
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <CP5
                    readonly={readonly}
                    data={{ ...defaultdata }}
                    form={form}
                    ques_id={"CP1-5"}
                  />
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="CP1-5"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <CP6
                    readonly={readonly}
                    data={{ ...defaultdata }}
                    form={form}
                    ques_id={"CP1-6"}
                  />
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="CP1-6"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <Form.Item label="CP1-7" className="title-header">
                    <Row>
                      <Col span={8}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Provide details of any corrective action taken or underway on issues related to fines / penalties / action taken by regulators/ law enforcement agencies/ judicial institutions, on cases of corruption and conflicts of interest.`}
                        </span>
                      </Col>
                      <Col span={16}>
                        <Form.Item name={["CP1-7", "r1c1"]}>
                          <Input.TextArea
                            readOnly={readonly}
                            rows={4}
                            placeholder="Provide details of any corrective action taken"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="CP1-7"
                  />
                  <Divider />
                </>
              )}
              {[1, 2, 3, 4].includes(assigned_template_id) && (
                <>
                  <CP8
                    readonly={readonly}
                    data={{ ...defaultdata }}
                    form={form}
                    ques_id={"CP1-8"}
                  />
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="CP1-8"
                  />
                  <Divider />
                </>
              )}
              {[2, 3, 4].includes(assigned_template_id) && (
                <>
                  <CP9
                    readonly={readonly}
                    data={{ ...defaultdata }}
                    form={form}
                    ques_id={"CP1-9"}
                  />
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="CP1-9"
                  />
                </>
              )}
            </Card>
          </Space>
          {[3, 4].includes(assigned_template_id) && (
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Card title="Leadership Indicators" size="small">
                <>
                  <CP10
                    readonly={readonly}
                    data={{ ...defaultdata }}
                    form={form}
                    ques_id={"CP1-10"}
                  />
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="CP1-10"
                  />
                  <Divider />
                </>
                <>
                  <Form.Item label="CP1-11" className="title-header">
                    <Row>
                      <Col span={10}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Does the entity have processes in place to avoid/ manage conflict of interests involving members of the Board? (Yes/No) If Yes, provide details of the same.`}
                        </span>
                      </Col>
                      <Col span={10}>
                        <Form.Item name={["CP1-11", "r1c1"]}>
                          <Input.TextArea
                            readOnly={readonly}
                            rows={4}
                            placeholder="Provide details of any corrective action taken"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="CP1-11"
                  />
                </>
              </Card>
            </Space>
          )}
          {/* <h2>Essential Indicators</h2> */}
          {[4].includes(assigned_template_id) && (
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Card title="Leadership Indicators" size="small">
                <>
                  <Form.Item label="CP1-12" className="title-header">
                    <Row>
                      <Col span={24}>
                        <span style={{ fontSize: "12px", fontWeight: "700" }}>
                          {`Details on confirmed incidents of corruption and actions taken:`}
                        </span>
                      </Col>
                      <Row style={{ width: "100%" }}>
                        <Col span={8}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Total number of confirmed incidents when contracts with business partners were terminated or not renewed due to viloations related to corruption`}
                          </span>
                        </Col>
                        <Col span={16}>
                          <Form.Item name={["CP1-12", "r1c1"]}>
                            <Input readOnly={readonly} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Row>
                  </Form.Item>
                  <CommentsApp setCommentReload={setCommentReload}
                    question_response_id={defaultdata?.question_response_id}
                    question_response_name="CP1-12"
                  />
                  <Divider />
                </>

                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP1-13" className="title-header">
                      <Row>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Process to remediate negative impact:`}
                          </span>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`A: Describe commitments to provide for or cooperate in the remediation of negative impacts that the organization identifies it has caused or contributed to`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-13", "r1c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`B: describe other processes by which the organization provides for or cooperates in the remediation of negative impacts that it identifies it has caused or contributed to`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-13", "r2c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="CP1-13"
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP1-14" className="title-header">
                      <Row>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Approach to tax`}
                          </span>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`A: Whether the organization has a tax strategy and, if so, a link to this strategy if publicly available`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-14", "r1c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`B: Description of the governance body or executive-level position within the organization that formally reviews and approves the tax strategy, and the frequency of this review`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-14", "r2c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`C: Description of the approach to regulatory compliance`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-14", "r3c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`D: how the approach to tax is linked to the business and sustainable development strategies of the organization.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-14", "r4c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="CP1-14"
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP1-15" className="title-header">
                      <Row>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Tax governance, control, and risk management`}
                          </span>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: A description of the tax governance and control framework, including:`}
                          </div>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`i. the governance body or executive-level position within the organization accountable for compliance with the tax strategy`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-15", "r1c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`ii. How the approach to tax is embedded within the organization`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-15", "r2c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`iii. The approach to tax risks, including how risks are identified, managed, and monitored`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-15", "r3c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`iv. How compliance with the tax governance and control framework is evaluated.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-15", "r4c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`B: A description of the mechanisms to raise concerns about the organization’s business conduct and the organization’s integrity in relation to tax.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-15", "r5c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`C: A description of the assurance process for disclosures on tax including, if applicable, a link or reference to the external assurance report(s) or assurance statement(s)`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-15", "r6c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="CP1-15"
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP1-16" className="title-header">
                      <Row>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Stakeholder engagement and management of concerns related to tax`}
                          </span>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: A description of the approach to stakeholder engagement and management of stakeholder concerns related to tax, including:`}
                          </div>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`i. The approach to engagement with tax authorities`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-16", "r1c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`ii. The approach to public policy advocacy on tax`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-16", "r2c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`iii. The processes for collecting and considering the views and concerns of stakeholders, including external stakeholders.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-16", "r3c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="CP1-16"
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP1-17" className="title-header">
                      <Row>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Country-by-country reporting`}
                          </span>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`[A]: All tax jurisdictions where the entities included in the organization’s audited consolidated financial statements, or in the financial information filed on public record, are resident for tax purposes.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-17", "r1c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={24}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`[B]: For each tax jurisdiction reported in earlier entries`}
                            </span>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`i. Names of the resident entities`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-17", "r2c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`ii. Primary activities of the organization`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-17", "r3c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`iii. Number of employees, and the basis of calculation of this number`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-17", "r4c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`iv. Revenues from third-party sales`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-17", "r5c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`v. Revenues from intra-group transactions with other tax jurisdictions`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-17", "r6c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`vi. Profit/loss before tax`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-17", "r7c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`vii. Tangible assets other than cash and cash equivalents`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-17", "r8c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`viii. Corporate income tax paid on a cash basis`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-17", "r9c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`ix. Corporate income tax accrued on profit/loss`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-17", "r10c1"]}>
                              <Input readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`x. Reasons for the difference between corporate income tax accrued on profit/loss and the tax due if the statutory tax rate is applied to profit/loss before tax.`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-17", "r11c1"]}>
                              <Input.TextArea
                                readOnly={readonly}
                                rows={2}
                                placeholder="Enter Reasons"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`[C]: The time period covered by the information reported in earlier entries`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-17", "r12c1"]}>
                              <Input.TextArea rows={2} readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="CP1-17"
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP1-18" className="title-header">
                      <Row>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Nomination and selection of the highest governance body:`}
                          </span>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`[A]: Describe the nomination and selection processes for the highest governance body and its committees`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-18", "r1c1"]}>
                              <Input.TextArea rows={2} readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`[B]: Describe the criteria used for nominating and selecting highest governance body members, including whether and how the following are taken into consideration`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-18", "r2c1"]}>
                              <Input.TextArea rows={2} readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`[C]: Competencies relevant to the impacts of the organization`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <Form.Item name={["CP1-18", "r3c1"]}>
                              <Input.TextArea rows={2} readOnly={readonly} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="CP1-18"
                    />
                    <Divider />
                  </>
                )}
                {[4].includes(assigned_template_id) && (
                  <>
                    <Form.Item label="CP1-19" className="title-header">
                      <Row style={{ width: "100%" }}>
                        <Col span={24}>
                          <span style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`Details on conflicts of interest`}
                          </span>
                          <div style={{ fontSize: "12px", fontWeight: "700" }}>
                            {`[A]: report whether conflicts of interest are disclosed to stakeholders, including, at a minimum, conflicts of interest relating to:`}
                          </div>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`i. Cross-board membership`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <div style={{ display: "flex" }}>
                              <Form.Item name={["CP1-19", "r1c1"]}>
                                <Select
                                  disabled={readonly}
                                  style={{
                                    width: "250px",
                                    marginRight: "10px",
                                  }}
                                  placeholder="Select Option"
                                >
                                  <Option value="Yes">Yes</Option>
                                  <Option value="No">No</Option>
                                </Select>
                              </Form.Item>

                              <Form.Item name={["CP1-19", "r1c2"]}>
                                <Input.TextArea rows={2} readOnly={readonly} />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`ii. Cross-shareholding with suppliers and other stakeholders`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <div style={{ display: "flex" }}>
                              <Form.Item name={["CP1-19", "r2c1"]}>
                                <Select
                                  disabled={readonly}
                                  style={{
                                    width: "250px",
                                    marginRight: "10px",
                                  }}
                                  placeholder="Select Option"
                                >
                                  <Option value="Yes">Yes</Option>
                                  <Option value="No">No</Option>
                                </Select>
                              </Form.Item>

                              <Form.Item name={["CP1-19", "r2c2"]}>
                                <Input.TextArea rows={2} readOnly={readonly} />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`iii. Existence of controlling shareholders`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <div style={{ display: "flex" }}>
                              <Form.Item name={["CP1-19", "r3c1"]}>
                                <Select
                                  disabled={readonly}
                                  style={{
                                    width: "250px",
                                    marginRight: "10px",
                                  }}
                                  placeholder="Select Option"
                                >
                                  <Option value="Yes">Yes</Option>
                                  <Option value="No">No</Option>
                                </Select>
                              </Form.Item>

                              <Form.Item name={["CP1-19", "r3c2"]}>
                                <Input.TextArea rows={2} readOnly={readonly} />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <Row style={{ width: "100%" }}>
                          <Col span={8}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              {`iv. related parties, their relationships, transactions, and outstanding balances`}
                            </span>
                          </Col>
                          <Col span={16}>
                            <div style={{ display: "flex" }}>
                              <Form.Item name={["CP1-19", "r4c1"]}>
                                <Select
                                  disabled={readonly}
                                  style={{
                                    width: "250px",
                                    marginRight: "5px",
                                  }}
                                  placeholder="Select Option"
                                >
                                  <Option value="Yes">Yes</Option>
                                  <Option value="No">No</Option>
                                </Select>
                              </Form.Item>

                              <Form.Item name={["CP1-19", "r4c2"]}>
                                <Input.TextArea rows={2} readOnly={readonly} />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </Form.Item>
                    <CommentsApp setCommentReload={setCommentReload}
                      question_response_id={defaultdata?.question_response_id}
                      question_response_name="CP1-19"
                    />
                  </>
                )}
              </Card>
            </Space>
          )}
          {readonly ? (
            ""
          ) : (
            <Space
              style={{
                //marginTop: "10px",
                marginBottom: "10px",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <Button style={{ marginLeft: 10 }} htmlType="submit">
                Save and Continue
              </Button>
            </Space>
          )}
        </Form>
      </div>
    </>
  );
};

export default Section_C;
