import React, { useEffect } from "react";
import { Row, Col, Form, Input } from "antd";
import FloatInput from "../../../../../Components/Input/numbertofloat";
import InputNumber from "../../../../../Components/Input/Number/number";

const T3A = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.data]);
  return (
    <div>
      <Row>
        <Col span={24}>
          <div id="tbl-cell">
            <Row>
              <Col span={4}></Col>
              <Col
                span={10}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Male
              </Col>
              <Col
                span={10}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Female
              </Col>
            </Row>
            <Row>
              <Col span={4}></Col>
              <Col span={3}>Number</Col>
              <Col span={7}>
                Median remuneration/ salary/ wages of respective category in
                lakhs INR
              </Col>
              <Col span={3}>Number</Col>
              <Col span={7}>
                Median remuneration/ salary/ wages of respective category in
                lakhs INR
              </Col>
            </Row>
            <Row>
              <Col span={4}>Board of Directors (BoD)</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r1c1"]} className="ant-col1">
                  <InputNumber
                    readOnly={props.readonly}
                    placeholder="Enter"
                    type="number"
                    min="0"
                  /> 
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name={[props.ques_id, "r1c2"]} className="ant-col1">
                <FloatInput size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r1c3"]} className="ant-col1">
                <InputNumber readOnly={props.readonly} placeholder="Enter"  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name={[props.ques_id, "r1c4"]} className="ant-col1">
                <FloatInput size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Key Managerial Personnel</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r2c1"]} className="ant-col1">
                <InputNumber readOnly={props.readonly} placeholder="Enter"  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name={[props.ques_id, "r2c2"]} className="ant-col1">
                <FloatInput size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r2c3"]} className="ant-col1">
                <InputNumber readOnly={props.readonly} placeholder="Enter"  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name={[props.ques_id, "r2c4"]} className="ant-col1">
                <FloatInput size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Employees other than BoD and KMP</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r3c1"]} className="ant-col1">
                <InputNumber readOnly={props.readonly} placeholder="Enter"  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name={[props.ques_id, "r3c2"]} className="ant-col1">
                <FloatInput size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r3c3"]} className="ant-col1">
                <InputNumber readOnly={props.readonly} placeholder="Enter"  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name={[props.ques_id, "r3c4"]} className="ant-col1">
                <FloatInput size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Workers</Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r4c1"]} className="ant-col1">
                <InputNumber readOnly={props.readonly} placeholder="Enter"  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name={[props.ques_id, "r4c2"]} className="ant-col1">
                <FloatInput size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[props.ques_id, "r4c3"]} className="ant-col1">
                <InputNumber readOnly={props.readonly} placeholder="Enter"  type="number" min="0"/>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name={[props.ques_id, "r4c4"]} className="ant-col1">
                <FloatInput size="small" readOnly={props.readonly} />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default T3A;
