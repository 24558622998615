import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createTicket,
  getTicketsList,
  updateTicket,
  createTicketConversations,
  getTicketConversationsInfo,
} from "../../../Config/actions";

const createTicketSlice = createAsyncThunk(
  "ticket/createTicketUser",
  async (value) => {
    return await createTicket(value);
  }
);
const fetchTickets = createAsyncThunk(
  "ticket/lsiting",
  async () => {
    const response = await getTicketsList();
    return response.data;
  }
);
const updateTicketsSlice = createAsyncThunk(
  "ticket/update",
  async (value) => {
    return await updateTicket(value);
  }
);
const createTicketConversationsSlice = createAsyncThunk(
  "ticket/conversations",
  async (value) => {
    return await createTicketConversations(value);
  }
);
const fetchticketConversationsInfo = createAsyncThunk(
  "ticket/conversations/info",
  async (ticket_id, thunkAPI) => {
    const response = await getTicketConversationsInfo(ticket_id);
    return response.data;
  }
);
export {
  createTicketSlice,
  fetchTickets,
  updateTicketsSlice,
  createTicketConversationsSlice,
  fetchticketConversationsInfo,
};
