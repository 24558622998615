import React, { useEffect, useState } from "react";
import { Divider, Row, Col, Form, Input, Select } from "antd";
import InputNumber from "../../../../Components/Input/Number/number";
import CommentsApp from "../../../../Components/Comments";

const { Option } = Select;
const CP3_2 = (props) => {
  useEffect(() => {
    props.form.setFieldsValue(props.data);
  }, [props.form, props.data]);

  return (
    <div id="tbl-cell">
      <Row>
        <Col span={6} className="text-center">
          <span
            style={{ fontSize: "12px", fontWeight: "600" }}
          >{`Benefits`}</span>
        </Col>
        <Col span={9} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            FY :{props?.data?.current_fy} Current Financial Year
          </span>
        </Col>
        <Col span={9} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            FY: {props?.data?.previous_fy} Previous Financial Year
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <span style={{ fontSize: "12px", fontWeight: "600" }}></span>
        </Col>
        <Col span={3} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`No. of employees covered as a % of total employees`}
          </span>
        </Col>
        <Col span={3} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`No. of workers covered as a % of total workers	`}
          </span>
        </Col>
        <Col span={3} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`Deducted and deposited with the authority (Y/N/N.A.)`}
          </span>
        </Col>
        <Col span={3} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`No. of employees covered as a % of total employees`}
          </span>
        </Col>
        <Col span={3} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`No. of workers covered as a % of total workers`}
          </span>
        </Col>
        <Col span={3} className="text-center">
          <span style={{ fontSize: "10px", fontWeight: "700" }}>
            {`Deducted and deposited with the authority (Y/N/N.A.)`}
          </span>
        </Col>
      </Row>
      {/* =============== */}
      <Row>
        <Col span={6} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>{`PF`}</span>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c1"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c2"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c3"]}>
            <Select placeholder="Select Option" disabled={props.readonly}>
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
              <Option value="NA">NA</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c4"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c5"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r1c6"]}>
            <Select placeholder="Select Option" disabled={props.readonly}>
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
              <Option value="NA">NA</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>Gratuity</span>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c1"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c2"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c3"]}>
            <Select placeholder="Select Option" disabled={props.readonly}>
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
              <Option value="NA">NA</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c4"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c5"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r2c6"]}>
            <Select placeholder="Select Option" disabled={props.readonly}>
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
              <Option value="NA">NA</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>ESI</span>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r3c1"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r3c2"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r3c3"]}>
            <Select placeholder="Select Option" disabled={props.readonly}>
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
              <Option value="NA">NA</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r3c4"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r3c5"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r3c6"]}>
            <Select placeholder="Select Option" disabled={props.readonly}>
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
              <Option value="NA">NA</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6} className="text-center">
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            Others – please specify
          </span>
        </Col>
        <Col span={3}>
          <Form.Item name={[props.ques_id, "r4c1"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r4c2"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r4c3"]}>
            <Select placeholder="Select Option" disabled={props.readonly}>
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
              <Option value="NA">NA</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r4c4"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r4c5"]}>
            <InputNumber readOnly={props.readonly} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={[props.ques_id, "r4c6"]}>
            <Select placeholder="Select Option" disabled={props.readonly}>
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
              <Option value="NA">NA</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default CP3_2;
